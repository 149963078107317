<template>
  <div :class="b({ variant })">
    <component :is="label ? 'e-label' : 'span'" :name="label">
      <e-input
        v-model="query"
        :class="b('search-input')"
        :placeholder="placeholder || $t('e-search.placeholder')"
        :aria-label="label ? undefined : placeholder || $t('e-search.placeholder')"
        :autofocus="autofocus || undefined"
        :disabled="disabled"
        name="query"
        @enter="onInputEnter"
      />
    </component>
    <div :class="b('action')">
      <button v-if="query"
              :class="b('reset-search')"
              :aria-label="$t('e-search.buttonResetSearch')"
              :disabled="disabled"
              type="button"
              @click="clearSearch"
      >
        <e-icon icon="i-close" size="24" />
      </button>
      <e-icon v-else icon="i-search" size="24" />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import eInput from '@/elements/e-input.vue';
  import eIcon from '@/elements/e-icon.vue';
  import eLabel from '@/elements/e-label.vue';

  // interface Setup {}

  // interface Data {}

  /**
   * Renders a search input field and a button to clear the input field.
   */
  export default defineComponent({
    name: 'e-search',

    components: {
      eLabel,
      eInput,
      eIcon,
    },

    props: {
      /**
       * Expects the search query string.
       */
      modelValue: {
        type: String,
        default: '',
      },

      /**
       * Accepts a custom placeholder for the input field.
       */
      placeholder: {
        type: String,
        default: null,
      },

      /**
       * Allows to set autofocus on the search input.
       * Currently used on the homepage.
       */
      autofocus: {
        type: Boolean,
        default: false,
      },

      /**
       * Sets the variant of the search input field.
       */
      variant: {
        type: String,
        default: 'default',
        validator(value: string) {
          return [
            'default',
            'faq',
            'input',
            'global-search',
          ].includes(value);
        },
      },

      /**
       * Allows to set a label for the search field.
       *
       * Note: because of buttons inside this component, you can not wrap it in a label yourself.
       */
      label: {
        type: String,
        default: undefined,
      },

      /**
       * Allows disabling the search.
       */
      disabled: {
        type: Boolean,
        default: undefined,
      },
    },

    emits: {
      'enter': (payload: string) => typeof payload === 'string',
      'update:modelValue': (payload: string) => typeof payload === 'string',
    },

    // setup(): Setup {},
    // data(): Data {
    //   return {
    //   };
    // },

    computed: {
      /**
       * Gets/Sets the 'modelValue' prop value.
       */
      query: {
        get(): string {
          return this.modelValue;
        },
        set(value: string): void {
          this.$emit('update:modelValue', value);
        },
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {

      /**
       * Clears the search input value.
       */
      clearSearch(): void {
        this.query = '';
      },

      onInputEnter(): void {
        this.$emit('enter', this.query);
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';
  @use '@/setup/scss/mixins';

  .e-search {
    $this: &;

    position: relative;
    display: block;

    &__action {
      position: absolute;
      right: variables.$spacing--2;
      bottom: 0.3em;
      display: block;
      line-height: 0;

      @include mixins.media(sm) {
        right: variables.$spacing--5;
      }

      .e-icon {
        width: 1em;
        height: 1em;
      }
    }

    &__reset-search {
      cursor: pointer;

      .e-icon {
        width: 0.8em;
        height: 0.8em;
        margin-bottom: 0.1em;
      }
    }

    &--variant-faq {
      #{$this}__search-input {
        height: 36px;
        padding-right: variables.$spacing--45;
        padding-left: variables.$spacing--0;
        border-width: 3px;
        border-top: 0;

        &::placeholder {
          font-weight: variables.$font-weight--bold;
        }
      }

      #{$this}__action {
        right: variables.$spacing--5;
      }
    }

    &--variant-global-search {
      @include mixins.font(variables.$font-size--25);

      #{$this}__search-input {
        height: 60px;
        padding-right: variables.$spacing--55;
        padding-left: variables.$spacing--25;
        border-top: 1px solid variables.$color-grayscale--0;
        border-bottom: 1px solid variables.$color-grayscale--0;
        font-size: inherit;

        @include mixins.media(md) {
          height: 36px;
          padding-right: variables.$spacing--45;
          padding-left: variables.$spacing--0;
          border-width: 3px;
          border-top: 0;
        }

        &::placeholder {
          font-weight: variables.$font-weight--bold;
        }
      }

      #{$this}__action {
        @include mixins.media($down: sm) {
          inset: 50% variables.$spacing--25 auto auto;
          transform: translateY(-50%);
        }
      }
    }
  }
</style>
