<template>
  <div :class="b()">
    <e-picture v-bind="ePictureAttributes"
               ref="image"
               :fetch-priority="isLcpCandidate ? 'high' : 'auto'"
               :loading="isLcpCandidate ? 'eager' : 'lazy'"
               @mouseenter.passive="showMagnification"
               @mouseleave.passive="hideMagnification"
               @mousemove.passive="onMouseMove"
               @load="onImageLoad"
    />
    <div v-if="isMagnificationVisible"
         :class="b('indicator')"
         :style="{ ...cursorPosition, ...indicatorSize }"
    ></div>
    <Teleport v-if="isMagnificationVisible" :to="teleportTo">
      <div ref="magnification" :class="b('magnification')">
        <e-picture v-bind="ePictureAttributes"
                   :class="b('magnified-image')"
                   :sizes="magnificationSizes"
                   :style="magnificationStyle"
                   :placeholder="false"
                   loading="eager"
                   @load="onMagnificationLoad"
        />
      </div>
    </Teleport>
  </div>
</template>

<script lang="ts">
  import {
    defineComponent,
    Ref,
    ref,
  } from 'vue';
  import ePicture from '@/elements/e-picture.vue';
  import { ImageSizes } from '@/types/sizes';

  interface Setup {
    image: Ref<typeof ePicture | undefined>;
    magnification: Ref<HTMLDivElement | undefined>;
    magnificationSizes: ImageSizes;
  }

  interface Data {
    hasImageLoaded: boolean;
    isMagnificationVisible: boolean;
    mousePositionOnImageX: number;
    mousePositionOnImageY: number;
    imageWidth: number;
    imageHeight: number;
    magnificationImageWidth: number;
    magnificationImageHeight: number;
    magnificationContainerWidth: number;
    magnificationContainerHeight: number;
  }

  interface SupportedPictureProps { // Hint: tried to use ExtractPublicPropTypes, but it did not return a useful type.
    srcset: string;
    alt: string;
    fallback: string;
    ratio?: number;
    sizes?: Partial<ImageSizes>;
    width?: number;
    height?: number;
  }

  interface Style {
    width: string;
    height: string;
    left: string;
    top: string;
    transform: string;
  }

  interface FocalPointPositionInPercentage {
    top: number;
    left: number;
  }

  /**
   * Renders an e-picture element with the possibility to magnify on hover.
   */
  export default defineComponent({
    name: 'c-magnifier-picture',

    components: { ePicture },

    props: {
      /* eslint-disable vue/require-prop-comment */
      srcset: ePicture.props.srcset,
      alt: ePicture.props.alt,
      fallback: ePicture.props.fallback,
      ratio: ePicture.props.ratio,
      sizes: ePicture.props.sizes,
      width: ePicture.props.width,
      height: ePicture.props.height,
      /* eslint-enable vue/require-prop-comment */

      /**
       * Allows to disable the magnifier functionality.
       */
      disabled: {
        type: Boolean,
        default: false,
      },

      /**
       * Allows to define an alternative <Teleport> name.
       */
      teleportTo: {
        type: String,
        default: 'body',
      },

      /**
       * Allows defining if the current item is a potential LCP candidate.
       */
      isLcpCandidate: {
        type: Boolean,
        default: false,
      },
    },
    // emits: {},

    setup(): Setup {
      return {
        image: ref(),
        magnification: ref(),
        magnificationSizes: {
          xxs: 479,
          fallback: 1440,
        },
      };
    },
    data(): Data {
      return {
        hasImageLoaded: false,
        isMagnificationVisible: false,
        mousePositionOnImageX: 0,
        mousePositionOnImageY: 0,
        imageWidth: 0,
        imageHeight: 0,
        magnificationImageWidth: 0,
        magnificationImageHeight: 0,
        magnificationContainerWidth: 0,
        magnificationContainerHeight: 0,
      };
    },

    computed: {
      ePictureAttributes(): SupportedPictureProps {
        return {
          srcset: this.srcset,
          alt: this.alt,
          fallback: this.fallback,
          ratio: this.ratio,
          sizes: this.sizes,
          width: this.width,
          height: this.height,
        };
      },

      focalPointPositionInPercentage(): FocalPointPositionInPercentage {
        return {
          left: (100 / this.imageWidth) * this.mousePositionOnImageX,
          top: (100 / this.imageHeight) * this.mousePositionOnImageY,
        };
      },

      magnificationStyle(): Pick<Style, 'transform'> {
        const {
          magnificationImageWidth,
          magnificationImageHeight,
          magnificationContainerWidth,
          magnificationContainerHeight,
        } = this;
        const {
          left,
          top,
        } = this.focalPointPositionInPercentage;
        // Yes, the division needs to be in a different order, based on direction. I don't know why...
        const maxHorizontalShift = Math.abs(1 - (magnificationImageWidth / magnificationContainerWidth));
        const maxVerticalShift = Math.abs(1 - (magnificationContainerHeight / magnificationImageHeight));

        return {
          transform: `translate(-${maxHorizontalShift * left}%, -${maxVerticalShift * top}%)`,
        };
      },

      cursorPosition(): Pick<Style, 'top' | 'left' | 'transform'> {
        const {
          left,
          top,
        } = this.focalPointPositionInPercentage;

        return {
          left: `${left}%`,
          top: `${top}%`,
          transform: `translate(-${left}%, -${top}%)`,
        };
      },

      indicatorSize(): Pick<Style, 'width' | 'height'> {
        const width = (this.magnificationContainerWidth / this.magnificationImageWidth) * this.imageWidth || 0;
        const height = (this.magnificationContainerHeight / this.magnificationImageHeight) * this.imageHeight || 0;

        return {
          width: width ? `${width}px` : '',
          height: height ? `${height}px` : '',
        };
      },
    },
    watch: {
      /**
       * Makes sure, the magnification is no longer visible, when the component gets disabled.
       */
      disabled(disabled: boolean): void {
        if (disabled) {
          this.hideMagnification();
        }
      },
    },

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    mounted() {
      window.addEventListener('resizeend', this.onResizeEnd);
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      showMagnification(): void {
        if (!this.disabled) {
          this.isMagnificationVisible = true;
        }
      },

      hideMagnification(): void {
        this.isMagnificationVisible = false;
      },

      onMouseMove(event: MouseEvent): void {
        const {
          offsetX: sourcePositionX,
          offsetY: sourcePositionY,
        } = event;

        this.mousePositionOnImageX = sourcePositionX;
        this.mousePositionOnImageY = sourcePositionY;
      },

      onMagnificationLoad(event: Event): void {
        const { target } = event;

        if (!target) {
          return;
        }

        this.$nextTick(() => {
          const magnificationImageStyles = window.getComputedStyle(target as Element);

          this.hasImageLoaded = true;

          this.magnificationImageWidth = parseInt(magnificationImageStyles.width, 10);
          this.magnificationImageHeight = parseInt(magnificationImageStyles.height, 10);

          this.updateMagnificationContainerDimensions();
        });
      },

      updateMagnificationContainerDimensions(): void {
        if (!this.magnification) {
          return;
        }

        const magnificationStyles = window.getComputedStyle(this.magnification);

        this.magnificationContainerWidth = parseInt(magnificationStyles.width, 10);
        this.magnificationContainerHeight = parseInt(magnificationStyles.height, 10);
      },

      updateImageDimensions(): void {
        const image = this.image?.$el.querySelector('img');

        if (!image) {
          return;
        }

        const imageStyles = window.getComputedStyle(image);

        this.imageWidth = parseInt(imageStyles.width, 10);
        this.imageHeight = parseInt(imageStyles.height, 10);
      },

      onResizeEnd(): void {
        this.updateMagnificationContainerDimensions();
      },

      onImageLoad(): void {
        this.$nextTick(() => this.updateImageDimensions());
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';

  .c-magnifier-picture {
    position: relative;

    &__magnification {
      max-width: 100%;
      max-height: 100%;
      overflow: hidden;

      body > & {
        position: fixed;
        top: 50vh;
        left: 50vw;
        width: 50vmin;
        height: 50vmin;
        transform: translate(-50%, -50%);
      }
    }

    &__magnified-image {
      position: relative;

      &,
      img {
        width: initial;
        max-width: none;
        height: initial;
        max-height: none;
      }
    }

    &__indicator {
      position: absolute;
      background: rgba(variables.$color-grayscale--1000, 0.4);
      pointer-events: none; // Prevents the element from blocking mouse events.
      aspect-ratio: 1;
    }
  }
</style>
