<template>
  <div :class="b(componentModifiers)">
    <div :class="b('inner')">
      <div :class="b('message')">
        {{ notification.message }}
      </div>
      <button :class="b('close-button')"
              :aria-label="$t('c-notification.buttonClose')"
              type="button"
              @click="close"
      >
        <e-icon icon="i-close" size="13" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { Modifiers } from '@/plugins/vue-bem-cn/src/globals';
  import notificationStore, { MappedNotificationItem } from '@/stores/notification';
  import eIcon from '@/elements/e-icon.vue';

  interface Setup {
    notificationStore: ReturnType<typeof notificationStore>;
  }

  interface Data {
    expireDelay: number;
  }

  /**
   * Notification component to be used within c-notification-container. See /styleguide/notifications for demo.
   */
  export default defineComponent({
    name: 'c-notification',

    components: {
      eIcon,
    },

    props: {
      /**
       * The notification object consisting of the following properties:
       */
      notification: {
        type: Object as PropType<MappedNotificationItem>,
        required: true,
      },
    },

    setup(): Setup {
      return {
        notificationStore: notificationStore(),
      };
    },
    data(): Data {
      return {
        /**
         * Defines the delay a notification expires in Milliseconds.
         */
        expireDelay: 3000,
      };
    },

    computed: {
      /**
       * Returns all modifiers for the component main class.
       */
      componentModifiers(): Modifiers {
        return {
          type: this.notification.type,
        };
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    mounted() {
      const { expire, expireDelay } = this.notification || {};

      if (expire) {
        setTimeout(() => {
          this.close();
        }, expireDelay || this.expireDelay);
      }
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      /**
       * Removes current notification from stack.
       */
      close(): void {
        this.notificationStore.popNotification(this.notification.id);
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '../setup/scss/mixins';
  @use '../setup/scss/variables';

  .c-notification {
    position: relative;
    padding: variables.$spacing--10 0;
    color: variables.$color-grayscale--1000;

    &__inner {
      @include mixins.container();

      display: flex;
      align-items: center;
    }

    &--type-success {
      background-color: variables.$color-status--success;
    }

    &--type-info {
      background-color: variables.$color-status--info;
    }

    &--type-error {
      background-color: variables.$color-status--error;
    }

    &__message {
      @include mixins.font(variables.$font-size--16, null, variables.$font-weight--bold);

      display: flex;
      flex: 1 1 auto;
      justify-content: center;
      align-items: center;
      padding-right: variables.$spacing--30;
    }

    &__close-button {
      position: relative;
      flex: 0;
      padding: variables.$spacing--5;
      cursor: pointer;
      color: inherit;

      &:hover::after,
      &:focus::after {
        position: absolute;
        content: '';
        border-bottom: 2px solid variables.$color-grayscale--1000;
        inset: auto variables.$spacing--2 0;
      }
    }
  }
</style>
