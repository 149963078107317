import { Price } from '@/types/price';
import i18n from '@/setup/i18n';

/**
 * Returns the base price unit of a price.
 */
export default function getBasePriceUnit(price?: Price | null): string | null {
  if (!price) {
    return null;
  }

  const unit = price.unitFactor && price.unitFactor > 1
    ? [price.unitFactor, price.unit].join(' ')
    : price.unit;

  return i18n.global.t('global.price.unit', { unit });
}
