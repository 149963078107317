<template>
  <div :class="b()">
    <div v-if="features" :class="b('specifications')">
      <h4>
        {{ $t('c-product-variant-detail.additionalInformation') }}
      </h4>
      <table :class="b('features')">
        <tr v-for="(value, key) in features" :key="key">
          <!-- eslint-disable vue/no-v-html -->
          <th v-html="key" :class="b('feature-label')"></th>
          <td v-html="value" :class="b('feature-value')"></td>
          <!-- eslint-enable vue/no-v-html -->
        </tr>
      </table>
    </div>
    <div :class="b('details')">
      <dl v-if="product.alternateQuantities?.length"
          :class="b('alternative-quantity-wrapper')"
      >
        <div>
          <h4 :class="b('section-title')">
            {{ $t('global.priceUnit') }}
          </h4>
          {{ priceUnit }}
        </div>
        <div>
          <h4 :class="b('section-title')">
            {{ $t('global.quantityUnit') }}
          </h4>
          <c-product-quantities :product="product" />
        </div>
      </dl>
      <div v-if="!sessionStore.getShowOnlyRecommendedRetailPrice && showVolumePrices"
           :class="b('volume-prices')"
      >
        <c-volume-prices :product="product" />
      </div>
      <div :class="b('stock-info')">
        <h4 :class="b('section-title')">
          {{ $t('global.deliveryAndPickupStock') }}
        </h4>
        <c-product-stock :class="b('stock')"
                         :product="product"
                         variant="vertical"
        />
      </div>
    </div>
    <div v-if="sessionStore.flags.showCadData && mediaLinks?.length" :class="b('media-links')">
      <h4>{{ $t('global.cadData') }}</h4>
      <c-media-links :media-links="mediaLinks"
                     :product="product"
                     add-download-attribute
      />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import useSessionStore from '@/stores/session';
  import cProductQuantities from '@/components/c-product-quantities.vue';
  import cProductStock from '@/components/c-product-stock.vue';
  import cMediaLinks from '@/components/c-media-links.vue';
  import cVolumePrices from '@/components/c-volume-prices.vue';
  import { StockState } from '@/helpers/map-stock-indicator';
  import getBasePriceUnit from '@/helpers/get-base-price-unit';
  import mapCadDataToMediaLinks from '@/helpers/map-cad-data-to-media-links';
  import { Product } from '@/types/product';
  import { ProductMediaLink } from '@/types/product-media-link';

  export type MappedProductFeature = {
    [key: string]: string;
  }

  export type MappedProductVariant = {
    id: string;
    features?: MappedProductFeature;
    isPromoted: boolean;
    product: Product;
    stockState?: StockState;
    selected?: boolean;
    priceUnit?: string;
  }

  type VariantFeature = {
    [key: keyof MappedProductFeature]: string;
  }

  type Setup = {
    sessionStore: ReturnType<typeof useSessionStore>;
  }

  // type Data = {}

  /**
   * Renders a product variant info for the product variant table.
   */
  export default defineComponent({
    name: 'c-product-variant-detail',

    components: {
      cVolumePrices,
      cMediaLinks,
      cProductQuantities,
      cProductStock,
    },

    props: {
      /**
       * Expects a mapped Product variant.
       */
      mappedProductVariant: {
        type: Object as PropType<MappedProductVariant>,
        required: true,
      },

      /**
       * Accepts an array of features, that should be excluded from the feature list.
       */
      excludeFeatures: {
        type: Array as PropType<string[]>,
        default: () => [],
      },
    },
    // emits: {},

    setup(): Setup {
      return {
        sessionStore: useSessionStore(),
      };
    },
    // data(): Data {
    //   return {};
    // },

    computed: {
      product() {
        return this.mappedProductVariant.product;
      },

      showVolumePrices(): boolean {
        const { volumePrices } = this.product;

        return !!volumePrices && volumePrices?.length > 1;
      },

      features(): VariantFeature | undefined {
        if (!this.mappedProductVariant.features) {
          return undefined;
        }

        const features = Object.entries(this.mappedProductVariant.features)
          .reduce((accumulator, [key, value]): VariantFeature => {
            if (!this.excludeFeatures.includes(key)) {
              accumulator[key] = value;
            }

            return accumulator;
          }, {} as VariantFeature);

        return Object.keys(features).length ? features : undefined;
      },

      priceUnit() {
        const { product } = this.mappedProductVariant;

        return getBasePriceUnit(product.specialPrice || product.price);
      },

      mediaLinks(): ProductMediaLink[] {
        const { variantCADData } = this.product;

        if (!variantCADData) {
          return [];
        }

        return mapCadDataToMediaLinks(variantCADData);
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-product-variant-detail {
    $grid-area--specifications: specifications;
    $grid-area--stock: stock;
    $this: &;

    display: grid;
    grid-template-areas: '#{$grid-area--specifications}' '#{$grid-area--stock}';
    padding-block: variables.$spacing--20;
    gap: variables.$spacing--30 variables.$spacing--20;

    @include mixins.media(sm) {
      grid-template-areas: '#{$grid-area--specifications} #{$grid-area--stock}';
      grid-template-columns: 1fr 350px;
    }

    @include mixins.media($media: print) {
      grid-template-columns: 1fr minmax(auto, 1fr);
    }

    &__specifications {
      grid-area: $grid-area--specifications;
    }

    &__features {
      tr:not(:last-child) {
        td,
        th {
          padding-bottom: variables.$spacing--5;
        }
      }
    }

    &__feature-label::after {
      content: ':';
    }

    &__feature-value {
      padding-left: variables.$spacing--20;
      font-weight: variables.$font-weight--bold;
    }

    &__details {
      display: grid;
      grid-area: $grid-area--stock;
      gap: variables.$spacing--40;
    }

    &__section-title {
      margin-bottom: variables.$spacing--10;
    }

    &__alternative-quantity-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    &__media-links {
      @include mixins.media($media: print) {
        display: none;
      }
    }

    &__stock {
      align-items: start;
    }
  }
</style>
