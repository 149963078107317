import decodeBase64 from '@/helpers/decode-base64';
import { User } from '@/types/user';

/**
 * Normalizes the user object delivered from the Backend (with base64 encoded email address).
 */
export default function normalizeUser(user: User): User {
  return {
    ...user,
    email: decodeBase64(user.email),
  };
}
