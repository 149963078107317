<template>
  <div :class="b({ loading })">
    <ul v-if="primaryFacets.length" :class="b('list', { primary: true, })">
      <template v-for="facet in primaryFacets" :key="facet.code">
        <li :class="b('list-item')">
          <component :is="FACET_COMPONENT_MAP[facet.displayType]"
                     :facet="facet"
                     :disabled="loading"
                     @update="$emit('update', $event)"
          />
        </li>
      </template>
    </ul>
    <ul v-if="primaryIconFacets.length" :class="b('icon-list', { primary: true, })">
      <template v-for="facet in primaryIconFacets" :key="facet.code">
        <li :class="b('icon-list-item')">
          <component :is="FACET_COMPONENT_MAP[facet.displayType]"
                     :facet="facet"
                     :disabled="loading"
                     @update="$emit('update', $event)"
          />
        </li>
      </template>
    </ul>
    <ul v-if="availabilityFacets.length" :class="b('list', { secondary: true, })">
      <template v-for="facet in availabilityFacets" :key="facet.code">
        <li :class="b('list-item')">
          <component :is="FACET_COMPONENT_MAP[facet.displayType]"
                     :facet="facet"
                     :disabled="loading"
                     @update="$emit('update', $event)"
          />
        </li>
      </template>
    </ul>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import cFacetMultiSelect from '@/components/c-facet-multi-select.vue';
  import cFacetSelect from '@/components/c-facet-select.vue';
  import cFacetIcon from '@/components/c-facet-icon.vue';
  import cFacetCheckbox from '@/components/c-facet-checkbox.vue';
  import cFacetRangeSlider from '@/components/c-facet-range-slider.vue';
  import filterValidFacets from '@/helpers/filter-valid-facets';
  import { Facet } from '@/types/facet';

  export enum FacetDisplayType {
    MultiSelect = 'CHECKBOX',
    Select = 'SELECT',
    RangeSlider = 'SLIDER',
    Checkbox = 'CHECKBOX_BOOLEAN',
    Icon = 'ICON',
  }

  type FacetComponentMap = {
    [key in FacetDisplayType]: string;
  }

  export const FACET_COMPONENT_MAP: FacetComponentMap = {
    [FacetDisplayType.MultiSelect]: 'c-facet-multi-select',
    [FacetDisplayType.Select]: 'c-facet-select',
    [FacetDisplayType.Icon]: 'c-facet-icon',
    [FacetDisplayType.Checkbox]: 'c-facet-checkbox',
    [FacetDisplayType.RangeSlider]: 'c-facet-range-slider',
  };

  interface Setup {
    FACET_COMPONENT_MAP: FacetComponentMap;
  }
  // interface Data {}

  /**
   * Renders a list of filter facets.
   */
  export default defineComponent({
    name: 'c-filter',
    components: {
      cFacetMultiSelect,
      cFacetSelect,
      cFacetIcon,
      cFacetCheckbox,
      cFacetRangeSlider,
    },

    props: {
      /**
       * Expects a list of facets to be passed.
       */
      facets: {
        type: Array as PropType<Facet[]>,
        required: true,
      },

      /**
       * Allows loading state to be passed.
       */
      loading: {
        type: Boolean,
        default: false,
      },
    },
    emits: {
      update: (payload: string) => true, // eslint-disable-line @typescript-eslint/no-unused-vars
    },

    setup(): Setup {
      return {
        FACET_COMPONENT_MAP,
      };
    },
    // data(): Data {
    //   return {};
    // },

    computed: {
      /**
       * Returns all facets that have a supported display type.
       */
      validFacets(): Facet[] {
        return filterValidFacets(this.facets);
      },

      /**
       * Returns the primary facets.
       */
      primaryFacets(): Facet[] {
        return this.validFacets.filter((facet) => {
          if (facet.filterSection) {
            return false;
          }

          return facet.displayType !== FacetDisplayType.Icon;
        }) || [];
      },

      /**
       * Returns the primary icon facets.
       */
      primaryIconFacets(): Facet[] {
        return this.validFacets.filter(facet => !facet.filterSection && facet.displayType === FacetDisplayType.Icon) || [];
      },

      /**
       * Returns the availability facets.
       * The facets gets sorted so checkbox facets come last.
       */
      availabilityFacets(): Facet[] {
        const availabilityFacets = this.validFacets
          .filter(facet => facet.filterSection === 'SECTION_1') || [];

        return availabilityFacets.sort((a, b) => {
          if (a.displayType === FacetDisplayType.Checkbox && b.displayType !== FacetDisplayType.Checkbox) {
            return 1;
          }

          if (a.displayType !== FacetDisplayType.Checkbox && b.displayType === FacetDisplayType.Checkbox) {
            return -1;
          }

          return 0;
        });
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-filter {
    position: relative;

    &__list,
    &__icon-list {
      & + & {
        margin-top: variables.$spacing--40;
      }
    }

    &__list {
      display: grid;
      grid-gap: variables.$spacing--20;
      grid-template-columns: minmax(0, 1fr);

      @include mixins.media(sm) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @include mixins.media(md) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      @include mixins.media(lg) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }

    &__list-item {
      align-self: end;
    }

    &__icon-list {
      display: flex;
      gap: variables.$spacing--20 variables.$spacing--50;
      flex-wrap: wrap;
    }

    &__loading {
      pointer-events: none;
    }
  }
</style>
