<template>
  <div :class="b({ disabled })">
    <div ref="sliderElement"></div>
    <div v-if="showValues" :class="b('values')">
      <div :class="b('value-lower')">
        <slot name="lowerValue" :lower-value="internalValues[0]">
          {{ internalValues[0] }}
        </slot>
      </div>
      <div :class="b('value-upper')">
        <slot name="upperValue" :upper-value="internalValues[1]">
          {{ internalValues[1] }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import {
    defineComponent,
    PropType,
    Ref,
    ref,
  } from 'vue';
  import noUiSlider, { API } from 'nouislider';

  export type Values = (string | number)[];

  interface Setup {
    sliderElement: Ref<HTMLDivElement>;
  }
  interface Data {
    sliderInstance: API | null;
    internalValues: Values;
  }

  /**
   * Renders a range input element.
   */
  export default defineComponent({
    name: 'e-input-range',

    // components: {},

    props: {
      /**
       * Value passed by v-model
       */
      modelValue: {
        type: Array as PropType<Values>,
        required: true,
      },

      /**
       * Expects minimum value to be passed.
       */
      min: {
        type: Number,
        required: true,
      },

      /**
       * Expects maximum value to be passed.
       */
      max: {
        type: Number,
        required: true,
      },

      /**
       * Allows passing a step interval.
       */
      step: {
        type: Number,
        default: 1,
      },

      /**
       * Allows defining whether the values are shown.
       */
      showValues: {
        type: Boolean,
        default: true,
      },

      /**
       * Allows passing disabled state.
       */
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    emits: {
      'update:modelValue': (payload: Values) => Array.isArray(payload) && payload.length === 2,
    },

    setup(): Setup {
      const sliderElement = ref();

      return {
        sliderElement,
      };
    },
    data(): Data {
      return {
        sliderInstance: null,
        internalValues: this.modelValue,
      };
    },

    // computed: {},
    watch: {
      /**
       * Observes model value for changes from outside and updates slider.
       */
      modelValue(modelValue: Values) {
        this.sliderInstance?.set(modelValue);
      },

      /**
       * Disables slider instance if changed from outside.
       */
      disabled(disabled: boolean) {
        if (disabled) {
          this.sliderInstance?.disable();
        } else {
          this.sliderInstance?.enable();
        }
      },
    },

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    mounted() {
      this.sliderInstance = noUiSlider.create(this.sliderElement, {
        start: this.modelValue,
        connect: true,
        range: {
          min: this.min,
          max: this.max,
        },
        step: this.step,
        handleAttributes: [
          { 'aria-label': this.$t('e-input-range.handleLower') },
          { 'aria-label': this.$t('e-input-range.handleUpper') },
        ],
      });

      this.sliderInstance.on('update', (values) => {
        this.internalValues = values;
      });

      this.sliderInstance.on('change', (values) => {
        this.$emit('update:modelValue', values);
      });

      if (this.disabled) {
        this.sliderInstance.disable();
      }
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    beforeUnmount() {
      this.sliderInstance?.off('update');
      this.sliderInstance?.off('end');
    },
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use 'nouislider/dist/nouislider.css';
  @use 'sass:math';
  @use '@/setup/scss/variables';
  @use '@/setup/scss/mixins';

  .e-input-range {
    $handle-size: 20px;

    padding-top: variables.$spacing--10;
    overflow: hidden;

    &__values {
      @include mixins.font(variables.$font-size--16, null, variables.$font-weight--regular);

      display: flex;
      justify-content: space-between;
      margin-top: variables.$spacing--5;
    }

    &--disabled &__values {
      color: variables.$color-grayscale--400;
    }

    // stylelint-disable selector-class-pattern
    .noUi-target {
      height: 10px;
      border: 0;
      border-radius: 0;
      background: variables.$color-grayscale--400;
      box-shadow: none;
    }

    .noUi-connects {
      border-radius: 0;
    }

    .noUi-connect {
      background: variables.$color-primary--1;
    }

    .noUi-handle {
      top: -5px;
      right: calc(math.div($handle-size, 2) * -1);
      display: flex;
      width: $handle-size;
      height: $handle-size;
      border: 0;
      background: none;
      box-shadow: none;
      cursor: pointer;

      &::before {
        top: 0;
        width: 4px;
        height: 100%;
        background-color: variables.$color-grayscale--0;
      }

      &::after {
        display: none;
      }
    }

    .noUi-handle-lower::before {
      left: 10px;
    }

    .noUi-handle-upper::before {
      left: 6px;
    }

    .noUi-target[disabled] {
      &,
      .noUi-handle {
        border-color: variables.$color-grayscale--400;
        cursor: not-allowed;
      }

      .noUi-connect {
        background: variables.$color-grayscale--400;
      }
    }
  }
</style>
