import { ProductMediaLink } from '@/types/product-media-link';
import { ProductCadData } from '@/types/product';
import { HybrisType, MediaLinkType } from '@/setup/globals';

export default function mapCadDataToMediaLinks(cadData: ProductCadData): ProductMediaLink[] {
  const { links, medias, cadenaslinks } = cadData;
  const productMediaLinks: ProductMediaLink[] = [];

  if (Array.isArray(links)) {
    productMediaLinks.push(...links.map(link => ({
      ...link,
      mediaLinkType: MediaLinkType.Link,
    })));
  }

  if (Array.isArray(medias)) {
    productMediaLinks.push(...medias.map(media => ({
      uid: media.code,
      name: media.description || media.code,
      fileSize: media.fileSize,
      url: media.url,
      linkTarget: media.linkTarget,
      addContextPath: false,
      visible: true,
      mediaLinkType: MediaLinkType.Media,
      type: HybrisType.Cmslink,
    }) satisfies ProductMediaLink));
  }

  if (Array.isArray(cadenaslinks)) {
    productMediaLinks.push(...cadenaslinks.map(link => ({
      ...link,
      mediaLinkType: MediaLinkType.Cadenas,
    })));
  }

  return productMediaLinks;
}
