<template>
  <div :class="b()">
    <slot></slot>
    <slot name="navigation">
      <div v-if="$viewport.isSm" :class="b('breadcrumb')">
        <c-breadcrumb :items="breadcrumbItems" @click="onBreadcrumbClick" />
      </div>

      <div :class="b('content-wrapper')">
        <div :class="b('content')">
          <template v-if="categoryChildren?.length">
            <nav :class="b('navigation')">
              <h2 v-if="!$viewport.isSm"
                  :class="b('navigation-title')"
              >
                {{ $t('c-main-navigation.listTitle') }}
              </h2>
              <a v-else-if="activeCategory?.link"
                 v-bind="activeCategory.link"
                 :class="b('all-of-category-link')"
              >
                <e-icon icon="i-arrow-2--right" size="19 17" />
                <i18n-t keypath="c-main-navigation.allSubcategoriesLabel"
                        tag="span"
                >
                  <template #category>
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <span v-html="activeCategory.title"></span>
                  </template>
                </i18n-t>
              </a>

              <c-navigation :overflow-scroll="!$viewport.isSm"
                            :navigation-nodes="listNavigationNodes"
                            @click="onCategoryClick"
              />
            </nav>

            <section :class="b('category-grid-container')">

              <c-navigation v-if="$viewport.isSm"
                            :navigation-nodes="categoryChildren"
                            variant="grid"
                            @click="onCategoryClick"
              />

              <transition-group v-else
                                :name="b('transition', { slideRight: true })"
                                @after-enter="onBeforeEnter"
                                @after-leave="onAfterLeave"
              >
                <div v-for="navigationNode in activeNavigationNodes"
                     :key="navigationNode.uid"
                     :class="b('category-grid')"
                     body-scroll-lock-ignore
                >
                  <c-navigation :navigation-nodes="navigationNode.children"
                                variant="grid"
                                @click="onCategoryClick"
                  >
                    <template #header>
                      <button :class="b('category-back-button')" type="button" @click="onBackButtonClick">
                        <e-icon icon="i-arrow-2--left" size="17" />
                        {{ navigationNode.title }}
                      </button>
                    </template>
                  </c-navigation>
                </div>
              </transition-group>
            </section>
          </template>
          <e-loader v-else
                    :class="b('loader')"
          />
        </div>
      </div>
    </slot>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
  import useNavigationStore, { MappedNavigationLink } from '@/stores/navigation';
  import cBreadcrumb from '@/components/c-breadcrumb.vue';
  import cNavigation from '@/components/c-navigation.vue';
  import eLoader from '@/elements/e-loader.vue';
  import eIcon from '@/elements/e-icon.vue';
  import { BreadcrumbItem } from '@/stores/breadcrumb';
  import useSessionStore from '@/stores/session';
  import { bodyScrollOptions } from '@/setup/options';

  interface Setup {
    navigationStore: ReturnType<typeof useNavigationStore>;
    sessionStore: ReturnType<typeof useSessionStore>;
  }

  interface Data {
    activeNavigationNodes: MappedNavigationLink[];
  }

  /**
   * Renders the main/category navigation.
   *
   * **WARNING: uses 'v-html' for the 'show all' link label. Make sure, that the source for this data is trustworthy.**
   */
  export default defineComponent({
    name: 'c-main-navigation',
    components: {
      cNavigation,
      eIcon,
      eLoader,
      cBreadcrumb,
    },

    // props: {},
    // emits: {},

    setup(): Setup {
      return {
        navigationStore: useNavigationStore(),
        sessionStore: useSessionStore(),
      };
    },
    data(): Data {
      return {
        activeNavigationNodes: [],
      };
    },

    computed: {
      categoryChildren(): MappedNavigationLink[] | null {
        return this.activeCategory?.children || this.navigationStore.mainNavigation;
      },

      breadcrumbItems(): BreadcrumbItem[] {
        return [
          {
            name: this.$t('c-main-navigation.breadcrumbAssortmentName'),
            url: '/',
          },
          ...this.activeNavigationNodes.map(node => ({
            name: node.title,
            url: node.link?.href || '#',
          })),
        ];
      },

      activeCategory(): MappedNavigationLink | null {
        return this.activeNavigationNodes.at(-1) || null;
      },

      listNavigationNodes(): MappedNavigationLink[] | undefined {
        return (this.$viewport.isSm && this.categoryChildren) || this.navigationStore.mainNavigation || undefined;
      },
    },
    // watch: {},

    // beforeCreate() {},
    created() {
      this.navigationStore.apiFetchNavigation();
      this.sessionStore.disableHeaderShadow = true;
    },
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    beforeUnmount() {
      this.sessionStore.disableHeaderShadow = false;
    },
    // unmounted() {},

    methods: {
      /**
       * Handles the Transition event for entering elements.
       */
      onBeforeEnter(element: Element): void {
        disableBodyScroll(element, bodyScrollOptions);
      },

      /**
       * Handles the Transition event for leaving elements.
       */
      onAfterLeave(element: Element): void {
        enableBodyScroll(element);
      },

      /**
       * Handles clicks on categories, since not all of them have a link definition.
       */
      onCategoryClick(event: MouseEvent, categoryChild: MappedNavigationLink): void {
        this.$gtm.pushSelectCategory(categoryChild.title);

        if (!categoryChild.link || categoryChild.children?.length) {
          event.preventDefault();

          this.activeNavigationNodes.push(categoryChild);
        }
      },

      /**
       * Handles clicks on breadcrumb navigation.
       */
      onBreadcrumbClick(breadcrumb: BreadcrumbItem, index: number, event: MouseEvent): void {
        event.preventDefault();

        this.activeNavigationNodes = this.activeNavigationNodes.slice(0, index);
      },

      /**
       * Handles click events of the back button.
       */
      onBackButtonClick(): void {
        this.activeNavigationNodes = this.activeNavigationNodes.slice(0, -1);
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';
  @use '@/setup/scss/mixins';

  .c-main-navigation {
    $navigation--column-span: 4;

    @include mixins.z-index(mobileNavigation);

    display: flex; // Required to allow overflow handling on grid child.
    flex-direction: column;
    background: variables.$color-grayscale--1000;

    @include mixins.media(md) {
      z-index: initial;
      padding-top: variables.$spacing--15;
      border-bottom: 4px solid variables.$color-grayscale--0;
    }

    &__breadcrumb {
      @include mixins.container;

      margin-block: variables.$spacing--25 variables.$spacing--30;

      @include mixins.media(md) {
        margin-block: variables.$spacing--25 variables.$spacing--50;
      }
    }

    &__content-wrapper {
      overflow: auto;

      @include mixins.media(sm) {
        @include mixins.container;
      }
    }

    &__content {
      display: grid;
      grid-template-areas: 'navigation';
      flex: 1 1 100%;
      min-height: 0; // Allows overflow.
      margin-top: variables.$spacing--40;

      @include mixins.media(sm) {
        grid-template-columns: repeat(12, 1fr);
        gap: variables.$spacing--50 20px;
        margin-top: 0;
      }
    }

    &__navigation-title {
      padding-bottom: variables.$spacing--5;
      border-bottom: 4px solid variables.$color-grayscale--0;
    }

    &__navigation {
      display: flex;
      grid-area: 1 / 1;
      flex-direction: column;
      min-height: 0; // Allow to collapse inside grid.
      max-height: 100%;
      font-weight: variables.$font-weight--bold;
      padding-inline: variables.$spacing--20;

      @include mixins.media(sm) {
        grid-area: 1 / 1 / 1 / span $navigation--column-span;
        padding-inline: 0;
      }
    }

    &__loader {
      grid-column: 1 / -1;
      color: variables.$color-primary--1;
    }

    &__category-grid-container {
      display: grid;
      grid-area: 1 / 1;
      min-height: 0; // Allows overflow.

      @include mixins.media(sm) {
        grid-area: 1 / #{$navigation--column-span + 1} / 1 / -1;
        min-height: initial;
      }

      &:empty {
        pointer-events: none; // Allows clicks on nav list.
      }

      > * {
        grid-area: 1 / 1 / 1 / 1;
        pointer-events: initial;
      }
    }

    &__category-grid {
      @include mixins.z-index(front); // Fixes a stacking issue with sticky mobile header.

      padding: 0 variables.$spacing--20 variables.$spacing--20;
      overflow: hidden auto;
      background: variables.$color-grayscale--1000;

      @include mixins.media(sm) {
        padding: 0;
      }
    }

    &__all-of-category-link {
      display: flex;
      order: 1;
      margin-bottom: variables.$spacing--10;
      padding-top: variables.$spacing--35;
      border-top: 2px solid variables.$color-grayscale--0;

      .e-icon {
        width: auto;
        height: 1em;
        margin: 0.28em variables.$spacing--10 0 0;
      }
    }

    &__category-back-button {
      @include mixins.font(variables.$font-size--25);

      display: flex;
      gap: variables.$spacing--15;
      align-items: center;
      padding-bottom: variables.$spacing--5;
      text-align: left;
    }

    &__transition--slide-right-enter-active,
    &__transition--slide-right-leave-active {
      transition: transform variables.$transition-duration--300 ease;
    }

    &__transition--slide-right-enter-from,
    &__transition--slide-right-leave-to {
      transform: translateX(calc(100% + #{variables.$spacing--20}));
    }
  }
</style>
