<template>
  <ul :class="b({ variant, overflowScroll })" body-scroll-lock-ignore>
    <li v-if="$slots.header" :class="b('item', { header: true })">
      <slot name="header"></slot>
    </li>
    <li v-for="navigationNode in navigationNodes"
        :key="navigationNode.uid"
        :class="b('item')"
    >
      <a v-bind="navigationNode.link"
         :class="b('link', { active: navigationNode.active })"
         @click="onCategoryClick($event, navigationNode)"
      >
        <e-picture v-if="variant === 'grid'"
                   v-bind="navigationNode.image"
                   :alt="navigationNode.title"
                   :ratio="1"
                   :sizes="categoryImageSizes"
        />
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="navigationNode.title"></span>
      </a>
    </li>
  </ul>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
  import { MappedNavigationLink } from '@/stores/navigation';
  import ePicture from '@/elements/e-picture.vue';
  import { ImageSizes } from '@/types/sizes';
  import { bodyScrollOptions } from '@/setup/options';

  interface Setup {
    categoryImageSizes: ImageSizes;
  }
  // interface Data {}

  /**
   * Renders a set of navigation nodes as list or grid.
   *
   * **WARNING: uses 'v-html' for the link labels. Make sure, that the source for this data is trustworthy.**
   */
  export default defineComponent({
    name: 'c-navigation',

    components: {
      ePicture,
    },

    props: {
      /**
       * Accepts a list of navigation links.
       */
      navigationNodes: {
        type: Array as PropType<MappedNavigationLink[]>,
        default: () => [],
      },

      /**
       * Determines if the container of the navigation items should overflow if required.
       */
      overflowScroll: {
        type: Boolean,
        default: false,
      },

      /**
       * Allows to set the layout variant.
       */
      variant: {
        type: String,
        default: 'list',
        validator(value: string) {
          return [
            'list',
            'grid',
          ].includes(value);
        },
      },

    },
    emits: {
      click: (event: MouseEvent, categoryChild: MappedNavigationLink) => !!event && !!categoryChild,
    },

    setup(): Setup {
      return {
        categoryImageSizes: {
          xxs: 210,
          xs: 230,
          sm: 304,
          md: 177,
          lg: 210,
          fallback: 220,
        },
      };
    },
    // data(): Data {
    //   return {};
    // },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    mounted() {
      if (this.overflowScroll) {
        disableBodyScroll(this.$el, bodyScrollOptions);
      }
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    beforeUnmount() {
      enableBodyScroll(this.$el);
    },
    // unmounted() {},

    methods: {
      /**
       * Handles clicks on categories, since not all of them have a link definition.
       */
      onCategoryClick(event: MouseEvent, categoryChild: MappedNavigationLink): void {
        this.$emit('click', event, categoryChild);
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';
  @use '@/setup/scss/mixins';

  .c-navigation {
    $this: &;

    &__item {
      margin-bottom: variables.$spacing--10;
      cursor: pointer;
    }

    &__link {
      display: block;

      @include mixins.media(sm) {
        @include mixins.link--secondary();

        display: inline;
      }
    }

    &__link span {
      word-break: break-word;
      hyphens: auto;
    }

    &__link--active,
    &__link:hover {
      color: variables.$color-primary--1;
    }

    &--variant-list {
      #{$this}__item {
        @include mixins.media($down: xs) {
          padding-block: variables.$spacing--10 variables.$spacing--2;
          // Spaced border-bottom
          background: linear-gradient(to right, variables.$color-grayscale--0 20%, transparent 0%) repeat-x bottom left / 8px 2px;
        }
      }
    }

    &--variant-grid {
      display: grid;
      grid-area: 1 / 1 / 1 / 1;
      grid-template-columns: repeat(2, 1fr);
      gap: variables.$spacing--20;
      background: variables.$color-grayscale--1000;

      @include mixins.media(md) {
        grid-template-columns: repeat(4, 1fr);
      }

      #{$this}__item {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        padding-bottom: variables.$spacing--10;
        border-bottom: 2px solid variables.$color-grayscale--0;
        font-weight: variables.$font-weight--bold;
        text-align: center;

        &:nth-child(4n + 1):nth-last-child(-n + 4), // First item of last row
        &:nth-child(4n + 1):nth-last-child(-n + 4) ~ #{$this}__item { // Every item after the first item of last row
          @include mixins.media(md) {
            border-bottom: 0;
          }
        }

        &--header {
          @include mixins.z-index(front); // Prevents interactions with overlapped tiles.

          position: sticky;
          top: 0;
          grid-column: span 2;
          padding: 0;
          border-bottom-width: 4px;
          background: variables.$color-grayscale--1000;
        }
      }

      #{$this}__link {
        display: flex;
        gap: variables.$spacing--5;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        border-bottom: 0;

        &:hover,
        &:focus {
          color: variables.$color-primary--1;
        }
      }
    }

    &--overflow-scroll {
      overflow: auto;
    }
  }
</style>
