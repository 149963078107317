import { defineStore } from 'pinia';
import { AxiosPromise } from 'axios';
import { Store } from '@/setup/globals';

type State = {
  runningRequests: {
    apiItemLookup: boolean;
    apiItemAttachments: boolean;
  };
}

type ItemLookupPayload = {
  itemNumber: string;
  supplierNumber: string;
}

type ItemAttachmentsPayload = {
  portal: string;
  supplierCode: string;
  supplierNumber: string | undefined;
}

const storeName = Store.Oxomi;
const OXOMI_API_URL = 'https://oxomi.com/service/json/item/attachments';

export default defineStore(storeName, {
  state: (): State => ({
    runningRequests: {
      apiItemLookup: false,
      apiItemAttachments: false,
    },
  }),
  getters: {},
  actions: {
    apiItemLookup(payload: ItemLookupPayload): AxiosPromise {
      this.runningRequests.apiItemLookup = true;

      return this.$api.get(this.$api.getApiUrl('oxomiItemLookup'), {
        params: payload,
      }).finally(() => {
        this.runningRequests.apiItemLookup = false;
      });
    },

    apiItemAttachments(payload: ItemAttachmentsPayload): AxiosPromise {
      const { portal, supplierCode, supplierNumber } = payload;
      const requestUrl = new URL(OXOMI_API_URL);

      requestUrl.searchParams.set('portal', portal);
      requestUrl.searchParams.set('item', supplierCode);

      if (supplierNumber) {
        requestUrl.searchParams.set('supplierNumber', supplierNumber);
      }

      this.runningRequests.apiItemAttachments = true;

      return this.$api.get(requestUrl.toString())
        .then(response => response).finally(() => {
          this.runningRequests.apiItemAttachments = false;
        });
    },
  },
});
