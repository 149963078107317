<template>
  <div :class="b()">
    <component v-for="(component, index) in mappedComponents"
               :is="component.componentName"
               :key="index"
               :data="component"
    />
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { CmsComponents } from '@/types/cms-components';
  import { HybrisType } from '@/setup/globals';
  import { CmsRowContainerDataProp } from '@/types/cms-row-container';
  import cCmsAccordion, { CmsAccordionData } from '@/components/c-cms-accordion.vue';
  import cCmsRowContainer from '@/components/c-cms-row-container.vue';
  import cCmsBannerCarousel, { CmsBannerCarouselComponentData } from '@/components/c-cms-banner-carousel.vue';
  import cCmsBanner, { CmsBannerComponentData } from '@/components/c-cms-banner.vue';

  type MappedAccordion = CmsAccordionData & {
    componentName: 'c-cms-accordion';
  }

  type MappedRowContainer = CmsRowContainerDataProp & {
    componentName: 'c-cms-row-container';
  }

  type MappedCmsBanner = CmsBannerComponentData & {
    componentName: 'c-cms-banner';
  }

  type MappedCmsBannerCarousel = CmsBannerCarouselComponentData & {
    componentName: 'c-cms-banner-carousel';
  }

  type MappedComponent = MappedAccordion | MappedRowContainer | MappedCmsBanner | MappedCmsBannerCarousel;

  // type Setup = {}

  // type Data = {}

  /**
   * Renders a list of CMS components.
   */
  export default defineComponent({
    name: 'c-cms-components-wrapper',

    components: {
      cCmsAccordion,
      cCmsRowContainer,
      cCmsBanner,
      cCmsBannerCarousel,
    },

    props: {
      /**
       * Expects a list of components.
       */
      components: {
        type: Array as PropType<CmsComponents>,
        required: true,
      },
    },
    // emits: {},

    // setup(): Setup {},
    // data(): Data {},

    computed: {
      mappedComponents(): MappedComponent[] {
        const { components } = this;

        if (!Array.isArray(components)) {
          return [];
        }

        const filteredComponents = components.filter(component => [
          HybrisType.Accordion,
          HybrisType.RowContainer,
          HybrisType.BannerComponent,
          HybrisType.BannerCarouselComponent,
        ].includes(component.type));

        return filteredComponents.map((component) => {
          switch (component.type) {
            case HybrisType.Accordion:
              return {
                data: {
                  [HybrisType.Accordion]: component,
                },
                componentName: 'c-cms-accordion',
              };

            case HybrisType.BannerComponent:
              return {
                data: {
                  [HybrisType.BannerComponent]: component,
                },
                componentName: 'c-cms-banner',
              };

            case HybrisType.BannerCarouselComponent:
              return {
                data: {
                  [HybrisType.BannerCarouselComponent]: component,
                },
                componentName: 'c-cms-banner-carousel',
              };

            case HybrisType.RowContainer:
            // Falls through

            default:
              return {
                data: {
                  [HybrisType.RowContainer]: component,
                },
                componentName: 'c-cms-row-container',
              };
          }
        });
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  // @use '@/setup/scss/mixins';
  // @use '@/setup/scss/variables';

  .c-cms-components-wrapper {
    // ...
  }
</style>
