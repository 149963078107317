import { defineStore } from 'pinia';
import {
  IS_STORAGE_AVAILABLE,
  StorageKey,
  Store,
  ApiResponseMessageAction,
} from '@/setup/globals';
import i18n from '@/setup/i18n';
import { ApiResponseMessages } from '@/types/api-response';
import { ApiResponseMessage } from '@/types/api-response-message';

export interface NotificationItem {

  /**
   * The type of the notification.
   */
  type?: 'success' | 'info' | 'error';

  /**
   * The message of the notification.
   */
  message?: string;

  /**
   * Defines whether the notification will expire/close automatically after a time.
   */
  expire?: boolean;

  /**
   * Defines the delay after which a notification will get closed automatically.
   */
  expireDelay?: number;

  /**
   * Defines in which container the notification should be displayed.
   */
  selector?: string | null;

  /**
   * URL which can be passed for redirecting the user.
   */
  redirectUrl?: string;

  /**
   * Can be used for forcing a page reload when showing the notification.
   */
  pageReload?: boolean;

  /**
   * Defines if the notification is displayed to the user.
   */
  showToUser?: boolean;
}

export interface MappedNotificationItem extends NotificationItem {

  /**
   * The ID of the notification.
   */
  id: number;
}

interface NotificationState {

  /**
   * Holds the notification items.
   */
  notifications: MappedNotificationItem[];

  /**
   * Defines if the global default notifications under the header are displayed.
   * Can be used to disable them for cases like when the global notifications
   * are displayed already at another place, e.g. in a modal.
   */
  showDefaultGlobalNotifications: boolean;
}

interface InitialData {
  messages?: ApiResponseMessages;
}

/**
 * Default unknown error notification template.
 */
const NOTIFICATION_UNKNOWN_ERROR: NotificationItem = {
  type: 'error',
  message: i18n.global.t('globalMessages.unknownApiError'),
};

const storeName = Store.Notification;

let currentId = 1;

/**
 * Handles notification redirects.
 */
function handleRedirectOrReload(notification: NotificationItem): void {
  const { redirectUrl } = notification || {};

  if (redirectUrl && IS_STORAGE_AVAILABLE) {
    localStorage.setItem(StorageKey.Notifications, JSON.stringify([{
      id: 1,
      ...notification,
      redirectUrl: undefined,
    }]));

    if (notification.pageReload) {
      window.location.reload();
    } else {
      window.location.href = redirectUrl;
    }
  }
}

/**
 * Gets localStorage messages and pushes them in the notification store to display.
 */
function getNotificationsFromStorage(): MappedNotificationItem[] {
  try {
    const messages = window.localStorage.getItem(StorageKey.Notifications);
    const messagesParsed = messages ? JSON.parse(messages) : null;

    if (Array.isArray(messagesParsed) && messagesParsed.length) {
      window.localStorage.removeItem(StorageKey.Notifications);

      return messagesParsed;
    }
  } catch (error) {
    console.error(new Error('An error occurred while retrieving messages from the localStorage.')); // eslint-disable-line no-console
  }

  return [];
}

/**
 * Adds a unique ID to a notification.
 */
function addId(notification: NotificationItem): MappedNotificationItem {
  currentId += 1;

  return {
    ...notification,
    id: currentId,
  };
}

function mapApiResponseMessage(message: ApiResponseMessage, type: 'success' | 'info' | 'error'): NotificationItem {
  const { secondsToShow, showToUser } = message || {};

  return {
    type,
    message: message.message,
    expire: typeof secondsToShow === 'number' && secondsToShow > 0,
    expireDelay: secondsToShow && secondsToShow * 1000,
    redirectUrl: message.redirectUrl || undefined,
    pageReload: message.action === ApiResponseMessageAction.PageReload,
    showToUser: typeof showToUser === 'boolean' ? showToUser : true,
  };
}

export function mapApiResponseMessages(messages: ApiResponseMessages): NotificationItem[] {
  const { SUCCESS, INFO, ERROR } = messages || {};

  const notifications: NotificationItem[] = [];

  if (Array.isArray(SUCCESS) && SUCCESS.length) {
    notifications.push(
      ...SUCCESS.map(message => mapApiResponseMessage(message, 'success'))
    );
  }

  if (Array.isArray(INFO) && INFO.length) {
    notifications.push(
      ...INFO.map(message => mapApiResponseMessage(message, 'info'))
    );
  }

  if (Array.isArray(ERROR) && ERROR.length) {
    notifications.push(
      ...ERROR.map(message => mapApiResponseMessage(message, 'error'))
    );
  }

  return notifications;
}

export default defineStore(storeName, {
  state: () => {
    const initialData: InitialData = window.initialData?.[storeName] || {};
    const { messages } = initialData || {};

    const state: NotificationState = {
      notifications: getNotificationsFromStorage(),
      showDefaultGlobalNotifications: true,
    };

    if (messages) {
      state.notifications.push(...mapApiResponseMessages(messages).map((notification) => {
        handleRedirectOrReload(notification);

        return addId(notification);
      }));
    }

    return state;
  },
  actions: {
    /**
     * Shows the given notification and returns its instance.
     */
    showNotification(notification: NotificationItem): void {
      handleRedirectOrReload(notification);

      if (notification.showToUser === false) {
        return;
      }

      const mappedNotification = addId(notification);

      this.notifications.push(mappedNotification);
    },

    /**
     * Removes a notification.
     */
    popNotification(id: number): void {
      this.notifications = this.notifications.filter(notification => notification.id !== id);
    },

    showUnknownError(): void {
      this.showNotification(NOTIFICATION_UNKNOWN_ERROR);
    },
  },
});
