<template>
  <ul :class="b()">
    <li v-if="actions.includes(ProductActions.Comparison) && $viewport.isMd" :class="b('item')">
      <button v-tooltip:top="$t('c-product-actions.comparisonButton')"
              :class="b('button', { active: productComparisonStore.getIsProductInComparison(product) })"
              :aria-label="$t('c-product-actions.comparisonButton')"
              type="button"
              @click="productComparisonStore.addOrRemoveProduct(product)"
      >
        <e-icon :class="b('icon')"
                icon="i-comparison"
                size="24"
        />
      </button>
    </li>
    <li v-if="actions.includes(ProductActions.Commission) && sessionStore.flags.showCommissions" :class="b('item')">
      <button v-tooltip:top="$t('c-product-actions.commissionButton')"
              :class="b('button', { active: !!selectedCommissionId })"
              :aria-label="$t('c-product-actions.commissionButton')"
              type="button"
              @click="showCommissionModal = true"
      >
        <e-icon :class="b('icon')"
                icon="i-commission"
                size="24"
        />
      </button>
    </li>
    <li v-if="actions.includes(ProductActions.Wishlist) && sessionStore.flags.showWishlists" :class="b('item')">
      <button v-tooltip:top="$t('c-product-actions.wishlistButton')"
              :class="b('button', { active: wishlistStore.getIsProductInAnyWishlist(product) })"
              :aria-label="$t('c-product-actions.wishlistButton')"
              type="button"
              @click="onClickWishlistButton"
      >
        <e-icon :class="b('icon')"
                icon="i-wishlist"
                size="24"
        />
      </button>
    </li>
  </ul>
  <c-commission-modal v-if="showCommissionModal"
                      :selected-commission-id="selectedCommissionId"
                      @update="updateGlobalCommission"
                      @close="showCommissionModal = false"
  />
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import tooltipDirective from '@/plugins/tooltip/directives/directive';
  import useSessionStore from '@/stores/session';
  import useWishlistStore from '@/stores/wishlist';
  import useProductComparisonStore from '@/stores/product-comparison';
  import eIcon from '@/elements/e-icon.vue';
  import cCommissionModal from '@/components/c-commission-modal.vue';
  import { WishlistModalMode } from '@/components/c-wishlist-modal.vue';
  import { Commission } from '@/types/commission';
  import { Product } from '@/types/product';

  export enum ProductActions {
    'Commission',
    'Wishlist',
    'Comparison'
  }

  interface Setup {
    sessionStore: ReturnType<typeof useSessionStore>;
    wishlistStore: ReturnType<typeof useWishlistStore>;
    productComparisonStore: ReturnType<typeof useProductComparisonStore>;
    ProductActions: typeof ProductActions;
  }

  interface Data {
    showCommissionModal: boolean;
  }

  /**
   * Renders the product action triggers.
   */
  export default defineComponent({
    name: 'c-product-actions',

    components: {
      cCommissionModal,
      eIcon,
    },

    directives: {
      tooltip: tooltipDirective,
    },

    props: {
      /**
       * Expects a product object.
       */
      product: {
        type: Object as PropType<Product>,
        required: true,
      },

      /**
       * Allows defining the actions that are enabled.
       */
      actions: {
        type: Array as PropType<ProductActions[]>,
        default: () => [
          ProductActions.Commission,
          ProductActions.Wishlist,
          ProductActions.Comparison,
        ],
      },

      /**
       * Allows passing the quantity.
       */
      quantity: {
        type: Number,
        default: undefined,
      },
    },
    // emits: {},

    setup(): Setup {
      return {
        sessionStore: useSessionStore(),
        wishlistStore: useWishlistStore(),
        productComparisonStore: useProductComparisonStore(),
        ProductActions,
      };
    },
    data(): Data {
      return {
        showCommissionModal: false,
      };
    },

    computed: {
      selectedCommissionId(): string | undefined {
        return this.sessionStore.globalCommission?.id;
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      updateGlobalCommission(commission: Commission | null) {
        this.sessionStore.setGlobalCommission(commission);
      },

      onClickWishlistButton(): void {
        this.wishlistStore.wishlistModalMode = WishlistModalMode.Product;
        this.wishlistStore.wishlistModalProduct = this.product;
        this.wishlistStore.wishlistModalQuantity = this.quantity;
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';

  .c-product-actions {
    display: flex;
    grid-column-gap: variables.$spacing--5;
    align-items: center;

    &__item {
      display: flex;
      align-items: center;
    }

    &__button {
      cursor: pointer;

      &--active {
        color: variables.$color-primary--1;
      }
    }
  }
</style>
