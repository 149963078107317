import { defineStore } from 'pinia';
import { AxiosPromise, AxiosResponse } from 'axios';
import useSessionStore from '@/stores/session';
import { HybrisType, Store, UserType } from '@/setup/globals';
import { Wishlist } from '@/types/wishlist';
import { WishlistEntry } from '@/types/wishlist-entry';
import { WishlistLabel } from '@/types/wishlist-label';
import { Product } from '@/types/product';
import { WishlistModalMode } from '@/components/c-wishlist-modal.vue';

export enum WishlistType {
  UserWishlist = 'userWishlist',
  CompanyWishlist = 'companyWishlist',
}

interface RunningRequests {
  apiUpdateWishlist: boolean;
  apiAddWishlist: boolean;
  apiRemoveWishlist: boolean;
  apiFetchLabels: boolean;
  apiAddLabelToWishlist: boolean;
  apiRemoveLabelFromWishlist: boolean;
  apiAddProductToWishlist: boolean;
  apiRemoveEntryFromWishlist: boolean;
  apiRemoveLabel: boolean;
  apiUpdateLabel: boolean;
  apiAddLabel: boolean;
  apiUpdateWishlistEntry: boolean;
  apiFetchWishlist: boolean;
  apiFetchWishlists: boolean;
  apiAddCartToWishlist: boolean;
  apiFetchWishlistsByProduct: boolean;
  apiRemoveProductFromWishlist: boolean;
  addProductToNewWishlist: boolean;
  apiImportWishlistEntries: boolean;
}

export interface InitialData {
  data: {
    companyWishlists: Wishlist[];
    userWishlists: Wishlist[];
  };
}

export interface UpdateWishlistPayload {
  code: string;
  name: string;
  entries?: Pick<WishlistEntry, 'identifier'>[];
  wishlistType?: WishlistType;
}

export type AddWishlistPayload = {
  name: string;
  entries?: {
    product: Pick<Product, 'code'>;
    commissionId?: string;
  }[];
  wishlistType?: WishlistType;
}

export type AddProductToNewWishlistPayload = AddWishlistPayload & {
  entries: {
    product: Pick<Product, 'code'>;
    commissionId?: string;
  }[];
  quantity?: number;
}

export interface AddProductToWishlistPayload {
  commissionId?: string;
  product: Pick<Product, 'code'>;
  quantity: number;
  wishlistCode: string;
}

export interface UpdateWishlistEntryPayload {
  wishlistCode: string;
  identifier: string;
  commissionId?: string;
  entryText?: string;
  quantity?: number;
}

type RemoveProductFromWishlist = {
  wishlistCode: string;
  product: Pick<Product, 'code'>;
}

interface State {
  companyWishlists: Wishlist[];
  userWishlists: Wishlist[];
  labels: WishlistLabel[];
  wishlistModalProduct?: Product;
  wishlistModalMode?: WishlistModalMode;
  wishlistModalQuantity?: number;
  runningRequests: RunningRequests;
}

const storeName = Store.Wishlist;

export default defineStore(storeName, {
  state: () => {
    const initialData = window.initialData?.[storeName];

    const state: State = {
      companyWishlists: initialData?.data.companyWishlists || [],
      userWishlists: initialData?.data.userWishlists || [],
      labels: [],
      wishlistModalProduct: undefined,
      wishlistModalMode: undefined,
      wishlistModalQuantity: undefined,
      runningRequests: {
        apiUpdateWishlist: false,
        apiAddWishlist: false,
        apiRemoveWishlist: false,
        apiFetchLabels: false,
        apiAddLabelToWishlist: false,
        apiRemoveLabelFromWishlist: false,
        apiAddProductToWishlist: false,
        apiRemoveEntryFromWishlist: false,
        apiRemoveLabel: false,
        apiUpdateLabel: false,
        apiAddLabel: false,
        apiUpdateWishlistEntry: false,
        apiFetchWishlist: false,
        apiFetchWishlists: false,
        apiAddCartToWishlist: false,
        apiFetchWishlistsByProduct: false,
        apiRemoveProductFromWishlist: false,
        addProductToNewWishlist: false,
        apiImportWishlistEntries: false,
      },
    };

    return state;
  },

  getters: {
    getIsProductInAnyWishlist(): (product: Product) => boolean {
      return (product: Product): boolean => this.getAllProducts.some(productItem => productItem.code === product.code);
    },

    getAllProducts(): Product[] {
      return [
        ...this.userWishlists,
        ...this.companyWishlists,
      ].flatMap(wishlist => wishlist.entries?.map(entry => entry.product) || []);
    },

    getWishlistByCode: state => (wishlistCode: string): Wishlist | undefined => [
      ...state.companyWishlists,
      ...state.userWishlists,
    ].find(wishlist => wishlist.code === wishlistCode),

    getIsLabelFeatureEnabled(): boolean {
      return useSessionStore().user.userType !== UserType.B2c;
    },
  },

  actions: {
    addWishlistEntryInState(entry: WishlistEntry, wishlistCode: string): void {
      const wishlistInState = this.getWishlistByCode(wishlistCode);

      if (wishlistInState) {
        if (!Array.isArray(wishlistInState.entries)) {
          wishlistInState.entries = [entry];
        } else {
          wishlistInState.entries.push(entry);
        }
      }
    },

    removeWishlistEntryInState(entryIdentifier: string, wishlistCode: string): void {
      const wishlistInState = this.getWishlistByCode(wishlistCode);

      if (wishlistInState) {
        wishlistInState.entries = wishlistInState.entries?.filter(entry => entry.identifier !== entryIdentifier) || [];
        wishlistInState.productsCount = wishlistInState.entries?.length || 0;
      }
    },

    replaceWishlistEntryInState(wishlistEntry: WishlistEntry, wishlistCode: string) {
      const entries = this.getWishlistByCode(wishlistCode)?.entries;

      if (entries) {
        const entryIndex = entries.findIndex(entry => entry.identifier === wishlistEntry.identifier);

        if (entryIndex > -1) {
          entries[entryIndex] = wishlistEntry;
        }
      }
    },

    replaceWishlistInState(wishlist: Wishlist, wishlistType: WishlistType): void {
      if (wishlistType === WishlistType.CompanyWishlist) {
        const index = this.companyWishlists.findIndex(companyWishlist => companyWishlist.code === wishlist.code);

        if (index > -1) {
          this.companyWishlists[index] = wishlist;
        }
      } else if (wishlistType === WishlistType.UserWishlist) {
        const index = this.userWishlists.findIndex(userWishlist => userWishlist.code === wishlist.code);

        if (index > -1) {
          this.userWishlists[index] = wishlist;
        }
      }
    },

    removeLabelFromWishlistInState(label: WishlistLabel, wishlistCode: string): void {
      const wishlist = this.getWishlistByCode(wishlistCode);

      if (wishlist) {
        wishlist.labels = wishlist.labels.filter(wishlistLabel => wishlistLabel.code !== label.code);
      }
    },

    moveUserListToCompanyLists(wishlist: Wishlist): void {
      this.userWishlists = this.userWishlists.filter(userWishlist => userWishlist.code !== wishlist.code);
      this.companyWishlists = [
        ...this.companyWishlists,
        wishlist,
      ];
    },

    handleWishlistsResponse(response: AxiosResponse, apiIdentifier: string): AxiosPromise {
      const { companyWishlists, userWishlists } = response.data?.data || {};

      if (!Array.isArray(companyWishlists) && !Array.isArray(userWishlists)) {
        return Promise.reject(new Error(`API failure during "${apiIdentifier}" request.`));
      }

      if (Array.isArray(companyWishlists)) {
        this.companyWishlists = companyWishlists;
      }

      if (Array.isArray(userWishlists)) {
        this.userWishlists = userWishlists;
      }

      return Promise.resolve(response);
    },

    handleUpdateLabelResponse(response: AxiosResponse, apiIdentifier: string): AxiosPromise {
      const { labels } = response.data?.data || {};

      if (Array.isArray(labels)) {
        this.labels = labels;

        return Promise.resolve(response);
      }

      return Promise.reject(new Error(`API failure during "${apiIdentifier}" request.`));
    },

    apiFetchWishlists(): AxiosPromise {
      this.runningRequests.apiFetchWishlists = true;

      return this.$api.get(this.$api.getApiUrl('wishlist')).then((response) => {
        const { userWishlists, companyWishlists } = response.data?.data || {};

        if (Array.isArray(userWishlists) || Array.isArray(companyWishlists)) {
          return response;
        }

        return Promise.reject(new Error('API failure during "wishlist/apiFetchWishlists" request.'));
      }).finally(() => {
        this.runningRequests.apiFetchWishlists = false;
      });
    },

    apiFetchWishlistsByProduct(productCode: string): AxiosPromise {
      this.runningRequests.apiFetchWishlistsByProduct = true;

      return this.$api.get(this.$api.getApiUrl('wishlistProductCombination', { productCode })).then((response) => {
        const { userWishlists, companyWishlists } = response.data?.data || {};

        if (Array.isArray(userWishlists) || Array.isArray(companyWishlists)) {
          return response;
        }

        return Promise.reject(new Error('API failure during "wishlist/apiFetchWishlistsByProduct" request.'));
      }).finally(() => {
        this.runningRequests.apiFetchWishlistsByProduct = false;
      });
    },

    apiFetchWishlist(code: string, wishlistType: WishlistType): AxiosPromise {
      this.runningRequests.apiFetchWishlist = true;

      return this.$api.get(this.$api.getApiUrl('wishlistDetails', { code })).then((response) => {
        const { wishlist } = response.data?.data || {};

        if (wishlist?.type === HybrisType.Wishlist) {
          this.replaceWishlistInState(wishlist, wishlistType);

          return response;
        }

        return Promise.reject(new Error('API failure during "wishlist/apiFetchWishlist" request.'));
      }).finally(() => {
        this.runningRequests.apiFetchWishlist = false;
      });
    },

    apiUpdateWishlist(payload: UpdateWishlistPayload, wishlistType: WishlistType): AxiosPromise {
      this.runningRequests.apiUpdateWishlist = true;

      return this.$api.patch(this.$api.getApiUrl('wishlist'), payload).then((response) => {
        const { wishlist } = response.data?.data || {};

        if (wishlist?.type === HybrisType.Wishlist) {
          this.replaceWishlistInState(wishlist, wishlistType);

          return response;
        }

        return Promise.reject(new Error('API failure during "wishlist/apiUpdateWishlist" request.'));
      }).finally(() => {
        this.runningRequests.apiUpdateWishlist = false;
      });
    },

    apiAddWishlist(payload: AddWishlistPayload): AxiosPromise {
      this.runningRequests.apiAddWishlist = true;

      return this.$api.post(this.$api.getApiUrl('wishlist'), payload)
        .then(response => this.handleWishlistsResponse(response, 'wishlist/apiAddWishlist'))
        .finally(() => {
          this.runningRequests.apiAddWishlist = false;
        });
    },

    addProductToNewWishlist(payload: AddProductToNewWishlistPayload): AxiosPromise {
      this.runningRequests.addProductToNewWishlist = true;

      return this.$api.post(this.$api.getApiUrl('wishlist'), payload, {
        params: {
          productDetail: 'true',
        },
      }).finally(() => {
          this.runningRequests.addProductToNewWishlist = false;
        });
    },

    apiRemoveWishlist(payload: Pick<Wishlist, 'code'>): AxiosPromise {
      this.runningRequests.apiRemoveWishlist = true;

      return this.$api.delete(this.$api.getApiUrl('wishlist'), {
        data: payload,
      }).then(response => this.handleWishlistsResponse(response, 'wishlist/apiRemoveWishlist'))
        .finally(() => {
          this.runningRequests.apiRemoveWishlist = false;
        });
    },

    apiFetchLabels(): AxiosPromise {
      this.runningRequests.apiFetchLabels = true;

      return this.$api.get(this.$api.getApiUrl('wishlistLabels')).then((response) => {
        const { labels } = response.data?.data || {};

        if (Array.isArray(labels)) {
          this.labels = labels;

          return response;
        }

        return Promise.reject(new Error('API failure during "wishlist/apiFetchLabels" request.'));
      }).finally(() => {
        this.runningRequests.apiFetchLabels = false;
      });
    },

    apiAddLabelToWishlist(label: WishlistLabel, wishlist: Wishlist): AxiosPromise {
      this.runningRequests.apiAddLabelToWishlist = true;

      return this.$api.put(this.$api.getApiUrl('labelForWishlist'), {
        labelCode: label.code,
        wishlistCode: wishlist.code,
      }).then((response) => {
        wishlist.labels.push(label);

        return response;
      }).finally(() => {
        this.runningRequests.apiAddLabelToWishlist = false;
      });
    },

    apiRemoveLabelFromWishlist(label: WishlistLabel, wishlist: Wishlist): AxiosPromise {
      this.runningRequests.apiRemoveLabelFromWishlist = true;

      return this.$api.delete(this.$api.getApiUrl('labelForWishlist'), {
        data: {
          labelCode: label.code,
          wishlistCode: wishlist.code,
        },
      }).then((response) => {
        this.removeLabelFromWishlistInState(label, wishlist.code);

        return response;
      }).finally(() => {
        this.runningRequests.apiRemoveLabelFromWishlist = false;
      });
    },

    apiAddProductToWishlist(payload: AddProductToWishlistPayload): AxiosPromise {
      this.runningRequests.apiAddProductToWishlist = true;

      return this.$api.post(this.$api.getApiUrl('wishlistEntry'), payload).then((response) => {
        const { wishlistEntry } = response.data?.data || {};

        if (wishlistEntry.type === HybrisType.WishlistEntry) {
          this.addWishlistEntryInState(wishlistEntry, payload.wishlistCode);

          return response;
        }

        return Promise.reject(new Error('API failure during "wishlist/apiAddProductToWishlist" request.'));
      }).finally(() => {
        this.runningRequests.apiAddProductToWishlist = false;
      });
    },

    apiRemoveEntryFromWishlist(identifier: string, wishlistCode: string): AxiosPromise {
      this.runningRequests.apiRemoveEntryFromWishlist = true;

      return this.$api.delete(this.$api.getApiUrl('wishlistEntry'), {
        data: {
          identifier,
          wishlistCode,
        },
      }).then((response) => {
        this.removeWishlistEntryInState(identifier, wishlistCode);

        return response;
      }).finally(() => {
        this.runningRequests.apiRemoveEntryFromWishlist = false;
      });
    },

    apiRemoveProductFromWishlist(payload: RemoveProductFromWishlist): AxiosPromise {
      this.runningRequests.apiRemoveProductFromWishlist = true;

      return this.$api.delete(this.$api.getApiUrl('wishlistEntry'), {
        params: {
          productDetail: 'true',
        },
        data: payload,
      }).finally(() => {
        this.runningRequests.apiRemoveProductFromWishlist = false;
      });
    },

    apiRemoveLabel(payload: Pick<WishlistLabel, 'code'>): AxiosPromise {
      this.runningRequests.apiRemoveLabel = true;

      return this.$api.delete(this.$api.getApiUrl('wishlistLabels'), {
        data: payload,
      }).then(response => this.handleUpdateLabelResponse(response, 'apiRemoveLabel'))
        .finally(() => {
          this.runningRequests.apiRemoveLabel = false;
        });
    },

    apiUpdateLabel(payload: Pick<WishlistLabel, 'code' | 'name'>): AxiosPromise {
      this.runningRequests.apiUpdateLabel = true;

      return this.$api.patch(this.$api.getApiUrl('wishlistLabels'), payload)
        .then(response => this.handleUpdateLabelResponse(response, 'apiUpdateLabel'))
        .finally(() => {
          this.runningRequests.apiUpdateLabel = false;
        });
    },

    apiAddLabel(payload: Pick<WishlistLabel, 'name'>): AxiosPromise {
      this.runningRequests.apiAddLabel = true;

      return this.$api.post(this.$api.getApiUrl('wishlistLabels'), payload)
        .then(response => this.handleUpdateLabelResponse(response, 'apiAddLabel'))
        .finally(() => {
          this.runningRequests.apiAddLabel = false;
        });
    },

    apiUpdateWishlistEntry(payload: UpdateWishlistEntryPayload, wishlistCode: string): AxiosPromise {
      this.runningRequests.apiUpdateWishlistEntry = true;

      return this.$api.patch(this.$api.getApiUrl('wishlistEntry'), payload)
        .then((response) => {
          const { wishlistEntry } = response.data?.data || {};

          if (wishlistEntry?.type === HybrisType.WishlistEntry) {
            this.replaceWishlistEntryInState(wishlistEntry, wishlistCode);

            return response;
          }

          return Promise.reject(new Error('API failure during "wishlist/apiUpdateWishlistEntry" request.'));
        })
        .finally(() => {
          this.runningRequests.apiUpdateWishlistEntry = false;
        });
    },

    apiAddCartToWishlist(code: string): AxiosPromise {
      this.runningRequests.apiAddCartToWishlist = true;

      return this.$api.patch(this.$api.getApiUrl('cartToWishlist'), {
        code,
      }).finally(() => {
        this.runningRequests.apiAddCartToWishlist = false;
      });
    },

    apiAddCartToNewWishlist(name: string, wishlistType: WishlistType): AxiosPromise {
      this.runningRequests.apiAddCartToWishlist = true;

      return this.$api.post(this.$api.getApiUrl('cartToWishlist'), {
        name,
        wishlistType,
      }).finally(() => {
        this.runningRequests.apiAddCartToWishlist = false;
      });
    },

    /**
     * Imports a CSV/DAT-file containing products into a wishlist.
     */
    apiImportWishlistEntries(payload: FormData, wishlistType: WishlistType): AxiosPromise {
      this.runningRequests.apiImportWishlistEntries = true;

      return this.$api.post(this.$api.getApiUrl('wishlistImportEntries'), payload)
        .then((response) => {
          const { wishlist } = response.data?.data || {};

          if (wishlist?.type === HybrisType.Wishlist) {
            this.replaceWishlistInState(wishlist, wishlistType);

            return response;
          }

          return Promise.reject(new Error('API failure during "wishlist/apiImportWishlistEntries" request.'));
        })
        .finally(() => {
          this.runningRequests.apiImportWishlistEntries = false;
        });
    },
  },
});
