<template>
  <div :class="b()">
    <e-loader />
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import eLoader from '@/elements/e-loader.vue';

  export type ProductPlaceholder = { page: number; index: number };

  // interface Setup {}
  interface Data {
    intersectionObserver: IntersectionObserver | undefined;
  }

  /**
   * Renders a product placeholder.
   * Emits event to parent as soon as it enters viewport.
   */
  export default defineComponent({
    name: 'c-product-placeholder',
    components: { eLoader },

    // components: {},

    props: {
      /**
       * Expects a product placeholder object.
       */
      placeholder: {
        type: Object as PropType<ProductPlaceholder>,
        required: true,
      },
    },
    emits: {
      enterViewport: (payload: ProductPlaceholder) => typeof payload?.page === 'number',
    },

    // setup(): Setup {},
    data(): Data {
      return {
        intersectionObserver: undefined,
      };
    },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    mounted() {
      this.intersectionObserver = new IntersectionObserver(([entry]: IntersectionObserverEntry[]) => {
        if (entry.isIntersecting) {
          this.$emit('enterViewport', this.placeholder);
        }
      }, {
        threshold: 0.5,
      });
      this.intersectionObserver.observe(this.$el);
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    beforeUnmount() {
      this.intersectionObserver?.disconnect();
    },
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';

  .c-product-placeholder {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 410px;
    border: 1px solid variables.$color-grayscale--400;

    .e-loader {
      color: variables.$color-grayscale--400;
      font-size: 10px;
    }
  }
</style>
