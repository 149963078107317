<template>
  <ul :class="b({ variant, isProductStockModalEnabled })"
      @touchstart="hasTouch = true"
      @click="isProductStockModalEnabled ? onClick() : undefined"
  >
    <li v-if="showIndicators.includes(INDICATORS.notExisting) && isNotExisting"
        :class="b('state', { notExisting: true, })"
    >
      {{ $t('global.productStock.notExisting') }}
    </li>
    <li v-if="showIndicators.includes(INDICATORS.liquidation) && product.inLiquidation"
        :class="b('state', { liquidation: true, })"
    >
      <c-stock-icon :label="$t('global.productStock.liquidation')"
                    :tooltip-position="labelToolTipModifier"
                    :hide-label="hideIconLabel"
                    icon="i-liquidation-1"
      />
    </li>
    <li v-if="showIndicators.includes(INDICATORS.substitute) && product.substituteProducts?.length"
        :class="b('state')"
        @click.stop="onClickSubstituteState"
    >
      <c-stock-icon :label="$t('global.productStock.substituteProduct')"
                    :tooltip-position="labelToolTipModifier"
                    :hide-label="hideIconLabel"
                    icon="i-substitute-product"
      />
    </li>
    <template v-if="showIndicators.includes(INDICATORS.stock)">
      <li v-if="stockState"
          :class="b('state', { delivery: true, })"
      >
        <c-stock-icon v-bind="stockState"
                      :tooltip-position="labelToolTipModifier"
                      :hide-label="hideIconLabel"
        />
      </li>
      <li v-if="additionalStockInfoState"
          :class="b('state', { additionalStockInfo: true })"
      >
        <c-stock-icon v-bind="additionalStockInfoState"
                      :tooltip-position="labelToolTipModifier"
                      :hide-label="hideIconLabel"
        />
      </li>
    </template>
    <li v-if="showIndicators.includes(INDICATORS.posStock) && isPosStockAvailable && posStockState"
        :class="b('state')"
    >
      <c-stock-icon v-bind="posStockState"
                    :tooltip-position="labelToolTipModifier"
                    :hide-label="hideIconLabel"
      />
    </li>
  </ul>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { BasePlacement } from '@popperjs/core';
  import tooltipDirective from '@/plugins/tooltip/directives/directive';
  import useSessionStore from '@/stores/session';
  import useProductStockStore from '@/stores/product-stock';
  import cStockIcon from '@/components/c-stock-icon.vue';
  import mapStockIndicator, { StockState } from '@/helpers/map-stock-indicator';
  import mapPosStockIndicator from '@/helpers/map-pos-stock-indicator';
  import mapAdditionalStockInfoIndicator from '@/helpers/map-additional-stock-info-indicator';
  import addContextPathToUrl from '@/helpers/add-context-path-to-url';
  import { AdditionalStockInfo, ProductSalesStatus, StockStatus } from '@/setup/globals';
  import { Product } from '@/types/product';
  import { ObjectValues } from '@/types/object-values';

  export const INDICATORS = {
    notExisting: 'notExisting',
    liquidation: 'liquidation',
    substitute: 'substitute',
    stock: 'stock',
    posStock: 'posStock',
  } as const;

  const VARIANT = {
    default: 'default',
    iconsOnly: 'icons-only',
    vertical: 'vertical',
  } as const;

  type Variant = ObjectValues<typeof VARIANT>;

  interface Setup {
    sessionStore: ReturnType<typeof useSessionStore>;
    productStockStore: ReturnType<typeof useProductStockStore>;
    INDICATORS: typeof INDICATORS;
  }

  interface Data {
    hasTouch: boolean;
  }

  type Indicators = ObjectValues<typeof INDICATORS>

  /**
   * Renders the product stock indicator.
   */
  export default defineComponent({
    name: 'c-product-stock',

    components: {
      cStockIcon,
    },

    directives: {
      tooltip: tooltipDirective,
    },

    props: {
      /**
       * Expects a product object.
       */
      product: {
        type: Object as PropType<Product>,
        required: true,
      },

      /**
       * Allows defining the display variant.
       */
      variant: {
        type: String as PropType<Variant>,
        default: VARIANT.default,
      },

      /**
       * Allows to toggle, which indicators should be displayed.
       */
      showIndicators: {
        type: Array as PropType<Indicators[]>,
        default: () => Object.values(INDICATORS),
      },
    },
    // emits: {},

    setup(): Setup {
      return {
        sessionStore: useSessionStore(),
        productStockStore: useProductStockStore(),
        INDICATORS,
      };
    },
    data(): Data {
      return {
        hasTouch: false,
      };
    },

    computed: {
      isProductStockModalEnabled(): boolean {
        const isOutOfStock = this.product.stock?.stockLevelStatus?.code === StockStatus.OutOfStock;

        return this.productStockStore.getIsProductStockModalEnabled && !isOutOfStock && !this.isNotExisting;
      },

      hideIconLabel() {
        return this.variant === VARIANT.iconsOnly;
      },

      stockState(): StockState | undefined {
        const { additionalStockInfo, inLiquidation } = this.product;
        const showState = !additionalStockInfo || additionalStockInfo === AdditionalStockInfo.AsLongAsInStock;

        if (inLiquidation || !showState) {
          return undefined;
        }

        return mapStockIndicator(this.product.stock);
      },

      additionalStockInfoState(): StockState | null {
        if (this.product.inLiquidation) {
          return null;
        }

        return mapAdditionalStockInfoIndicator(this.product.additionalStockInfo);
      },

      posStockState(): StockState | null {
        return mapPosStockIndicator(this.product.posStock);
      },

      isPosStockAvailable(): boolean {
        const userHasPointOfService = !!this.sessionStore.user.defaultPos;

        return this.sessionStore.flags.showPickupStock && userHasPointOfService;
      },

      labelToolTipModifier(): BasePlacement | 'hidden' {
        const showTooltipOnTouchDevices = !this.isProductStockModalEnabled;

        if ((!this.hasTouch || showTooltipOnTouchDevices) && this.variant === VARIANT.iconsOnly) {
          return 'top';
        }

        return 'hidden';
      },

      isNotExisting(): boolean {
        return this.product.salesStatus === ProductSalesStatus.NotExisting;
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      /**
       * Shows product stock modal on click.
       */
      onClick(): void {
        this.productStockStore.productStockModal = { product: this.product };
      },

      /**
       * Handles click on substitute product.
       */
      onClickSubstituteState(): void {
        const { substituteProducts } = this.product;

        if (!Array.isArray(substituteProducts) || !substituteProducts.length) {
          return;
        }

        const showSubstituteProductsInComparisonView = substituteProducts.length > 1;

        if (!showSubstituteProductsInComparisonView) {
          window.location.assign(addContextPathToUrl(substituteProducts[0].url));

          return;
        }

        this.productStockStore.substituteProductsModalProduct = this.product;
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-product-stock {
    $this: &;

    display: flex;
    gap: variables.$spacing--5 variables.$spacing--15;
    flex-wrap: wrap;
    align-items: center;

    &[role='button'] {
      cursor: pointer;
    }

    &--variant-icons-only {
      column-gap: variables.$spacing--5;
    }

    &--variant-vertical {
      flex-direction: column;
    }

    &--is-product-stock-modal-enabled {
      cursor: pointer;
    }
  }
</style>
