import { defineStore } from 'pinia';
import { AxiosPromise, AxiosResponse } from 'axios';
import { HybrisType, Store } from '@/setup/globals';
import { FavouriteEntry } from '@/types/favourite-entry';
import { Product } from '@/types/product';

export interface FavouriteList {
  entries: FavouriteEntry[];
  type: HybrisType.FavouriteList;
}

export interface InitialData {
  data: {
    favoritelist: FavouriteList;
  };
}

interface RunningRequests {
  apiAddFavourite: boolean;
  apiRemoveFavourite: boolean;
}

interface State {
  entries: FavouriteEntry[];
  runningRequests: RunningRequests;
}

export type AddFavouritePayload = {
  product: Pick<Product, 'code'>;
}

export type RemoveFavouritePayload = {
  product: Pick<Product, 'code'>;
};

const storeName = Store.Favourite;

export default defineStore(storeName, {
  state: () => {
    const initialData = window.initialData[storeName];
    const state: State = {
      entries: initialData?.data?.favoritelist?.entries || [],
      runningRequests: {
        apiAddFavourite: false,
        apiRemoveFavourite: false,
      },
    };

    return state;
  },

  getters: {
    getFavouriteProductCodes(): string[] {
      return this.entries.map(entry => entry.product.code);
    },
  },

  actions: {
    apiAddFavourite(payload: AddFavouritePayload): AxiosPromise {
      this.runningRequests.apiAddFavourite = true;

      return this.$api.post(this.$api.getApiUrl('favoritesEntry'), payload)
        .then(response => this.handleFavouriteUpdateResponse(response, 'favourite/apiAddFavourite'))
        .finally(() => {
          this.runningRequests.apiAddFavourite = false;
        });
    },

    apiRemoveFavourite(payload: RemoveFavouritePayload): AxiosPromise {
      this.runningRequests.apiRemoveFavourite = true;

      return this.$api.delete(this.$api.getApiUrl('favoritesEntry'), {
        data: payload,
      }).then(response => this.handleFavouriteUpdateResponse(response, 'favourite/apiRemoveFavourite'))
        .finally(() => {
          this.runningRequests.apiRemoveFavourite = false;
        });
    },

    handleFavouriteUpdateResponse(response: AxiosResponse, apiIdentifier: string): AxiosPromise {
      const { entries } = response.data.data?.favoritelist || {};

      if (Array.isArray(entries)) {
        this.entries = entries;

        return Promise.resolve(response);
      }

      return Promise.reject(new Error(`API failure during "${apiIdentifier}" request.`));
    },
  },
});

