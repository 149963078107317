import { defineStore } from 'pinia';
import { Store } from '@/setup/globals';

type Item = object;

type State = {
  items?: Item[];
}

export type InitialData = Item[];

const storeName = Store.StructuredData;

export default defineStore(storeName, {
  state: (): State => ({
    items: window.initialData?.[storeName],
  }),
  getters: {},
  actions: {},
});
