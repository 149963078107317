<template>
  <c-accordion :headline="headline"
               :entries="entries"
  />
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import cAccordion from '@/components/c-accordion.vue';
  import { CmsAccordionGroup } from '@/types/cms-accordion-group';
  import mapAccordionGroupEntries, { AccordionGroupEntry } from '@/helpers/map-accordion-group-entry';

  interface Setup {
    entries: AccordionGroupEntry[];
    headline: string;
  }

  // interface Data {}

  export interface CmsAccordionData {
    data: {
      sfsAccordionComponent: CmsAccordionGroup;
    };
  }

  /**
   * Renders a CMS accordion.
   */
  export default defineComponent({
    name: 'c-cms-accordion',

    components: { cAccordion },

    props: {
      /**
       *  Receives a HYBRIS accordion data object.
       */
      data: {
        type: Object as PropType<CmsAccordionData>,
        required: true,
      },
    },
    // emits: {},

    setup(props): Setup {
      // eslint-disable-next-line vue/no-setup-props-destructure -- prop does not need to be reactive.
      const { headline, entries } = props.data.data.sfsAccordionComponent;

      return {
        headline,
        entries: mapAccordionGroupEntries(entries),
      };
    },
    // data(): Data {
    //   return {};
    // },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  // @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-cms-accordion {
    margin-bottom: variables.$spacing--40;
  }
</style>
