<template>
  <c-contact-person v-bind="contactPersonProps"
                    :class="b()"
  />
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import cContactPerson, { ContactPersonProps } from '@/components/c-contact-person.vue';
  import mapContactPersonProps from '@/helpers/map-contact-person-props';
  import { CmsContactPerson } from '@/types/cms-contact-person';

  type Setup = {
    contactPersonProps: ContactPersonProps;
  };

  // type Data = {}

  export interface CmsContactPersonData {
    data: {
      sfsContactPersonComponent: CmsContactPerson;
    };
  }

  /**
   * Renders a CMS data based contact person tile.
   */
  export default defineComponent({
    name: 'c-cms-contact-person',
    components: {
      cContactPerson,
    },

    // components: {},

    props: {
      /**
       * Expects a CMS data object.
       */
      data: {
        type: Object as PropType<CmsContactPersonData>,
        required: true,
      },
    },
    // emits: {},

    setup(props): Setup {
      return {
        contactPersonProps: mapContactPersonProps(props.data.data.sfsContactPersonComponent),
      };
    },
    // data(): Data {
    //   return {};
    // },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use 'sass:math';
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-cms-contact-person {
    $gap: variables.$spacing--20;

    margin-bottom: variables.$spacing--40;

    &.c-contact-person {
      &--col-sm-4 {
        @include mixins.media(md) {
          width: calc(#{percentage(math.div(1, 3))} - #{$gap});
        }
      }

      &--col-sm-6 {
        @include mixins.media(md) {
          width: calc(#{percentage(math.div(1, 2))} - #{$gap});
        }
      }

      &--col-sm-12 {
        width: 100%;
      }
    }
  }
</style>
