import { ImageSrcset } from '@/types/image';
import { ProductMedia } from '@/types/product';
import { Media } from '@/types/media';

export type ValidMedia = Media & {
  width: number;
}

/**
 * Maps the images of a product for use in a `<e-picture>`-Element using `srcset`.
 * Product images are an exception in that they are not in a media container.
 */
export default function mapProductImagesSrcSet(medias: ProductMedia[]): ImageSrcset {
  const validMedias = medias?.filter(media => !!media.width) as ValidMedia[] || [];

  // Fallback for media containers that are not yet converted by HYBRIS and don't have valid medias.
  if (!validMedias?.length && medias?.length) {
    const fallbackImage = medias[0];

    return {
      srcset: medias?.map(media => media.url).join(', ') || '',
      fallback: fallbackImage?.url || '',
      alt: fallbackImage?.altText || '',
    };
  }

  const sourcesSortedByAscendingWidth = validMedias.sort((a, b) => a.width - b.width) || [];
  const largestSource = sourcesSortedByAscendingWidth.at(-1);

  return {
    srcset: sourcesSortedByAscendingWidth.map(media => `${media.url} ${media.width}w`).join(', ') || '',
    fallback: largestSource?.url || '',
    alt: largestSource?.altText || '',
    ratio: 1,
  };
}
