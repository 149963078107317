import { defineStore } from 'pinia';
import axios, { AxiosPromise } from 'axios';
import {
  Store,
  IS_STORAGE_AVAILABLE,
  StorageKey,
  HybrisType,
} from '@/setup/globals';
import { ApiResponse } from '@/types/api-response';
import { CategorySuggestion } from '@/types/category-suggestion';
import { Product } from '@/types/product';
import { CmsContentSuggestion } from '@/types/cms-content-suggestion';

export interface Result {
  categories: CategorySuggestion[];
  products: Product[];
  contents: CmsContentSuggestion[];
  groupingThreshold: number;
  categoriesAmount: number;
  productsAmount: number;
  contentsAmount: number;
  type: HybrisType.AutocompleteResult;
}

export interface InitialData {
  data: {
    currentSearchTerm: string;
  };
}

export interface ApiResult extends ApiResponse {
  data: {
    result: Result;
  };
}

interface RunningRequests {
  apiGetSearchSuggestions: boolean;
}

export interface State {

  /**
   * Stores the search suggestions.
   */
  result: Result | null;

  /**
   * Holds the current search query.
   */
  query: string;

  /**
   * Holds the minimum amount of characters for a search.
   */
  minimumCharacters: number;

  /**
   * Holds the running requests.
   */
  runningRequests: RunningRequests;
}

const storeName = Store.SearchSuggestions;

interface StoredSearchResult {
  query: string;
  result: Result;
  timestamp: number;
}

/**
 * Loads the stored search results from the session storage.
 */
function restoreSearchResultsFromStorage(): StoredSearchResult[] {
  if (!IS_STORAGE_AVAILABLE) {
    return [];
  }

  try {
    const rawResultsFromStore = window.sessionStorage.getItem(StorageKey.SearchSuggestions);

    if (!rawResultsFromStore) {
      return [];
    }

    const parsedResult = JSON.parse(rawResultsFromStore);

    if (!Array.isArray(parsedResult)) {
      throw new Error('Search Result Data from Storage is corrupt');
    }

    return parsedResult;
  } catch (e) {
    return [];
  }
}

/**
 * Looks up the stored search results for a result matching the given query.
 */
function restoreSearchResultByQuery(query: string): Result | null {
  const expirationTime = 10 * 60000; // 10 minutes
  const resultFromStorage = restoreSearchResultsFromStorage().find(result => result.query === query);

  if (!resultFromStorage) {
    return null;
  }

  if (resultFromStorage.timestamp < Date.now() - expirationTime) {
    return null; // Result is older than the maximum expiration time
  }

  return resultFromStorage.result;
}

/**
 * Stores a search result in the session storage.
 */
function storeSearchResult(query: string, result: Result): void {
  const maximumResultsCached = 30;

  if (!IS_STORAGE_AVAILABLE) {
    return;
  }

  const resultsFromStore = restoreSearchResultsFromStorage();

  resultsFromStore.push({
    query,
    result,
    timestamp: Date.now(),
  });

  window.sessionStorage.setItem(
    StorageKey.SearchSuggestions,
    JSON.stringify(resultsFromStore.slice(-maximumResultsCached))
  );
}

export default defineStore(storeName, {
  state: () => {
    const initialData = window.initialData?.[storeName];

    if (!initialData) {
      throw new Error(`Initial data for ${storeName} store missing`);
    }

    const { data } = initialData;

    const state: State = {
      result: null,
      minimumCharacters: 3,
      query: data.currentSearchTerm || '',
      runningRequests: {
        apiGetSearchSuggestions: false,
      },
    };

    return state;
  },
  getters: {
    /**
     * Returns whether the search provided any results.
     */
    hasResults(): boolean {
      const { result } = this;

      if (!result) {
        return false;
      }

      const { productsAmount, categoriesAmount, contentsAmount } = result;

      return (productsAmount + categoriesAmount + contentsAmount) > 0;
    },

    /**
     * Gets the categories from the search result.
     */
    getCategories(): CategorySuggestion[] {
      const { categories } = this.result || {};

      return Array.isArray(categories) ? categories : [];
    },

    /**
     * Gets the CMS contents from the search result.
     */
    getCmsContents(): CmsContentSuggestion[] {
      const { contents } = this.result || {};

      return Array.isArray(contents) ? contents : [];
    },

    /**
     * Gets the products from the search result.
     */
    getProducts(): Product[] {
      const { products } = this.result || {};

      return Array.isArray(products) ? products : [];
    },
  },
  actions: {
    /**
     * Clears the current search.
     */
    clearSearch(): void {
      this.query = '';
      this.result = null;
    },

    /**
     * Fetches search suggestions.
     * Stores results in the session storage to reduce server load.
     */
    apiGetSearchSuggestions(term: string): AxiosPromise | Promise<null> {
      const errorMessage = 'API failure during "search-suggestion/apiGetSearchSuggestions" request.';
      const resultFromStorage = restoreSearchResultByQuery(term);

      if (resultFromStorage) {
        this.result = resultFromStorage;

        return Promise.resolve(null);
      }

      this.runningRequests.apiGetSearchSuggestions = true;

      return this.$api.get(this.$api.getApiUrl('autocomplete'), {
        params: {
          term,
        },
      }, 'apiGetSearchSuggestions').then((response) => {
        const { result } = response.data?.data || {};

        if (result?.type === HybrisType.AutocompleteResult) {
          this.result = result;

          setTimeout(() => {
            storeSearchResult(term, result);
          });

          return response;
        }

        return Promise.reject(new Error(errorMessage));
      }).catch((error) => {
        if (axios.isCancel(error)) {
          return Promise.reject(error);
        }

        return Promise.reject(new Error(errorMessage));
      }).finally(() => {
        this.runningRequests.apiGetSearchSuggestions = false;
      });
    },
  },
});
