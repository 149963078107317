import useCustomerPriceStore from '@/stores/customer-price';
import { Price } from '@/types/price';
import { Product } from '@/types/product';

/**
 * Gets the net price of a product.
 */
export default function getProductNetPrice(product: Product): Price | null {
  return useCustomerPriceStore().getCustomerPriceByProduct(product) || product.specialPrice || product.price || null;
}
