import { MediaContainer } from '@/types/media-container';
import { ImageSrcset } from '@/types/image';

export const VALID_MIME_TYPES = [
  'image/webp',
];

/**
 * Because HYBRIS is not able to only provide medias that should be displayed
 * we have to filter them in frontend for some cases.
 */
export enum MediaFormatFilter {
  MarketingParagraph = 'marketingParagraph',
  Banner = 'bannerAdvertisment',
  PointOfService = 'pointOfService',
}

/**
 * Maps a media container for use in a `<e-picture>`-Element using `srcset`.
 */
export default function mapMediaContainerSrcSet(
  mediaContainer: MediaContainer,
  mediaFormatFilter?: MediaFormatFilter
): ImageSrcset {
  const { medias } = mediaContainer || {};

  const validMedias = medias
    ?.filter(media => !!media.width && !!media.height
      && VALID_MIME_TYPES.includes(media.mime)
      && (!mediaFormatFilter || media.mediaFormat?.includes(mediaFormatFilter)));

  // Fallback for media containers that are not yet converted by HYBRIS/don't have valid medias.
  if (!validMedias?.length) {
    return {
      srcset: medias?.map(media => media.url).join(', ') || '',
      fallback: medias?.[0]?.url || '',
      alt: medias?.[0]?.altText || '',
    };
  }

  const sourcesSortedByAscendingWidth = validMedias.sort((a, b) => (a.width || 0) - (b.width || 0));
  const largestSource = sourcesSortedByAscendingWidth.at(-1);

  return {
    srcset: sourcesSortedByAscendingWidth.map(media => `${media.url} ${media.width}w`).join(', '),
    fallback: largestSource?.url || '',
    alt: largestSource?.altText || '',
    ratio: (largestSource?.width || 0) / (largestSource?.height || 1),
  };
}
