import useSessionStore from '@/stores/session';

/**
 * Adds context path from session store to a URL.
 */
export default function addContextPathToUrl(url: string): string {
  const sessionStore = useSessionStore();

  if (!url) {
    return '';
  }

  return url.indexOf('https://') > -1 || url.indexOf('http://') > -1 || url.indexOf('//') > -1
    ? url
    : `${sessionStore.contextPath}${url}`;
}
