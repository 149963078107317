<template>
  <c-m2m-calculator />
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import cM2mCalculator from '@/components/c-m2m-calculator.vue';

  // type Setup = {}

  // type Data = {}

  /**
   * Integrates the m2m-calculator web-component.
   */
  export default defineComponent({
    name: 'c-cms-m2m-calculator',

    components: { cM2mCalculator },

    // props: {},
    // emits: {},

    // setup(): Setup {},
    // data(): Data {},

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  // @use '@/setup/scss/mixins';
  // @use '@/setup/scss/variables';

  // stylelint-disable selector-class-pattern
  .c-cms-m2m-calculator { // TODO: update selector according to filename
    // ...
  }
</style>
