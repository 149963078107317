import { Dashboard } from '@/types/dashboard';
import cmsLinkAttributes, { LinkWithModalAttribute } from './cms-link-attributes';

export interface AccountNavigationItem {
  label: string;
  link: LinkWithModalAttribute | undefined;
  icon: string;
  badgeAmount?: number;
  visible: boolean;
}

const accountNavigationIcons: { [key: string]: string } = {
  profilePageLink: 'i-profile',
  passwordPageLink: 'i-update-password',
  wishlistsLink: 'i-wishlist',
  favoritesLink: 'i-favorite',
  orderHistoryLink: 'i-order-history',
  returnManagementLink: 'i-return',
  offersLink: 'i-offer',
  collectiveOrdersLink: 'i-collective-order',
  commissionLink: 'i-commission',
  digitalCustomerCardLink: 'i-digital-customer-card',
  userManagementLink: 'i-users',
  myProductsLink: 'i-my-product',
};

/**
 * Creates a mapped account navigation with all links wrapped in items.
 */
export default function mapAccountNavigation(navigation: Dashboard, isOverlayNavigation?: boolean): AccountNavigationItem[] {
  const navigationItemTypes = Object.keys(accountNavigationIcons);

  return Object.entries(navigation).reduce((accumulator, [key, link]) => {
    if (navigationItemTypes.includes(key) && link.visible) {
      const collectiveOrdersLink = 'collectiveOrdersLink';
      const item: AccountNavigationItem = {
        label: link.name,
        link: cmsLinkAttributes(link),
        icon: (accountNavigationIcons[key] === 'i-users') && isOverlayNavigation ? 'i-users-2' : accountNavigationIcons[key],
        visible: link.visible,
      };

      if (key === collectiveOrdersLink) {
        item.badgeAmount = navigation.numberOfCollectiveCartsOpen;
      }

      accumulator.push(item);
    }

    return accumulator;
  }, [] as AccountNavigationItem[]);
}
