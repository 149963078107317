<template>
  <c-modal v-model:is-open="isOpen"
           :title="$t('global.volumePrices')"
           :class="b()"
           close-on-outside-click
  >
    <e-loader v-if="customerPriceStore.runningRequests.apiFetchCustomerPrices" />
    <div v-else-if="product">
      <div v-if="product.alternateQuantities?.length"
           :class="b('alternative-quantity-wrapper')"
      >
        <div>
          <h4 :class="b('title')">
            {{ $t('global.priceUnit') }}
          </h4>
          {{ getBasePriceUnit(product.price) }}
        </div>
        <div>
          <h4 :class="b('title')">
            {{ $t('global.quantityUnit') }}
          </h4>
          <c-product-quantities :product="product" />
        </div>
      </div>
      <c-volume-prices v-if="product" :product="product" />
    </div>
  </c-modal>
</template>

<script lang="ts">
  import { defineComponent, reactive } from 'vue';
  import useCustomerPriceStore from '@/stores/customer-price';
  import cModal from '@/components/c-modal.vue';
  import eLoader from '@/elements/e-loader.vue';
  import cProductQuantities from '@/components/c-product-quantities.vue';
  import cVolumePrices from '@/components/c-volume-prices.vue';
  import getBasePriceUnit from '@/helpers/get-base-price-unit';
  import { Product } from '@/types/product';

  type Setup = {
    customerPriceStore: ReturnType<typeof useCustomerPriceStore>;
    getBasePriceUnit: typeof getBasePriceUnit;
  }

  // type Data = {}

  type State = Record<'product', Product | undefined>;

  export const state: State = reactive({
    product: undefined,
  });

  /**
   * Renders the volume price modal.
   */
  export default defineComponent({
    name: 'c-volume-price-modal',

    components: {
      cVolumePrices,
      cProductQuantities,
      eLoader,
      cModal,
    },

    // props: {},
    // emits: {},

    setup(): Setup {
      return {
        customerPriceStore: useCustomerPriceStore(),
        getBasePriceUnit,
      };
    },
    // data(): Data {},

    computed: {
      product(): Product | undefined {
        return state.product;
      },

      isOpen: {
        get(): boolean {
          return !!state.product;
        },

        set(value: boolean): void {
          if (!value) {
            state.product = undefined;
          }
        },
      },
    },
    watch: {
      product(product: Product): void {
        if (product) {
          this.customerPriceStore.apiFetchCustomerPrices([{
            code: product.code,
          }]);
        }
      },
    },

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-volume-price-modal {
    &__title {
      margin-bottom: variables.$spacing--10;
    }

    &__alternative-quantity-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: variables.$spacing--20;
      padding-bottom: variables.$spacing--20;
      border-bottom: 2px solid variables.$color-grayscale--0;
    }

    .c-volume-prices {
      @include mixins.media($down: xs) {
        @include mixins.font(variables.$font-size--16);
      }
    }
  }
</style>
