<template>
  <div :class="b()">
    <div :class="b('container', { show })">
      <div :class="b('header')">
        <img v-bind="sessionStore.getLogoAttributes"
             :alt="$t('c-barcode-scanner.altLogo')"
             :class="b('logo')"
        >
        <button :aria-label="$t('c-barcode-scanner.buttonClose')"
                :class="b('close')"
                type="button"
                @click="close"
        >
          <e-icon icon="i-close" size="20" />
        </button>
        <c-notification-container :class="b('notifications')" />
      </div>
      <div ref="barcodePickerContainer" :class="b('picker')"></div>
      <div v-if="activeScan" :class="b('active-scan')">
        <span :class="b('product-code')">
          {{ $t('c-barcode-scanner.productCode', { productCode: activeScan.productCode || activeScan.ean }) }}
        </span>
        <span :class="b('quantity')">
          {{ $t('c-barcode-scanner.quantity', { quantity: activeScan.quantity }) }}
        </span>
        <template v-if="mode === 'add-to-cart'">
          <e-button :aria-label="$t('c-barcode-scanner.buttonAddToCart')"
                    type="button"
                    height="200"
                    @click="onClickAddToCart"
          >
            <e-icon icon="i-cart" size="22" />
          </e-button>
        </template>
        <template v-else>
          <e-button :aria-label="$t('c-barcode-scanner.buttonSearch')"
                    type="button"
                    height="200"
                    @click="onClickSearch"
          >
            <e-icon icon="i-search" size="20" />
          </e-button>
        </template>
      </div>
    </div>
    <!-- Class "app-qr-scan" required by wrapper app -->
    <button :aria-label="$t('c-barcode-scanner.buttonScan')"
            :class="b('trigger')"
            class="app-qr-scan"
            type="button"
            @click="startScanner"
    >
      <e-icon icon="i-barcode-scan" size="25" />
    </button>
  </div>
</template>

<script lang="ts">
  import {
    defineComponent,
    PropType,
    ref,
    Ref,
  } from 'vue';
  import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
  import {
    Barcode,
    ScanSettings,
    configure,
    BarcodePicker,
    ScanResult,
  } from 'scandit-sdk';
  import useSessionStore from '@/stores/session';
  import useCheckoutStore from '@/stores/checkout';
  import useNotificationStore from '@/stores/notification';
  import eIcon from '@/elements/e-icon.vue';
  import eButton from '@/elements/e-button.vue';
  import cNotificationContainer from '@/components/c-notification-container.vue';
  import isValidDeviceForBarcodeScanner from '@/helpers/is-valid-device-for-barcode-scanner';
  import searchResultPageUrl from '@/helpers/search-result-page-url';

  interface Setup {
    settings: ScanSettings;
    sessionStore: ReturnType<typeof useSessionStore>;
    checkoutStore: ReturnType<typeof useCheckoutStore>;
    notificationStore: ReturnType<typeof useNotificationStore>;
    barcodePickerContainer: Ref<HTMLDivElement | null | undefined>;
  }

  interface Scan {
    productCode: string;
    ean: string;
    quantity: number;
  }

  interface Data {
    isConfigured: boolean;
    barcodePicker: BarcodePicker | null;
    show: boolean;
    activeScan: Scan | null;
    isLoading: boolean;
  }

  export enum Mode {
    Search = 'search',
    AddToCart = 'add-to-cart',
  }

  /**
   * Renders the barcode scanner.
   *
   * https://www.npmjs.com/package/scandit-sdk
   *
   * Important note when updating the package:
   * Ensure that the version of the WebAssembly files (see `engineLocation`)
   * correspond to version of the package.
   */
  export default defineComponent({
    name: 'c-barcode-scanner',

    components: {
      cNotificationContainer,
      eButton,
      eIcon,
    },

    props: {
      /**
       * Expects the mode for the barcode scanner to be defined.
       */
      mode: {
        type: String as PropType<Mode>,
        required: true,
      },
    },
    // emits: {},

    setup(): Setup {
      return {
        sessionStore: useSessionStore(),
        checkoutStore: useCheckoutStore(),
        notificationStore: useNotificationStore(),
        barcodePickerContainer: ref(),
        settings: new ScanSettings({
          enabledSymbologies: [
            Barcode.Symbology.CODE128,
            Barcode.Symbology.CODE39,
            Barcode.Symbology.EAN13,
          ],
          codeDuplicateFilter: 2000,
          searchArea: {
            width: 0.8,
            height: 0.04,
            x: 0.04,
            y: 0.48,
          },
        }),
      };
    },
    data(): Data {
      return {
        isConfigured: false,
        barcodePicker: null,
        show: false,
        activeScan: null,
        isLoading: false,
      };
    },

    // computed: {},
    watch: {
      /**
       * Disables/enables body scroll when overlay gets toggled.
       */
      show(show: boolean): void {
        if (show) {
          disableBodyScroll(this.$el);
          this.$gtm.pushOpenBarcodeScanner(this.mode, this.sessionStore.user.uid);

          return;
        }

        enableBodyScroll(this.$el);
      },
    },

    // beforeCreate() {},
    created() {
      const { scanditApiKey } = this.sessionStore.apiKeys || {};

      if (!scanditApiKey || !isValidDeviceForBarcodeScanner || this.isConfigured) {
        return;
      }

      configure(scanditApiKey, {
        engineLocation: `${import.meta.env.BASE_URL}/scanditSdk`,
      }).then(() => {
        this.isConfigured = true;
      });
    },
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      /**
       * Starts the scanner.
       */
      startScanner(): void {
        const { openScanner } = window.mobileApp || {};

        if (typeof openScanner === 'function') {
          openScanner(this.mode);

          return;
        }

        const { barcodePickerContainer } = this;

        if (!this.isConfigured || !barcodePickerContainer) {
          return;
        }

        BarcodePicker.create(barcodePickerContainer, {
          playSoundOnScan: true,
          vibrateOnScan: true,
          scanSettings: this.settings,
        }).then((barcodePicker) => {
          this.show = true;

          barcodePicker.setTargetScanningFPS(20);
          barcodePicker.setCameraSwitcherEnabled(false);
          barcodePicker.on('scan', this.onScan);

          this.barcodePicker = barcodePicker;
        }).catch((e) => {
          throw new Error(e);
        });
      },

      /**
       * Handles Scan Event, parses result.
       */
      onScan(scanResult: ScanResult): void {
        const { data, symbology } = scanResult.barcodes[0] || {};

        if (!data || !symbology) {
          return;
        }

        let productCode = '';
        let quantity = 1;
        let ean = '';

        // Barcode Type "Code-128"
        if (symbology === 'code128') {
          if (data.indexOf('*') > -1 && data.indexOf('#') > -1) {
            productCode = data.substring(
              data.lastIndexOf('*') + 1,
              data.lastIndexOf('#')
            );

            quantity = +data.substring(data.lastIndexOf('#') + 1);
          } else {
            // If it has no # and * in the scanned code it will be handled like an EAN code.
            ean = data;
          }
        }

        // Barcode Type "EAN-13" and "Code-39" => Always with quantity: 1
        if (symbology === 'ean13' || symbology === 'code39') {
          ean = data;
        }

        this.notificationStore.showNotification({
          message: this.$t('c-barcode-scanner.notificationScanSuccessful'),
          type: 'success',
          expire: true,
        });

        this.activeScan = {
          productCode,
          ean,
          quantity,
        };
      },

      /**
       * Handles submitting a search with the scanned product.
       */
      onClickSearch(): void {
        const { productCode, ean } = this.activeScan || {};

        if (productCode) {
          window.location.href = searchResultPageUrl(productCode);

          return;
        }

        if (ean) {
          window.location.href = searchResultPageUrl(ean, true);
        }
      },

      /**
       * Handles click on add to cart.
       */
      onClickAddToCart(): void {
        const { productCode, ean, quantity } = this.activeScan || {};

        if (!quantity) {
          return;
        }

        if (productCode) {
          this.addToCart(productCode, quantity);

          return;
        }

        if (ean) {
          this.addToCartByEan(ean, quantity);
        }
      },

      /**
       * Adds a scanned product to the cart.
       */
      addToCart(code: string, quantity: number): void {
        this.isLoading = true;
        this.checkoutStore.apiAddToCart({
          product: {
            code,
          },
          quantity,
          commissionId: this.sessionStore.globalCommission?.id || '',
        }).then(() => {
          this.$gtm.pushAddToCart({
            item_id: code,
            quantity,
          });
        }).finally(() => {
          this.isLoading = false;
        });
      },

      /**
       * Adds a scanned product to the cart by EAN number.
       */
      addToCartByEan(ean: string, quantity: number): void {
        this.isLoading = true;
        this.checkoutStore.apiAddToCartByEan({
          ean,
          quantity,
          commissionId: this.sessionStore.globalCommission?.id || '',
        }).finally(() => {
          this.isLoading = false;
        });
      },

      /**
       * Closes the scanner.
       */
      close(): void {
        this.show = false;
        this.barcodePicker?.destroy();
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';
  @use '@/setup/scss/mixins';

  .c-barcode-scanner {
    &__container {
      @include mixins.z-index(barcodeScanner);

      position: fixed;
      top: 0;
      left: 0;
      display: none;
      width: 100dvw;
      height: 100dvh;
      padding: variables.$spacing--20;
      background-color: variables.$color-grayscale--1000;
    }

    &__header {
      position: relative;
      display: flex;
      justify-content: space-between;
    }

    &__container--show {
      display: grid;
      grid-row-gap: variables.$spacing--20;
      grid-template-rows: auto auto 1fr;
    }

    &__active-scan {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      align-self: start;
      padding-top: variables.$spacing--10;
      border-top: 2px solid variables.$color-grayscale--0;
      column-gap: variables.$spacing--20;
    }

    &__product-code {
      @include mixins.font(variables.$font-size--18, variables.$line-height--20);
    }

    &__quantity {
      white-space: nowrap;
    }

    &__picker {
      overflow: hidden;
    }

    &__close,
    &__trigger {
      cursor: pointer;
    }

    &__notifications {
      position: absolute;
      inset-inline: 0;
    }
  }
</style>
