<template>
  <div :class="b()">
    <c-product-actions v-if="showProductActions"
                       :product="product"
                       :quantity="internalQuantity"
                       :actions="productActions"
    />
    <e-quantity-select v-if="showQuantitySelect"
                       v-model="internalQuantity"
                       :step-size="product.roundingUnitFactor"
                       :min="product.roundingUnitFactor"
                       :disabled="disabled || isLoading"
                       @enter="submit"
    >
      <slot name="quantitySelect"></slot>
    </e-quantity-select>
    <div
      v-tooltip:[tooltipPosition]="tooltip"
      :class="b('button-wrapper')"
    >
      <e-button
        :class="b('submit-button')"
        :aria-label="$t('c-add-to-cart.buttonAdd')"
        :href="sessionStore.flags.isComnormUser && comNormUrl(product)"
        :progress="isLoading"
        :disabled="disabled"
        height="200"
        type="button"
        @click="submit"
      >
        <e-icon :icon="icon" size="22" />
      </e-button>
    </div>

  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { BasePlacement } from '@popperjs/core';
  import tooltipDirective from '@/plugins/tooltip/directives/directive';
  import useCheckoutStore from '@/stores/checkout';
  import useSessionStore from '@/stores/session';
  import cProductActions, { ProductActions } from '@/components/c-product-actions.vue';
  import eQuantitySelect from '@/elements/e-quantity-select.vue';
  import eButton from '@/elements/e-button.vue';
  import eIcon from '@/elements/e-icon.vue';
  import comNormUrl from '@/helpers/comnorm-url';
  import { GaListName } from '@/plugins/google-tag-manager';
  import { Product } from '@/types/product';

  export enum AddToCartDisabledReason {
    IsChemicalProduct = 'IS_CHEMICAL_PRODUCT',
  }

  interface Setup {
    checkoutStore: ReturnType<typeof useCheckoutStore>;
    sessionStore: ReturnType<typeof useSessionStore>;
    comNormUrl: typeof comNormUrl;
  }

  interface Data {
    internalQuantity: number;
    isLoading: boolean;
  }

  /**
   * Renders the add-to-cart feature.
   */
  export default defineComponent({
    name: 'c-add-to-cart',

    components: {
      cProductActions,
      eButton,
      eQuantitySelect,
      eIcon,
    },

    directives: {
      tooltip: tooltipDirective,
    },

    props: {
      /**
       * Expects a product item to be passed.
       */
      product: {
        type: Object as PropType<Product>,
        required: true,
      },

      /**
       * Allows defining whether the quantity select is displayed.
       */
      showQuantitySelect: {
        type: Boolean,
        default: true,
      },

      /**
       * Allows to define the quantity from outside.
       */
      quantity: {
        type: Number,
        default: undefined,
      },

      /**
       * Expects a GTM list name to be passed.
       */
      gtmListName: {
        type: String as PropType<GaListName>,
        required: true,
      },

      /**
       * Allows passing a commission ID.
       */
      commissionId: {
        type: String,
        default: undefined,
      },

      /**
       * Allows passing an entry text.
       */
      entryText: {
        type: String,
        default: undefined,
      },

      /**
       * Allows enabling product actions.
       */
      showProductActions: {
        type: Boolean,
        default: false,
      },

      /**
       * Allows defining the list of product actions that are enabled.
       */
      productActions: {
        type: Array as PropType<ProductActions[]>,
        default: undefined,
      },
    },
    // emits: {},

    setup(): Setup {
      return {
        checkoutStore: useCheckoutStore(),
        sessionStore: useSessionStore(),
        comNormUrl,
      };
    },
    data(): Data {
      return {
        internalQuantity: this.quantity || this.product.initialQuantity || this.product.roundingUnitFactor,
        isLoading: false,
      };
    },

    computed: {
      /**
       * Returns whether the add to cart is disabled.
       * In most cases the button will not get shown at all,
       * but for some special cases like for chemical products the add to cart
       * has to be displayed in a disabled state.
       */
      disabled(): boolean {
        return !this.product.showAddToCart || !!this.product.addToCartDisabledReason;
      },

      /**
       * Returns the button icon.
       */
      icon(): string {
        if (this.sessionStore.flags.isComnormUser) {
          return 'i-comnorm';
        }

        if (this.isDisabledBecauseChemicalProduct) {
          return 'i-hazard';
        }

        return 'i-cart';
      },

      isDisabledBecauseChemicalProduct(): boolean {
        return this.product.addToCartDisabledReason === AddToCartDisabledReason.IsChemicalProduct;
      },

      tooltip(): string {
        if (this.isDisabledBecauseChemicalProduct) {
          return this.$t('c-add-to-cart.tooltipChemicalProduct');
        }

        return '';
      },

      tooltipPosition(): BasePlacement | 'hidden' {
        if (this.tooltip) {
          return 'top';
        }

        return 'hidden';
      },
    },
    watch: {
      quantity(quantity): void {
        if (typeof quantity === 'number') {
          this.internalQuantity = quantity;
        }
      },
    },

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      /**
       * Adds a product to the cart.
       */
      submit(): void {
        const { internalQuantity } = this;

        if (internalQuantity < 1) {
          return;
        }

        this.isLoading = true;
        this.checkoutStore.apiAddToCart({
          product: {
            code: `${this.product.code}`,
          },
          quantity: internalQuantity,
          commissionId: this.commissionId || this.sessionStore.globalCommission?.id || '',
          entryText: this.entryText,
        }).then(() => {
          this.$gtm.pushAddToCart(this.$gtm.mapProductToListItem(this.product), this.gtmListName);
        }).finally(() => {
          this.isLoading = false;
        });
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';

  .c-add-to-cart {
    display: flex;
    column-gap: variables.$spacing--10;

    .e-quantity-select {
      width: 120px;
    }

    &__button-wrapper {
      display: flex;
    }
  }
</style>
