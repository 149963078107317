<template>
  <c-modal v-model:is-open="isOpen"
           :title="$t('global.deliveryAndPickupStock')"
           size="800"
           close-on-outside-click
  >
    <div v-if="productStockStore.runningRequests.apiGetProductStock" :class="b('loader')">
      <e-loader />
    </div>
    <template v-else-if="productStockInformationResult">
      <div v-if="stockState || additionalStockInfoState" :class="b('delivery-state')">
        <h3>
          {{ $t('c-product-stock-modal.titleDelivery') }}
        </h3>
        <dl :class="b('list')">
          <dt :class="b('list-label')">
            {{ $t('c-product-stock-modal.deliveryLabel') }}
          </dt>
          <dd :class="b('list-value')">
            <div v-if="stockState" :class="b('state')">
              <e-icon :icon="stockState.icon"
                      :class="b('state-icon')"
                      size="18"
              />
              {{ stockState.label }}
            </div>
            <div v-if="additionalStockInfoState" :class="b('state')">
              <e-icon :icon="additionalStockInfoState.icon"
                      :class="b('state-icon')"
                      size="18"
              />
              {{ additionalStockInfoState.label }}
            </div>
          </dd>
        </dl>
      </div>
      <div v-if="mappedPosStocks.length" :class="b('pickup-states')">
        <h3>
          {{ $t('c-product-stock-modal.titlePickup') }}
        </h3>
        <dl :class="b('list')">
          <template v-for="posStock in mappedPosStocks" :key="posStock.posName">
            <dt :class="b('list-label')">
              {{ posStock.posName }}
            </dt>
            <dd :class="b('list-value', { posStock: true })">
              <div :class="b('state')">
                <template v-if="posStock.stockState">
                  <e-icon :icon="posStock.stockState.icon"
                          :class="b('state-icon')"
                          size="18"
                  />
                  {{ posStock.stockState.label }}
                </template>
              </div>
              <div :class="b('stock-level')">
                {{ `${posStock.stockLevel} ${posStock.unit}` }}
              </div>
            </dd>
          </template>
        </dl>
      </div>
      <i18n-t :class="b('footer-info-text')"
              keypath="c-product-stock-modal.footerInfoText"
              tag="p"
      >
        <template #storefinderLink>
          <a v-bind="cmsLinkAttributes(sessionStore.links.storefinderLink)"
             :class="b('storefinder-link')"
          >
            {{ $t('c-product-stock-modal.storefinderLink') }}
          </a>
        </template>
      </i18n-t>
    </template>
  </c-modal>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import cModal from '@/components/c-modal.vue';
  import useSessionStore from '@/stores/session';
  import useProductStockStore, {
    ProductStockInformationResult,
    ProductStockModal,
  } from '@/stores/product-stock';
  import eIcon from '@/elements/e-icon.vue';
  import eLoader from '@/elements/e-loader.vue';
  import mapStockIndicator, { StockState } from '@/helpers/map-stock-indicator';
  import mapPosStockIndicator from '@/helpers/map-pos-stock-indicator';
  import cmsLinkAttributes from '@/helpers/cms-link-attributes';
  import { PosStock } from '@/types/pos-stock';
  import mapAdditionalStockInfoIndicator from '@/helpers/map-additional-stock-info-indicator';
  import { AdditionalStockInfo } from '@/setup/globals';

  interface Setup {
    productStockStore: ReturnType<typeof useProductStockStore>;
    sessionStore: ReturnType<typeof useSessionStore>;
    cmsLinkAttributes: typeof cmsLinkAttributes;
  }

  // interface Data {}

  interface MappedPosStock extends PosStock {
    stockState: StockState | null;
  }

  /**
   * Renders the product stock modal.
   */
  export default defineComponent({
    name: 'c-product-stock-modal',

    components: {
      eLoader,
      cModal,
      eIcon,
    },

    // props: {},
    // emits: {},

    setup(): Setup {
      return {
        productStockStore: useProductStockStore(),
        sessionStore: useSessionStore(),
        cmsLinkAttributes,
      };
    },
    // data(): Data {
    //   return {};
    // },

    computed: {
      isOpen: {
        get(): boolean {
          return !!this.productStockStore.productStockModal;
        },

        set(isOpen: boolean): void {
          if (!isOpen) {
            this.productStockStore.productStockModal = null;
          }
        },
      },

      productStockInformationResult(): ProductStockInformationResult | null {
        return this.productStockStore.productStockModal?.productStockInformationResult || null;
      },

      isProductInLiquidation(): boolean {
        const { inLiquidation, anyVariantIsInLiquidation } = this.productStockInformationResult || {};

        return !!inLiquidation || !!anyVariantIsInLiquidation;
      },

      stockState(): StockState | undefined {
        const { additionalStockInfo } = this.productStockInformationResult || {};
        const showState = !additionalStockInfo || additionalStockInfo === AdditionalStockInfo.AsLongAsInStock;

        if (!showState) {
          return undefined;
        }

        return mapStockIndicator(this.productStockInformationResult?.stock);
      },

      additionalStockInfoState(): StockState | null {
        if (this.isProductInLiquidation) {
          return null;
        }

        return mapAdditionalStockInfoIndicator(this.productStockInformationResult?.additionalStockInfo);
      },

      mappedPosStocks(): MappedPosStock[] {
        const { posStock } = this.productStockInformationResult || {};

        if (!Array.isArray(posStock)) {
          return [];
        }

        return posStock.map(posStockItem => ({
          ...posStockItem,
          stockState: mapPosStockIndicator(posStockItem, false),
        }));
      },
    },
    watch: {
      'productStockStore.productStockModal': function(productStockModal?: ProductStockModal) {
        if (productStockModal) {
          this.productStockStore.apiGetProductStock(productStockModal.product).then((response) => {
            productStockModal.productStockInformationResult = response.data.data;
          });
        }
      },
    },

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-product-stock-modal {
    &__list {
      display: grid;
      grid-gap: variables.$spacing--20;
      grid-template-columns: minmax(140px, auto) 1fr;

      @include mixins.media(sm) {
        grid-row-gap: variables.$spacing--10;
      }
    }

    &__delivery-state,
    &__pickup-states {
      margin-bottom: variables.$spacing--40;
      padding-bottom: variables.$spacing--15;
      border-bottom: 1px solid variables.$color-grayscale--0;
    }

    &__state {
      display: flex;
      column-gap: variables.$spacing--5;
    }

    &__state-icon {
      margin-top: 0.2em;
    }

    &__list-value--pos-stock {
      display: grid;
      grid-gap: variables.$spacing--5 variables.$spacing--15;

      @include mixins.media(sm) {
        grid-template-columns: 1fr auto;
      }
    }

    &__storefinder-link {
      @include mixins.link--primary();
    }

    &__footer-info-text {
      @include mixins.font(variables.$font-size--18, variables.$line-height--25);
    }

    &__loader {
      padding-block: variables.$spacing--25;
      color: variables.$color-grayscale--0;
      font-size: 10px;
    }
  }
</style>
