<template>
  <span :class="b(modifiers)">
    <!-- Search field -->
    <input v-if="isOpen && hasSearch"
           v-model="searchTerm"
           ref="searchField"
           :placeholder="$t('e-multiselect.searchFieldPlaceholder')"
           :class="b('search-field')"
           type="text"
           @mouseenter="hover = true"
           @mouseleave="hover = false"
    >

    <!-- Trigger Button -->
    <button v-else
            ref="fieldWrapper"
            :class="b('field-wrapper', { open: isOpen })"
            :disabled="isDisabled"
            type="button"
            @click="isOpen = !isOpen"
            @mouseenter="hover = true"
            @mouseleave="hover = false"
    >
      <span :class="b('output-value', { active : modelValue?.length > 0 })">
        {{ outputValue }}
      </span>
      <span :class="b('icon-wrapper')">
        <e-icon v-if="hasDefaultState && !focus"
                :class="b('arrow-icon')"
                icon="i-arrow--down"
                size="15"
        />
        <e-icon v-else
                :class="b('state-icon')"
                :icon="stateIcon"
                size="20"
        />
        <span v-if="progress" :class="b('progress-wrapper')">
          <e-loader variant="tiny" />
        </span>
      </span>
    </button>

    <!-- Content -->
    <transition name="top-slide">
      <span v-show="isOpen"
            v-outside-click="{ excludeRefs: ['fieldWrapper', 'searchField'], handler: close }"
            :class="b('options-wrapper')"
      >
        <ul :class="b('options-list')">
          <li v-for="option in filteredOptions"
              :key="option[valueField]"
              :class="b('options-item')"
          >
            <e-checkbox v-model="internalValue"
                        :value="option[valueField]"
                        :name="`e-multiselect--${uuid}`"
                        :disabled="isDisabled"
                        @change="onChangeOption"
            >
              {{ option[labelField] }}
            </e-checkbox>
          </li>
        </ul>
      </span>
    </transition>
  </span>
</template>

<script lang="ts">
  import {
    defineComponent,
    PropType,
    ref,
    Ref,
    toRefs,
  } from 'vue';
  import useUuid, { Uuid } from '@/compositions/uuid';
  import useFormStates, { FormStates, withProps } from '@/compositions/form-states';
  import { Modifiers } from '@/plugins/vue-bem-cn/src/globals';
  import eCheckbox from '@/elements/e-checkbox.vue';
  import eIcon from '@/elements/e-icon.vue';
  import eLoader from '@/elements/e-loader.vue';

  export interface Option {
    value: string;
    label: string;
    [key: string]: string;
  }

  type Value = boolean | string | number;

  interface Setup extends FormStates, Uuid {
    searchField: Ref<HTMLInputElement | null>;
    fieldWrapper: Ref<HTMLButtonElement | null>;
  }

  interface Data {
    isOpen: boolean;
    searchTerm: string;
  }

  /**
   * This renders a multi-select component.
   */
  export default defineComponent({
    name: 'e-multiselect',

    components: {
      eCheckbox,
      eIcon,
      eLoader,
    },

    props: {
      ...withProps(),

      /**
       * Value passed by v-model
       */
      modelValue: {
        default: () => [],
        type: Array as PropType<string[]>,
      },

      /**
       * Defines the available options to select.
       *
       * e.g. `[{ <valueField>: 'id1', <labelField>: 'Label 1' },{ <valueField>: 'id2', <labelField>: 'Label 2' },...]`
       */
      options: {
        required: true,
        type: Array as PropType<Option[]>,
      },

      /**
       * The text to display if no option is selected by default.
       * The placeholder can also be disabled by passing 'false' to this prop.
       */
      placeholder: {
        type: [String, Boolean],
        default: true,
      },

      /**
       * Defines if the component should have a search field.
       */
      hasSearch: {
        type: Boolean,
        default: false,
      },

      /**
       * Defines if the component should be in disabled mode.
       */
      disabled: {
        type: Boolean,
        default: false,
      },

      /**
       * Shows a progress loader in the component.
       */
      progress: {
        type: Boolean,
        default: false,
      },

      /**
       * Allows to change the default field, from which the value is taken for each option.
       */
      valueField: {
        type: String,
        default: 'value',
      },

      /**
       * Allows to change the default field, from which the label text is taken for each option.
       */
      labelField: {
        type: String,
        default: 'label',
      },
    },

    // eslint-disable-next-line vue/require-emit-validator -- Disabled because value is of type 'unknown'
    emits: ['update:modelValue', 'close', 'changeOption'],

    setup(props): Setup {
      const searchField = ref();
      const fieldWrapper = ref();

      return {
        ...useFormStates(toRefs(props).state),
        ...useUuid(),
        searchField,
        fieldWrapper,
      };
    },

    data(): Data {
      return {
        /**
         * @type {boolean} Holds the internal opening state of the options.
         */
        isOpen: false,

        /**
         * @type {string} Holds the value fo the search input field.
         */
        searchTerm: '',
      };
    },

    computed: {
      /**
       * Defines state modifier classes.
       *
       * @returns  {object}   BEM classes
       */
      modifiers(): Modifiers {
        return {
          ...this.stateModifiers,
          disabled: this.isDisabled,
        };
      },

      /**
       * V-model handler for the checkboxes (options).
       */
      internalValue: {
        get(): string[] {
          return this.modelValue;
        },
        set(value: string[]) {
          /**
           * Emits checkbox value e.g. true/false or value
           */
          this.$emit('update:modelValue', value);
        },
      },

      /**
       * Gets the current output value which is either the selected options or a placeholder text if available.
       */
      outputValue(): string {
        if (this.selectionAsString) {
          return this.selectionAsString;
        }

        if (typeof this.placeholder === 'string') {
          return this.placeholder;
        }

        return this.placeholder ? this.$t('e-multiselect.defaultPlaceholder') : '';
      },

      /**
       * Gets a combined string of all the currently selected labels.
       *
       * @returns {string}
       */
      selectionAsString(): string {
        if (this.internalValue.length) {
          return this.options
            .filter(option => this.internalValue.includes(option[this.valueField]))
            .map(option => option[this.labelField])
            .join(', ');
        }

        return '';
      },

      /**
       * Shows if the disabled state of the component should be active.
       *
       * @returns {boolean}
       */
      isDisabled(): boolean {
        return this.disabled || this.progress;
      },

      /**
       * Gets the filtered options if the user used the search.
       *
       * @returns {array.<object>}
       */
      filteredOptions(): Option[] {
        if (this.hasSearch && this.searchTerm) {
          return this.options.filter(option => option[this.labelField].includes(this.searchTerm));
        }

        return this.options;
      },
    },
    watch: {
      /**
       * Observes the "isOpen" property and sets the focus on the search field if it's available.
       *
       * @param {boolean} open - The open state.
       */
      isOpen(open) {
        if (this.hasSearch && open) {
          this.$nextTick(() => {
            this.searchField?.focus();
          });
        }
      },
    },

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeDestroy() {},
    // destroyed() {},

    methods: {
      /**
       * Close options event handler.
       */
      close() {
        if (this.disabled) {
          return;
        }

        this.isOpen = false;
        this.searchTerm = '';

        /**
         * Emits the closing event to the parent.
         *
         * @event close
         * @type {array.<string>}
         */
        this.$emit('close', this.internalValue);
      },

      /**
       * Emits change option event to the parent.
       */
      onChangeOption(value: Value, isChecked: boolean) {
        this.$emit('changeOption', value, isChecked);
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '../setup/scss/variables';
  @use '../setup/scss/mixins';

  .e-multiselect {
    $this: &;
    $e-multiselect-height: 30px;

    position: relative;
    display: block;

    &__field-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      min-height: $e-multiselect-height;
      border-bottom: 2px solid variables.$color-grayscale--0;
      background-color: variables.$color-grayscale--1000;
      cursor: pointer;
      font-weight: initial;

      &:focus {
        outline: none;
      }

      .e-icon {
        transition: transform variables.$transition-duration--200 linear;
      }
    }

    &__field-wrapper--open {
      border: none;

      #{$this}__arrow-icon {
        transform: rotate(180deg);
      }

      #{$this}__output-value {
        padding-bottom: 2px;
      }
    }

    &__output-value {
      max-width: calc(100% - 20px);
      overflow: hidden;
      text-align: left;
      white-space: nowrap;
      text-overflow: ellipsis;

      &--active {
        color: variables.$color-primary--1;
        font-weight: variables.$font-weight--bold;
      }
    }

    .e-checkbox__label-text {
      font-weight: variables.$font-weight--bold;
    }

    &__options-wrapper {
      @include mixins.z-index(dropdown);

      position: absolute;
      top: 100%;
      left: 0;
      display: block;
      width: 100%;
      max-height: 300px;
      padding: variables.$spacing--10 0;
      overflow: auto;
      border-top: 0;
      border-bottom: 2px solid variables.$color-primary--1;
      background-color: variables.$color-grayscale--1000;
      transform-origin: top;
    }

    &__options-list {
      overflow: hidden; // needed to fix weird dropdown height issue
    }

    &__options-item {
      padding: variables.$spacing--5 variables.$spacing--5;
    }

    &__progress-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 100%;
      padding-right: variables.$spacing--10;
      color: variables.$color-grayscale--0;
    }

    &__search-field {
      width: 100%;
      min-height: $e-multiselect-height;
      padding: 0 variables.$spacing--0;
      outline: none;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }

    &__icon-wrapper {
      padding-top: 2px;
    }

    &--disabled {
      #{$this}__output-value {
        color: variables.$color-grayscale--400;
      }

      #{$this}__field-wrapper {
        border-color: variables.$color-grayscale--400;
        color: variables.$color-grayscale--400;
        pointer-events: none;
      }
    }

    // States
    &--state-error {
      #{$this}__field-wrapper {
        @include mixins.icon(error, 22px, right 5px center, false); // FF does not support mask on <select>.

        border-color: currentColor;
        color: variables.$color-status--error;

        &:hover,
        &:focus {
          border-color: currentColor;
        }

        &--open {
          .e-multiselect__icon-wrapper {
            padding-bottom: 2px;
          }
        }
      }

      #{$this}__output-value {
        color: currentColor;
      }
    }

    &--state-success {
      #{$this}__field-wrapper {
        @include mixins.icon(check, 22px, right 5px center, false); // FF does not support mask on <select>.
      }
    }

    // Transition
    .top-slide-enter-active,
    .top-slide-leave-active {
      transition: all variables.$transition-duration--200 ease-in-out;
    }

    .top-slide-enter,
    .top-slide-leave-to {
      opacity: 0;
      transform: scaleY(0);
    }

    .top-slide-enter-to,
    .top-slide-leave {
      opacity: 1;
      transform: scaleY(1);
    }
  }
</style>
