<template>
  <div :class="b({ variant })">
    <c-collapse-group v-if="variant === Variant.Grouped" :one-open="false">
      <c-collapse v-for="parentCategory in mappedCategoryGroups"
                  :key="parentCategory.code"
                  :title="parentCategory.name"
                  :open="$viewport.isSm"
      >
        <ul :class="b('list')">
          <li v-for="category in parentCategory.categories"
              :key="category.code"
              :class="b('item')"
          >
            <a :href="addContextPathToUrl(category.url)"
               :class="b('link', { selected: category.selected })"
            >
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span v-html="category.term" :class="b('category-name')"></span>
            </a>
          </li>
        </ul>
      </c-collapse>
    </c-collapse-group>
    <component v-else
               :is="$viewport.isSm || variant === Variant.Images ? 'div' : 'c-collapse'"
               :title="mobileCollapseTitle"
    >
      <ul :class="b('list')">
        <li v-for="category in mappedCategories"
            :key="category.code"
            :class="b('item')"
        >
          <a :href="addContextPathToUrl(category.url)"
             :class="b('link', { selected: category.selected })"
          >
            <e-picture v-if="variant === Variant.Images && category.picture"
                       :srcset="category.picture.srcset"
                       :ratio="1"
                       :fallback="category.picture.fallback"
                       :alt="category.picture.alt"
                       :sizes="pictureSizes"
            />
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="category.term" :class="b('category-name')"></span>
          </a>
        </li>
      </ul>
    </component>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import productResultStore from '@/stores/product-result';
  import ePicture from '@/elements/e-picture.vue';
  import cCollapseGroup from '@/components/c-collapse-group.vue';
  import cCollapse from '@/components/c-collapse.vue';
  import mapMediaContainerSrcSet from '@/helpers/map-media-container-srcset';
  import addContextPathToUrl from '@/helpers/add-context-path-to-url';
  import groupCategoriesByThreshold, { CategoriesGrouped, CategoryGroup } from '@/helpers/group-categories-by-threshold';
  import { ImageSrcset } from '@/types/image';
  import { CategorySuggestion } from '@/types/category-suggestion';
  import { ImageSizes } from '@/types/sizes';

  enum Variant {
    Default = 'default',
    Images = 'images',
    Grouped = 'grouped',
  }

  interface Setup {
    pictureSizes: ImageSizes;
    productResultStore: ReturnType<typeof productResultStore>;
    Variant: typeof Variant;
    addContextPathToUrl: typeof addContextPathToUrl;
  }
  // interface Data {}

  interface Category extends CategorySuggestion {
    picture: ImageSrcset | undefined;
  }

  /**
   * Renders a list of category links.
   * Does have different display variants (images, grouped, default).
   *
   * **WARNING: uses 'v-html'. Make sure, that the source for this data is trustworthy.**
   */
  export default defineComponent({
    name: 'c-category-links',

    components: {
      ePicture,
      cCollapse,
      cCollapseGroup,
    },

    // props: {},
    // emits: {},

    setup(): Setup {
      return {
        productResultStore: productResultStore(),
        pictureSizes: {
          xxs: 439,
          xs: 353,
          sm: 306,
          md: 274,
          lg: 255,
          fallback: 210,
        },
        addContextPathToUrl,
        Variant,
      };
    },
    // data(): Data {
    //   return {};
    // },

    computed: {
      /**
       * Returns category grouping threshold.
       */
      groupingThreshold(): number {
        return this.productResultStore.productResultData?.data?.groupingThreshold || 0;
      },

      /**
       * Returns category groups.
       */
      categoriesGrouped(): CategoriesGrouped | null {
        const { getCategories } = this.productResultStore;

        if (!Array.isArray(getCategories)) {
          return null;
        }

        return groupCategoriesByThreshold(getCategories, this.groupingThreshold);
      },

      /**
       * Returns category groups mapped for rendering collapse elements.
       */
      mappedCategoryGroups(): CategoryGroup[] {
        const { groups, remainingCategories } = this.categoriesGrouped || {};
        const mappedCategoryGroups = [];

        if (groups?.length) {
          mappedCategoryGroups.push(...groups.map(categoryGroup => ({
            ...categoryGroup,
            name: categoryGroup.name || this.$t('c-category-links.defaultParentCategoryName'),
          })));
        }

        if (remainingCategories?.length) {
          mappedCategoryGroups.push({
            code: 'remaining-categories',
            name: groups?.length
              ? this.$t('c-category-links.collapseTitleAdditionalCategories')
              : this.$t('c-category-links.collapseTitleCategories'),
            categories: remainingCategories,
          });
        }

        return mappedCategoryGroups;
      },

      /**
       * Returns the display variant.
       */
      variant(): Variant {
        if (this.productResultStore.getSearchResult?.showCategoriesWithImages) {
          return Variant.Images;
        }

        if (this.groupingThreshold > 0) {
          return Variant.Grouped;
        }

        return Variant.Default;
      },

      /**
       * Returns categories enriched with mapped media container.
       */
      mappedCategories(): Category[] {
        return this.productResultStore.getCategories.map(category => ({
          ...category,
          picture: category.categoryImage && mapMediaContainerSrcSet(category.categoryImage),
        }));
      },

      /**
       * Returns the title for the mobile collapse.
       */
      mobileCollapseTitle(): string {
        return this.productResultStore.getCategories.find(category => category.selected)?.term
          || this.$t('c-category-links.collapseTitleFallback');
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use 'sass:math';
  @use '@/setup/scss/variables';
  @use '@/setup/scss/mixins';

  .c-category-links {
    $this: &;

    &__list {
      display: flex;
      gap: variables.$spacing--5 variables.$spacing--40;
      flex-flow: wrap;
    }

    &__link {
      @include mixins.font(variables.$font-size--18, variables.$line-height--25, variables.$font-weight--bold);
      @include mixins.link--primary();
      @include mixins.hyphens();
    }

    &__category-name {
      @include mixins.hyphens();
    }

    &--variant-grouped,
    &--variant-default {
      #{$this}__link {
        border-bottom-color: transparent;

        &--selected,
        &:hover,
        &:focus {
          border-bottom-color: variables.$color-primary--1;
          color: variables.$color-primary--1;
        }
      }
    }

    &--variant-grouped {
      #{$this}__item {
        width: calc(50% - variables.$spacing--20);

        @include mixins.media(sm) {
          width: auto;
        }
      }

      #{$this}__link {
        @include mixins.font(variables.$font-size--16);
      }

      .c-collapse {
        @include mixins.media(sm) {
          margin-bottom: variables.$spacing--15;
        }
      }
    }

    &--variant-images {
      #{$this}__list {
        display: grid;
        grid-gap: variables.$spacing--40 variables.$spacing--20;
        grid-template-columns: repeat(2, 1fr);

        @include mixins.media(sm) {
          grid-template-columns: repeat(3, 1fr);
        }

        @include mixins.media(md) {
          grid-template-columns: repeat(4, 1fr);
        }

        @include mixins.media(lg) {
          grid-template-columns: repeat(5, 1fr);
        }

        @include mixins.media(xl) {
          grid-template-columns: repeat(6, 1fr);
        }
      }

      #{$this}__link {
        @include mixins.hyphens();

        display: grid;
        grid-template-rows: auto 1fr;
        height: 100%;
        padding-bottom: variables.$spacing--5;
        text-align: center;

        &:hover,
        &:focus {
          color: variables.$color-primary--1;
        }
      }

      #{$this}__category-name {
        align-self: end;
      }
    }

    .c-collapse {
      border-bottom: 2px solid variables.$color-grayscale--0;
    }
  }
</style>
