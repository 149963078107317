import { DirectiveBinding } from 'vue';
import { CustomDirective } from '@/types/custom-directive';

function escapeRegExp(input: string): string {
  return input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
}

/**
 * Updates the highlighting.
 *
 * **WARNING XSS: Make sure content is trustworthy because HTML will be placed.
 */
function update(el: HTMLElement, binding: DirectiveBinding): void {
  const { value } = binding || {};
  const { content, query } = value || {};
  const highlightClassName = 'v-highlight-string';

  if (!query) {
    return;
  }

  el.innerHTML = content
    .replaceAll(new RegExp(escapeRegExp(query), 'gi'), (match: string) => `<span class="${highlightClassName}">${match}</span>`);
}

/**
 * Directive for highlighting a part of string.
 *
 * Example:
 *
 * <div v-highlight-string="{ content: 'Text containing something to MY-HIGHLIGHT-QUERY', query: 'MY-HIGHLIGHT-QUERY' }"></div>
 */
export default {
  name: 'highlight-string',

  directive: {
    beforeMount: update,
    beforeUpdate: update,
  },
} satisfies CustomDirective;
