<template>
  <ul :class="b({ highlightLastItem })">
    <li v-for="(item, index) in itemsInternal"
        :key="item.url"
        :class="b('item')"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <a v-html="item.name"
         :class="b('link')"
         :href="addContextPathToUrl(item.url)"
         :title="$t('c-breadcrumb.linkTitle', { name: item.name })"
         @click="onItemClick(item, index, $event)"
      >
      </a>
    </li>
  </ul>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import addContextPathToUrl from '@/helpers/add-context-path-to-url';
  import breadcrumbStore, { BreadcrumbItem } from '@/stores/breadcrumb';

  interface Setup {
    breadcrumbStore: ReturnType<typeof breadcrumbStore>;
    addContextPathToUrl: typeof addContextPathToUrl;
  }

  /**
   * Renders a list of breadcrumbs items from the store module.
   *
   * **WARNING: uses 'v-html' for the breadcrumb label. Make sure, that the source for this data is trustworthy.**
   */
  export default defineComponent({
    name: 'c-breadcrumb',

    // components: {},

    props: {
      /**
       * Accepts a custom list of breadcrumb items.
       */
      items: {
        type: Array as PropType<BreadcrumbItem[] | null>,
        default: null,
      },

      /**
       * Highlights the last entry as "active" element.
       */
      highlightLastItem: {
        type: Boolean,
        default: true,
      },
    },
    emits: {
      click: (payload: BreadcrumbItem, index: number, event: MouseEvent) => !!payload && Number.isInteger(index) && !!event,
    },

    setup(): Setup {
      return {
        breadcrumbStore: breadcrumbStore(),
        addContextPathToUrl,
      };
    },
    // data() {
    //   return {};
    // },

    computed: {
      itemsInternal(): BreadcrumbItem[] {
        return this.items || [
          {
            name: this.$t('c-breadcrumb.home'),
            url: '/',
          },
          ...this.breadcrumbStore.items,
        ];
      },
    },
    // watch: {},

    // beforeCreate() {},
    created() {
      this.breadcrumbStore.hasBreadcrumbComponent = true;
    },
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      /**
       * Forwards clicks on a breadcrumb item to the parent.
       */
      onItemClick(item: BreadcrumbItem, index: number, event: MouseEvent) {
        this.$emit('click', item, index, event);
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '../setup/scss/variables';
  @use '../setup/scss/mixins';

  .c-breadcrumb {
    $this: &;

    @include mixins.media(sm) {
      border-bottom: 1px solid variables.$color-grayscale--0;
    }

    &__item {
      display: inline-flex;
      align-items: center;

      &::after {
        content: '';
        width: 30px;
        height: 30px;
        background: no-repeat url('@/assets/icons/i-arrow--right.svg') center;
        background-size: 10px;

        @include mixins.media($media: print) {
          display: flex;
          content: '>';
          justify-content: center;
          align-items: baseline;
          background: none;
        }
      }
    }

    &--highlight-last-item {
      #{$this}__item:last-child {
        #{$this}__link {
          color: variables.$color-primary--1;
        }
      }
    }

    &__link {
      @include mixins.font(variables.$font-size--16, variables.$line-height--20, variables.$font-weight--bold);

      border-bottom-color: transparent;
      color: variables.$color-grayscale--0;

      @include mixins.media($media: print) {
        font-weight: variables.$font-weight--regular;
      }
    }

    &__item:last-child {
      &::after {
        content: none;
      }
    }
  }
</style>
