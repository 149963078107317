<template>
  <form :class="b()" @submit.prevent="onSubmit">
    <span v-if="showTitle" :class="b('title')">
      {{ $t('c-quick-add-to-cart.titleQuickAddToCart') }}:
    </span>
    <e-label :name="$t('c-quick-add-to-cart.labelArticleNumber')" :class="b('article-number')">
      <e-input
        v-model="code"
        :placeholder="$t('c-quick-add-to-cart.labelCode')"
        name="product-code"
      />
    </e-label>
    <e-label v-if="sessionStore.flags.showCommissions"
             :name="$t('c-quick-add-to-cart.labelCommission')"
             :class="b('commission')"
             tag="div"
             inner-tag="div"
    >
      <e-input-commission :selected-commission-id="sessionStore.globalCommission?.id"
                          @update="onUpdateCommission"
      />
    </e-label>
    <e-quantity-select v-model="quantity" :class="b('quantity-select')" />
    <e-button :class="b('submit-button')"
              :aria-label="$t('c-quick-add-to-cart.buttonSubmit')"
              :progress="isLoading"
              height="200"
              type="submit"
    >
      <e-icon icon="i-cart" size="22" />
    </e-button>
  </form>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import useCheckoutStore from '@/stores/checkout';
  import useSessionStore from '@/stores/session';
  import eLabel from '@/elements/e-label.vue';
  import eInput from '@/elements/e-input.vue';
  import eQuantitySelect from '@/elements/e-quantity-select.vue';
  import eButton from '@/elements/e-button.vue';
  import eIcon from '@/elements/e-icon.vue';
  import eInputCommission from '@/elements/e-input-commission.vue';
  import { Commission } from '@/types/commission';
  import { GaListName } from '@/plugins/google-tag-manager';

  interface Setup {
    checkoutStore: ReturnType<typeof useCheckoutStore>;
    sessionStore: ReturnType<typeof useSessionStore>;
  }

  interface Data {
    quantity: number;
    code: string;
    isLoading: boolean;
  }

  /**
   * Renders the quick add to cart form.
   */
  export default defineComponent({
    name: 'c-quick-add-to-cart',

    components: {
      eInputCommission,
      eIcon,
      eButton,
      eQuantitySelect,
      eInput,
      eLabel,
    },

    props: {
      /**
       * Defines whether the title is displayed.
       */
      showTitle: {
        type: Boolean,
        default: false,
      },

      /**
       * Expects a GTM list name to be passed.
       */
      gtmListName: {
        type: String as PropType<GaListName>,
        required: true,
      },

    },
    // emits: {},

    setup(): Setup {
      return {
        checkoutStore: useCheckoutStore(),
        sessionStore: useSessionStore(),
      };
    },

    data(): Data {
      return {
        quantity: 1,
        code: '',
        isLoading: false,
      };
    },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      /**
       * Tries to add a product by the given code to the cart.
       */
      onSubmit(): void {
        const { quantity, code } = this;

        if (!(quantity > 0) || !code) {
          return;
        }

        this.isLoading = true;
        this.checkoutStore.apiAddToCart({
          product: {
            code: `${code}`,
          },
          quantity,
          commissionId: this.sessionStore.globalCommission?.id || '',
        }, true).then(() => {
          this.code = '';
          this.quantity = 1;

          this.$gtm.pushAddToCart({
            item_id: code,
            quantity,
          }, this.gtmListName);
        }).finally(() => {
          this.isLoading = false;
        });
      },

      /**
       * Updates the global commission.
       */
      onUpdateCommission(commission: Commission | null) {
        this.sessionStore.setGlobalCommission(commission);
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-quick-add-to-cart {
    display: flex;
    gap: variables.$spacing--20;
    flex-wrap: wrap;
    align-items: flex-end;

    @include mixins.media(sm) {
      flex-wrap: nowrap;
    }

    &__title {
      font-weight: variables.$font-weight--bold;
    }

    &__article-number,
    &__commission {
      flex: 1 0 100%;

      @include mixins.media(sm) {
        flex: 0 0 auto;
      }

      @include mixins.media(lg) {
        min-width: 260px;
      }
    }

    &__quantity-select,
    &__submit-button {
      flex: 1 0 auto;

      @include mixins.media(sm) {
        flex-grow: 0;
      }
    }

    &__quantity-select {
      @include mixins.media(lg) {
        width: 130px;
      }
    }

    .e-label__name {
      display: none;
    }
  }
</style>
