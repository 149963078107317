<template>
  <div :class="b(modifiers)">
    <component v-bind="cmsLinkAttributes(banner.link)" :is="banner.link ? 'a' : 'div'" @click="onClick">
      <div :class="b('inner')">
        <div :class="b('content-wrapper')">
          <div v-if="banner.headline1" :class="b('headline-1')">
            {{ banner.headline1 }}
          </div>
          <div v-if="banner.headline2" :class="b('headline-2')">
            {{ banner.headline2 }}
          </div>
          <span v-if="banner.link && banner.headlineTheme" :class="b('link-indicator')">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-if="banner.link.name" v-html="banner.link.name"></span>
            <e-icon icon="i-arrow-2--right" size="18" />
          </span>
        </div>

        <div v-if="banner.infoText" :class="b('action-bubble')">
          {{ banner.infoText }}
        </div>
      </div>
      <e-picture v-bind="mappedImage"
                 :sizes="sizes"
                 :ratio="$viewport.isSm ? 1920 / 450 : 375 / 600"
                 :fetch-priority="isLcpCandidate ? 'high' : 'auto'"
                 :loading="isLcpCandidate ? 'eager' : 'lazy'"
                 @load="onLoad"
      />
    </component>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import ePicture from '@/elements/e-picture.vue';
  import eIcon from '@/elements/e-icon.vue';
  import cmsLinkAttributes from '@/helpers/cms-link-attributes';
  import mapMediaContainerSrcSet, { MediaFormatFilter } from '@/helpers/map-media-container-srcset';
  import { ImageSizes } from '@/types/sizes';
  import { CmsBanner } from '@/types/cms-banner';
  import { ImageSources } from '@/types/image';
  import { BREAKPOINTS, BREAKPOINTS_MAX } from '@/setup/globals';
  import { Modifiers } from '@/plugins/vue-bem-cn/src/globals';

  type Setup = {
    sizes: ImageSizes;
    cmsLinkAttributes: typeof cmsLinkAttributes;
  };

  export enum Themes {
    Theme1 = 'theme1',
    Theme2 = 'theme2',
    Theme3 = 'theme3',
    Theme4 = 'theme4',
    Theme5 = 'theme5',
    Theme6 = 'theme6',
  }

  export enum InfoTextTheme {
    Theme1 = 'theme1',
    Theme2 = 'theme2',
    Theme3 = 'theme3',
  }

  export enum InfoTextStyle {
    RoundFontSizeRegular = 'ROUND_FONT_SIZE_REGULAR',
    RoundFontSizeBig = 'ROUND_FONT_SIZE_BIG',
  }

  // type Data = {}

  /**
   * Renders an image banner with an optional link around it.
   *
   * **WARNING: uses 'v-html' for the 'banner.link.name'. Make sure, that the source for this data is trustworthy.**
   */
  export default defineComponent({
    name: 'c-banner',

    components: {
      ePicture,
      eIcon,
    },

    props: {
      /**
       * Receives cms banner object.
       */
      banner: {
        type: Object as PropType<CmsBanner>,
        required: true,
      },

      /**
       * Allows passing an index for banners that are part of a carousel.
       */
      carouselIndex: {
        type: Number,
        default: undefined,
      },

      /**
       * Allows defining if the banner is an LCP candidate.
       */
      isLcpCandidate: {
        type: Boolean,
        default: false,
      },
    },
    emits: {
      load: () => true,
    },

    setup(): Setup {
      return {
        sizes: {
          xxs: 439,
          xs: 727,
          sm: 951,
          md: 1159,
          fallback: 1360,
        },
        cmsLinkAttributes,
      };
    },
    // data(): Data {},
    computed: {
      mappedImage(): ImageSources {
        const mappedMainImageWide = mapMediaContainerSrcSet(this.banner.mainImageWide, MediaFormatFilter.Banner);
        const mappedMainImageNarrow = mapMediaContainerSrcSet(this.banner.mainImageNarrow, MediaFormatFilter.Banner);

        // Define sources based on our media queries
        const sources = {
          [`(max-width: ${BREAKPOINTS_MAX.xs}px)`]: mappedMainImageNarrow?.srcset,
          [`(min-width: ${BREAKPOINTS.sm}px)`]: mappedMainImageWide?.srcset,
        };

        return {
          sources,
          fallback: mappedMainImageNarrow?.fallback,
          alt: mappedMainImageNarrow?.alt,
        };
      },

      modifiers(): Modifiers {
        const {
          heroBanner,
          headlineTheme,
          infoTextStyle,
          infoTextTheme,
        } = this.banner;

        return {
          heroBanner,
          headlineTheme: headlineTheme || Themes.Theme1,
          infoTextStyle: infoTextStyle
            ? infoTextStyle.toLowerCase().replaceAll('_', '-')
            : InfoTextStyle.RoundFontSizeRegular,
          infoTextTheme: infoTextTheme || InfoTextTheme.Theme1,
        };
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},
    // mounted() {},

    methods: {
      onClick(): void {
        if (this.banner.link) {
          this.$gtm.pushSelectPromotion(
            `${this.banner.headline1} ${this.banner.headline2}`,
            this.mappedImage.fallback,
            this.carouselIndex || 0
          );
        }
      },

      onLoad(): void {
        this.$gtm.pushViewPromotion(
          `${this.banner.headline1} ${this.banner.headline2}`,
          this.mappedImage.fallback,
          this.carouselIndex || 0
        );

        this.$emit('load');
      },
    },
  // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-banner {
    $this: &;

    position: relative;

    &__inner {
      @include mixins.z-index(front);

      position: absolute;
      left: 50%;
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      height: 100%;
      transform: translate(-50%, 0%);
      padding-inline: variables.$spacing--20;

      @include mixins.media(sm) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: variables.$spacing--20 variables.$spacing--50;
      }

      @include mixins.media(md) {
        align-items: center;
        padding-inline: variables.$spacing--50;
      }
    }

    &__action-bubble {
      @include mixins.font(variables.$font-size--18,
        variables.$line-height--20,
        variables.$font-weight--bold);
      @include mixins.hyphens();

      display: flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      min-width: 120px;
      height: 120px;
      padding: variables.$spacing--10;
      border-radius: 100%;
      background-color: var(--action-bubble-background-color);
      color: var(--action-bubble-color);
      text-align: center;

      @include mixins.media($down: xs) {
        position: absolute;
        top: 10%;
        right: 5%;
      }

      @include mixins.media(sm) {
        align-self: flex-start;
      }

      @include mixins.media(lg) {
        position: absolute;
        top: 10%;
        right: 5%;
      }
    }

    &__content-wrapper {
      width: 100%;
      padding-bottom: variables.$spacing--100;

      @include mixins.media(sm) {
        padding-bottom: variables.$spacing--0;
      }
    }

    &__link-indicator {
      @include mixins.font(variables.$font-size--18,
        variables.$line-height--25,
        variables.$font-weight--bold);

      display: inline-flex;
      gap: variables.$spacing--10;
      align-items: center;
      margin-top: variables.$spacing--20;
      border-bottom: 2px solid var(--headline-1-color);
      color: var(--headline-1-color);
      padding-block: variables.$spacing--5;
    }

    &__headline-1,
    &__headline-2 {
      $min_width: 320px;
      $max_width: 1800px;
      $min_font: 20px;
      $max_font: 110px;

      @include mixins.fluid-font-size($min_width, $max_width, $min_font, $max_font);
      @include mixins.hyphens();

      margin: 0;
      font-weight: variables.$font-weight--bold;
      line-height: 1;

      @include mixins.media(sm) {
        $max_font: 70px;

        @include mixins.fluid-font-size($min_width, $max_width, $min_font, $max_font);
      }

      @include mixins.media(lg) {
        $max_font: 80px;

        @include mixins.fluid-font-size($min_width, $max_width, $min_font, $max_font);
      }
    }

    &__headline-1 {
      color: var(--headline-1-color);
    }

    &__headline-2 {
      padding-left: variables.$spacing--40;
      color: var(--headline-2-color);

      @include mixins.media(md) {
        padding-left: variables.$spacing--80;
      }
    }

    &--hero-banner {
      #{$this}__inner {
        @include mixins.layout();
      }
    }

    &--info-text-style-round-font-size-big &__action-bubble {
      @include mixins.font(variables.$font-size--25, variables.$line-height--30, variables.$font-weight--bold);
    }
  }

  // Theme 1
  .shop-theme--theme-01 .c-banner {
    // Headline themes
    &--headline-theme-theme1 {
      --headline-1-color: #{variables.$color-grayscale--0};
      --headline-2-color: #{variables.$color-primary--1};
    }

    &--headline-theme-theme2 {
      --headline-1-color: #{variables.$color-grayscale--1000};
      --headline-2-color: #{variables.$color-primary--1};
    }

    &--headline-theme-theme3 {
      --headline-1-color: #{variables.$color-grayscale--0};
      --headline-2-color: #{variables.$color-grayscale--1000};
    }

    &--headline-theme-theme4 {
      --headline-1-color: #{variables.$color-grayscale--1000};
      --headline-2-color: #{variables.$color-grayscale--1000};
    }

    &--headline-theme-theme5 {
      --headline-1-color: #{variables.$color-grayscale--1000};
      --headline-2-color: #{variables.$color-grayscale--0};
    }

    &--headline-theme-theme6 {
      --headline-1-color: #{variables.$color-grayscale--0};
      --headline-2-color: #{variables.$color-grayscale--0};
    }

    // Info  text (action bubble) themes
    &--info-text-theme-theme1 {
      --action-bubble-color: #{variables.$color-grayscale--1000};
      --action-bubble-background-color: #{variables.$color-primary--1};
    }

    &--info-text-theme-theme2 {
      --action-bubble-color: #{variables.$color-grayscale--0};
      --action-bubble-background-color: #{variables.$color-grayscale--1000};
    }

    &--info-text-theme-theme3 {
      --action-bubble-color: #{variables.$color-grayscale--1000};
      --action-bubble-background-color: #{variables.$color-grayscale--0};
    }
  }

  // Theme 2
  .shop-theme--theme-02 .c-banner {
    // Headline themes
    &--headline-theme-theme1 {
      --headline-1-color: #{variables.$color-primary--1};
      --headline-2-color: #{variables.$color-secondary--1};
    }

    &--headline-theme-theme2 {
      --headline-1-color: #{variables.$color-grayscale--1000};
      --headline-2-color: #{variables.$color-secondary--1};
    }

    &--headline-theme-theme3 {
      --headline-1-color: #{variables.$color-primary--1};
      --headline-2-color: #{variables.$color-grayscale--0};
    }

    // Info  text (action bubble) themes
    &--info-text-theme-theme1 {
      --action-bubble-color: #{variables.$color-grayscale--0};
      --action-bubble-background-color: #{variables.$color-secondary--1};
    }

    &--info-text-theme-theme2 {
      --action-bubble-color: #{variables.$color-grayscale--1000};
      --action-bubble-background-color: #{variables.$color-primary--1};
    }
  }
</style>
