function base64ToBytes(base64: string): Uint8Array {
  const binString = atob(base64);
  const byteValues = new Uint8Array(binString.length);

  for (let i = 0; i < binString.length; i++) {
    byteValues[i] = binString.charCodeAt(i);
  }

  return byteValues;
}

/**
 * Decodes the given base64 string.
 */
export default function decodeBase64(string: string): string {
  try {
    return new TextDecoder().decode(base64ToBytes(string));
  } catch {
    return string;
  }
}
