import { RowComponent, VueComponent } from '@/components/c-row-container.vue';
import { VideoSource } from '@/elements/e-video.vue';
import mapCmsMarketingParagraphProps from '@/helpers/map-cms-marketing-paragraph-props';
import mapColumnWidth from '@/helpers/map-column-width';
import { CmsRowContainer } from '@/types/cms-row-container';
import { HybrisType } from '@/setup/globals';
import mapNewsletterRegistrationProps from './map-newsletter-registration-props';
import mapContactPersonProps from '@/helpers/map-contact-person-props';

/**
 * Creates an array with mapped row components.
 */
export default function mapHybrisRowContainerComponents(hybrisRowContainer: CmsRowContainer): RowComponent[] {
    const mappedHybrisComponents = hybrisRowContainer.rowComponents.reduce((acc, component) => { // .reduce was used because TS did not allow .filter.map combination.
          switch (component.type) {
            case HybrisType.MarketingParagraph:
              acc.push({
                attributes: mapCmsMarketingParagraphProps(component),
                name: VueComponent.MarketingParagraph,
              });

              break;

            case HybrisType.YoutubeVideo:
              acc.push({
                attributes: {
                  source: VideoSource.Youtube,
                  width: mapColumnWidth(component.width),
                  videoId: component.youtubeId,
                  headline: component.headline,
                  headlineTag: component.headlineTag,
                },
                name: VueComponent.YoutubeVideo,
              });

              break;

            case HybrisType.NewsletterRegistration:
              acc.push({
                attributes: mapNewsletterRegistrationProps(component),
                name: VueComponent.NewsletterRegistration,
              });

              break;

            case HybrisType.ContactPersonComponent:
              acc.push({
                attributes: mapContactPersonProps(component),
                name: VueComponent.ContactPerson,
              });

              break;

            case HybrisType.M2mCalculator:
              acc.push({
                name: VueComponent.M2mCaclulator,
              });

              break;

            case HybrisType.OxomiPortalPage:
              acc.push({
                attributes: {
                  portal: component.portal,
                  page: component.page,
                  width: mapColumnWidth(component.width),
                },
                name: VueComponent.OxomiPortalPage,
              });

              break;

            case HybrisType.OxomiEmbedCatalog:
              acc.push({
                attributes: {
                  portal: component.portal,
                  showDetails: component.showDetails,
                  catalogId: component.catalogId,
                  width: mapColumnWidth(component.width),
                },
                name: VueComponent.OxomiEmbedCatalog,
              });

              break;

            // no default
          }

          return acc;
        }, [] as RowComponent[]);

   return mappedHybrisComponents;
}

