<template>
  <div :class="b()">
    <e-label tag="div">
      <template #name>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="facet.name"></span>
      </template>
      <e-multiselect v-model="value"
                     :options="options"
                     :disabled="disabled"
                     :has-search="options.length > 5"
                     @change-option="onChangeOption"
      />
    </e-label>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { Facet } from '@/types/facet';
  import eMultiselect, { Option } from '@/elements/e-multiselect.vue';
  import eLabel from '@/elements/e-label.vue';

  // interface Setup {}
  interface Data {
    value: string[];
    resetApiUrl?: string ;
  }

  /**
   * Renders a multi-select filter facet.
   */
  export default defineComponent({
    name: 'c-facet-multi-select',

    components: { eLabel, eMultiselect },

    props: {
      /**
       * Expects a facet object.
       */
      facet: {
        type: Object as PropType<Facet>,
        required: true,
      },

      /**
       * Allows passing disabled state.
       */
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    emits: {
      update: (payload: string) => !!payload,
    },

    // setup(): Setup {},
    data(): Data {
      return {
        value: [],
      };
    },

    computed: {
      /**
       * Returns facet values mapped as multi select options.
       */
      options(): Option[] {
        return this.facet.values.map(value => ({
          value: value.code,
          label: value.name,
        }));
      },
    },
    watch: {
      /**
       * Observes provided facet for changes and updates multi select value.
       */
      facet: {
        handler(facet: Facet) {
          this.value = facet.values?.filter(value => value.selected).flatMap(value => `${value.code}`) || [];
        },
        deep: true,
        immediate: true,
      },
    },

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      /**
       * Handles update.
       */
      onChangeOption(value: string): void {
        const facetValue = this.facet.values.find(facetValueItem => `${facetValueItem.code}` === value);

        if (!facetValue?.query) {
          return;
        }

        this.$emit('update', facetValue.query.apiUrl);
        this.$gtm.pushSelectFilter(this.facet, value);
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  // @use '@/setup/scss/variables';

  .c-facet-multi-select {
    // ...
  }
</style>
