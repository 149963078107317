<template>
  <div v-if="checkoutStore.cart" :class="b()">
    <div v-outside-click="{ handler: onOutsideClick, excludeIds: ['c-header__mini-cart-toggle'] }"
         :class="b('inner')"
    >
      <h2>
        {{ $t('c-mini-cart.title') }}
      </h2>
      <template v-if="checkoutStore.getCartEntriesAmount">
        <ul :class="b('entry-list')">
          <li v-for="entry in checkoutStore.getCartEntries"
              :key="entry.entryNumber"
              :class="b('entry')"
          >
            <c-mini-cart-entry :entry="entry" />
          </li>
        </ul>
        <div :class="b('footer')">
          <c-checkout-totals v-if="checkoutStore.cart.showPrices && !sessionStore.flags.retailConnectEnabled"
                             :total-price="checkoutStore.cart.totalPriceWithoutFreight"
                             :show-delivery-cost="false"
                             :show-total-tax="false"
                             :class="b('totals')"
          />
          <e-button :href="addContextPathToUrl(sessionStore.links.cartLink.url)"
                    :aria-label="$t('c-mini-cart.linkGotoCart')"
                    width="full"
          >
            {{ $t('c-mini-cart.buttonCheckout') }}
          </e-button>
        </div>
      </template>
      <div v-else :class="b('no-entries-text')">
        {{ $t('c-mini-cart.textNoEntries') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
  import eButton from '@/elements/e-button.vue';
  import useCheckoutStore from '@/stores/checkout';
  import useSessionStore from '@/stores/session';
  import cMiniCartEntry from '@/components/c-mini-cart-entry.vue';
  import cCheckoutTotals from '@/components/c-checkout-totals.vue';
  import addContextPathToUrl from '@/helpers/add-context-path-to-url';
  import { bodyScrollOptions } from '@/setup/options';

  interface Setup {
    checkoutStore: ReturnType<typeof useCheckoutStore>;
    sessionStore: ReturnType<typeof useSessionStore>;
    addContextPathToUrl: typeof addContextPathToUrl;
  }

  // interface Data {}

  /**
   * Renders the mini cart overlay.
   */
  export default defineComponent({
    name: 'c-mini-cart',
    components: {
      cCheckoutTotals,
      cMiniCartEntry,
      eButton,
    },

    // components: {},

    // props: {},
    emits: {
      outsideClick: () => true,
    },

    setup(): Setup {
      return {
        checkoutStore: useCheckoutStore(),
        sessionStore: useSessionStore(),
        addContextPathToUrl,
      };
    },
    // data(): Data {
    //   return {};
    // },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    mounted() {
      disableBodyScroll(this.$el, bodyScrollOptions);
      this.$gtm.pushMiniCartState('open');
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    beforeUnmount() {
      enableBodyScroll(this.$el);
      this.$gtm.pushMiniCartState('close');
    },
    // unmounted() {},

    methods: {
      /**
       * Handles outside click.
       */
      onOutsideClick(): void {
        this.$emit('outsideClick');
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-mini-cart {
    @include mixins.layout();

    display: flex;
    justify-content: flex-end;
    padding-block: variables.$spacing--5 variables.$spacing--20;

    &__inner {
      width: 360px;
      padding: variables.$spacing--40 variables.$spacing--20 0;
      overflow-y: auto;
      background-color: variables.$color-grayscale--1000;
    }

    &__entry-list {
      margin-top: variables.$spacing--30;
      margin-bottom: variables.$spacing--20;
      border-top: 4px solid variables.$color-grayscale--0;
    }

    &__entry {
      padding: variables.$spacing--15 0;

      &:not(:last-of-type) {
        border-bottom: 2px solid variables.$color-grayscale--0;
      }
    }

    &__totals {
      width: 100%;
      min-width: auto;
      margin-bottom: variables.$spacing--20;
    }

    &__no-entries-text {
      padding-bottom: variables.$spacing--40;
    }

    &__footer {
      position: sticky;
      bottom: 0;
      margin-right: - variables.$spacing--20;
      margin-left: - variables.$spacing--20;
      padding: variables.$spacing--20;
      border-top: 2px solid variables.$color-grayscale--0;
      background-color: variables.$color-grayscale--1000;
    }
  }
</style>
