<template>
  <div :class="b({ colSm: width })"></div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { OxomiPortal } from '@/types/oxomi-portal';
  import { ColumnWidth } from '@/components/c-row-container.vue';

  // type Setup = {}

  // type Data = {}

  export type OxomiEmbedCatalogProps = {
    portal: OxomiPortal;
    catalogId: string;
    showDetails?: boolean;
    width?: ColumnWidth;
  }

  /**
   * Renders an Oxomi Embed Catalog.
   */
  export default defineComponent({
    name: 'c-oxomi-embed-catalog',

    // components: {},

    props: {
      /**
       * Expects an oxomi portal.
       */
      portal: {
        type: Object as PropType<OxomiPortal>,
        required: true,
      },

      /**
       * Expects a catalog ID
       */
      catalogId: {
        type: String,
        required: true,
      },

      /**
       * Allows a page to be passed.
       */
      showDetails: {
        type: Boolean,
        default: false,
      },

      /**
       * Allows to set the column width.
       */
      width: {
        type: Number as PropType<ColumnWidth>,
        default: 12,
      },
    },
    // emits: {},

    // setup(): Setup {},
    // data(): Data {},

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    mounted() {
      this.$oxomi(this.portal).then(() => {
        window.oxomi.embedCatalog({
          target: this.$el,
          catalog: this.catalogId,
          showDetails: this.showDetails,
        });
      });
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-oxomi-embed-catalog {
    &--col-sm-4 {
      @include mixins.media(sm) {
        grid-column: span 4;
      }
    }

    &--col-sm-6 {
      @include mixins.media(sm) {
        grid-column: span 6;
      }
    }

    &--col-sm-12 {
      @include mixins.media(sm) {
        grid-column: span 12;
      }
    }

    .oxomi-catalog-details {
      @include mixins.font(variables.$font-size--16, variables.$line-height--18);

      width: auto !important; // stylelint-disable-line declaration-no-important
    }

    .oxomi-border {
      padding: 0;
      border: 0;
    }
  }
</style>
