<template>
  <component :is="type"
             :class="b(modifiers)"
             :style="style"
             v-bind="attributes"
             @touchstart="hasTouch = true"
             @mouseenter="onMouseEnter"
             @mouseleave="onMouseLeave"
             @mousedown="onMouseDown"
             @mouseup="onMouseUp"
             @focus="onFocus"
             @blur="onBlur"
             @click="onClick"
  >
    <e-loader v-if="progress" :spacing="0" />
    <!-- @slot Button content. -->
    <slot v-else></slot>
  </component>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import propScale from '@/helpers/prop.scale';
  import { Modifiers } from '@/plugins/vue-bem-cn/src/globals';
  import eLoader from './e-loader.vue';

  interface Attributes {
    role: string | null;
    disabled: boolean;
    [key: string]: string | boolean | null;
  }

  interface ElementDimensions {
    width: string;
    height: string;
  }

  interface Data {
    hasHover: boolean;
    isActive: boolean;
    hasFocus: boolean;
    hasTouch: boolean;
  }

  /**
   * Renders a `<button>` or `<a>` element (based on existing `href` attribute) with button style.
   * The component uses a `<slot>` to render the content.
   *
   * [You can also define inherited attributes for `<button>`](https://developer.mozilla.org/de/docs/Web/HTML/Element/button#Attribute)
   *
   * [You can also define inherited attributes for `<a>`](https://developer.mozilla.org/de/docs/Web/HTML/Element/a#Attribute)
   */
  export default defineComponent({
    name: 'e-button',

    components: {
      eLoader,
    },

    props: {
      /**
       * Defines the width of the button
       *
       * Valid values: `[full, auto]`
       */
      width: {
        type: String,
        default: null,
        validator: (value: string) => [
          'full',
          'auto',
        ].includes(value),
      },

      /**
       * Modifies the inner spacing for the button.
       */
      spacing: propScale(500, [
        0,
        500,
      ]),

      /**
       * If `true` the button shows a progress animation
       */
      progress: {
        type: Boolean,
        default: false,
      },

      /**
       * Allows defining the styling variant of the button.
       */
      variant: {
        type: String,
        default: 'default',
        validator: (value: string) => [
          'default',
          'secondary',
        ].includes(value),
      },

      /**
       * Allows defining the height of the button.
       */
      height: {
        type: String,
        default: '400',
        validator: (value: string) => [
          '150',
          '200',
          '400',
        ].includes(value),
      },

      /**
       * Forces the hover state
       */
      hover: {
        type: Boolean,
        default: false,
      },

      /**
       * Forces the focus state
       */
      focus: {
        type: Boolean,
        default: false,
      },

      /**
       * Forces the active state
       */
      active: {
        type: Boolean,
        default: false,
      },

      /**
       * Disables the button
       */
      disabled: {
        type: Boolean,
        default: false,
      },

      /**
       * Overwrites the element of the button component.
       * This option to overwrite the default "anchor" or "button" tag should get only used for edge cases where
       * a button has to be inside another anchor tag or similar.
       */
      element: {
        type: String,
        default: null,
      },

      /**
       * Adds modifier for when the button has an icon.
       */
      hasIcon: {
        type: Boolean,
        default: false,
      },
    },

    emits: {
      click(payload: Event): boolean {
        return typeof payload === 'object';
      },
    },

    data(): Data {
      return {
        /**
         * Internal flag to determine hover state.
         */
        hasHover: this.hover,

        /**
         * Internal flag to determine active state.
         */
        isActive: this.active,

        /**
         * Internal flag to determine focus state.
         */
        hasFocus: this.focus,

        /**
         * Determines if the current device uses touch.
         */
        hasTouch: false,
      };
    },

    computed: {
      /**
       * Returns an Object of class modifiers.
       */
      modifiers(): Modifiers {
        return {
          width: this.width,
          spacing: this.spacing,
          progress: this.progress,
          disabled: this.disabled,
          variant: this.variant,
          height: this.height,
          hover: this.hover || this.hasHover,
          focus: this.focus || this.hasFocus,
          active: this.active || this.isActive,
          touch: this.hasTouch,
          hasIcon: this.hasIcon,
        };
      },

      /**
       * Returns an Object of attributes.
       */
      attributes(): Attributes {
        return {
          role: this.$attrs.href ? 'button' : null, // Fallback
          ...this.$attrs,
          disabled: this.disabled || this.progress,
        };
      },

      /**
       * Returns inline styles to keep dimensions during progress state.
       */
      style(): ElementDimensions | null {
        return this.progress && this.width !== 'full'
          ? this.getElementDimensions()
          : null;
      },

      /**
       * Gets the type of the component (DOM element).
       */
      type(): string {
        return this.element || (this.$attrs.href ? 'a' : 'button');
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      /**
       * Mouseenter event handler.
       */
      onMouseEnter() {
        this.hasHover = true;
      },

      /**
       * Mouseleave event handler.
       */
      onMouseLeave() {
        this.hasHover = false;
        this.isActive = false;
      },

      /**
       * Mousedown event handler.
       */
      onMouseDown() {
        this.isActive = true;
      },

      /**
       * Mouseup event handler.
       */
      onMouseUp() {
        this.isActive = false;
      },

      /**
       * Focus event handler.
       */
      onFocus() {
        this.hasFocus = true;
      },

      /**
       * Blur event handler.
       */
      onBlur() {
        this.hasHover = false;
        this.hasFocus = false;
      },

      /**
       * Click event handler.
       */
      onClick(event: Event): void {
        this.$el.blur();

        /**
         * Click event
         */
        this.$emit('click', event);
      },

      /**
       * Returns the current width and height of the button.
       */
      getElementDimensions(): ElementDimensions | null {
        const element = this.$el;

        return element
          ? { width: `${element.offsetWidth}px`, height: `${element.offsetHeight}px` }
          : null;
      },
    },
  });
</script>

<style lang="scss">
  @use 'sass:math';
  @use '../setup/scss/mixins';
  @use '../setup/scss/variables';

  .e-button {
    $border-width: 2px;
    $line-height: 24px;

    @include mixins.button--primary($border-width, $line-height);

    display: flex;

    &--variant-secondary {
      @include mixins.button--secondary($border-width, $line-height);
    }

    &--width-full {
      width: 100%;
    }

    &--width-auto {
      min-width: 0;
    }

    &--spacing-0 {
      padding: 0;
    }

    &--height-150 {
      $vertical-padding: math.div(32px - $line-height - ($border-width * 2), 2);

      padding-top: $vertical-padding;
      padding-bottom: $vertical-padding;
    }

    &--height-200 {
      $vertical-padding: math.div(38px - $line-height - ($border-width * 2), 2);

      padding-top: $vertical-padding;
      padding-bottom: $vertical-padding;
    }

    &--progress,
    &--progress:disabled,
    &--progress:disabled:hover,
    &--progress:disabled:focus,
    &--progress:hover,
    &--progress:focus {
      overflow: hidden; // Prevents overflow of animation
      border-color: transparent;
      background-color: variables.$color-primary--1;

      .e-loader {
        color: variables.$color-grayscale--1000;
        font-size: math.div($line-height, 3);
      }
    }

    &--has-icon {
      position: relative;
      display: flex;
      align-items: center;
      column-gap: variables.$spacing--10;
      padding-inline: variables.$spacing--15;

      .e-icon {
        @include mixins.media($down: xs) {
          width: 18px; // Forces a small icon for the mobile viewports
        }
      }
    }
  }
</style>
