<template>
  <dialog ref="dialog" :class="b()">
    <e-loader />
    <div v-if="sessionStore.globalLoadingMessage" :class="b('message')">
      {{ sessionStore.globalLoadingMessage }}
    </div>
  </dialog>
</template>

<script lang="ts">
  import { defineComponent, ref, Ref } from 'vue';
  import sessionStore from '@/stores/session';
  import eLoader from '@/elements/e-loader.vue';

  interface Setup {
    sessionStore: ReturnType<typeof sessionStore>;
    dialog: Ref<HTMLDialogElement>;
  }

  // interface Data {}

  /**
   * The e-global-loader component provides a loading indicator that can be displayed across the entire application.
   */
  export default defineComponent({
    name: 'e-global-loader',

    components: {
      eLoader,
    },

    // props: {},
    // emits: {},

    setup(): Setup {
      const dialog = ref();

      return {
        sessionStore: sessionStore(),
        dialog,
      };
    },

    // data() {
    //   return {};
    // },

    watch:  {
      /**
       * Watches for changes of the «isGlobalLoadingVisible» loader status and handles the dialog modal accordingly.
       */
      'sessionStore.isGlobalLoadingVisible': {
        immediate: true,
        handler(isVisible: boolean): void {
          if (isVisible) {
            this.dialog?.showModal();
          } else {
            this.dialog?.close();
          }
        },
      },
    },
    // computed: {},
    // methods: {}
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .e-global-loader {
    all: revert;
    overflow: hidden;
    outline: none;
    border: 0;
    background: transparent;
    color: variables.$color-grayscale--1000;

    &__message {
      @include mixins.font(variables.$font-size--25, variables.$line-height--30, variables.$font-weight--bold);

      margin-top: variables.$spacing--20;
      text-align: center;
    }

    &::backdrop {
      background-color: rgba(variables.$color-grayscale--0, 0.7);
    }
  }
</style>
