import { Tab } from '@/components/c-tabs.vue';
import { ProductSliderTab } from '@/components/c-cms-product-slider-tabs.vue';

/**
 * Maps the given CMS data of tab component into a compatible array of tab definitions.
 */
export default function mapCmsTabs(components: ProductSliderTab[], activeTabIndex = 0): Tab[] {
    return components.map((component, index) => ({
        active: index === activeTabIndex,
        id: index.toString(),
        title: component.headline,
    }));
}

