import { CustomDirective } from '@/types/custom-directive';

/**
 * Removes the "href" value from the given link and adds click handling via JS.
 * It also replaces the text of the link in case the text contains an E-Mail address.
 */
function modifiesAnchorElement(el: HTMLElement): void {
  const href = el.getAttribute('href');
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  if (href?.startsWith('mailto:')) {
    el.removeAttribute('href');
    el.addEventListener('click', () => {
      if (href) {
        window.location.href = href;
      }
    });

    // Adds "&zwnj;" between each character of the displayed E-Mail address.
    if (emailRegex.test(el.innerHTML)) {
      el.innerHTML = el.innerHTML.split('').join('&zwnj;');
    }
  }
}

/**
 * Formats the given element which can be either a link or an HTML element that can contain links.
 */
function format(el: HTMLElement): void {
  if (el.tagName === 'A') {
    modifiesAnchorElement(el);
  }

  const anchorElements: NodeListOf<HTMLElement> = el.querySelectorAll('a[href]');

  if (anchorElements.length) {
    anchorElements.forEach(anchor => modifiesAnchorElement(anchor));
  }
}

/**
 * Directive to remove mailto links and adds javascript handling.
 */
export default {
  name: 'protect-mailto',
  directive: {
    beforeMount: format,
    updated: format,
  },
} satisfies CustomDirective;
