<template>
  <div v-if="isButtonVisible" :class="b()">
    <c-tooltip :popper-options="{ placement }">
      <button
        :class="b('button')"
        :aria-label="$t('e-back-top.up')"
        type="button"
        @click="scrollToTop"
      >

        <e-icon icon="i-arrow--up" size="20" />
      </button>

      <template #tooltip>
        {{ $t('e-back-top.up') }}
      </template>
    </c-tooltip>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { Placement } from '@popperjs/core';
  import eIcon from '@/elements/e-icon.vue';
  import cTooltip from '@/plugins/tooltip/c-tooltip.vue';

  // interface Setup {}

  interface Data {
    isButtonVisible: boolean;

    /**
     * Holds the placement of the popper instance.
     */
    placement: Placement;
  }

  /**
   * The "Back to top" button allows users to scroll to the top of the page.
   */
  export default defineComponent({
    name: 'e-back-top',

    components: {
      eIcon,
      cTooltip,
    },

    // props: {},
    // emits: {},

    // setup(): Setup {},

    data(): Data {
      return {
        isButtonVisible: false,
        placement: 'left',
      };
    },

    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},

    // data(): Data {
    //   return {};
    // },

    // computed: {}
    mounted() {
      window.addEventListener('scroll', this.calculateVisibility, { passive: true });
    },

    unmounted() {
      window.removeEventListener('scroll', this.calculateVisibility);
    },

    methods: {

      /**
       * Calculates whether or not the "Back-to-Top" button should be visible on the page
       */
      calculateVisibility(): void {
        const { innerHeight, scrollY } = window;
        const halfWindowHeight = innerHeight / 2;

        this.isButtonVisible = scrollY > halfWindowHeight;
      },

      /**
       * Scrolls the window to the top of the page without animation
       */
      scrollToTop(): void {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '../setup/scss/variables';
  @use '../setup/scss/mixins';

  .e-back-top {
    @include mixins.z-index(header);

    position: fixed;
    bottom: calc(4% + #{variables.$spacing--20});
    left: variables.$spacing--25;

    @include mixins.media($media: print) {
      display: none;
    }

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      border: 2px solid variables.$color-primary--1;
      background: variables.$color-grayscale--1000;
      cursor: pointer;
      color: variables.$color-primary--1;
    }
  }
</style>
