import { ContactPersonProps } from '@/components/c-contact-person.vue';
import mapColumnWidth from '@/helpers/map-column-width';
import { CmsContactPerson } from '@/types/cms-contact-person';
import normalizeContactPerson from '@/helpers/normalize-contact-person';

export default function mapContactPersonProps(cmsContactPerson: CmsContactPerson): ContactPersonProps {
  return {
    contactPerson: normalizeContactPerson(cmsContactPerson.contactPerson),
    width: mapColumnWidth(cmsContactPerson.width),
  };
}
