<template>
  <div :class="b()">
    <e-label tag="div" inner-tag="div">
      <template #name>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="facet.name"></span>
      </template>
      <e-input-range v-model="value"
                     :min="min"
                     :max="max"
                     :step="step"
                     :disabled="disabled"
                     @update:model-value="onUpdate"
      >
        <template #lowerValue="{ lowerValue }">
          {{ Number(lowerValue).toFixed(precision) }} {{ facet.unit }}
        </template>
        <template #upperValue="{ upperValue }">
          {{ Number(upperValue).toFixed(precision) }} {{ facet.unit }}
        </template>
      </e-input-range>
    </e-label>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { Facet } from '@/types/facet';
  import eInputRange, { Values } from '@/elements/e-input-range.vue';
  import eLabel from '@/elements/e-label.vue';

  // interface Setup {}
  interface Data {
    value: Values;
  }

  /**
   * Renders a range slider filter facet.
   */
  export default defineComponent({
    name: 'c-facet-range-slider',

    components: { eInputRange, eLabel },

    props: {
      /**
       * Expects a facet object.
       */
      facet: {
        type: Object as PropType<Facet>,
        required: true,
      },

      /**
       * Allows passing disabled state.
       */
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    emits: {
      update: (payload: string) => typeof payload === 'string',
    },

    // setup(): Setup {},
    data(): Data {
      return {
        value: [],
      };
    },

    computed: {
      /**
       * Gets the minimum value.
       */
      min(): number {
        return parseFloat(this.facet.sliderTopValues?.[0]?.code || '');
      },

      /**
       * Gets the maximum value.
       */
      max(): number {
        return parseFloat(this.facet.sliderTopValues?.[1]?.code || '');
      },

      /**
       * Calculates the step interval.
       */
      step(): number {
        const { min, max } = this;

        if (max - min <= 1) {
          return 0.01;
        }

        if (max - min <= 10) {
          return 0.1;
        }

        if (max - min <= 50) {
          return 0.5;
        }

        return 1;
      },

      /**
       * Gets the decimal precision for displaying the values.
       */
      precision(): number {
        return `${this.step}`.split('.')[1]?.length || 0;
      },
    },
    watch: {
      /**
       * Observes provided facet for changes and updates slider values.
       */
      facet: {
        handler(facet: Facet) {
          this.value = [
            parseFloat(facet.currentMinValue || ''),
            parseFloat(facet.currentMaxValue || ''),
          ];
        },
        deep: true,
        immediate: true,
      },
    },

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      /**
       * Handles update.
       */
      onUpdate(values: Values): void {
        const { apiUrl } = this.facet.sliderTopValues?.[0]?.query || {};

        if (!apiUrl) {
          return;
        }

        this.$emit('update', apiUrl.replace('minValueHere', `${values[0]}`).replace('maxValueHere', `${values[1]}`));
        this.$gtm.pushSelectFilter(this.facet, values.join('|'));
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  // @use '@/setup/scss/variables';

  .c-facet-range-slider {
    // ...
  }
</style>
