<template>
  <div v-tooltip:[tooltipPosition]="label"
       :class="b()"
  >
    <e-icon :class="b('state-icon')"
            :icon="icon"
            :alt="label"
            size="18"
    />
    <template v-if="!hideLabel">
      {{ label }}
    </template>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import eIcon from '@/elements/e-icon.vue';
  import tooltipDirective from '@/plugins/tooltip/directives/directive';

  // type Setup = {}

  // type Data = {}

  /**
   * Renders a stock icon label.
   */
  export default defineComponent({
    name: 'c-stock-icon',

    components: { eIcon },

    directives: {
      tooltip: tooltipDirective,
    },

    props: {
      /**
       * Expects a label for the state.
       */
      label: {
        type: String,
        required: true,
      },

      /**
       * Expects an icon for the state.
       */
      icon: {
        type: String,
        required: true,
      },

      /**
       * Allows to hide the icon label.
       */
      hideLabel: {
        type: Boolean,
        default: false,
      },

      /**
       * Allows to modify the tooltip position.
       */
      tooltipPosition: {
        type: String,
        default: 'top',
      },
    },
    // emits: {},

    // setup(): Setup {},
    // data(): Data {
    //   return {};
    // },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-stock-icon {
    display: flex;
    align-items: center;
    column-gap: variables.$spacing--5;

    .e-icon {
      width: 1.125em;
      height: 1.125em;
    }
  }
</style>
