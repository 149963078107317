<template>
  <c-modal v-model:is-open="isOpen"
           :title="$t('c-commission-modal.title')"
           close-on-outside-click
           @close="onClose"
  >
    <form :class="b('form')" @submit.prevent="onSubmitNewCommission">
      <e-input v-model="number"
               name="new-commission"
               :aria-label="$t('c-commission-modal.inputAddNewCommission')"
               :placeholder="$t('c-commission-modal.inputAddNewCommission')"
               maxlength="35"
      />
      <e-button :aria-label="$t('c-commission-modal.buttonAddNewCommission')"
                type="submit"
                height="200"
      >
        <e-icon icon="i-add" size="22" />
      </e-button>
    </form>
    <ul v-if="commissions.length" :class="b('list')">
      <li v-if="!selectedCommissionId && fallbackCommission" :class="b('list-item')">
        <span :class="b('fallback-commission')">
          {{ fallbackCommission }}
        </span>
        <button :aria-label="$t('c-commission-modal.buttonUnselect')"
                :title="$t('c-commission-modal.buttonUnselect')"
                :class="b('button-unselect')"
                type="button"
                @click="update(null)"
        >
          <e-icon icon="i-close" size="18" />
        </button>
      </li>
      <li v-for="commission in commissions"
          :key="commission.id"
          :class="b('list-item', { selected: commission.id === selectedCommissionId })"
      >
        <button :title="commission.description"
                :class="b('button-select-commission')"
                :aria-label="$t('c-commission-modal.buttonSelectCommission', { commission: commission.number })"
                type="button"
                @click="update(commission)"
        >
          <e-icon v-if="commission.id !== selectedCommissionId" icon="i-arrow--right" size="18" />
          {{ commission.number }}
        </button>
        <button v-if="commission.id === selectedCommissionId"
                :aria-label="$t('c-commission-modal.buttonUnselect')"
                :title="$t('c-commission-modal.buttonUnselect')"
                :class="b('button-unselect')"
                type="button"
                @click="update(null)"
        >
          <e-icon icon="i-close" size="18" />
        </button>
      </li>
    </ul>
  </c-modal>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import cModal from '@/components/c-modal.vue';
  import eInput from '@/elements/e-input.vue';
  import eButton from '@/elements/e-button.vue';
  import eIcon from '@/elements/e-icon.vue';
  import useSessionStore from '@/stores/session';
  import { Commission } from '@/types/commission';

  interface Setup {
    sessionStore: ReturnType<typeof useSessionStore>;
  }

  interface Data {
    isOpen: boolean;
    number: string;
  }

  /**
   * Renders the commission modal.
   */
  export default defineComponent({
    name: 'c-commission-modal',

    components: {
      eButton,
      eInput,
      eIcon,
      cModal,
    },

    props: {
      /**
       * Allows passing the selected commission id.
       */
      selectedCommissionId: {
        type: String,
        default: null,
      },

      /**
       * Allows passing a fallback commission
       * for cases where there is no commission id.
       */
      fallbackCommission: {
        type: String,
        default: null,
      },
    },
    emits: {
      close: () => true,
      update: (payload: Commission | null) => payload === null || !!payload,
    },

    setup(): Setup {
      return {
        sessionStore: useSessionStore(),
      };
    },
    data(): Data {
      return {
        isOpen: false,
        number: '',
      };
    },

    computed: {
      /**
       * Returns the list of commissions from the session store.
       */
      commissions(): Commission[] {
        const { commissions } = this.sessionStore;

        return Array.isArray(commissions) ? commissions : [];
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    mounted() {
      this.$nextTick(() => {
        this.isOpen = true; // Delay opening modal so the modals transition is working.
      });
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      /**
       * Handles submitting a new commission.
       */
      onSubmitNewCommission(): void {
        const { number } = this;

        if (!number) {
          return;
        }

        this.sessionStore.apiAddCommission({
          number,
        }).then((response) => {
          const { commissions } = response.data?.data || {};

          if (!Array.isArray(commissions)) {
            return;
          }

          const addedCommission = commissions.find((commission: Commission) => commission.number === number);

          if (addedCommission) {
            this.update(addedCommission);
          }
        });
      },

      /**
       * Emits update with commission id to parent.
       */
      update(commission: Commission | null): void {
        this.$emit('update', commission);
        this.isOpen = false;
      },

      /**
       * Handles closing the modal.
       */
      onClose(): void {
        this.$nextTick(() => {
          this.$emit('close');
        });
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-commission-modal {
    &__form {
      display: grid;
      grid-column-gap: variables.$spacing--20;
      grid-template-columns: 1fr auto;
      align-items: end;
      margin-bottom: variables.$spacing--25;
    }

    &__list-item {
      display: grid;
      grid-column-gap: variables.$spacing--20;
      grid-template-columns: 1fr auto;
    }

    &__list-item--selected &__button-select-commission {
      color: variables.$color-primary--1;
      font-weight: variables.$font-weight--bold;
    }

    &__fallback-commission {
      @include mixins.font(variables.$font-size--20, variables.$line-height--25, variables.$font-weight--bold);

      color: variables.$color-primary--1;
    }

    &__button-select-commission {
      @include mixins.font(variables.$font-size--20, variables.$line-height--25);

      display: flex;
      align-items: center;
      cursor: pointer;
      column-gap: variables.$spacing--5;
      padding-block: variables.$spacing--10;
    }

    &__button-unselect {
      padding: variables.$spacing--5 0;
      cursor: pointer;
    }
  }
</style>
