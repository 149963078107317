import { defineStore } from 'pinia';
import { AxiosPromise } from 'axios';
import addContextPathToUrl from '@/helpers/add-context-path-to-url';
import { Store } from '@/setup/globals';
import { Product } from '@/types/product';
import { CategorySuggestion } from '@/types/category-suggestion';
import { Pagination } from '@/types/pagination';
import { Facet } from '@/types/facet';
import { CmsContentSuggestion } from '@/types/cms-content-suggestion';
import { SearchState } from '@/types/search-state';
import { CmsComponents } from '@/types/cms-components';

export interface SearchResult {
  categoryName?: string;
  categoryText?: string;
  categoryCode?: string;
  results: Product[];
  categories?: CategorySuggestion[];
  pagination: Pagination;
  currentQuery: SearchState;
  facets?: Facet[];
  showCategoriesWithImages: boolean;
  contents?: CmsContentSuggestion[];
  contentPagination?: Pagination;
  noResultsText?: string;
  freeTextSearch?: string;
  spellingCorrectionText?: string;
}

export interface ApiGetProductsPayload {
  page: number;
  apiUrl: string;
  loadingPlaceholder?: boolean;
  reset: boolean;
}

export interface ProductResultData {
  data: {
    searchResult: SearchResult;
    groupingThreshold?: number;
    topCmsComponents?: CmsComponents;
    bottomCmsComponents?: CmsComponents;
  };
}

interface ProductResultState {

  /**
   * Holds the product result data
   */
  productResultData: ProductResultData | null;

  /**
   * Holds the product items of the result.
   */
  results: Product[];
}

const storeName = Store.ProductResult;

export default defineStore(storeName, {
  state: () => {
    const state: ProductResultState = {
      results: [],
      productResultData: null,
    };

    const initialData = window.initialData?.[storeName];

    if (initialData?.data) {
      const { searchResult } = initialData.data || {};
      const { results } = searchResult || {};

      if (searchResult) {
        state.productResultData = initialData;
      }

      if (Array.isArray(results)) {
        state.results = results;
      }
    }

    return state;
  },

  getters: {
    /**
     * Gets the search result from the product result.
     */
    getSearchResult(): SearchResult | null {
      return this.productResultData?.data.searchResult || null;
    },

    /**
     * Gets the category name from the search result.
     */
    getCategoryName(): string {
      return this.getSearchResult?.categoryName || '';
    },

    /**
     * Gets categories from the search result.
     */
    getCategories(): CategorySuggestion[] {
      return this.getSearchResult?.categories || [];
    },

    /**
     * Gets the pagination from the search result.
     */
    getPagination(): Pagination | undefined {
      return this.getSearchResult?.pagination;
    },

    /**
     * Gets the current page from the pagination object.
     */
    getCurrentPage(): number {
      return this.getPagination?.currentPage || 0;
    },

    /**
     * Gets the filter facets from the search result.
     */
    getFacets(): Facet[] {
      return this.getSearchResult?.facets || [];
    },

    /**
     * Gets the CMS contents from the search results.
     */
    getContents(): CmsContentSuggestion[] {
      return this.getSearchResult?.contents || [];
    },

    /**
     * Gets the CMS contents pagination from the search result.
     */
    getContentPagination(): Pagination | undefined {
      return this.getSearchResult?.contentPagination;
    },

    getTopCmsComponents(): CmsComponents | undefined {
      return this.productResultData?.data.topCmsComponents;
    },

    getBottomCmsComponents(): CmsComponents | undefined {
      return this.productResultData?.data.bottomCmsComponents;
    },
  },

  actions: {
    /**
     * Fetches products from the API.
     */
    apiGetProducts(payload: ApiGetProductsPayload): AxiosPromise {
      const {
        page,
        apiUrl,
        loadingPlaceholder,
        reset,
      } = payload;

      return this.$api.get(addContextPathToUrl(apiUrl), {
        params: {
          page,
        },
      }).then((response) => {
        const { searchResult } = response.data?.data || {};

        if (searchResult) {
          const { results } = searchResult;

          if (!loadingPlaceholder) {
            this.productResultData = response.data;
          }

          if (Array.isArray(results)) {
            if (reset) {
              this.results = [];
            }

            this.results = [
              ...loadingPlaceholder ? results : [],
              ...this.results,
              ...!loadingPlaceholder ? results : [],
            ];
          }

          return response;
        }

        return Promise.reject(new Error('API failure during "product-result/apiGetProducts" request.'));
      });
    },
  },
});
