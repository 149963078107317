<template>
  <div :class="b()">
    <div v-if="!readOnly"
         :class="b('inner')"
         role="button"
         @click="showModal = true"
    >
      <e-input v-model="commission"
               name="commission"
               :aria-label="$t('e-input-commission.label')"
               :placeholder="showPlaceholder ? $t('e-input-commission.placeholder') : null"
               :class="b('input')"
               :border="inputBorder ? 500 : 0"
      >
        <template #[iconSlot]>
          <e-icon icon="i-pencil" size="18" />
        </template>
      </e-input>
    </div>
    <div v-else-if="commission" :class="b('readonly')">
      <h6 :class="b('readonly-title')">
        {{ $t('e-input-commission.readonlyTitle') }}
      </h6>
      {{ commission }}
    </div>
    <c-commission-modal v-if="showModal"
                        :selected-commission-id="selectedCommissionId"
                        :fallback-commission="fallbackCommission"
                        @close="showModal = false"
                        @update="onUpdate"
    />
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import useSessionStore from '@/stores/session';
  import eInput from '@/elements/e-input.vue';
  import eIcon from '@/elements/e-icon.vue';
  import cCommissionModal from '@/components/c-commission-modal.vue';
  import { Commission } from '@/types/commission';

  interface Setup {
    sessionStore: ReturnType<typeof useSessionStore>;
  }

  export enum IconSlot {
    Before = 'before',
    Default = 'default',
  }

  interface Data {
    showModal: boolean;
  }

  /**
   * Renders an input-like field which allows choosing a commission.
   */
  export default defineComponent({
    name: 'e-input-commission',

    components: {
      cCommissionModal,
      eInput,
      eIcon,
    },

    props: {
      /**
       * Allows passing the selected commission id.
       */
      selectedCommissionId: {
        type: String,
        default: null,
      },

      /**
       * Allows passing a fallback commission
       * for cases where there is no commission id.
       */
      fallbackCommission: {
        type: String,
        default: null,
      },

      /**
       * Enables read-only-mode.
       */
      readOnly: {
        type: Boolean,
        default: false,
      },

      /**
       * Allows defining whether to show the input placeholder.
       */
      showPlaceholder: {
        type: Boolean,
        default: true,
      },

      /**
       * Allows defining the position of the pencil icon.
       */
      iconSlot: {
        type: String as PropType<IconSlot>,
        default: IconSlot.Default,
      },

      /**
       * Allows defining whether the input has a border.
       */
      inputBorder: {
        type: Boolean,
        default: true,
      },
    },
    emits: {
      update: (payload: Commission | null) => payload === null || !!payload,
    },

    setup(): Setup {
      return {
        sessionStore: useSessionStore(),
      };
    },
    data(): Data {
      return {
        showModal: false,
      };
    },

    computed: {
      /**
       * Commission used for v-model of input without setter-functionality.
       */
      commission: {
        get(): string {
          const { selectedCommissionId, fallbackCommission } = this;

          if (selectedCommissionId) {
            return this.sessionStore.getCommissionByCommissionId(selectedCommissionId)?.number || '';
          }

          if (fallbackCommission) {
            return fallbackCommission;
          }

          return '';
        },
        set(): void {
          // Do nothing
        },
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      /**
       * Emits update with commission id to parent.
       */
      onUpdate(commission: Commission | null) {
        this.$emit('update', commission);
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .e-input-commission {
    &__inner {
      cursor: pointer;
    }

    &__readonly {
      @include mixins.font(variables.$font-size--16);
    }

    &__readonly-title {
      margin-bottom: variables.$spacing--5;
      font-weight: variables.$font-weight--bold;
    }

    &__input {
      pointer-events: none;
    }
  }
</style>
