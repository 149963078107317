import { VueComponent } from '@/components/c-row-container.vue';
import mapAccordionEntryComponents, { AccordionEntry } from './map-accordion-entry-components';
import { CmsAccordionEntry } from '@/types/cms-accordion-entry';

export interface AccordionGroupEntry {
  headline: string;
  open: boolean;
  cmsComponents: AccordionEntry[];
  name: VueComponent.AccordionEntry;
}

/**
 * Creates an array with mapped accordion group entries.
 */
export default function mapAccordionGroupEntries(entries: CmsAccordionEntry[]): AccordionGroupEntry[] {
  return entries.map(entry => ({
    headline: entry.headline,
    open: entry.opened,
    cmsComponents: mapAccordionEntryComponents(entry.cmsComponents),
    name: VueComponent.AccordionEntry,
  }));
}

