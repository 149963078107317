<template>
  <div v-if="product" :class="b()">
    <transition v-if="$viewport.isMd" :name="b('transition', { fade: true })">
      <c-sticky-product-header
        v-if="showStickyHeader"
        :product="product"
        :gtm-list-name="GA_LIST_NAME.ProductDetailPage()"
      />
    </transition>

    <section ref="header" :class="b('header')">
      <div v-if="$viewport.isSm" :class="b('breadcrumb-wrapper')">
        <c-breadcrumb v-if="$viewport.isSm" :highlight-last-item="false" />
        <ul v-if="$viewport.isMd" :class="b('action-list')">
          <li :class="b('action-item', { print: true })">
            <button :class="b('action', { print: true })"
                    type="button"
                    @click="showPrintView"
            >
              {{ $t('l-product-detail.printLabel') }}
              <e-icon :icon="Icon.Print" />
            </button>
          </li>
          <template v-if="previousProduct || nextProduct">
            <li :class="b('action-item')">
              <a v-tooltip:[previousProductTooltipPosition]="previousProduct?.name"
                 :class="b('action', { productNavigation: true })"
                 :href="previousProduct?.url"
                 :title="previousProduct?.url"
              >
                <e-icon :icon="Icon.ArrowLeft" size="20" />
              </a>
            </li>
            <li :class="b('action-item')">
              <a v-tooltip:[nextProductTooltipPosition]="nextProduct?.name"
                 :class="b('action', { productNavigation: true })"
                 :href="nextProduct?.url"
                 :title="nextProduct?.url"
              >
                <e-icon :icon="Icon.ArrowRight" size="20" />
              </a>
            </li>
          </template>
        </ul>
      </div>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <h1 v-html="product.name" :class="b('title')"></h1>
      <div :class="b('subtitle')">
        <span v-if="!isBaseProduct" :class="b('variant-product-code')">
          {{ $t('l-product-detail.productCode') }}: <strong>{{ product.code }}</strong>
        </span>
        <span v-if="product.customerProductId" :class="b('customer-product-id')">
          {{ product.customerProductId }}
        </span>
        <e-picture v-if="!$viewport.isMd && brandLogo"
                   v-bind="brandLogo"
                   :class="b('brand-logo', { mobile: true })"
        />
      </div>
      <div :class="b('gallery')">
        <c-lightbox :disabled="!$viewport.isMd"
                    @update:is-open="handleLightboxUpdate"
        >
          <c-slider v-model:active-slide="activeSlide"
                    :navigation="false"
                    :pagination="!$viewport.isMd"
          >
            <c-slider-item-2 v-for="(galleryImage, index) in galleryImages"
                             :key="galleryImage.fallback"
                             :image="galleryImage"
                             :disable-magnification="!$viewport.isLg"
                             :teleport-magnification-to="`.${b('magnifier-teleport')}`"
                             :is-lcp-candidate="index === 0"
            />
          </c-slider>
          <template v-if="isLightboxOpen" #lightbox="{ close }">
            <div :class="b('lightbox-slider')">
              <c-slider v-model:active-slide="activeSlide"
                        :variant="SliderVariant.Lightbox"
              >
                <c-slider-item-4 v-for="galleryImage in galleryImages"
                                 :key="galleryImage.fallback"
                                 :image="galleryImage"
                                 :disable-magnification="!$viewport.isLg"
                                 :teleport-magnification-to="`.${b('magnifier-teleport')}`"
                                 :sizes="lightboxSizes"
                />
              </c-slider>
              <button :class="b('lightbox-close')"
                      :aria-label="$t('global.close')"
                      type="button"
                      @click="close"
              >
                <e-icon icon="i-close" />
              </button>
            </div>
          </template>
        </c-lightbox>
        <c-slider v-if="$viewport.isMd"
                  v-model:active-slide="activeSlide"
                  :class="b('thumbnail-slider')"
                  :navigation="false"
                  :pagination="false"
                  @mousedown.passive="hasPointerInteractionWithSlider = true"
                  @mousemove.passive="onMove"
                  @mouseup.passive="hasPointerInteractionWithSlider = false"
                  @click.passive="onThumbnailSliderClick"
        >
          <c-slider-item-3 v-for="galleryImage in galleryImages"
                           :key="galleryImage.fallback"
                           :class="b('thumbnail')"
                           :image="galleryImage"
          />
        </c-slider>
        <c-favourite-button :product="product" :class="b('favourite-button')" />
      </div>
      <div :class="b('description-wrapper')">
        <div :class="b('magnifier-teleport')"></div>
        <ul v-if="product.orderNumber || product.supplierCode || product.newItem"
            :class="b('additional-codes')"
        >
          <li v-if="product.newItem">
            <e-badge variant="hexagon" :class="b('badge', { newProduct: true })">
              {{ $t('c-product-grid-item.flagNewProduct') }}
            </e-badge>
          </li>
          <li v-if="product.orderNumber"
              :class="b('additional-code', { orderNumber: true })"
          >
            {{ product.orderNumber }}
          </li>
          <li v-if="product.supplierCode"
              :class="b('additional-code')"
          >
            {{ product.supplierCode }}
          </li>
        </ul>
        <c-collapse-text v-if="product.description || product.productLongTexts?.length"
                         :class="b('product-texts')"
                         :lines-visible="$viewport.isMd ? 10 : 5"
        >
          <div class="wysiwyg">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-if="product.description" v-html="product.description" :class="b('description')"></div>
            <template v-if="product.productLongTexts?.length">
              <dl v-for="longText in product.productLongTexts"
                  :key="longText.title"
                  :class="b('long-text')"
              >
                <dt :class="b('long-text-title')">
                  {{ longText.title }}
                </dt>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <dd v-html="longText.text"></dd>
              </dl>
            </template>
          </div>
        </c-collapse-text>

        <div v-if="mappedProductFeatures" :class="b('product-features')">
          <h3 :class="b('specifications-title')">
            {{ $t('global.specifications') }}
          </h3>
          <dl :class="b('product-features-list')">
            <template v-for="feature in mappedProductFeatures" :key="feature">
              <!-- eslint-disable vue/no-v-html -->
              <dt v-html="Object.keys(feature)[0]"></dt>
              <dd :class="b('feature-value-wrapper')">
                <span v-html="feature[Object.keys(feature)[0]]"></span>
                <c-tooltip v-if="feature.description?.length"
                           :width="feature.description.length > 500 ? '800' : undefined"
                >
                  <e-icon icon="i-info" size="20" />
                  <template #tooltip>
                    <span :class="[b('feature-value-description',
                                     { scrollable : feature.description.length > 500 && $viewport.isMobile })]"
                    >
                      {{ feature.description }}
                    </span>
                  </template>
                </c-tooltip>
              </dd>
              <!-- eslint-enable vue/no-v-html -->
            </template>
          </dl>
        </div>
        <ul v-if="product.pictograms?.length" :class="b('pictogram-list')">
          <li v-for="(pictogram, index) in product.pictograms" :key="index">
            <img :class="b('pictogram-image')"
                 :src="pictogram.url"
                 :alt="pictogram.name"
                 :title="pictogram.name"
            >
          </li>
        </ul>
        <a v-if="showMoreInformationLink"
           :class="b('more-information-link')"
           href="#additional-information"
           @click="scrollToAdditionalInformation"
        >
          {{ $t('global.moreInformation') }}
          <e-icon :icon="Icon.ArrowDown" size="18" />
        </a>
      </div>
      <div :class="b('cart', { positionBottom : !isBaseProduct })">
        <e-picture v-if="$viewport.isMd && brandLogo"
                   v-bind="brandLogo"
                   :class="b('brand-logo')"
        />
        <template v-if="!isBaseProduct">
          <div :class="b('price')">
            <c-product-price-detailed :product="product" show-discount-group />
          </div>
          <div :class="b('add-to-cart-wrapper')">
            <div v-if="configurationLink" :class="b('configuration-button')">
              <e-button
                :href="addContextPathToUrl(configurationLink)"
                variant="secondary"
                height="200"
                spacing="0"
              >
                {{ $t('global.configuration') }}
              </e-button>
            </div>
            <c-add-to-cart
              v-else
              :class="b('add-to-cart')"
              :product="product"
              :gtm-list-name="GA_LIST_NAME.ProductDetailPage()"
              show-product-actions
            />
          </div>
          <div :class="b('stock')">
            <c-product-stock :product="product" variant="vertical" />
          </div>
        </template>
      </div>
    </section>

    <section v-if="productVariants.length" :class="b('variants')">
      <c-product-variants-table :scope="ProductScopeIdentifier.DETAIL" />
    </section>

    <section
      v-if="!productDetailStore.runningRequests.apiLoadProductVariantDetail"
      id="additional-information"
      :class="b('additional-information')"
    >
      <c-product-detail-tabs :product="product" @update="(tabs) => { detailTabs = tabs }" />
      <c-cms-product-slider-tabs
        v-if="productSliderTabs"
        :data="productSliderTabs"
        :view-type="ProductSliderTabsViewType.GRID"
      />
      <c-cms-components-wrapper
        v-if="product.cmsComponents?.length"
        :components="product.cmsComponents"
      />
    </section>
  </div>
</template>

<script lang="ts">
  import { defineComponent, Ref, ref } from 'vue';
  import { Placement } from '@popperjs/core';
  import useProductDetailStore, { ProductScopeIdentifier } from '@/stores/product-detail';
  import useSessionStore from '@/stores/session';
  import useCustomerPriceStore from '@/stores/customer-price';
  import cBreadcrumb from '@/components/c-breadcrumb.vue';
  import eIcon from '@/elements/e-icon.vue';
  import eButton from '@/elements/e-button.vue';
  import cAddToCart from '@/components/c-add-to-cart.vue';
  import cStickyProductHeader from '@/components/c-sticky-product-header.vue';
  import ePicture from '@/elements/e-picture.vue';
  import cCollapseText from '@/components/c-collapse-text.vue';
  import cSlider, { SliderVariant } from '@/components/c-slider.vue';
  import cTooltip from '@/plugins/tooltip/c-tooltip.vue';
  import cSliderItem2 from '@/components/c-slider-item-2.vue';
  import cSliderItem3 from '@/components/c-slider-item-3.vue';
  import cProductDetailTabs from '@/components/c-product-detail-tabs.vue';
  import cLightbox from '@/components/c-lightbox.vue';
  import cSliderItem4 from '@/components/c-slider-item-4.vue';
  import eBadge from '@/elements/e-badge.vue';
  import setActiveLayout from '@/compositions/set-active-layout';
  import mapMediaContainerSrcSet from '@/helpers/map-media-container-srcset';
  import getFeaturesByProduct from '@/helpers/get-features-by-product';
  import mapFeatureValues from '@/helpers/map-feature-values';
  import tooltipDirective from '@/plugins/tooltip/directives/directive';
  import { MappedProductFeature, MappedProductVariant }
    from '@/components/c-product-variant-detail.vue';
  import {
    Icon, Layout, ProductTag, WINDOW_HISTORY_TYPES,
  } from '@/setup/globals';
  import cProductStock from '@/components/c-product-stock.vue';
  import cProductPriceDetailed from '@/components/c-product-price-detailed.vue';
  import cCmsProductSliderTabs, { ViewType } from '@/components/c-cms-product-slider-tabs.vue';
  import cCmsComponentsWrapper from '@/components/c-cms-components-wrapper.vue';
  import cProductVariantsTable from '@/components/c-product-variants-table.vue';
  import cFavouriteButton from '@/components/c-favourite-button.vue';
  import { Tab } from '@/components/c-tabs.vue';
  import mapStockIndicator from '@/helpers/map-stock-indicator';
  import addContextPathToUrl from '@/helpers/add-context-path-to-url';
  import { GA_LIST_NAME } from '@/plugins/google-tag-manager';
  import { ProductNavigation } from '@/types/product-navigation';
  import { ImageSizes } from '@/types/sizes';
  import { ImageSrcset } from '@/types/image';
  import { Product } from '@/types/product';
  import { CmsTab } from '@/types/cms-tab';

  type Setup = {
    productDetailStore: ReturnType<typeof useProductDetailStore>;
    sessionStore: ReturnType<typeof useSessionStore>;
    customerPriceStore: ReturnType<typeof useCustomerPriceStore>;
    header: Ref<HTMLDivElement | undefined>;
    headerIntersectionObserver?: IntersectionObserver;
    lightboxSizes: ImageSizes;
    SliderVariant: typeof SliderVariant;
    Icon: typeof Icon;
    addContextPathToUrl: typeof addContextPathToUrl;
    ProductSliderTabsViewType: typeof ViewType;
    ProductScopeIdentifier: typeof ProductScopeIdentifier;
    GA_LIST_NAME: typeof GA_LIST_NAME;
  }

  type Data = {
    showStickyHeader: boolean;
    isLightboxOpen: boolean;
    activeSlide: number;
    hasPointerInteractionWithSlider: boolean;
    didDragThumbnails: boolean;
    selectedVariant?: Product;
    productSliderTabs?: CmsTab;
    detailTabs?: Tab[];
  }

  const layout = Layout.ProductDetail;

  /**
   * Renders a product detail layout.
   *
   * **WARNING: uses 'v-html'. Make sure, that the source for this data is trustworthy.**
   */
  export default defineComponent({
    name: layout,

    directives: {
      tooltip: tooltipDirective,
    },
    components: {
      cFavouriteButton,
      eButton,
      cProductVariantsTable,
      cCmsComponentsWrapper,
      cCmsProductSliderTabs,
      cProductPriceDetailed,
      cSliderItem4,
      cLightbox,
      cProductStock,
      cSliderItem3,
      cSliderItem2,
      cSlider,
      cCollapseText,
      cStickyProductHeader,
      ePicture,
      cAddToCart,
      eIcon,
      cBreadcrumb,
      cProductDetailTabs,
      eBadge,
      cTooltip,
    },

    // props: {},
    // emits: {},

    setup(): Setup {
      setActiveLayout(layout);

      return {
        productDetailStore: useProductDetailStore(),
        sessionStore: useSessionStore(),
        customerPriceStore: useCustomerPriceStore(),
        header: ref(),
        headerIntersectionObserver: undefined,
        SliderVariant,
        lightboxSizes: {
          xxs: 480,
          fallback: 1440,
        },
        Icon,
        addContextPathToUrl,
        ProductSliderTabsViewType: ViewType,
        ProductScopeIdentifier,
        GA_LIST_NAME
      };
    },
    data(): Data {
      return {
        showStickyHeader: false,
        isLightboxOpen: false,
        activeSlide: 0,
        hasPointerInteractionWithSlider: false,
        didDragThumbnails: false,
        productSliderTabs: undefined,
        detailTabs: undefined,
      };
    },

    computed: {
      isBaseProduct(): boolean {
        return !this.product?.baseProduct;
      },

      showMoreInformationLink(): boolean {
        const hasDetailTabs = !!this.detailTabs?.length;
        const hasProductSliderTabs = !!this.productSliderTabs?.data?.tabs?.length;
        const hasCmsComponents = !!this.product?.cmsComponents?.length;

        return hasDetailTabs || hasProductSliderTabs || hasCmsComponents;
      },

      filteredProductVariants(): Product[] {
        return this.productDetailStore.getVariantResult(ProductScopeIdentifier.DETAIL)?.results || [];
      },

      mappedProductFeatures(): MappedProductFeature[] | undefined {
        const { classifications } = this.product || {};

        if (!classifications || !this.product) {
          return undefined;
        }

        const features = getFeaturesByProduct(this.product, true, false)
          .filter(feature => feature.displayType !== 'PICTO');

        if (!features.length) {
          return undefined;
        }

        const mappedFeaturesList: MappedProductFeature[] = [];

        features.forEach((feature) => {
          const mappedFeature: MappedProductFeature = {
            [feature.name]: mapFeatureValues(feature),
            description: feature.featureValues[0]?.description ?? '',
          };

          mappedFeaturesList.push(mappedFeature);
        });

        return mappedFeaturesList;
      },

      productVariants(): MappedProductVariant[] {
        const productVariants = this.filteredProductVariants;

        return productVariants.map((product): MappedProductVariant => {
          const productFeatures = product.classifications
            ? getFeaturesByProduct(product, true, false)
            : [];
          const mappedProductFeatures: MappedProductFeature = {};

          productFeatures.forEach((feature) => {
            mappedProductFeatures[feature.name] = mapFeatureValues(feature);
          });

          return {
            id: product.code,
            features: mappedProductFeatures,
            isPromoted: product.tags?.includes(ProductTag.Promoted) || false,
            product,
            stockState: mapStockIndicator(product.stock),
          };
        });
      },

      product(): Product | undefined {
        return this.productDetailStore.getProduct(ProductScopeIdentifier.DETAIL);
      },

      previousProduct(): ProductNavigation | undefined {
        return this.productDetailStore.getPreviousProduct(ProductScopeIdentifier.DETAIL);
      },

      nextProduct(): ProductNavigation | undefined {
        return this.productDetailStore.getNextProduct(ProductScopeIdentifier.DETAIL);
      },

      brandLogo(): ImageSrcset | undefined {
        const { icon } = this.product?.brand || {};

        return icon?.medias?.length ? mapMediaContainerSrcSet(icon) : undefined;
      },

      galleryImages(): ImageSrcset[] {
        return this.product?.galleryImages?.map(mediaContainer => mapMediaContainerSrcSet(mediaContainer)) || [];
      },

      previousProductTooltipPosition(): Placement | 'hidden' {
        return this.previousProduct ? 'bottom' : 'hidden';
      },

      nextProductTooltipPosition(): Placement | 'hidden' {
        return this.nextProduct ? 'bottom' : 'hidden';
      },

      configurationLink(): string | undefined {
        return (this.product?.configurable && this.product.configurationUrl) || undefined;
      },
    },
    watch: {
      showStickyHeader(show: boolean) {
        this.sessionStore.disableHeaderShadow = show;
      },

      product: {
        handler(product: Product): void {
          const { productSliderApiUrl } = product;

          if (productSliderApiUrl) {
            this.fetchProductSliderTabs(productSliderApiUrl);
          } else {
            this.productSliderTabs = undefined;
          }
        },
        immediate: true,
      },
    },

    // beforeCreate() {},
    created() {
      const variantCodes = this.filteredProductVariants?.map(({ code }) => ({ code }));

      if (this.product) {
        this.$gtm.pushViewItem(this.$gtm.mapProductToListItem(this.product));
      }

      this.$gtm.pushViewItemList(this.filteredProductVariants.map(this.$gtm.mapProductToListItem), GA_LIST_NAME.VariantList());

      if (Array.isArray(variantCodes) && variantCodes.length) {
        this.customerPriceStore.apiFetchCustomerPrices(variantCodes);
      }
    },
    // beforeMount() {},
    mounted() {
      this.productDetailStore.apiFetchPreviousAndNextProduct(ProductScopeIdentifier.DETAIL);

      window.addEventListener('popstate', this.onPopState);

      if (this.header) {
        this.headerIntersectionObserver = new IntersectionObserver((entries) => {
          const { isIntersecting } = entries[0];

          this.showStickyHeader = !isIntersecting;
        });

        this.headerIntersectionObserver.observe(this.header);
      }
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    unmounted() {
      this.sessionStore.disableHeaderShadow = false;
      window.removeEventListener('popstate', this.onPopState);
    },

    methods: {
      onMove(): void {
        if (this.hasPointerInteractionWithSlider) {
          this.didDragThumbnails = true;
        }
      },

      handleLightboxUpdate(isOpen: boolean) {
        this.isLightboxOpen = isOpen;
      },

      scrollToClickedThumbnailSlide(slideElement: Element): void {
        if (!this.didDragThumbnails && slideElement) {
          const slideContainer = slideElement.parentElement;

          if (!slideContainer) {
            return;
          }

          this.activeSlide = Array.prototype.indexOf.call(slideContainer.children, slideElement);
        }
      },

      onThumbnailSliderClick(event: MouseEvent): void {
        const slideElement = (event.target as Element).closest(`.${cSliderItem3.name}`); // eslint-disable-line no-extra-parens

        if (slideElement) {
          this.scrollToClickedThumbnailSlide(slideElement);
        }

        this.hasPointerInteractionWithSlider = false;
        this.didDragThumbnails = false;
      },

      onPopState(event: PopStateEvent): void {
        const variantUrl = event.state?.type === WINDOW_HISTORY_TYPES.productDetailVariantFilterUpdate && event.state?.url;

        if (variantUrl) {
          // Note: this could be optimized by using state data instead (so no redirect is needed).
          window.location.replace(variantUrl);
        }
      },

      showPrintView(): void {
        window.print();

        if (this.product) {
          this.$gtm.pushPrintDetail(this.product);
        }
      },

      fetchProductSliderTabs(apiUrl: string): void {
        this.productDetailStore.apiFetchProductSliderTabs(apiUrl).then((response) => {
          this.productSliderTabs = response.data;
        });
      },

      scrollToAdditionalInformation(event: Event): void {
        event.preventDefault();

        this.$el.querySelector('#additional-information')?.scrollIntoView({ behavior: 'smooth' });
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';
  @use '@/setup/scss/mixins';

  .l-product-detail {
    $grid-area--breadcrumb-wrapper: breadcrumb-wrapper;
    $grid-area--title: title;
    $grid-area--product-code: product-code;
    $grid-area--gallery: gallery;
    $grid-area--description: description;
    $grid-area--cart: cart;
    $this: &;

    @include mixins.font(variables.$font-size--16);

    &__header {
      @include mixins.media(md) {
        display: grid;
        grid-template-areas:
          '#{$grid-area--breadcrumb-wrapper} #{$grid-area--breadcrumb-wrapper} #{$grid-area--breadcrumb-wrapper}'
          '#{$grid-area--title} #{$grid-area--title} #{$grid-area--title}'
          '#{$grid-area--product-code} #{$grid-area--product-code} #{$grid-area--product-code}'
          '#{$grid-area--gallery} #{$grid-area--description} #{$grid-area--cart}';
        grid-template-columns: 340px minmax(0, 620px) auto;
      }

      @include mixins.media($media: print) {
        grid-template-columns: 25% 1fr auto;
      }
    }

    &__breadcrumb-wrapper {
      display: flex;
      grid-area: $grid-area--breadcrumb-wrapper;

      @include mixins.media(sm) {
        gap: variables.$spacing--10;
        justify-content: space-between;
        margin-bottom: variables.$spacing--40;
        border-bottom: 1px solid variables.$color-grayscale--0;
      }
    }

    &__action-list {
      display: flex;
      gap: variables.$spacing--10;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: variables.$spacing--20;
      place-self: start end;

      @include mixins.media(md) {
        margin-bottom: 0;
      }

      @include mixins.media($media: print) {
        display: none;
      }
    }

    &__action-item {
      display: flex;
      align-items: center;
      padding-block: variables.$spacing--2;

      &:nth-child(n + 3) {
        padding-left: variables.$spacing--10;
        border-left: 2px solid variables.$color-grayscale--0;
      }

      &--print {
        display: none;

        @include mixins.media(md) {
          display: block;

          &:not(:last-child) {
            padding-right: variables.$spacing--40;
          }
        }
      }
    }

    &__action {
      &--print {
        display: flex;
        gap: variables.$spacing--10;
        align-items: center;
        cursor: pointer;
        white-space: nowrap;

        &:hover,
        &:focus {
          color: variables.$color-primary--1;
        }
      }

      &--product-navigation:not([href]) {
        opacity: 0.3;
        pointer-events: none;
      }
    }

    &__title {
      grid-area: $grid-area--title;
      margin-bottom: variables.$spacing--10;
      padding-bottom: variables.$spacing--5;
      border-bottom: 4px solid variables.$color-grayscale--0;

      @include mixins.media(md) {
        margin-bottom: variables.$spacing--2;
        padding-bottom: 0;
        border-bottom: 0;
      }

      @include mixins.media($media: print) {
        font-size: variables.$font-size--30;
      }
    }

    &__subtitle {
      display: flex;
      grid-area: $grid-area--product-code;
      align-items: flex-start;
      margin-bottom: variables.$spacing--10;

      @include mixins.media(md) {
        display: block;
        margin-bottom: variables.$spacing--30;
      }

      @include mixins.media($media: print) {
        margin-bottom: variables.$spacing--20;
      }
    }

    &__customer-product-id {
      color: variables.$color-grayscale--0;

      &:not(:first-child) {
        margin-left: variables.$spacing--10;
        padding-left: variables.$spacing--10;
        border-left: 1px solid variables.$color-grayscale--0;
      }
    }

    &__gallery {
      $border-padding: variables.$spacing--10;

      position: relative;
      grid-area: $grid-area--gallery;
      max-width: 500px;
      margin: 0 auto variables.$spacing--20;

      @include mixins.media(md) {
        max-width: none;
        margin: 0 variables.$spacing--20 0 0;
        padding-right: $border-padding;
        border-right: 3px solid variables.$color-grayscale--0;

        &::before {
          @include mixins.z-index(front);

          position: absolute;
          top: variables.$spacing--5;
          right: $border-padding + variables.$spacing--5;
          content: '';
          width: 25px;
          height: 25px;
          background: url('../assets/icons.svg#i-zoom');
          pointer-events: none;
        }
      }
    }

    &__thumbnail-slider {
      margin-top: variables.$spacing--15;

      @include mixins.media($media: print) {
        display: none;
      }
    }

    &__thumbnail:not(:first-child) {
      margin-left: variables.$spacing--10;
    }

    &__description-wrapper {
      position: relative;
      grid-area: $grid-area--description;
      padding-bottom: variables.$spacing--20;
    }

    &__magnifier-teleport {
      @include mixins.z-index(front);

      position: absolute;
      width: 100%; // Could not use 'inset' because of Safari.
      height: 100%;
      max-height: 50dvh;
      pointer-events: none;
    }

    &__additional-codes {
      display: flex;
      gap: variables.$spacing--10;
      align-items: center;
      margin-bottom: variables.$spacing--20;
    }

    &__additional-code {
      & + & {
        padding-left: variables.$spacing--10;
        border-left: 2px solid variables.$color-grayscale--0;
      }

      &--order-number {
        font-weight: variables.$font-weight--bold;
      }
    }

    &__description {
      margin-bottom: variables.$spacing--30;
    }

    &__long-text {
      margin-bottom: variables.$spacing--20;
    }

    &__long-text-title {
      @include mixins.heading-h3;

      @include mixins.media(md) {
        margin-bottom: variables.$spacing--15;
      }

      @include mixins.media($media: print) {
        font-size: variables.$font-size--18;
      }
    }

    &__more-information-link {
      @include mixins.link--secondary();

      display: inline-flex;
      gap: variables.$spacing--10;
      align-items: center;
      margin-top: variables.$spacing--20;
      padding-top: variables.$spacing--10;
      border-top: 2px solid variables.$color-grayscale--0;
      font-weight: variables.$font-weight--bold;

      @include mixins.media(md) {
        padding-top: 0;
        border-top: 0;
      }

      @include mixins.media($media: print) {
        display: none;
      }
    }

    &__cart {
      $bottom: variables.$spacing--100;

      display: flex;
      grid-area: $grid-area--cart;
      flex-direction: column;
      align-items: flex-end;
      margin: 0 0 $bottom variables.$spacing--25;

      @include mixins.media(md) {
        position: sticky;
      }

      @include mixins.media($media: print) {
        position: relative;
        bottom: 0;
        margin-bottom: 0;
        margin-left: variables.$spacing--50;
      }

      &--position-bottom {
        align-self: end;

        @include mixins.media(md) {
          bottom: $bottom;
        }
      }
    }

    &__brand-logo {
      max-width: 130px;
      margin-bottom: variables.$spacing--30;

      &--mobile {
        max-width: 100px;
        margin: 0 0 0 auto;
      }
    }

    &__price {
      margin-bottom: variables.$spacing--15;
      text-align: right;
    }

    &__add-to-cart-wrapper {
      display: flex;
      gap: variables.$spacing--15;
      align-items: center;
      margin-bottom: variables.$spacing--15;

      @include mixins.media($media: print) {
        display: none;
      }
    }

    &__stock .c-product-stock {
      align-items: flex-end;
    }

    &__lightbox-slider {
      .c-slider__stage-inner {
        width: 80dvmin;
        height: 80dvmin;
      }
    }

    &__lightbox-close {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      color: variables.$color-grayscale--1000;
    }

    &__transition {
      &--fade-enter-active,
      &--fade-leave-active {
        transition: opacity variables.$transition-duration--200 ease;
      }

      &--fade-enter-from,
      &--fade-leave-to {
        opacity: 0;
      }
    }

    &__additional-information {
      display: grid;
      grid-row-gap: variables.$spacing--50;
      scroll-margin-top: calc(var(--header-height) + var(--sticky-product-header-height, 0px));

      @include mixins.media(sm) {
        scroll-margin-top: calc(var(--header-height) + var(--sticky-product-header-height, 120px));
      }

      .c-cms-product-slider-tabs,
      .c-cms-components-wrapper {
        @include mixins.media($media: print) {
          display: none;
        }
      }

      .c-tabs {
        overflow: auto;
      }
    }

    &__variants {
      margin-block: variables.$spacing--50;
    }

    &__configuration-button {
      .e-button {
        padding-right: variables.$spacing--5;
        padding-left: variables.$spacing--5;
        white-space: nowrap;
      }
    }

    &__pictogram-list {
      display: flex;
      gap: variables.$spacing--15;
      flex-wrap: wrap;
    }

    &__pictogram-image {
      width: 50px;
    }

    &__specifications-title {
      @include mixins.media(md) {
        margin-bottom: variables.$spacing--15;
      }
    }

    &__product-features {
      margin-bottom: variables.$spacing--20;
    }

    &__product-features-list {
      display: inline-grid;
      grid-template-columns: auto auto;
      column-gap: variables.$spacing--30;
    }

    &__feature-value-wrapper {
      display: flex;
      gap: 0 variables.$spacing--10;
      align-items: center;
    }

    &__feature-value-description {
      &--scrollable {
        display: block;
        max-height: 40vh;
        overflow: auto;
        pointer-events: all;
      }
    }

    &__favourite-button {
      @include mixins.z-index(front);

      position: absolute;
      top: 0;
      left: 0;
      min-width: 35px;
      min-height: 35px;
      padding: variables.$spacing--5;

      .e-icon {
        width: 25px;
        height: 25px;
      }
    }

    &__product-texts {
      margin-bottom: variables.$spacing--20;
    }

    .c-breadcrumb {
      margin: 0;
      border: 0;

      @include mixins.media($media: print) {
        margin-bottom: variables.$spacing--20;
      }
    }

    .c-sticky-product-header {
      @include mixins.media($media: print) {
        display: none;
      }
    }
  }
</style>
