<template>
  <span :class="b({ disabled })">
    <e-loader v-if="isLoading" variant="tiny" negative />
    <template v-else>
      <button :class="b('button', { remove: true })"
              :disabled="decrementDisabled"
              :aria-label="$t('e-quantity-select.buttonDecrement')"
              type="button"
              @click="decrementQuantity"
      >
        <e-icon icon="i-minus" size="18" />
      </button>
      <input v-model="internalValue"
             :class="b('input')"
             :max="max"
             :min="min"
             :step="stepSize"
             :disabled="disabled"
             :aria-label="$t('e-quantity-select.labelQuantity')"
             name="quantity"
             type="number"
             @keydown="onKeyDown"
             @change="onInputChanged"
             @keyup.enter="onEnterKeyUp"
             @input="onInput"
      >
      <button :class="b('button', { add: true })"
              :disabled="incrementDisabled"
              :aria-label="$t('e-quantity-select.buttonIncrement')"
              type="button"
              @click="incrementQuantity"
      >
        <e-icon icon="i-plus" size="18" />
      </button>
    </template>
    <span v-if="$slots.default" :class="b('slot')">
      <slot></slot>
    </span>
  </span>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import eLoader from '@/elements/e-loader.vue';
  import eIcon from '@/elements/e-icon.vue';

  interface Data {
    internalValue: number;
    debounceTimeoutId?: ReturnType<typeof setTimeout>;
  }

  /**
   * The e-quantity-select component increments and decrements a numerical value in response to user clicks or arrow key presses.
   * It also rounds values when manually entered into the input field based on the set step size.
   */
  export default defineComponent({
    name: 'e-quantity-select',

    components: {
      eLoader,
      eIcon,
    },

    props: {
      /**
       * Value passed by v-model.
       */
      modelValue: {
        type: Number,
        required: true,
      },

      /**
       * The minimum value that can be selected.
       */
      min: {
        type: Number,
        default: 1,
      },

      /**
       * The maximum value that can be selected.
       */
      max: {
        type: Number,
        default: 100000,
      },

      /**
       * The size of each step for the quantity selector for minus and plus buttons.
       */
      stepSize: {
        type: Number,
        default: 1,
      },

      /**
       * The current loading state of the component.
       */
      isLoading: {
        type: Boolean,
        default: false,
      },

      /**
       * Allows disabling the quantity selector.
       */
      disabled: {
        type: Boolean,
        default: false,
      },

      /**
       * Allows enabling a debounced update on input.
       */
      updateOnInput: {
        type: Boolean,
        default: false,
      },
    },

    emits: {
      'update:modelValue': (payload: number) => true, // eslint-disable-line @typescript-eslint/no-unused-vars
      'enter': () => true,
    },

    // setup(): Setup {},

    data(): Data {
      return {
        internalValue: this.modelValue,
        debounceTimeoutId: undefined,
      };
    },

    computed: {
      /**
       * Disabled state for the `decrement`-button.
       */
      decrementDisabled(): boolean {
        return this.disabled || this.modelValue <= this.min;
      },

      /**
       * Disabled state for the `increment`-button.
       */
      incrementDisabled(): boolean {
        return this.disabled || this.modelValue >= this.max;
      },
    },

    watch: {
      /**
       * Updates internal value when model value was changed from outside.
       */
      modelValue(value): void {
        if (this.internalValue !== value) {
          this.internalValue = value;
        }
      },
    },
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      /**
       * Returns valid quantity based on stepSize and min/max-quantity.
       */
      getValidQuantity(quantity: number): number {
        if (quantity < this.min) {
          return this.min;
        }

        if (quantity > this.max) {
          return this.max;
        }

        if (quantity % this.stepSize !== 0) {
          return Math.round(quantity / this.stepSize) * this.stepSize;
        }

        return quantity;
      },

      onInput(): void {
        if (!this.updateOnInput) {
          return;
        }

        if (this.debounceTimeoutId) {
          clearTimeout(this.debounceTimeoutId);
        }

        this.debounceTimeoutId = setTimeout(() => {
          this.updateQuantity(this.internalValue);
        }, 1500);
      },

      /**
       * Handles changes in input field.
       */
      onInputChanged(): void {
        this.updateQuantity(this.internalValue);
      },

      /**
       * Updates the model value.
       */
      updateQuantity(quantity: number): void {
        const validQuantity = this.getValidQuantity(quantity);

        this.$emit('update:modelValue', validQuantity);
        this.internalValue = validQuantity;
      },

      /**
       * Increments the current quantity by the step size.
       */
      incrementQuantity(): void {
        this.updateQuantity(this.modelValue + this.stepSize);
      },

      /**
       * Decrements the current quantity by the step size.
       */
      decrementQuantity(): void {
        this.updateQuantity(this.modelValue - this.stepSize);
      },

      /**
       * Handles keydown events.
       */
      onKeyDown(event: KeyboardEvent): void {
        if (event.key === 'ArrowDown') {
          event.preventDefault();
          this.decrementQuantity();
        } else if (event.key === 'ArrowUp') {
          event.preventDefault();
          this.incrementQuantity();
        }
      },

      /**
       * Handles enter keyup event.
       */
      onEnterKeyUp(): void {
        this.$emit('enter');
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '../setup/scss/variables';
  @use '../setup/scss/mixins';

  .e-quantity-select {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    height: 38px;
    border: 2px solid variables.$color-grayscale--0;

    &__button {
      display: flex;
      align-items: center;
      height: 100%;
      cursor: pointer;

      &:hover,
      &:focus {
        color: variables.$color-primary--1;
      }

      &:disabled {
        cursor: default;
        color: variables.$color-grayscale--400;
      }

      &--remove {
        padding-left: variables.$spacing--10;
      }

      &--add {
        padding-right: variables.$spacing--10;
      }
    }

    &__input {
      width: 100%;
      font-weight: variables.$font-weight--bold;
      text-align: center;

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        margin: 0;
        -webkit-appearance: none;
      }

      /* Firefox */
      &[type='number'] {
        -moz-appearance: textfield;
      }

      &[disabled] {
        color: variables.$color-grayscale--400;
        -webkit-text-fill-color: variables.$color-grayscale--400;
      }
    }

    &__slot {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      margin-top: variables.$spacing--2;
      text-align: center;
    }

    &--disabled {
      border-color: variables.$color-grayscale--400;
    }
  }
</style>
