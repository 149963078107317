<template>
  <span :class="b({ variant })">
    <slot></slot>
  </span>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  // interface Setup {}
  // interface Data {}

  /**
   * Renders a badge.
   */
  export default defineComponent({
    name: 'e-badge',

    // components: {},

    props: {
      /**
       * Expects the variant of the badge to be passed.
       */
      variant: {
        type: String,
        required: true,
        validator: (value: string) => [
          'hexagon',
        ].includes(value),
      },
    },
    // emits: {},

    // setup(): Setup {},
    // data(): Data {
    //   return {};
    // },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';
  @use '@/setup/scss/mixins';

  .e-badge {
    @include mixins.font(12px, null, variables.$font-weight--bold);

    display: flex;
    align-items: center;
    height: 25px;
    padding: 0 variables.$spacing--5;
    color: variables.$color-grayscale--1000;
    text-transform: uppercase;

    &--variant-hexagon {
      $clip-size: 7px;

      background-color: variables.$color-primary--1;
      clip-path:
        polygon(
          $clip-size 0,
          100% 0,
          100% calc(100% - #{$clip-size}),
          calc(100% - #{$clip-size}) 100%,
          0 100%,
          0 $clip-size
        );
    }
  }
</style>
