import { BodyScrollOptions } from 'body-scroll-lock';

export const bodyScrollOptions: BodyScrollOptions = {
  reserveScrollBarGap: true,

  // This is needed to allow scrolling inside a container for iOS.
  // https://github.com/willmcpo/body-scroll-lock#allowtouchmove
  allowTouchMove: (el: HTMLElement | Element): boolean => {
    while (el && el !== document.body) {
      if (el.getAttribute('body-scroll-lock-ignore') !== null) {
        return true;
      }

      if (el.parentElement) {
        el = el.parentElement;
      }
    }

    return false;
  },
};

export default {
  bodyScrollOptions,
};
