<template>
  <ul v-if="attachments?.length" :class="b()">
    <li v-for="(attachment, index) in attachments"
        :key="index"
        :class="b('attachement-item')"
    >
      <a :href="attachment?.url"
         :class="b('link')"
         rel="noopener noreferrer"
         target="_blank"
      >
        <!-- eslint-disable-next-line vue/max-len -->
        {{ attachment.type === 'datasheet' ? $t('c-oxomi-item-attachments.datasheet') : attachment.type === 'catalog' ? $t('c-oxomi-item-attachments.catalog') : '' }}
      </a>
      <span>
        ({{ attachment?.filesize ? `${attachment?.filename}, ${attachment?.filesize}` : attachment?.filename }})
      </span>
      <span :class="b('description')">
        {{ attachment?.description }}
      </span>
    </li>
  </ul>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { AttachmentType } from '@/setup/globals';

  // type Setup = {}

  export type OxomiAttachement = {
    iconUrl: string;
    typeName: string;
    description: string;
    filename: string;
    url: string;
    filesize?: string;
    filesizeInBytes?: number;
    type: AttachmentType.DATASHEET | AttachmentType.CATALOG;
  };

  export type OxomiAttachmentResponseData = {
    success: boolean;
    error: boolean;
    fromAssortment: boolean;
    attachmentsFound: boolean;
    numAttachments: number;
    attachments: OxomiAttachement[];
  };

  /**
   * Renders oxomi item attachments.
   */
  export default defineComponent({
    name: 'c-oxomi-item-attachments',

    // components: {},

    props: {
      /**
       * Expects document attachements.
       */
      attachments: {
        type: Array as PropType<OxomiAttachement[]>,
        required: true,
      },
    },
    // emits: {},

    // setup(): Setup {
    //   return {};
    // },
    // data(): Data {},

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
  // beforeUpdate() {},
  // updated() {},
  // activated() {},
  // deactivated() {},
  // beforeUnmount() {},
  // unmounted() {},

  // methods: {},
  // render() {},
  });
</script>

<style lang="scss">
@use '@/setup/scss/mixins';
@use '@/setup/scss/variables';

.c-oxomi-item-attachments {
  padding-bottom: variables.$spacing--5;

  &__link {
    @include mixins.link--primary();

    &:hover,
    &:focus {
      color: variables.$color-primary--1;
    }
  }

  &__description {
    display: block;
  }
}
</style>
