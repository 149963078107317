import { defineStore } from 'pinia';
import { AxiosPromise } from 'axios';
import useSessionStore from '@/stores/session';
import uniqueObjectArray from '@/helpers/unique-object-array';
import { Store } from '@/setup/globals';
import { Product } from '@/types/product';
import { Price } from '@/types/price';

interface RunningRequests {
  apiFetchCustomerPrices: boolean;
}

interface State {

  /**
   * Holds the queue of products for which fetching the prices has been requested.
   */
  queue: Pick<Product, 'code'>[];

  /**
   * Holds the list of products with customer prices.
   */
  productsCustomerPrices: Pick<Product, 'code' | 'customerPrice' | 'customerPrices'>[];

  /**
   * Holds the running requests.
   */
  runningRequests: RunningRequests;
}

const storeName = Store.CustomerPrice;

export default defineStore(storeName, {
  state: () => {
    const state: State = {
      queue: [],
      productsCustomerPrices: [],
      runningRequests: {
        apiFetchCustomerPrices: false,
      },
    };

    return state;
  },

  getters: {
    getCustomerPriceByProduct: state => (product: Product): Price | undefined => {
      if (!useSessionStore().flags.showCustomerSpecificPrices) {
        return undefined;
      }

      const { code, customerPrice } = product;

      if (customerPrice) {
        return customerPrice;
      }

      return state.productsCustomerPrices.find(productItem => productItem.code === code)?.customerPrice || undefined;
    },

    getCustomerPricesByProduct: state => (product: Product): Price[] | undefined => {
      if (!useSessionStore().flags.showCustomerSpecificPrices) {
        return undefined;
      }

      const { code, customerPrices } = product;

      if (customerPrices) {
        return customerPrices;
      }

      return state.productsCustomerPrices.find(productItem => productItem.code === code)?.customerPrices || undefined;
    },
  },

  actions: {
    /**
     * Fetches prices for a list of products
     */
    apiFetchCustomerPrices(products: Pick<Product, 'code'>[]): AxiosPromise | Promise<void> {
      if (!useSessionStore().flags.showCustomerSpecificPrices) {
        return Promise.resolve();
      }

      const payload = uniqueObjectArray(products)
        .filter((payloadItem) => {
          const isInQueue = !!this.queue.find(product => product.code === payloadItem.code);
          const priceAlreadyFetched = !!this.productsCustomerPrices
            .find(product => product.code === payloadItem.code);

          return !priceAlreadyFetched && !isInQueue;
        });

      if (!payload.length) {
        return Promise.resolve();
      }

      this.queue = [
        ...this.queue,
        ...payload,
      ];

      this.runningRequests.apiFetchCustomerPrices = true;

      return this.$api.post(this.$api.getApiUrl('prices'), payload).then((response) => {
        const { result } = response.data?.data || {};

        if (!Array.isArray(result)) {
          return Promise.reject(new Error('API failure during "customer-price/apiFetchCustomerPrices" request.'));
        }

        this.productsCustomerPrices = [
          ...this.productsCustomerPrices,
          ...result.map(product => ({
            code: product.code,
            customerPrice: product.customerPrice,
            customerPrices: product.customerPrices,
          })),
        ];

        return response;
      }).finally(() => {
        const payloadItemCodes = payload.flatMap(payloadItem => payloadItem.code);

        this.queue = this.queue.filter(item => !payloadItemCodes.includes(item.code));
        this.runningRequests.apiFetchCustomerPrices = false;
      });
    },
  },
});
