<template>
  <footer :class="b()">
    <nav v-if="navigationStore.getMainFooterNav.length" :class="b('navigation')">
      <ul v-if="$viewport.isSm" :class="b('navigation-list-l0')">
        <li v-for="(node, nodeIndex) in navigationStore.getMainFooterNav"
            :key="node.uid"
            :class="b('navigation-list-l0-item')"
        >
          <div :class="b('navigation-list-l0-title')">
            {{ node.localizedTitle }}
          </div>
          <ul v-if="node.links?.length" :class="b('navigation-list-l1')">
            <li v-for="link in node.links"
                :key="link.uid"
                :class="b('navigation-list-l1-item')"
            >
              <a v-bind="cmsLinkAttributes(link)"
                 :class="b('navigation-list-l1-link')"
                 @click="onClickLink(link)"
              >
                {{ link.name }}
              </a>
            </li>
            <li v-if="showLoginLink && nodeIndex === navigationStore.getMainFooterNav.length - 1"
                :class="b('navigation-list-l1-item')"
            >
              <!-- Class "app-login-link" required by wrapper app -->
              <a :href="sessionStore.links.loginLogoutLink.url"
                 :class="b('navigation-list-l1-link')"
                 class="app-login-link"
                 @click="handleLoginButtonClick"
              >
                {{ $t('c-footer.linkLogin') }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <c-collapse-group v-else :class="b('navigation-list-l0-collapse')">
        <c-collapse v-for="(node, nodeIndex) in navigationStore.getMainFooterNav"
                    :key="node.uid"
                    :title="node.localizedTitle"
        >
          <ul v-if="node.links?.length" :class="b('navigation-list-l1')">
            <li v-for="link in node.links"
                :key="link.uid"
                :class="b('navigation-list-l1-item')"
            >
              <a v-bind="cmsLinkAttributes(link)"
                 :class="b('navigation-list-l1-link')"
                 @click="onClickLink(link)"
              >
                {{ link.name }}
              </a>
            </li>
            <li v-if="showLoginLink && nodeIndex === navigationStore.getMainFooterNav.length - 1"
                :class="b('navigation-list-l1-item')"
            >
              <!-- Class "app-login-link" required by wrapper app -->
              <a :href="sessionStore.links.loginLogoutLink.url"
                 :class="b('navigation-list-l1-link')"
                 class="app-login-link"
                 @click="handleLoginButtonClick"
              >
                {{ $t('c-footer.linkLogin') }}
              </a>
            </li>
          </ul>
        </c-collapse>
      </c-collapse-group>
    </nav>
    <div :class="b('badges-and-social-links')">
      <ul v-if="mappedFooterBadgeImages?.length" :class="b('badges-list')">
        <li v-for="(badge, index) in mappedFooterBadgeImages"
            :key="index"
            :class="b('badges-item')"
        >
          <e-picture :srcset="badge.srcset"
                     :alt="badge.alt"
                     :fallback="badge.fallback"
                     :sizes="badgeSizes"
                     :width="50"
                     :height="50"
          />
        </li>
      </ul>
      <nav :class="b('social-links')">
        <ul :class="b('social-links-list')">
          <li v-for="socialLink in mapSocialLinks"
              :key="socialLink.uid"
              :class="b('social-links-item')"
          >
            <a v-bind="cmsLinkAttributes(socialLink)"
               :class="b('social-links-link')"
               target="_blank"
               rel="noopener noreferrer"
               @click="onClickLink(socialLink)"
            >
              <e-icon v-if="socialLink.icon"
                      :icon="socialLink.icon"
                      :alt="socialLink.name"
                      size="15"
              />
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <div :class="b('bottom-bar')">
      <div :class="b('bottom-bar-inner')">
        <nav v-if="navigationStore.getLegalLinks?.length" :class="b('legal-links')">
          <ul :class="b('legal-links-list')">
            <li v-for="legalLink in navigationStore.getLegalLinks"
                :key="legalLink.uid"
                :class="b('legal-links-item')"
            >
              <a v-bind="cmsLinkAttributes(legalLink)"
                 :class="b('legal-links-link')"
                 @click="onClickLink(legalLink)"
              >
                {{ legalLink.name }}
              </a>
            </li>
          </ul>
        </nav>
        <div :class="b('copyright')">
          {{ copyRightText }}
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import navigationStore from '@/stores/navigation';
  import useSessionStore from '@/stores/session';
  import useCheckoutStore from '@/stores/checkout';
  import cmsLinkAttributes from '@/helpers/cms-link-attributes';
  import eIcon from '@/elements/e-icon.vue';
  import ePicture from '@/elements/e-picture.vue';
  import cCollapseGroup from '@/components/c-collapse-group.vue';
  import cCollapse from '@/components/c-collapse.vue';
  import { isOpen as isCartMergeModalOpen } from '@/components/c-cart-merge-modal.vue';
  import mapMediaContainerSrcSet from '@/helpers/map-media-container-srcset';
  import { CmsLink } from '@/types/cms-link';
  import { ImageSrcset } from '@/types/image';
  import { ImageSizes } from '@/types/sizes';

  /**
   * Map of social media icons.
   */
  const SOCIAL_LINKS_ICONS: Record<string, string> = {
    youtubeLink: 'i-youtube',
    linkedInLink: 'i-linkedin',
    facebookLink: 'i-facebook',
    instagramLink: 'i-instagram',
    tiktokLink: 'i-tiktok',
  };

  interface Setup {
    navigationStore: ReturnType<typeof navigationStore>;
    sessionStore: ReturnType<typeof useSessionStore>;
    checkoutStore: ReturnType<typeof useCheckoutStore>;
    cmsLinkAttributes: typeof cmsLinkAttributes;
    badgeSizes: ImageSizes;
  }

  export default defineComponent({
    name: 'c-footer',

    components: {
      eIcon,
      ePicture,
      cCollapseGroup,
      cCollapse,
    },

    // props: {},
    setup(): Setup {
      return {
        navigationStore: navigationStore(),
        sessionStore: useSessionStore(),
        checkoutStore: useCheckoutStore(),
        cmsLinkAttributes,
        badgeSizes: {
          xxs: 50,
          fallback: 50,
        },
      };
    },
    // data() {
    //   return {};
    // },

    computed: {
      /**
       * Returns copyright text including current year.
       */
      copyRightText(): string {
        return this.$t('c-footer.labelCopyright', { year: this.$dayjs().format('YYYY') });
      },

      /**
       * Returns social links enriched with icon.
       */
      mapSocialLinks(): CmsLink[] {
        return this.navigationStore.getSocialLinks?.map(link => ({
          ...link,
          icon: SOCIAL_LINKS_ICONS[link.uid],
        })).filter(link => !!link.icon) || [];
      },

      /**
       * Returns mapped badges.
       */
      mappedFooterBadgeImages(): ImageSrcset[] {
        const { footerBadgeImages } = this.navigationStore;

        return Array.isArray(footerBadgeImages)
          ? footerBadgeImages?.map(badge => mapMediaContainerSrcSet(badge))
          : [];
      },

      showLoginLink(): boolean {
        return import.meta.env.VITE_LOGIN_LINK_POSITION === 'footer';
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      onClickLink(link: CmsLink): void {
        this.$gtm.pushFooterLink(link);
      },

      handleLoginButtonClick(event: MouseEvent): void {
        if (this.checkoutStore.getCartEntriesAmount > 0) {
          event.preventDefault();
          isCartMergeModalOpen.value = true;
        }
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '../setup/scss/variables';
  @use '../setup/scss/mixins';

  .c-footer {
    grid-area: footer;
    margin-top: variables.$spacing--100;
    border-top: 5px solid variables.$color-grayscale--0;

    @include mixins.media($media: print) {
      display: none;
    }

    &__navigation {
      @include mixins.container();
    }

    &__navigation-list-l0 {
      display: grid;
      grid-gap: variables.$spacing--25;
      grid-template-columns: repeat(2, 1fr);
      padding: variables.$spacing--40 0;

      @include mixins.media(sm) {
        grid-template-columns: repeat(3, 1fr);
      }

      @include mixins.media(lg) {
        grid-template-columns: repeat(5, 1fr);
      }
    }

    &__navigation-list-l0-collapse {
      margin: variables.$spacing--40 0 variables.$spacing--60;
    }

    &__navigation-list-l0-title {
      @include mixins.font(variables.$font-size--20, variables.$line-height--25, variables.$font-weight--bold);

      margin-bottom: variables.$spacing--30;
    }

    &__navigation-list-l1-item {
      margin-bottom: variables.$spacing--5;
    }

    &__navigation-list-l1-link {
      @include mixins.font(variables.$font-size--18);
      @include mixins.link--primary();

      border-bottom-color: transparent;
    }

    &__badges-and-social-links {
      @include mixins.container();

      display: grid;
      grid-gap: variables.$spacing--50 variables.$spacing--25;
      grid-template-columns: 1fr;

      @include mixins.media(sm) {
        grid-template-columns: repeat(3, 1fr);
      }

      @include mixins.media(lg) {
        grid-template-columns: repeat(5, 1fr);
      }
    }

    &__badges-list {
      display: flex;
      grid-row: 2/3;
      margin-bottom: variables.$spacing--30;

      @include mixins.media(sm) {
        grid-column: 1/3;
        grid-row: 1/2;
        margin-bottom: variables.$spacing--70;
      }

      @include mixins.media(lg) {
        grid-column: 1/5;
      }
    }

    &__badges-item {
      width: 50px;

      &:not(:last-of-type) {
        margin-right: variables.$spacing--15;
      }
    }

    &__social-links {
      grid-row: 1/2;

      @include mixins.media(sm) {
        grid-column: 3/4;
        grid-row: 1/2;
        margin-bottom: variables.$spacing--70;
      }

      @include mixins.media(lg) {
        grid-column: 5/6;
      }
    }

    &__social-links-list {
      display: flex;

      @include mixins.media($down: xs) {
        justify-content: flex-end;
      }
    }

    &__social-links-item {
      &:not(:last-of-type) {
        margin-right: variables.$spacing--20;
      }
    }

    &__social-links-link {
      display: block;
      padding: variables.$spacing--5;
      border-top: 3px solid variables.$color-grayscale--0;

      &:hover,
      &:focus {
        border-color: variables.$color-primary--1;
        color: variables.$color-primary--1;
      }
    }

    &__bottom-bar {
      padding: variables.$spacing--10 0 variables.$spacing--20;
      background-color: variables.$color-grayscale--0;

      @include mixins.media(sm) {
        padding: variables.$spacing--10 0;
      }
    }

    &__bottom-bar-inner {
      @include mixins.container();

      display: grid;
      grid-template-columns: 1fr;

      @include mixins.media(md) {
        grid-template-columns: 1fr auto;
      }
    }

    &__legal-links-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: variables.$spacing--20;

      @include mixins.media(md) {
        justify-content: start;
        margin-bottom: 0;
      }
    }

    &__legal-links-item {
      margin: 0 variables.$spacing--20;

      &:first-of-type {
        @include mixins.media(md) {
          margin-left: 0;
        }
      }
    }

    &__legal-links-link {
      @include mixins.font(variables.$font-size--16, null, variables.$font-weight--bold);

      &,
      &:hover,
      &:focus {
        color: variables.$color-grayscale--1000;
      }
    }

    &__copyright {
      @include mixins.font(variables.$font-size--16);

      color: variables.$color-grayscale--1000;
      text-align: center;

      @include mixins.media(md) {
        font-weight: variables.$font-weight--bold;
        text-align: right;
      }
    }
  }
</style>
