import { ImageSizes } from '@/types/sizes';

/**
 * @type {object} Lookup table of available breakpoints.
 *
 * Keep in sync with SCSS variables!
 */
export const BREAKPOINTS = {
  // Keep in sync with SCSS variables!
  xxs: 0,
  xs: 480,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1440,
};

/**
 * @type {object} Lookup table of available breakpoints max values.
 */
export const BREAKPOINTS_MAX = {
  xxs: BREAKPOINTS.xs - 1,
  xs: BREAKPOINTS.sm - 1,
  sm: BREAKPOINTS.md - 1,
  md: BREAKPOINTS.lg - 1,
  lg: BREAKPOINTS.xl - 1,
};

/**
 * @type {number} Global resize debounce value.
 */
export const RESIZE_DEBOUNCE = 100;

export const FALLBACK_CURRENCY_ISO_CODE = 'CHF';

/**
 * @type {boolean} Holds a flag if local and session storage are available.
 * They can lead to exceptions if cookies are disabled on the client.
 */
export const IS_STORAGE_AVAILABLE = (function isStorageAvailable(): boolean {
  const keyValue = '__storage_test__';

  try {
    const storage = window.localStorage;

    storage.setItem(keyValue, keyValue);
    storage.removeItem(keyValue);

    return true;
  } catch (error) {
    return false;
  }
}());

/**
 * Contains list of all pinia stores.
 */
export enum Store {
  Navigation = 'navigation',
  Session = 'session',
  Breadcrumbs = 'breadcrumbs',
  Notification = 'notification',
  ProductResult = 'productResult',
  CustomerPrice = 'customerPrice',
  ProductStock = 'productStock',
  ProductDetail = 'productDetail',
  Checkout = 'checkout',
  SearchSuggestions = 'searchSuggestions',
  Registration = 'registration',
  Favourite = 'favourite',
  Wishlist = 'wishlist',
  AccountNavigation = 'accountNavigation',
  UserManagement = 'userManagement',
  Profile = 'profile',
  OrderHistory = 'orderHistory',
  CollectiveOrder = 'collectiveOrder',
  ContactList = 'contactList',
  ProductComparison = 'productComparison',
  CustomerAssortment = 'customerAssortment',
  Oxomi = 'oxomi',
  LocationFinder = 'locationFinder',
  LocationDetail = 'locationDetail',
  Cpq = 'cpq',
  StructuredData = 'structuredData',
}

/**
 * Holds all available application icon names.
 */
export enum Icon {
  ArrowLeft = 'i-arrow--left',
  ArrowRight = 'i-arrow--right',
  ArrowDown = 'i-arrow--down',
  ArrowUp = 'i-arrow--up',
  InStock = 'i-in-stock',
  AvailableIn1To2Days = 'i-available-in-1-to-2-days',
  AvailableIn3To5Days = 'i-available-in-3-to-5-days',
  AvailableIn1To2Weeks = 'i-available-in-1-to-2-weeks',
  AvailableIn3To4Weeks = 'i-available-in-3-to-4-weeks',
  DeliveryDateOnRequest = 'i-date-on-request',
  OutOfStock = 'i-out-of-stock',
  PickupInStock = 'i-pickup-in-stock',
  PickupInOtherStore = 'i-pickup-in-other-store',
  PickupOutOfStock = 'i-pickup-out-of-stock',
  AsLongAsInStock = 'i-as-long-as-in-stock',
  Liquidation1 = 'i-liquidation-1',
  Print = 'i-print',
}

export const FILTER_SECTIONS = {
  section1: 'SECTION_1',
} as const;

export const WINDOW_HISTORY_TYPES = {
  productDetailVariantFilterUpdate: 'lProductDetail.variantFilterUpdate',
} as const;

/**
 * Defines possible link target values.
 */
export enum LinkTarget {
  Self = '_self',
  Blank = '_blank',
  // Only add additional types if required.
}

export enum HybrisLinkTarget {
  SameWindow = 'sameWindow',
  NewWindow = 'newWindow',
  Modal = 'modal',
}

/**
 * Defines price types for price objects.
 */
export enum PriceType {
  From = 'FROM',
  Buy = 'BUY',
}

/**
 * Defines tag values for product objects.
 */
export enum ProductTag {
  Promoted = 'promoted', // This tag represents that a product was already bought by the customer, despite the name.
}

export enum ProductSalesStatus {
  NotExisting = 'NOT_EXISTING',
}

export enum MediaLinkType {
  Media = 'media',
  Cadenas = 'cadenas',
  Link = 'link',
}

/**
 * Default responsive image sizes.
 */
export const DEFAULT_IMAGE_SIZES: ImageSizes = {
  ...BREAKPOINTS_MAX,
  fallback: 1440,
};

/**
 * Holds all storage keys used in the application.
 */
export enum StorageKey {
  Notifications = 'appNotifications',
  ProductGridListStyle = 'appProductGridListStyle',
  LastVisitedProduct = 'appLastVisitedProduct',
  SearchSuggestions = 'appSearchSuggestions',
  Navigation = 'appNavigation',
  GlobalCommission = 'appGlobalCommission',
  ShowOnlyRecommendedRetailPrices = 'showOnlyRecommendedRetailPrice',
  ProductComparison = 'appProductComparison',
  UserLastname = 'sfs_lastname',
  UserFirstname = 'sfs_firstname',
  UserEmail = 'sfs_email',
  UserCustomerNumber = 'sfs_customernumber',
}

/**
 * Identifier attribute for links that should be opened in a modal.
 */
export const MODAL_LINK_IDENTIFIER = 'data-modal-link';

/**
 * Holds types provided by HYBRIS.
 */
export enum HybrisType {
  CheckoutData = 'sfsCheckout',
  Cart = 'cart',
  OrderEntry = 'orderEntry',
  Order = 'Order',
  CheckoutStep = 'sfsCheckoutStep',
  CategorySuggestion = 'categorySuggestion',
  Cmslink = 'cMSLinkComponent',
  Media = 'media',
  MediaContainer = 'mediaContainer',
  NavigationNode = 'navigationNode',
  OxomiPortal = 'sfsOXOMIPortal',
  OxomiPortalPage = 'sfsOXOMIPortalPageComponent',
  OxomiEmbedCatalog = 'sfsOXOMIEmbedCatalogComponent',
  PointOfService = 'pointOfService',
  Price = 'price',
  Product = 'product',
  Cad = 'cAD',
  ProductLongText = 'productLongText',
  ProductBrand = 'productBrand',
  Stock = 'stock',
  StockLevelStatus = 'StockLevelStatus',
  Language = 'language',
  Customer = 'customer',
  Commission = 'sfsCommission',
  FreeDeliveryDelta = 'sfsFreeDeliveryDelta',
  AccordionWrapper = 'sfsAccordionWrapperComponent',
  Accordion = 'sfsAccordionComponent',
  AccordionGroup = 'sfsAccordionGroupComponent',
  AccordionEntry = 'sfsAccordionEntryComponent',
  DeliveryOption = 'sfsDeliveryOption',
  DeliveryMode = 'deliveryMode',
  DeliveryDateTime = 'sfsDeliveryDateTime',
  Address = 'Address',
  MarketingParagraph = 'sfsMarketingParagraphComponent',
  RowContainer = 'sfsRowContainerComponent',
  PaymentMode = 'sfsPaymentMode',
  RegistrationForm = 'sfsRegister',
  FavouriteList = 'favorites',
  FavouriteEntry = 'favoriteEntry',
  OciCart = 'oCICart',
  OciCartEntry = 'oCICartEntry',
  Wishlist = 'wishlist',
  WishlistEntry = 'wishlistEntry',
  WishlistLabel = 'wishlistLabel',
  OrderDocument = 'orderDocument',
  YoutubeVideo = 'sfsYoutubeComponent',
  Dashboard = 'sfsDashboardComponent',
  ProductSliderTab = 'productSliderTab',
  NewsletterRegistration = 'sfsNewsletterComponent',
  OrderStatus = 'OrderStatus',
  SearchState = 'searchState',
  ProductQuantities = 'sfsProductQuantities',
  CollectiveOrder = 'collectiveOrder',
  AutocompleteResult = 'sfsAutocompleteResult',
  ContactPerson = 'contactPerson',
  ContactRegion = 'contactRegion',
  ContactDepartment = 'contactDepartment',
  ContactDivision = 'contactDivision',
  ContactPersonComponent = 'sfsContactPersonComponent',
  BannerComponent = 'sfsBannerComponent',
  BannerCarouselComponent = 'sfsBannerCarouselComponent',
  TodayOpeningTime = 'todayOpeningTime',
  WeekdayOpeningDay = 'weekdayOpeningDay',
  SpecialOpeningDay = 'specialOpeningDay',
  Pictogram = 'pictogram',
  M2mCalculator = 'sfsMToMCalculatorComponent',
}

/**
 * Holds the checkout delivery options.
 */
export enum DeliveryOptionId {
  Delivery = 'delivery',
  Pickup = 'pickup',
}

/**
 * Holds the checkout delivery mode display types.
 */
export enum DeliveryModeDateDisplayType {
  Below = 'BELOW',
  DateSelect = 'DATESELECT',
  Radio = 'RADIO',
}

/**
 * Holds the checkout delivery date time display types.
 */
export enum DeliveryDateTimeDateDisplayDetailType {
  DateWeekday = 'DATE_WEEKDAY',
  DateWeekdayTime = 'DATE_WEEKDAY_TIME',
  DateOnly = 'DATE_ONLY',
  DateTime = 'DATE_TIME',
}

export enum StockStatus {
  InStock = 'inStock',
  AvailableIn1To2Days = 'availableIn1To2Days',
  AvailableIn3To5Days = 'availableIn3To5Days',
  AvailableIn1To2Weeks = 'availableIn1To2Weeks',
  AvailableIn3To4Weeks = 'availableIn3To4Weeks',
  DeliveryDateOnRequest = 'deliveryDateOnRequest',
  OutOfStock = 'outOfStock',
}

export enum PosStockStatus {
  PickupInStock = 'pickupInStock',
  PickupInOtherStore = 'pickupInOtherStore',
  PickupOutOfStock = 'pickupOutOfStock',
}

export enum AdditionalStockInfo {
  AsLongAsInStock = 'AS_LONG_AS_IN_STOCK',
  NoLongerDeliverable = 'NO_LONGER_DELIVERABLE',
  NotYetAvailable = 'NOT_YET_AVAILABLE',
}

export enum Layout {
  Cart = 'l-cart',
  SearchResult = 'l-search-result',
  CategoryResult = 'l-category-result',
  CheckoutDelivery = 'l-checkout-delivery',
  CheckoutPayment = 'l-checkout-payment',
  CheckoutReview = 'l-checkout-review',
  CheckoutConfirmation = 'l-checkout-confirmation',
  CheckoutLogin = 'l-checkout-login',
  Registration = 'l-registration',
  ProductDetail = 'l-product-detail',
  WishlistOverview = 'l-wishlist-overview',
  Favourites = 'l-favourites',
  UserManagement = 'l-user-management',
  Profile = 'l-profile',
  OrderHistory = 'l-order-history',
  CommissionManagement = 'l-commission-management',
  CustomerCard = 'l-customer-card',
  AccountNavigation = 'l-account-navigation',
  CollectiveOrderOverview = 'l-collective-order-overview',
  ProductComparison = 'l-product-comparison',
  CustomerAssortment = 'l-customer-assortment',
  ContactList = 'l-contact-list',
  LocationFinder = 'l-location-finder',
  LocationDetail = 'l-location-detail',
  Cpq = 'l-cpq',
}

export enum PermissionGroupUid {
  CompanyWishlistRead = 'companyWishlistRead',
  CompanyWishlistAdmin = 'companyWishlistAdmin',
  WishlistLabelAdmin = 'wishlistLabelAdmin',
  B2bCollectiveOrdersAdmin = 'b2bCollectiveOrdersAdmin',
  B2bAdminGroup = 'b2badmingroup',
  B2bCustomerGroup = 'b2bcustomergroup',
}

export enum OrderDocumentType {
  DeliveryReceipt = 'DELIVERY_RECEIPT',
  Invoice = 'INVOICE',
  OrderConfirmation = 'ORDER_CONFIRMATION',
  ReturnDelivery = 'RETURN_DELIVERY',
}

export enum HeadlineTag {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
}

export enum UserType {
  B2b = 'B2B',
  B2c = 'B2C',
}

export enum OrderStatusCode {
  A = 'A',
  C = 'C',
}

export enum CollectiveOrderStatus {
  Approved = 'APPROVED',
  Check = 'CHECK',
}

export enum ApiResponseMessageAction {
  Redirect = 'REDIRECT',
  PageReload = 'PAGE_RELOAD',
}

export enum RetailerProductStock {
  Full = 'full',
  Low = 'low',
  Empty = 'empty',
  SupplierDelivery = 'supplierDelivery',
}

export const RETAIL_STOCK_LABELS = {
  [RetailerProductStock.Full]: 'global.retailStockFull',
  [RetailerProductStock.Low]: 'global.retailStockLow',
  [RetailerProductStock.Empty]: 'global.retailStockEmpty',
  [RetailerProductStock.SupplierDelivery]: 'global.retailStockSupplierDelivery',
};

export const RETAIL_STOCK_ICONS = {
  [RetailerProductStock.Full]: 'i-in-stock',
  [RetailerProductStock.Low]: 'i-available-low',
  [RetailerProductStock.Empty]: 'i-out-of-stock',
  [RetailerProductStock.SupplierDelivery]: 'i-available-low',
};

export enum AttachmentType {
  DATASHEET = 'datasheet',
  CATALOG = 'catalog',
}
