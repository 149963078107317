<template>
  <div :class="b({ hasOverflow, isExpanded })" :style="rootStyles">
    <div ref="content" :class="b('content')">
      <slot></slot>
    </div>
    <button v-if="hasOverflow"
            :class="b('button')"
            :aria-label="$t(isExpanded ? 'c-collapse-text.buttonContract' : 'c-collapse-text.buttonExpand')"
            type="button"
            @click="isExpanded = !isExpanded"
    >
      <e-icon icon="i-arrow--down" size="22" />
    </button>
  </div>
</template>

<script lang="ts">
  import { defineComponent, Ref, ref } from 'vue';
  import eIcon from '@/elements/e-icon.vue';

  interface RootStyles {
    [key: string]: string;
  }

  interface Setup {
    content: Ref<HTMLDivElement | undefined>;
  }

  interface Data {
    hasOverflow: boolean;
    isExpanded: boolean;
    scrollHeight: number;
    resizeObserver: ResizeObserver | null;
  }

  /**
   * Renders a text that can be collapsed/expanded.
   */
  export default defineComponent({
    name: 'c-collapse-text',
    components: { eIcon },

    props: {
      /**
       * Allows defining the amount of lines that should be visible when not expanded.
       */
      linesVisible: {
        type: Number,
        default: 3,
      },

      /**
       * Desired line height (px).
       */
      lineHeight: {
        type: Number,
        default: 25,
      },
    },
    // emits: {},

    setup(): Setup {
      return {
        content: ref(),
      };
    },
    data(): Data {
      return {
        hasOverflow: true,
        isExpanded: false,
        scrollHeight: 0,
        resizeObserver: null,
      };
    },

    computed: {
      /**
       * Returns dynamic styles for the root element.
       */
      rootStyles(): RootStyles {
        return {
          '--c-collapse-text--lines-visible': `${this.linesVisible}`,
          '--c-collapse-text--line-height': `${this.lineHeight}px`,
          '--c-collapse-text--scroll-height': `${this.scrollHeight}px`,
        };
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    mounted() {
      document.fonts.ready.then(() => {
        this.resizeObserver = new ResizeObserver(this.updateDefinitions);
        this.resizeObserver.observe(this.$el);
      });
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    beforeUnmount() {
      this.resizeObserver?.disconnect();
    },
    // unmounted() {},

    methods: {
      /**
       * Updates `hasOverflow` property and scroll height.
       */
      updateDefinitions(): void {
        const { scrollHeight } = this.content || {};

        if (!scrollHeight) {
          return;
        }

        this.$nextTick(() => {
          this.scrollHeight = scrollHeight;
          this.hasOverflow = scrollHeight > this.lineHeight * this.linesVisible;
        });
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-collapse-text {
    $this: &;

    --c-collapse-text--line-height: variables.$line-height--25;

    @include mixins.font(variables.$font-size--18);

    line-height: var(--c-collapse-text--line-height);

    &__button {
      position: relative;
      display: flex;
      justify-content: center;
      width: 100%;
      padding-block: variables.$spacing--15;
      cursor: pointer;

      @include mixins.media($media: print) {
        display: none;
      }

      &::before {
        position: absolute;
        top: calc(var(--c-collapse-text--line-height) * -1);
        left: 0;
        content: '';
        width: 100%;
        height: var(--c-collapse-text--line-height);
        background-image:
          linear-gradient(
            to bottom,
            rgba(variables.$color-grayscale--1000, 0),
            rgba(variables.$color-grayscale--1000, 1)
          );

        @include mixins.media($media: print) {
          content: none;
        }
      }

      .e-icon {
        transition: transform variables.$transition-duration--200 ease-in-out;
      }
    }

    &--is-expanded &__button {
      &::before {
        display: none;
      }

      .e-icon {
        transform: rotate(180deg);
      }
    }

    &--has-overflow {
      #{$this}__content {
        max-height: calc(var(--c-collapse-text--lines-visible) * var(--c-collapse-text--line-height));
        overflow: hidden;
        transition: max-height variables.$transition-duration--200 ease-in-out;

        @include mixins.media($media: print) {
          max-height: none;
          margin-bottom: variables.$spacing--30;
        }
      }
    }

    &--has-overflow#{&}--is-expanded {
      #{$this}__content {
        max-height: var(--c-collapse-text--scroll-height);
      }
    }
  }
</style>
