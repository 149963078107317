import { Plugin } from 'vue';
import loadScript from '@/helpers/load-script';
import i18n from '@/setup/i18n';
import useOxomiStore from '@/stores/oxomi';
import useCheckoutStore from '@/stores/checkout';
import useSessionStore from '@/stores/session';
import getProductNetPrice from '@/helpers/get-product-net-price';
import mapStockIndicator from '@/helpers/map-stock-indicator';
import getBasePriceUnit from '@/helpers/get-base-price-unit';
import addContextPathToUrl from '@/helpers/add-context-path-to-url';
import { OxomiPortal } from '@/types/oxomi-portal';
import { Product } from '@/types/product';

type MappedInfoPlayItemFeatureField = {
  name: string;
  span: boolean;
  value: string | number;
}

type MappedInfoPlayItemFeature = {
  title: string;
  fields: MappedInfoPlayItemFeatureField[];
}

type MappedInfoPlayItem = {
  itemNumber: string;
  supplierName: string;
  shortText: string;
  previewImageUrl: string;
  quantity: number;
  quantityUnitName: string;
  features: MappedInfoPlayItemFeature[];
}

type InfoPlayObject = {
  itemNumber: string;
  compactSelection: string;
  supplierNumber: string;
  supplierNumbers: string[];
  direct: null | boolean;
  catalogId: string;
  catalogName: string;
  pageNum: number;
  visiblePageNum: string;
  quantity: string;
  items: MappedInfoPlayItem[];
}

type NextStep = (payload: InfoPlayObject) => void;

export type Options = OxomiPortal & {
  infoplayItemLookup(infoPlayObject: InfoPlayObject, nextStep: NextStep): void;
  infoplayBasketHandler(infoPlayObject: InfoPlayObject, nextStep: NextStep): void;
  infoplayEmptyResultHandler(): void;
  infoplayMenuTitle: string;
  lang: string;
  disableOverlayHistory: boolean;
}

type PortalPageOptions = {
  target: HTMLElement;
  page?: string;
}

type EmbedCatalogOptions = {
  target: HTMLElement;
  catalog: string;
  showDetails: boolean;
}

type ShowErrorOptions = {
  message: string;
}

export type ExistsResponse = {
  success: boolean;
  error: boolean;
  catalog: boolean;
  video: boolean;
  gallery: boolean;
  image: boolean;
  attachment: boolean;
  text: boolean;
  datasheet: string;
}

export type ExistsOptions = {
  itemNumber?: string;
  supplierNumber?: string;
  item?: string;
  callback(response: ExistsResponse): void;
}

export type ItemPagesOptions = {
  supplierNumber?: string;
  supplierItemNumber?: string;
  itemNumber?: string;
  showDetails: boolean;
  target: HTMLElement;
  own?: boolean;
  lang: string;
}

export type ItemAttachmentsOptions = {
  itemNumber?: string;
  supplierNumber?: string;
  target: HTMLElement;
  type: string;
}

export type ItemVideoOptions = {
  itemNumber?: string;
  supplierNumber?: string;
  target: HTMLElement;
}

declare global {
  interface Window {
    oxomi_ready(): void;
    oxomi_server: string | undefined;
    oxomi: {
      init(options: Options): void;
      portalPage(options: PortalPageOptions): void;
      embedCatalog(options: EmbedCatalogOptions): void;
      showError(options: ShowErrorOptions): void;
      exists(options: ExistsOptions): void;
      itemPages(options: ItemPagesOptions): void;
      itemAttachments(options: ItemAttachmentsOptions): void;
      itemVideos(options: ItemVideoOptions): void;
    };
  }
}

function mapInfoPlayItem(product: Product): MappedInfoPlayItem {
  const price = getProductNetPrice(product);
  const { images } = product;

  return {
    itemNumber: product.code,
    supplierName: product.brand?.name || '',
    shortText: product.name,
    previewImageUrl: Array.isArray(images) && images.length
      ? images?.find(image => image.mediaFormat === 'productXS')?.url || images[0]?.url
      : '',
    quantity: product.roundingUnitFactor,
    quantityUnitName: product.orderUnit,
    features: [
      {
        title: i18n.global.t('oxomi.infoPlayAdditionalInformation'),
        fields: [
          {
            name: i18n.global.t('global.netPrice'),
            span: true,
            value: price ? `${price.value} ${getBasePriceUnit(price)}` : '',
          },
          {
            name: i18n.global.t('oxomi.infoPlayUnitFactor'),
            span: true,
            value: product.roundingUnitFactor,
          },
          {
            name: i18n.global.t('global.availability'),
            span: true,
            value: mapStockIndicator(product.stock)?.label || '',
          },
          {
            name: '',
            span: true,
            value: `<a href="${addContextPathToUrl(product.url)}">${i18n.global.t('oxomi.infoPlayGotoProduct')}</a>`,
          },
        ],
      },
    ],
  };
}

function infoplayItemLookup(infoPlayObject: InfoPlayObject, nextStep: NextStep): void {
  useOxomiStore().apiItemLookup({
    itemNumber: infoPlayObject.itemNumber,
    supplierNumber: infoPlayObject.supplierNumber,
  }).then((response) => {
    const { product } = response.data.data || {};

    if (product) {
      infoPlayObject.items.push(mapInfoPlayItem(product));
    } else {
      infoPlayObject.items = [];
    }

    nextStep(infoPlayObject);
  });
}

function infoplayBasketHandler(infoPlayObject: InfoPlayObject, nextStep: NextStep): void {
  useCheckoutStore().apiAddToCart({
    product: {
      code: infoPlayObject.items[0].itemNumber,
    },
    quantity: parseInt(infoPlayObject.quantity, 10),
    commissionId: useSessionStore().globalCommission?.id || '',
  }).then(() => {
    nextStep(infoPlayObject);
  });
}

const plugin: Plugin = {
  install(app) {
    let isInitialized = false;
    let isOxomiReady = false;

    app.config.globalProperties.$oxomi = (portal?: OxomiPortal) => new Promise<void>((resolve) => {
      if (!isInitialized && portal) {
        isInitialized = true;

        window.oxomi_ready = () => {
          window.oxomi.init({
            ...portal,
            infoplayItemLookup,
            infoplayBasketHandler,
            infoplayEmptyResultHandler: () => {
              window.oxomi.showError({
                message: i18n.global.t('oxomi.infoPlayEmptyResultText'),
              });
            },
            infoplayMenuTitle: i18n.global.t('oxomi.infoPlayMenuTitle'),
            lang: i18n.global.locale.split('-')[0],
            disableOverlayHistory: true,
          });

          isOxomiReady = true;
        };

        loadScript(
          `${window.location.protocol === 'https:' ? 'https:' : 'http:'}//${typeof window.oxomi_server === 'undefined' ? 'oxomi.com' : window.oxomi_server}/assets/frontend/oxomi.js` // eslint-disable-line vue/max-len
        );
      }

      if (isOxomiReady) {
        resolve();
      } else {
        const checkReady = () => {
          if (isOxomiReady) {
            resolve();
          } else {
            setTimeout(checkReady, 100); // Check again after 100 milliseconds
          }
        };

        checkReady();
      }
    });
  },
};

export default plugin;
