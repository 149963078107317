import useSessionStore, { ApiUrls } from '@/stores/session';
import addContextPathToUrl from '@/helpers/add-context-path-to-url';

interface UrlKeyValues {
  [key: string]: string;
}

/**
 * Gets the url for the given 'urlKey'. The method also accepts an Object of interpolation values.
 */
export default function getApiUrl(urlKey: keyof ApiUrls, values?: UrlKeyValues): string {
  const sessionStore = useSessionStore();

  let url = sessionStore.apiUrls[urlKey];

  if (!url) {
    throw new Error(`Unable to find an API url with the identifier ${urlKey}.`);
  }

  if (values) {
    Object.entries(values).forEach(([key, value]) => {
      url = url.replace(`:${key}`, value);
    });
  }

  return addContextPathToUrl(url);
}
