<template>
  <div :class="b()"></div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import loadScript from '@/helpers/load-script';
  import { PAGE_LANG } from '@/setup/i18n';

  // type Setup = {}

  // type Data = {}

  const scriptUrl = 'https://consent.cookiebot.com/{id}/cd.js';

  /**
   * Embeds the cookie declaration script.
   *
   * See: https://www.cookiebot.com/en/manual-implementation/
   */
  export default defineComponent({
    name: 'c-cms-cookiebot-declaration',

    // components: {},

    props: {
      /**
       * Expects the Cookkiebot ID.
       */
      id: {
        type: String,
        required: true,
      },
    },
    // emits: {},

    // setup(): Setup {},
    // data(): Data {},

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    mounted() {
      loadScript(scriptUrl.replace('{id}', this.id), undefined, {
        async: true,
        appendElement: this.$el,
        attributes: [
          {
            key: 'data-culture',
            value: PAGE_LANG?.split('-')?.[0] || 'de', // Did not use locale from i18n setup because of a timing issue.
          }, {
            key: 'id',
            value: 'CookieDeclaration',
          },
        ],
      });
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  // @use '@/setup/scss/mixins';
  // @use '@/setup/scss/variables';

  .c-cms-cookiebot-declaration {
    // ...
  }
</style>
