<template>
  <button v-if="sessionStore.flags.showWishlists"
          :class="b()"
          :aria-label="$t('c-favourite-button.buttonFavourite')"
          :title="$t('c-favourite-button.buttonFavourite')"
          type="button"
          @click="toggleFavourite"
  >
    <e-loader v-if="isRequestRunning" />
    <e-icon v-else
            :icon="isFavourite ? 'i-favorite--filled' : 'i-favorite'"
            size="22"
    />
  </button>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import useFavouriteStore from '@/stores/favourite';
  import useSessionStore from '@/stores/session';
  import eLoader from '@/elements/e-loader.vue';
  import eIcon from '@/elements/e-icon.vue';
  import { Product } from '@/types/product';

  interface Setup {
    favouriteStore: ReturnType<typeof useFavouriteStore>;
    sessionStore: ReturnType<typeof useSessionStore>;
  }

  interface Data {
    runningRequests: {
      apiAddFavourite: boolean;
      apiRemoveFavourite: boolean;
    };
  }

  /**
   * Renders a favourite button for a given product that indicates if the product
   * is currently a favourite and allows to mark or unmark the product as favourite.
   */
  export default defineComponent({
    name: 'c-favourite-button',
    components: { eIcon, eLoader },

    props: {
      /**
       * Expects a product item.
       */
      product: {
        type: Object as PropType<Product>,
        required: true,
      },
    },
    // emits: {},

    setup(): Setup {
      return {
        favouriteStore: useFavouriteStore(),
        sessionStore: useSessionStore(),
      };
    },
    data(): Data {
      return {
        runningRequests: {
          apiAddFavourite: false,
          apiRemoveFavourite: false,
        },
      };
    },

    computed: {
      isFavourite(): boolean {
        const { baseProduct, code } = this.product;

        return this.favouriteStore.getFavouriteProductCodes.includes(baseProduct || code);
      },

      isRequestRunning(): boolean {
        const { runningRequests } = this;

        return [
          runningRequests.apiRemoveFavourite,
          runningRequests.apiAddFavourite,
        ].some(Boolean);
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      toggleFavourite(): void {
        const { baseProduct, code } = this.product;

        const payload = {
          product: {
            code: baseProduct || code,
          },
        };

        if (this.isFavourite) {
          this.runningRequests.apiRemoveFavourite = true;
          this.favouriteStore.apiRemoveFavourite(payload).finally(() => {
            this.runningRequests.apiRemoveFavourite = false;
          });
        } else {
          this.runningRequests.apiAddFavourite = true;
          this.favouriteStore.apiAddFavourite(payload).finally(() => {
            this.runningRequests.apiAddFavourite = false;
          });
        }
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';

  .c-favourite-button {
    cursor: pointer;

    .e-loader {
      font-size: 5px;
    }

    &:hover,
    &:focus {
      color: variables.$color-primary--1;
    }
  }
</style>
