import { defineStore } from 'pinia';
import { AxiosPromise, AxiosResponse } from 'axios';
import { IS_STORAGE_AVAILABLE, StorageKey, Store } from '@/setup/globals';
import { NavigationNode } from '@/types/navigation-node';
import { CmsLink } from '@/types/cms-link';
import { MediaContainer } from '@/types/media-container';
import useSessionStore from './session';
import mapMediaContainerSrcSet from '@/helpers/map-media-container-srcset';
import { ImageSrcset } from '@/types/image';
import cmsLinkAttributes, { LinkWithModalAttribute } from '@/helpers/cms-link-attributes';
import addContextPathToUrl from '@/helpers/add-context-path-to-url';
import { PAGE_LANG } from '@/setup/i18n';

export interface MappedNavigationLink {
  uid: string;
  title: string;
  active: boolean;
  link?: LinkWithModalAttribute | null;
  children: MappedNavigationLink[];
  image?: ImageSrcset;
}

interface NavigationState {

  /**
   * Stores footer navigation nodes.
   */
  footerNodes: null | NavigationNode[];

  /**
   * Stores the badge images for displaying in footer.
   */
  footerBadgeImages: null | MediaContainer[];

  /**
   * Holds the main navigation data.
   */
  mainNavigation: MappedNavigationLink[] | null;
}

interface InitialData {
  data: {
    footerComponent: {
      navigationNodes: NavigationNode[];
      badges: MediaContainer[];
    };
  };
}

const storeName = Store.Navigation;
const navigationCacheDurationInSeconds = 300;

/**
 * Maps the navigation data to a Vue compatible format.
 * Link items are also converted to navigation nodes. For historical reasons navigation
 * nodes are placed in 'children' and 'links'.
 */
function mapNavigationChildren(children: NavigationNode[]): MappedNavigationLink[] {
  const sessionStore = useSessionStore();
  const { currentPageUID } = sessionStore;

  return children.map((navigationNode): MappedNavigationLink => {
    const mappedChild: MappedNavigationLink = {
      uid: navigationNode.uid,
      title: navigationNode.localizedTitle,
      children: (navigationNode.children && mapNavigationChildren(navigationNode.children)) || [],
      image: navigationNode.image && mapMediaContainerSrcSet(navigationNode.image),
      active: navigationNode.uid === currentPageUID,
    };

    navigationNode.links?.forEach((link) => {
      const activeLink = link.uid === currentPageUID;

      if (link.visible) {
        mappedChild.children?.push({
          uid: link.uid,
          title: link.name,
          active: activeLink,
          link: cmsLinkAttributes(link),
          children: [],
          image: link.navigationImage && mapMediaContainerSrcSet(link.navigationImage),
        });
      } else { // For some historical reasons, invisible links should be used as node link.
        mappedChild.link = cmsLinkAttributes(link);

        if (activeLink) {
          mappedChild.active = true;
        }
      }
    });

    return mappedChild;
  });
}

export default defineStore(storeName, {
  state: () => {
    const initialData: InitialData = window.initialData?.[storeName] || {};
    const state: NavigationState = {
      footerNodes: null,
      footerBadgeImages: null,
      mainNavigation: null,
    };

    if (initialData) {
      const { footerComponent } = initialData?.data || {};

      state.footerNodes = footerComponent?.navigationNodes[0]?.children || [];
      state.footerBadgeImages = footerComponent?.badges || [];
    }

    return state;
  },
  getters: {
    /**
     * Returns the navigation nodes for the main footer navigation.
     */
    getMainFooterNav(): NavigationNode[] {
      return this.footerNodes?.find(node => node.uid === 'MainFooterNav')?.children || [];
    },

    /**
     * Returns the list of legal links for the footer.
     */
    getLegalLinks(): CmsLink[] {
      return this.footerNodes?.find(node => node.uid === 'SubFooterNav')?.links || [];
    },

    /**
     * Returns the list of legal links for the footer.
     */
    getSocialLinks(): CmsLink[] {
      return this.footerNodes?.find(node => node.uid === 'socialFooterNav')?.links || [];
    },
  },
  actions: {
    /**
     * Saves the current navigation data to the localStorage.
     */
    saveNavigationToLocalStorage(navigationLocale: string): void {
      const sessionStore = useSessionStore();
      const cache = {
        navigation: this.mainNavigation,
        userUid: sessionStore.user.uid,
        language: navigationLocale,
        timestamp: Date.now(),
      };

      localStorage.setItem(StorageKey.Navigation, JSON.stringify(cache));
    },

    /**
     * Restores the navigation data from the localStorage.
     */
    restoreNavigationFromLocalStore(): Promise<void> {
      const sessionStore = useSessionStore();

      return new Promise((resolve, reject) => {
        // Prevents restore navigation when it's already stored in the pinia store.
        if (this.mainNavigation) {
          resolve();

          return;
        }

        try {
          const localStorageCache = JSON.parse(localStorage.getItem(StorageKey.Navigation) || '');
          const userUid = sessionStore.user.uid;
          const cacheTimestamp = Math.round(localStorageCache.timestamp / 1000);
          const isOutdatedCache = Math.round(Date.now() / 1000) > cacheTimestamp + navigationCacheDurationInSeconds;

          if (!isOutdatedCache && localStorageCache?.userUid === userUid && localStorageCache.language === PAGE_LANG) {
            this.mainNavigation = localStorageCache.navigation;

            resolve();
          } else {
            this.mainNavigation = null;
            reject();
          }
        } catch (error) {
          this.mainNavigation = null;

          reject();
        }
      });
    },

    /**
     * Fetches the main navigation data from the localStorage or API.
     */
    apiFetchNavigation(): Promise<void | AxiosPromise | AxiosResponse> {
      const sessionStore = useSessionStore();
      const url = addContextPathToUrl(sessionStore.apiUrls.cmsNavigation);

      if (!url) {
        throw new Error('API URL for getMainNavigation is missing!');
      }

      return this.restoreNavigationFromLocalStore()
        .catch(() => this.$api.get(url).then((response) => {
          const { navigationNode } = response.data?.data?.tree || {};

          // Gets the locale which is in the request header of this response.
          const navigationLocale = JSON.parse(JSON.stringify(response.config.headers || {}))?.locale;

          this.mainNavigation = mapNavigationChildren(navigationNode.children);

          if (IS_STORAGE_AVAILABLE && navigationLocale) {
            this.saveNavigationToLocalStorage(navigationLocale);
          }

          return response;
        }));
    },
  },
});
