import useSessionStore from '@/stores/session';
import { Product } from '@/types/product';

/**
 * Returns the ComNorm URL for a given product.
 */
export default function comNormUrl(product: Product): string {
  const sessionStore = useSessionStore();
  const url = new URL(window.location.href);

  url.searchParams.set('method', 'GetProductInfo');
  url.searchParams.set('ProductNumber', `${product.code}`);
  url.searchParams.set('SessionKey', sessionStore.sessionKey);

  return url.href;
}
