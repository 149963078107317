import { onMounted, onUnmounted } from 'vue';
import useSessionStore from '@/stores/session';
import { Layout } from '@/setup/globals';

export default function setActiveLayout(layout: Layout) {
  onMounted(() => {
    useSessionStore().activeLayout = layout;
  });

  onUnmounted(() => {
    useSessionStore().activeLayout = null;
  });
}
