import { CmsColumnWidth } from '@/components/c-cms-row-container.vue';
import { ColumnWidth } from '@/components/c-row-container.vue';

/**
 * Maps the given cms column width and maps it so it returns a number for width.
 */
export default function mapColumnWidth(width: CmsColumnWidth): ColumnWidth {
    let mappedWidth;

    switch (width) {
        case CmsColumnWidth.Col4:
          mappedWidth = ColumnWidth.Col4;
          break;

        case CmsColumnWidth.Col6:
          mappedWidth = ColumnWidth.Col6;
          break;

        default:
          mappedWidth = ColumnWidth.Col12;
    }

   return mappedWidth;
}

