interface Callback {
  id: string;
  callback(): unknown;
}

type Attribute = {
  key: string;
  value: string;
};

type Options = {
  defer?: boolean;
  async?: boolean;
  appendElement?: Element;
  attributes?: Attribute[];
}

let scriptLoadingQueue: string[] = [];
let loadScriptCallbacks: Callback[] = [];

/**
 * This helper will load the given script source unless it is already present in the DOM.
 * The callback is called after the script has been loaded.
 *
 * Note: Could be improved by using a Promise instead of a callback.
 */
export default function loadScript(scriptSrc: string, callback?: () => unknown, options?: Options): void {
  const existingTag = document.querySelector(`script[src="${scriptSrc}"]`);

  if (!existingTag) {
    const script = document.createElement('script');
    const {
      defer,
      async,
      appendElement,
      attributes,
    } = options || {};

    scriptLoadingQueue.push(scriptSrc);

    script.type = 'text/javascript';
    script.src = scriptSrc;
    script.defer = !!defer;
    script.async = !!async;

    if (Array.isArray(attributes)) {
      attributes.forEach((attribute) => {
        script.setAttribute(attribute.key, attribute.value);
      });
    }

    if (typeof callback === 'function') {
      script.onload = (): void => {
        callback();

        loadScriptCallbacks = loadScriptCallbacks.filter((queueItem) => {
          const isCurrentScript = queueItem.id === scriptSrc;

          if (isCurrentScript) {
            queueItem.callback();
          }

          return isCurrentScript;
        });

        scriptLoadingQueue = scriptLoadingQueue.filter(loadingScript => loadingScript !== scriptSrc);
      };
    }

    if (appendElement) {
      appendElement.appendChild(script);
    } else {
      document.head.appendChild(script);
    }
  } else if (typeof callback === 'function') {
    if (scriptLoadingQueue.includes(scriptSrc)) {
      loadScriptCallbacks.push({
        id: scriptSrc,
        callback,
      });
    } else {
      callback();
    }
  }
}
