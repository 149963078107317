<template>
  <div :class="b()">
    <e-label>
      <template #name>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="facet.name"></span>
      </template>
      <e-select v-model="value"
                :options="options"
                :disabled="disabled"
                :name="facet.name"
                has-selectable-placeholder
                @update:model-value="onUpdate"
      />
    </e-label>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { Facet } from '@/types/facet';
  import eLabel from '@/elements/e-label.vue';
  import eSelect, { Options } from '@/elements/e-select.vue';

  // interface Setup {}
  interface Data {
    value: string;
    resetApiUrl: string | null;
  }

  /**
   * Renders a select filter facet.
   */
  export default defineComponent({
    name: 'c-facet-select',

    components: {
      eSelect,
      eLabel,
    },

    props: {
      /**
       * Expects a facet object.
       */
      facet: {
        type: Object as PropType<Facet>,
        required: true,
      },

      /**
       * Allows passing disabled state.
       */
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    emits: {
      update: (payload: string) => !!payload,
    },

    // setup(): Setup {},
    data(): Data {
      return {
        value: '',
        resetApiUrl: null,
      };
    },

    computed: {
      /**
       * Returns facet values mapped as select options.
       */
      options(): Options[] {
        return this.facet.values.map(value => ({
          value: value.code,
          label: value.name,
        }));
      },
    },
    watch: {
      /**
       * Observes provided facet for changes and updates select value.
       */
      facet: {
        handler(facet: Facet) {
          const selectedFacetValue = facet.values?.find(value => value.selected);

          if (selectedFacetValue) {
            this.resetApiUrl = selectedFacetValue.query.apiUrl; // Don't use resetApiUrl there because this would reset all facets.
          }

          this.value = `${selectedFacetValue?.code || ''}`;
        },
        deep: true,
        immediate: true,
      },
    },

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      /**
       * Handles update.
       */
      onUpdate(value: string) {
        const apiUrl = value
          ? this.facet.values.find(facetValue => `${facetValue.code}` === value)?.query?.apiUrl
          : this.resetApiUrl;

        if (apiUrl) {
          this.$emit('update', apiUrl);
          this.$gtm.pushSelectFilter(this.facet, value);
        }
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  // @use '@/setup/scss/variables';

  .c-facet-select {
    // ...
  }
</style>
