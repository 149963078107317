<template>
  <div :class="b({ isLoading })">
    {{ formattedPrice }}
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { Product } from '@/types/product';
  import { Price } from '@/types/price';
  import { PriceType } from '@/setup/globals';
  import useCustomerPriceStore from '@/stores/customer-price';
  import formatPrice from '@/helpers/format-price';

  interface Setup {
    customerPriceStore: ReturnType<typeof useCustomerPriceStore>;
  }

  // interface Data {}

  /**
   * Renders the price of a product.
   *
   * Priority order: `customerPrice` -> `specialPrice` -> `price`
   */
  export default defineComponent({
    name: 'c-product-price',

    // components: {},

    props: {
      /**
       * Expects a product object.
       */
      product: {
        type: Object as PropType<Product>,
        required: true,
      },
    },
    // emits: {},

    setup(): Setup {
      return {
        customerPriceStore: useCustomerPriceStore(),
      };
    },
    // data(): Data {
    //   return {};
    // },

    computed: {
      formattedPrice(): string {
        const { price } = this;

        if (!price) {
          return '';
        }

        const prefix = price.priceType === PriceType.From
          ? `${this.$t('global.price.fromPricePrefix')} `
          : '';

        return `${prefix}${formatPrice(price.value, true)}`;
      },

      price(): Price | null {
        const { price, specialPrice } = this.product;

        return this.customerPriceStore.getCustomerPriceByProduct(this.product) || specialPrice || price || null;
      },

      isLoading(): boolean {
        return !!this.customerPriceStore.queue.find(product => product.code === this.product.code);
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '../setup/scss/variables';

  .c-product-price {
    &--is-loading {
      color: variables.$color-grayscale--400;
    }
  }
</style>
