<template>
  <c-modal :is-open="isOpen"
           :title="$t('c-cart-merge-modal.confirmationModalTitle')"
           :is-closable="false"
           size="800"
  >
    <form :class="b('form')" @submit.prevent="confirm">
      <fieldset :class="b('action-fieldset')">
        <e-radio v-model="action"
                 :value="ActionOption.MERGE"
                 :name="ActionOption.MERGE"
        >
          {{ $t('c-cart-merge-modal.actionMergeLabel') }}
        </e-radio>
        <e-radio v-model="action"
                 :value="ActionOption.OLD"
                 :name="ActionOption.OLD"
        >
          {{ $t('c-cart-merge-modal.actionAnonymousCartLabel') }}
        </e-radio>
        <e-radio v-model="action"
                 :value="ActionOption.NEW"
                 :name="ActionOption.NEW"
        >
          {{ $t('c-cart-merge-modal.actionUserCartLabel') }}
        </e-radio>
      </fieldset>
      <div :class="b('form-footer')">
        <e-button variant="secondary"
                  type="button"
                  @click="cancel"
        >
          {{ $t('global.cancel') }}
        </e-button>
        <e-button type="submit">
          {{ $t('global.continue') }}
        </e-button>
      </div>
    </form>
  </c-modal>
</template>

<script lang="ts">
  import { defineComponent, ref, Ref } from 'vue';
  import cModal from '@/components/c-modal.vue';
  import eButton from '@/elements/e-button.vue';
  import useSessionStore from '@/stores/session';
  import eRadio from '@/elements/e-radio.vue';
  import setCookie from '@/helpers/cookie-handler';

  enum ActionOption {
    MERGE = 'MERGE',
    OLD = 'OLD',
    NEW = 'NEW',
  }

  export const isOpen = ref(false);

  interface Setup {
    sessionStore: ReturnType<typeof useSessionStore>;
    ActionOption: typeof ActionOption;
    isOpen: Ref;
  }

  interface Data {
    action: ActionOption;
  }

  const cookieName = 'restorationOption';

  /**
   * Renders the modal which gives the user the option to merge the new cart with the existing one.
   */
  export default defineComponent({
    name: 'c-cart-merge-modal',
    components: {
      eRadio,
      eButton,
      cModal,
    },

    // components: {},

    // props: {},
    // emits: {},

    setup(): Setup {
      return {
        sessionStore: useSessionStore(),
        ActionOption,
        isOpen,
      };
    },
    data(): Data {
      return {
        action: ActionOption.MERGE,
      };
    },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      confirm(): void {
        setCookie(cookieName, this.action);

        this.$nextTick(() => {
          window.location.href = this.sessionStore.links.loginLogoutLink.url;
        });
      },

      cancel(): void {
        isOpen.value = false;
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-cart-merge-modal {
    &__action-fieldset {
      display: flex;
      grid-row-gap: variables.$spacing--15;
      flex-direction: column;
    }

    &__form {
      display: flex;
      grid-row-gap: variables.$spacing--40;
      flex-direction: column;
    }

    &__form-footer {
      display: grid;
      grid-gap: variables.$spacing--20;

      @include mixins.media(sm) {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
</style>
