/**
 * Gets the current scrollbar width.
 */
export default function getScrollBarWidth() {
  const tempElement = document.createElement('div');

  tempElement.style.cssText = 'overflow:scroll; visibility:hidden; position:absolute;top:0;bottom:0;';

  document.body.appendChild(tempElement);

  const width = tempElement.offsetWidth - tempElement.clientWidth;

  const windowHeight = tempElement.clientHeight; // element has window height as it is absolutely positioned
  const bodyHeight = document.body.clientHeight;

  tempElement.remove();

  // if content is smaller than window, we return 0 as no scrollbar is visible
  if (bodyHeight <= windowHeight) {
    return 0;
  }

  return width;
}
