<template>
  <div :class="b({ disabled })" @click.prevent="onAnchorClick">
    <slot></slot>
    <dialog v-if="rendered || open"
            ref="dialog"
            :class="b('dialog')"
            @click.exact="onClick"
            @close="close"
    >
      <div ref="content"
           :class="b('content')"
      >
        <slot name="lightbox" :close="close"></slot>
      </div>
    </dialog>
  </div>
</template>

<script lang="ts">
  import { defineComponent, Ref, ref } from 'vue';
  import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
  import { bodyScrollOptions } from '@/setup/options';

  interface Setup {
    dialog: Ref<HTMLDialogElement | undefined>;
    content: Ref<HTMLDivElement | undefined>;
  }

  interface Data {
    open: boolean;
    rendered: boolean;
  }

  /**
   * Renders a lightbox with the given content.
   */
  export default defineComponent({
    name: 'c-lightbox',

    // components: {},

    props: {
      /**
       * Allows do disable the lightbox functionality.
       */
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    emits: {
      'update:isOpen': (isOpen: boolean) => true, // eslint-disable-line @typescript-eslint/no-unused-vars
    },

    setup(): Setup {
      return {
        dialog: ref(),
        content: ref(),
      };
    },
    data(): Data {
      return {
        open: false,
        rendered: false,
      };
    },

    // computed: {},
    watch: {
      /**
       * Watches the open state and forwards it to the dialog.
       */
      open: {
        immediate: true,
        handler(open: boolean): void {
          if (open) {
            this.rendered = true;
            this.$emit('update:isOpen', open);
          }

          this.$nextTick(() => {
            const { dialog } = this;

            if (!dialog) {
              return;
            }

            if (open && !this.disabled) {
              dialog.showModal();
              disableBodyScroll(dialog, bodyScrollOptions);
            } else {
              enableBodyScroll(dialog);
              dialog.close();
              this.$emit('update:isOpen', false);
            }
          });
        },
      },

      disabled(): void {
        this.close();
        this.$emit('update:isOpen', false);
      },
    },

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      onAnchorClick(): void {
        this.open = true;
        this.$emit('update:isOpen', this.open);
      },

      onClick(event: MouseEvent): void {
        const { target } = event;
        const isContentClick = target && (target === this.content || this.content?.contains(target as Node));

        if (!isContentClick) {
          this.close();
        }
      },

      close(): void {
        this.open = false;
        this.$emit('update:isOpen', this.open);
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';
  @use '@/setup/scss/mixins';

  .c-lightbox {
    display: contents;

    &:not(&--disabled) {
      cursor: zoom-in;
    }

    &__dialog {
      width: 100dvw;
      height: 100dvh;
      padding: 0;
      overflow: visible;
      outline: 0;
      border: 0;
      background: transparent;
      cursor: zoom-out;
      place-content: center;

      &[open] {
        display: grid;
      }

      &::backdrop {
        background: rgba(variables.$color-grayscale--0, 0.85);
      }
    }

    &__content img {
      width: auto;
      max-width: 90dvw;
      height: auto;
      max-height: 90dvh;

      @include mixins.media(md) {
        max-width: 80vw;
        max-height: 80vh;
      }
    }
  }
</style>
