import { defineStore } from 'pinia';
import { RemovableRef, useStorage } from '@vueuse/core';
import axios, { AxiosPromise, AxiosResponse } from 'axios';
import {
  Store,
  IS_STORAGE_AVAILABLE,
  FALLBACK_CURRENCY_ISO_CODE,
  StorageKey,
  Layout,
  HybrisType,
} from '@/setup/globals';
import useFavouriteStore, { FavouriteList } from '@/stores/favourite';
import { Language } from '@/types/language';
import { CmsLink } from '@/types/cms-link';
import { Commission } from '@/types/commission';
import { User } from '@/types/user';
import theme01Logo from '@/themes/theme-01/logo.svg';
import theme02Logo from '@/themes/theme-02/logo.svg';
import { Dashboard } from '@/types/dashboard';
import { ContactPerson } from '@/types/contact-person';
import { PointOfService } from '@/types/point-of-service';
import normalizeContactPerson from '@/helpers/normalize-contact-person';
import normalizeUser from '@/helpers/normalize-user';

export interface ApiUrls {
  cmsNavigation: string;
  cartEntry: string;
  cartEntryEan: string;
  cartEntryText: string;
  autocomplete: string;
  cart: string;
  cartImport: string;
  deliveryMode: string;
  deliveryAddress: string;
  zipLocation: string;
  commission: string;
  commissionEntry: string;
  orderReference: string;
  orderComment: string;
  paymentMode: string;
  paymentAddress: string;
  voucher: string;
  productStock: string;
  registration: string;
  cartWishlist: string;
  wishlist: string;
  wishlistDetails: string;
  wishlistPrint: string;
  wishlistLabels: string;
  wishlistProductCombination: string;
  wishlistImportEntries: string;
  labelForWishlist: string;
  wishlistEntry: string;
  previousNextProduct: string;
  prices: string;
  favoritesEntry: string;
  userRights: string;
  usermanagementEntry: string;
  checkDeleteUserAllowed: string;
  deleteUser: string;
  usermanagementContactPerson: string;
  registerUser: string;
  address: string;
  orderCart: string;
  orderHistoryDocuments: string;
  orderHistoryDetail: string;
  orderHistoryFilter: string;
  collectiveOrders: string;
  returnOrder: string;
  contactPerson: string;
  variants: string;
  product: string;
  productComparison: string;
  oxomiItemLookup: string;
  storeLocator: string;
  favoriteStore: string;
  dataProtectionPolicy: string;
  calculatePrices: string;
  cpqFormAction: string;
  cpqCartAction: string;
  cartToWishlist: string;
  retailConnectRetailers: string;
  search: string;
  searchUrlEan: string;
  initializeDatatrans: string;
  shipmentTrackingBearerToken: string;
  pollerDefaultConfig: string;
}

export interface Links {
  loginLogoutLink: CmsLink;
  storefinderLink: CmsLink;
  conditionsLink: CmsLink;
  dataProtectionLink: CmsLink;
  cartLink: CmsLink;
  orderHistoryLink: CmsLink;
  collectiveOrdersLink: CmsLink;
  myAccountLink: CmsLink;
  compareProductsLink: CmsLink;
  contactPageLink: CmsLink;
  contactPersonOverviewLink: CmsLink;
}

interface LogoDefinition {
  src: string;
  width: string;
  height: string;
}

export type Flags = {
  showCadData: boolean;
  adminUserCreationEnabled: boolean;
  retailConnectEnabled: boolean;
  showPickupStock: boolean;
  showLanguageSelector: boolean;
  userLikeEnabled: boolean;
  showCustomerSpecificPrices: boolean;
  userLikeOnlyForLoggedInUsers: boolean;
  isOciUser: boolean;
  apiTestExecuted: boolean;
  hideCheckout: boolean;
  showPositionTexts: boolean;
  isPunchoutUser: boolean;
  storeSelectionEnabled: boolean;
  isComnormUser: boolean;
  showCommissions: boolean;
  showWishlists: boolean;
  showComments: boolean;
  showCollectiveOrders: boolean;
  googleTagManagerEnabled: boolean;
  addToCartEnabled: boolean;
  parcelperformEnabled: boolean;
  showFavourites: boolean;
  showMyAccount: boolean;
}

interface ApiKeys {
  googleApiKey: string;
  googleRecaptchaSiteKey: string;
  scanditApiKey: string;
  userlikeWidgetKey: string;
  askNicelyEmailHash?: string;
}

interface ThirdPartyUrls {
  dataTransScriptUrl: string;
  ociLogoutUrl: string;
  punchoutSend: string;
  punchoutCancel: string;
}

export interface SessionState {

  /**
   * Stores the theme id.
   */
  theme: string;

  /**
   * Stores global loading status.
   */
  isGlobalLoadingVisible: boolean;

  globalLoadingMessage?: string;

  /**
   * Stores the global backdrop state.
   */
  isGlobalBackdropVisible: boolean;

  /**
   * Stores the context path for the application.
   */
  contextPath: string;

  /**
   * Stores the available api urls.
   */
  apiUrls: ApiUrls;

  /**
   * Holds the list of third party URLs.
   */
  thirdPartyUrls: ThirdPartyUrls;

  /**
   * Holds the list of CMS links.
   */
  links: Links;

  /**
   * Holds the user information.
   */
  user: User;

  /**
   * Holds the feature flags.
   */
  flags: Flags;

  /**
   * Holds the dashboard.
   */
  dashboard: Dashboard;

  /**
   * Holds the contact person.
   */
  contactPerson: ContactPerson;

  /**
   * Holds the available language list.
   */
  languages: Language[];

  /**
   * Holds  the API keys.
   */
  apiKeys: ApiKeys;

  /**
   * The ID of the currently active page.
   */
  currentPageUID: string;

  /**
   * Holds the users commissions.
   */
  commissions: Commission[];

  /**
   * Holds the currently selected commission.
   */
  globalCommission: Commission | null;

  /**
   * Holds the currently rendered layout.
   */
  activeLayout: Layout | null;

  /**
   * Allows the user to only show recommended retail prices (used by Allchemet).
   *
   * NOTE: Should only be used through getter!
   */
  showOnlyRecommendedRetailPrice: false | RemovableRef<boolean>;

  disableHeaderShadow: boolean;

  /**
   * Holds the modal stack (first element in the Array is the modal on top).
   * This is needed to know for enabling only the outside click of the top-modal and prevent the modals behind from closing.
   */
  openModalStack: number[];

  /**
   * Holds the active session key which is needed in the Comnorm context.
   */
  sessionKey: string;

  runningRequests: {
    apiGetAddressAutocomplete: boolean;
    apiDeleteCommission: boolean;
    apiUpdateCommission: boolean;
    apiAddCommission: boolean;
    apiAcceptCadenasDataProtectionPolicy: boolean;
  };
}

/**
 * This data could not yet be integrated in the regular initial data
 * for the session store because it would have been quite complex on HYBRIS side.
 */
export interface NavigationBarInitialData {
  data: {
    navigationBarComponent: {
      currentPageUID: string;
    };
  };
}

export interface InitialData {
  contextPath: string;
  data: {
    api: ApiUrls;
    flags: Flags;
    apiKeys: ApiKeys;
    links: Links;
    user: User;
    languages: Language[];
    favoriteList: FavouriteList;
    contactPerson: ContactPerson;
    dashboard: Dashboard;
    thirdPartyUrls: ThirdPartyUrls;
    sessionKey: string;
  };
}

/**
 * Trys to restore the selected commission from the local storage.
 */
function restoreGlobalCommissionFromStorage(userCommissions: Commission[]): Commission | null {
  if (!IS_STORAGE_AVAILABLE) {
    return null;
  }

  try {
    const commissionFromStorage = window.localStorage.getItem(StorageKey.GlobalCommission);

    if (!commissionFromStorage) {
      return null;
    }

    const parsedCommissionFromStorage = JSON.parse(commissionFromStorage);

    return userCommissions
      .find(userCommission => userCommission.id === parsedCommissionFromStorage?.id) || null;
  } catch (e) {
    return null;
  }
}

const storeName = Store.Session;

export default defineStore(storeName, {
  state: () => {
    const initialData: InitialData = window.initialData?.[storeName] || {};
    const navigationBarInitialData: NavigationBarInitialData = window.initialData.navigationBar || {};
    const { favoriteList, contactPerson } = initialData.data;
    const { commissions } = initialData.data.user;
    const favouriteStore = useFavouriteStore();
    const state: SessionState = {
      theme: import.meta.env.VITE_THEME_ID,
      isGlobalLoadingVisible: false,
      globalLoadingMessage: undefined,
      isGlobalBackdropVisible: false,
      contextPath: initialData.contextPath,
      apiUrls: initialData.data.api,
      apiKeys: initialData.data.apiKeys,
      thirdPartyUrls: initialData.data.thirdPartyUrls,
      flags: initialData.data.flags,
      links: initialData.data.links,
      user: normalizeUser(initialData.data.user),
      languages: initialData.data.languages,
      currentPageUID: navigationBarInitialData?.data?.navigationBarComponent?.currentPageUID,
      commissions,
      disableHeaderShadow: false,
      globalCommission: restoreGlobalCommissionFromStorage(commissions),
      activeLayout: null,
      dashboard: initialData.data.dashboard,
      contactPerson: contactPerson && normalizeContactPerson(contactPerson),
      showOnlyRecommendedRetailPrice: import.meta.env.VITE_ENABLE_RECOMMENDED_RETAIL_PRICE === '1'
        && useStorage(StorageKey.ShowOnlyRecommendedRetailPrices, false),
      openModalStack: [],
      sessionKey: initialData.data.sessionKey,
      runningRequests: {
        apiGetAddressAutocomplete: false,
        apiDeleteCommission: false,
        apiUpdateCommission: false,
        apiAddCommission: false,
        apiAcceptCadenasDataProtectionPolicy: false,
      },
    };

    if (Array.isArray(favoriteList?.entries) && !favouriteStore.entries.length) {
      favouriteStore.entries = favoriteList?.entries;
    }

    return state;
  },
  getters: {
    /**
     * Returns if the user is currently logged in.
     */
    getIsLoggedIn(): boolean {
      const { uid } = this.user;

      return !!uid && uid !== 'anonymous';
    },

    /**
     * Evaluates if the retail price view is enabled, based on the current Theme.
     */
    getShowOnlyRecommendedRetailPrice(): boolean {
      return import.meta.env.VITE_ENABLE_RECOMMENDED_RETAIL_PRICE === '1' && this.showOnlyRecommendedRetailPrice;
    },

    /**
     * Returns the theme related logo.
     */
    getLogoAttributes(): LogoDefinition {
      switch (this.theme) {
        case 'theme-02':
          return {
            src: theme02Logo,
            width: '160',
            height: `${(25 / 200) * 160}`,
          };

        default:
          return {
            src: theme01Logo,
            width: '83',
            height: `${(150 / 290) * 83}`,
          };
      }
    },

    getCommissionByCommissionId: state => (commissionId: string): Commission | undefined => state.commissions
      .find(commission => commission.id === commissionId),

    /**
     * Returns if a given permission group is selected.
     */
    getIsPermissionGroupSelected: state => (uid: string): boolean => {
      const { permissionGroups } = state.user;

      if (!Array.isArray(permissionGroups)) {
        return false;
      }

      return !!permissionGroups.find(permissionGroup => permissionGroup.uid === uid)?.selected;
    },

    getCurrencyIsoCode(): string {
      return this.user.currency?.isocode || FALLBACK_CURRENCY_ISO_CODE;
    },

    getIsPunchoutOrOciUser(): boolean {
      return this.flags.isPunchoutUser || this.flags.isOciUser;
    },
  },
  actions: {
    showGlobalLoader(message?: string): void {
      this.globalLoadingMessage = message;
      this.isGlobalLoadingVisible = true;
    },

    hideGlobalLoader(): void {
      this.globalLoadingMessage = undefined;
      this.isGlobalLoadingVisible = false;
    },

    /**
     * Sets the global commission.
     * Stores the global commission in local storage if available.
     */
    setGlobalCommission(commission: Commission | null): void {
      this.globalCommission = commission;

      if (IS_STORAGE_AVAILABLE) {
        if (!commission) {
          window.localStorage.removeItem(StorageKey.GlobalCommission);
        } else {
          window.localStorage.setItem(StorageKey.GlobalCommission, JSON.stringify(commission));
        }
      }
    },

    /**
     * Sets the current theme id.
     */
    setTheme(id: string): void {
      this.theme = id;
    },

    handleCommissionResponse(response: AxiosResponse, apiIdentifier: string): AxiosPromise {
      const { commissions } = response.data?.data || {};

      if (Array.isArray(commissions)) {
        this.commissions = commissions;

        return Promise.resolve(response);
      }

      return Promise.reject(new Error(`API failure during "${storeName}/${apiIdentifier}" request.`));
    },

    /**
     * Fetches address autocomplete suggestions.
     */
    apiGetAddressAutocomplete(query: string, uniqueId = 'apiGetAddressAutocomplete'): AxiosPromise {
      const errorMessage = 'API failure during "session/apiGetAddressAutocomplete" request.';

      this.runningRequests.apiGetAddressAutocomplete = true;

      return this.$api.get(this.$api.getApiUrl('zipLocation'), {
        params: {
          q: query, // eslint-disable-line id-length
        },
      }, uniqueId).then((response) => {
        if (Array.isArray(response.data?.data?.result)) {
          return response;
        }

        return Promise.reject(new Error(errorMessage));
      }).catch((error) => {
        if (axios.isCancel(error)) {
          return Promise.reject(error);
        }

        return Promise.reject(new Error(errorMessage));
      }).finally(() => {
        this.runningRequests.apiGetAddressAutocomplete = false;
      });
    },

    /**
     * Adds a new commission.
     */
    apiAddCommission(payload: Partial<Commission>): AxiosPromise {
      this.runningRequests.apiAddCommission = true;

      return this.$api.post(this.$api.getApiUrl('commission'), payload)
        .then(response => this.handleCommissionResponse(response, 'apiAddCommission'))
        .finally(() => {
          this.runningRequests.apiAddCommission = false;
        });
    },

    /**
     * Updates a commission.
     */
    apiUpdateCommission(payload: Partial<Commission>): AxiosPromise {
      this.runningRequests.apiUpdateCommission = true;

      return this.$api.patch(this.$api.getApiUrl('commission'), payload)
        .then(response => this.handleCommissionResponse(response, 'apiUpdateCommission'))
        .finally(() => {
          this.runningRequests.apiUpdateCommission = false;
        });
    },

    /**
     * Deletes a commission.
     */
    apiDeleteCommission(payload: Pick<Commission, 'id'>): AxiosPromise {
      this.runningRequests.apiDeleteCommission = true;

      return this.$api.delete(this.$api.getApiUrl('commission'), {
        data: payload,
      })
        .then(response => this.handleCommissionResponse(response, 'apiDeleteCommission'))
        .finally(() => {
          this.runningRequests.apiUpdateCommission = false;
        });
    },

    apiAcceptCadenasDataProtectionPolicy(): AxiosPromise {
      this.runningRequests.apiAcceptCadenasDataProtectionPolicy = true;

      return this.$api.post(this.$api.getApiUrl('dataProtectionPolicy')).finally(() => {
        this.runningRequests.apiAcceptCadenasDataProtectionPolicy = false;
      });
    },

    apiUpdateFavoriteStore(location: PointOfService, favorite: boolean): AxiosPromise {
      const url = new URL(this.$api.getApiUrl('favoriteStore'), window.location.origin);

      return this.$api.post(url.toString(), {
        name: location.name,
        favorite,
      }).then((response) => {
        if (this.user.defaultPos) {
          this.user.defaultPos = favorite ? location : { favorite: false, type: HybrisType.PointOfService };
        }

        return response;
      });
    },
  },
});
