import { Plugin } from 'vue';
import { createMessenger, v1 } from '@userlike/messenger';
import { User } from '@/types/user';
import useSessionStore from '@/stores/session';

export interface Userlike {
  version: number;
  messenger: null | Promise<v1.Api>;
  mount(): void;
  createApi(): Promise<v1.Api>;
  setContactInfo(user: User): void;
  setCustomData(user: User): void;
}

/**
 * Adds the Userlike interface to the app.
 */
const plugin: Plugin = {
  /**
   * Install method of the Google Tag Manager plugin.
   */
  install(app) {
    app.config.globalProperties.$userlike = {
      /**
       * Holds the API version.
       */
      version: 1,

      /**
       * Holds the messenger api object.
       */
      messenger: null,

      /**
       * Initializes the messenger API.
       */
      mount(): void {
        if (useSessionStore().apiKeys.userlikeWidgetKey) {
          this.messenger = new Promise((resolve, reject) => {
            this.createApi().then((api) => {
              api.mount()
                .then(() => {
                  resolve(api);
                });
            })
              .catch(e => reject(e));
          });

          if (useSessionStore().getIsLoggedIn) {
            const { user } = useSessionStore();

            this.setContactInfo(user);
            this.setCustomData(user);
          }
        }
      },

      /**
       * Creates the API.
       */
       createApi(): Promise<v1.Api> {
        return new Promise((resolve) => {
          createMessenger({
            version: this.version,
            widgetKey: useSessionStore().apiKeys.userlikeWidgetKey,
          }).then((result) => {
            if (!result || result.kind === 'error') {
              throw new Error(result.error);
            }

            const { api } = result.value || {};

            if (api === null) {
              throw new Error('Messenger API reached end-of-life, please check documentation for upgrade.');
            }

            resolve(api as v1.Api);
          });
        });
      },

      /**
       * Sets the name and email of the current user to the messenger instance.
       */
      setContactInfo(user: User): void {
        const { name, email } = user;

        this.messenger?.then((api: v1.Api) => api.setContactInfo({
          name,
          email,
        }));
      },

      /**
       * Sets custom user data to the messenger API.
       */
      setCustomData(user: User): void {
        const {
          name,
          customerId,
          email,
          unit,
        } = user;

        this.messenger?.then((api: v1.Api) => api.setCustomData({
          customerId,
          name,
          email,
          companyName: unit?.name || '',
        }));
      },
    };
  },
};

export default plugin;
