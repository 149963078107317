<template>
  <li :class="b()">
    <e-picture v-bind="image"
               :sizes="sizes"
               loading="eager"
    />
  </li>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import ePicture from '@/elements/e-picture.vue';
  import { ImageSrcset } from '@/types/image';
  import { ImageSizes } from '@/types/sizes';

  // interface Setup {}
  // interface Data {}

  /**
   * Renders a lightbox slide.
   */
  export default defineComponent({
    name: 'c-slider-item-4',

    components: {
      ePicture,
    },

    props: {
      /**
       * Expects an ImageSrcset.
       */
      image: {
        type: Object as PropType<ImageSrcset>,
        required: true,
      },

      /**
       * Allows to set an alternative sizes definition.
       */
      sizes: {
        type: Object as PropType<ImageSizes>,
        default: () => ({
          xxs: 480,
          xs: 728,
          sm: 952,
          fallback: 307,
        }),
      },
    },
    // emits: {},

    // setup(): Setup {},
    // data(): Data {
    //   return {};
    // },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  .c-slider-item-4 {
    position: relative;
    display: flex;
    flex: 0 0 100%;
    justify-content: center;
  }
</style>
