<template>
  <div :class="b()"></div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { Product } from '@/types/product';

  // type Setup = {}

  // type Data = {}

  /**
   * Renders oxomi item videos.
   */
  export default defineComponent({
    name: 'c-oxomi-item-videos',

    // components: {},

    props: {
      /**
       * Expects a product object.
       */
      product: {
        type: Object as PropType<Product>,
        required: true,
      },
    },
    // emits: {},

    // setup(): Setup {},
    // data(): Data {},

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    mounted() {
      this.$oxomi().then(() => {
        const { brand, supplierCode } = this.product;

        window.oxomi.itemVideos({
          itemNumber: supplierCode,
          supplierNumber: brand?.supplierNumber,
          target: this.$el,
        });
      });
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  // @use '@/setup/scss/mixins';
  // @use '@/setup/scss/variables';

  .c-oxomi-item-videos {
    // ...
  }
</style>
