<template>
  <div :class="b()">
    <e-checkbox v-model="checked"
                :name="facet.name"
                :disabled="disabled"
                value
                @update:model-value="onUpdate"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-html="facet.name"></span>
    </e-checkbox>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { Facet } from '@/types/facet';
  import eCheckbox from '@/elements/e-checkbox.vue';

  // interface Setup {}
  interface Data {
    checked: boolean;
  }

  /**
   * Renders a checkbox filter facet.
   */
  export default defineComponent({
    name: 'c-facet-checkbox',
    components: { eCheckbox },

    // components: {},

    props: {
      /**
       * Expects a facet object.
       */
      facet: {
        type: Object as PropType<Facet>,
        required: true,
      },

      /**
       * Allows passing disabled state.
       */
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    emits: {
      update: (payload: string) => !!payload,
    },

    // setup(): Setup {},
    data(): Data {
      return {
        checked: false,
      };
    },

    // computed: {},
    watch: {
      /**
       * Observes provided facet for changes and updates multi select value.
       */
      facet: {
        handler(facet: Facet) {
          this.checked = facet.values?.some(value => value.code === 'true' && !!value.selected);
        },
        deep: true,
        immediate: true,
      },
    },

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      /**
       * Handles update.
       */
      onUpdate(): void {
        const { values } = this.facet;
        const { query } = values.find(facetValue => facetValue.code === 'true') || {}; // The code for checkbox facet values provided by HYBRIS will either be 'true' or 'false' (as string)

        if (!query) {
          return;
        }

        this.$emit('update', query.apiUrl);
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  // @use '@/setup/scss/variables';

  .c-facet-checkbox {
    // ...
  }
</style>
