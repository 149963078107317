<template>
  <table :class="b({ enableRowLinks, hasRowLinks: !!rowLink?.href, htmlHeadings, })"
         :style="rootStyles"
         @contextmenu.capture="onContextMenu"
         @mousedown.capture="onMouseDown"
         @mouseup="onMouseUp($event)"
  >
    <!-- @slot Allows to display a customized header row. -->
    <slot
      :columns="columns"
      :sort-by="sortBy"
      :sort-ascending="sortAscending"
      name="header"
    >
      <tr v-if="isMobile && hasSortableColumns"
          :class="b('toggle-row')"
      >
        <th :colspan="colspan">
          <button ref="toggleButton"
                  :class="b('toggle')"
                  type="button"
                  @click.prevent="toggleSortingOptions"
          >
            <e-icon icon="i-sort" size="22" />
            {{ $t('e-table.toggleSortingOptions') }}
          </button>
        </th>
      </tr>
      <tr ref="headerRow" :class="b('header-row', { sorting: true })">
        <th v-if="hasDetailRows"
            :class="b('header-cell', { hidden: true, detailToggle: true })"
        >
          <span class="invisible">
            {{ $t('e-table.showDetailsHeader') }}
          </span>
        </th>
        <template v-if="showSortingOptions">
          <th v-if="selectable"
              :class="b('header-cell', { selectColumn: true })"
          >
            <e-checkbox v-model="toggleAllValue"
                        :disabled="disabled"
                        value="0"
                        name="total"
            >
              <span :class="{ invisible : !showSortingOptions || !isMobile }">
                {{ selectedInternal.length ? $t('e-table.deselectAll') : $t('e-table.selectAll') }}
              </span>
            </e-checkbox>
          </th>

          <th
            v-for="column in columns"
            :key="column.key"
            :class="b('header-cell', headerCellModifiers(column))"
          >
            <button
              v-if="column.sortable !== false"
              :class="b('sort', sortButtonModifiers(column))"
              :aria-label="$t('e-table.sort', { name: column.title })"
              type="button"
              @click="onClickSort(column)"
            >
              <span :class="[b('sort-label'), { invisible: !isHeaderLabelVisible(column) }]">
                <!-- eslint-disable vue/no-v-html -->
                <span v-if="htmlHeadings" v-html="column.title"></span>
                <template v-else>
                  <!-- Adding the support for functions was needed, to allow the use of translations in the header row -->
                  {{ typeof column.title === 'function' ? column.title() : column.title }}
                </template>
              </span>
              <e-icon icon="i-arrow--down"
                      width="16"
                      height="16"
                      inline
              />
            </button>
            <span v-else :class="[b('sort-label'), { invisible: isMobile || !isHeaderLabelVisible(column) }]">
              <!-- eslint-disable vue/no-v-html -->
              <span v-if="htmlHeadings" v-html="column.title"></span>
              <template v-else>
                <!-- Adding the support for functions was needed, to allow the use of translations in the header row -->
                {{ typeof column.title === 'function' ? column.title() : column.title }}
              </template>
            </span>
          </th>
        </template>
      </tr>
    </slot>
    <template v-for="(item, itemIndex) in itemsSorted" :key="item[itemIdentifier] || itemIndex">
      <tr :class="b('data-row', calculateRowModifiers(item))"
          :role="clickOnRowOpensDetail ? 'button' : undefined"
          @click="clickOnRowOpensDetail && toggleRowDetail(item)"
      >
        <td v-if="selectable"
            :class="b('data-cell')"
            role="cell"
        >
          <div :class="b('select-column')">
            <e-checkbox v-model="selectedInternal"
                        :value="item[itemIdentifier]"
                        :name="`e-table__selection--${uuid}`"
                        :disabled="disabled"
            >
              <span :class="{ invisible : !isMobile }">
                {{ $t('e-table.selectItem') }}
              </span>
            </e-checkbox>
          </div>
        </td>
        <td v-if="hasDetailRows"
            :class="b('data-cell', { detailToggle: true })"
            :data-label="$t('e-table.showDetailsHeader')"
            role="cell"
        >
          <label :class="b('detail-toggle-label', { disabled: clickOnRowOpensDetail })">
            <!-- Note: had to separate the input types, because TS struggled with prop checking if type was dynamic. -->
            <input v-if="onlyOneDetailRow"
                   v-model="expandedRowRadioValue"
                   :class="b('detail-toggle-input')"
                   :value="item[itemIdentifier]"
                   type="radio"
                   name="details"
                   @click="resetRadioToggleIfAlreadySelected(item)"
            >
            <input v-else
                   v-model="expandedRowsComputed"
                   :class="b('detail-toggle-input')"
                   :value="item[itemIdentifier]"
                   type="checkbox"
                   name="details"
            >
            <e-icon :class="b('detail-toggle-icon')"
                    :alt="$t('e-table.showDetailsHeader')"
                    icon="i-arrow--down"
            />
          </label>
        </td>
        <td
          v-for="(column, columnIndex) in columns"
          :key="columnIndex"
          :class="b('data-cell', cellModifiers(column))"
          :data-label="isHeaderLabelVisible(column) ? columnTitle(column) : ''"
          role="cell"
          @click="column.onClick || rowLink?.href ? onCellClick(item, column, $event) : null"
        >
          <a v-if="!column.onClick && typeof rowLink?.href === 'function'"
             :class="b('cell-link')"
             :href="rowLink.href(item) || '#'"
             :title="rowTitle(item)"
             tabindex="-1"
          ></a>

          <!-- eslint-disable vue/no-v-html -->
          <span v-if="isMobile && htmlHeadings" v-html="isHeaderLabelVisible(column) ? columnTitle(column) : ''"></span>

          <!-- @slot The default 'date' cell formatting for the project. Can be overwritten by a locale <template #date> -->
          <slot v-if="column.slotName === 'date'"
                :item="item"
                :column="column"
                name="date"
          >
            {{ $dayjs(item[column.key] as Date).format(DateFormat.DD_MM_YYYY) }}
          </slot>
          <!-- @slot Use this dynamic slot to add custom templates to the cells -->
          <slot
            v-else
            :name="column.slotName"
            :item="item"
            :column="column"
          >
            {{ item[column.key] }}
          </slot>
        </td>
      </tr>
      <tr v-if="hasDetailRows && isOpen(item[itemIdentifier])"
          :key="`detail--${itemIndex}`"
          :class="b('detail-row')"
      >
        <td :colspan="colspan" :class="b('detail-cell')">
          <!-- @slot Slot can be used to show detail information for a row. -->
          <slot name="detailRow"
                :item="item"
                :toggle="toggleRowDetail"
          ></slot>
        </td>
      </tr>
    </template>
    <!-- @slot Allows to display a customized 'no results' row. -->
    <slot v-if="!itemsSorted.length" name="noResults" :columns="columns">
      <tr>
        <td
          :colspan="columns.length"
          :class="b('no-results')"
        >
          {{ $t('global.noResultsText') }}
        </td>
      </tr>
    </slot>
    <tfoot v-else-if="$slots.footer">
      <tr :class="b('footer-row')">
        <td
          :colspan="colspan"
          :class="b('footer-cell')"
        >
          <slot name="footer" :columns="columns"></slot>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script lang="ts">

  import {
    defineComponent,
    PropType,
    Ref,
    ref,
  } from 'vue';
  import { DateFormat } from '@/plugins/dayjs';
  import eIcon from '@/elements/e-icon.vue';
  import eCheckbox from '@/elements/e-checkbox.vue';
  import useUuid, { Uuid } from '@/compositions/uuid';
  import { Modifiers } from '@/plugins/vue-bem-cn/src/globals';

  export type ItemId = number | string; // Note: Only use 'string'. If possible get rid of type too.

  export type TableItem = { // Note: this should be a generic, but I'm not sure if this is even possible...
    disabled?: boolean;
    [key: string]: ItemId | unknown;
  }

  export type TableColumn = {
    title: string | (() => string);
    key: string;
    align?: 'left' | 'center' | 'right';
    slotName?: string;
    sortable?: boolean;
    nowrap?: boolean;
    titleHidden?: boolean | (() => boolean);
    printOnly?: boolean;
    onClick?(item: TableItem, column: TableColumn, toggleDetailRow: () => void, event?: Event): void;
    sort?(a: unknown, b: unknown): number;
  }

  export type ToggleEvent = {
    open: boolean;
    item: TableItem;
  }

  type RowLink = {
    href?(item: TableItem, column?: TableColumn, event?: Event): string;
    title?: string | ((item?: TableItem) => string);
  }

  type RowModifiers = {
    disabled: boolean;
    detailVisible: boolean;
    detailToggle: boolean;
  }

  type RootStyles = {
    [key: string]: string;
  }

  interface Setup extends Uuid {
    DateFormat: typeof DateFormat;
    toggleButton: Ref<HTMLButtonElement>;
    headerRow: Ref<HTMLTableRowElement>;
  }

  interface Data {

    /**
     * Holds a flag if sorting options are visible
     */
    showSortingOptions: boolean;

    /**
     * The currently selected 'column' to be sorted by.
     */
    sortBy: TableColumn | null;

    /**
     * Holds to sort direction in case a 'sortBy' is active.
     */
    sortAscending: boolean;

    /**
     * Holds a flag if row links should be enabled.
     */
    enableRowLinks: boolean;

    /**
     * Holds a flag if there is currently a text selection inside the component.
     */
    hasSelection: boolean;

    /**
     * Holds the height of the sorting list toggle button.
     */
    toggleButtonHeight: number;

    /**
     * Holds the height of the header row.
     */
    headerRowHeight: number;

    /**
     * Row items that should be displayed in expanded state.
     */
    expandedRows: ItemId[];
  }

  /**
   * This component renders a table based on a given column setup and an array of row items.
   * By default, the cell values are used as text.
   *
   * Custom cell templates can be defined by defining a 'slotName' for a column and give a custom template
   * by using an identical named slot binding ('v-slot:\<slotName\>') to the component.
   *
   * **WARNING: Potentially uses 'v-html' for the headings. Make sure, that the source for this data is trustworthy.**
   */
  export default defineComponent({
    name: 'e-table',

    components: {
      eIcon,
      eCheckbox,
    },

    props: {
      /**
       * Array of data objects to render in table.
       */
      items: {
        type: Array as PropType<TableItem[]>,
        required: true,
      },

      /**
       * Allows to set an Array of selected items.
       */
      selected: {
        type: Array as PropType<TableItem[]>,
        default: () => [],
      },

      /**
       * Array of column definition objects.
       */
      columns: {
        type: Array as PropType<TableColumn[]>,
        required: true,
      },

      /**
       * Accepts a method to generate a link for each row (except for columns with 'onClick' callback).
       */
      rowLink: {
        type: Object as PropType<RowLink>,
        default: null,
      },

      /**
       * Adds a checkbox to each row to allow selections.
       */
      selectable: {
        type: Boolean,
        default: false,
      },

      /**
       * Disables the tables interaction features.
       */
      disabled: {
        type: Boolean,
        default: false,
      },

      /**
       * Determines if the table should show an additional column to toggle row details.
       */
      hasDetailRows: {
        type: Boolean,
        default: false,
      },

      /**
       * Allows to change the identifier property for a row item by which it will be identified internally.
       */
      itemIdentifier: {
        type: String as PropType<ItemId>,
        default: 'id',
      },

      /**
       * Allow only 1 visible detail row at a time.
       */
      onlyOneDetailRow: {
        type: Boolean,
        default: false,
      },

      /**
       * When detail can be opened by clicking anyhwere on a row.
       */
      openDetailOnRowClick: {
        type: Boolean,
        default: false,
      },

      /**
       * Enables HTML for headings.
       * This was only implement for the product variant table because
       * attribute titles potentially contain HTML.
       */
      htmlHeadings: {
        type: Boolean,
        default: false,
      },

      /**
       * Allows defining rows that are initially expanded.
       */
      externalExpandedRows: {
        type: Array as PropType<ItemId[]>,
        default: undefined,
      },
    },
    emits: {
      'update:selected': (payload: unknown): boolean => Array.isArray(payload),
      'detailToggled': (payload: ToggleEvent) => !!payload.item,
    },

    setup(): Setup {
      const toggleButton = ref();
      const headerRow = ref();

      return {
        DateFormat,
        ...useUuid(),
        toggleButton,
        headerRow,
      };
    },
    data(): Data {
      return {
        showSortingOptions: true,
        sortBy: null,
        sortAscending: true,
        enableRowLinks: false,
        hasSelection: false,
        toggleButtonHeight: 0,
        headerRowHeight: 0,
        expandedRows: this.externalExpandedRows ?? [],
      };
    },

    computed: {
      /**
       * Model Value for toggle all checkbox.
       */
      toggleAllValue: {
        get(): boolean {
          return !!this.selectedInternal.length;
        },
        set(): void {
          if (this.selectedInternal.length) {
            this.selectedInternal = [];
          } else {
            this.selectedInternal = this.items.map(item => item[this.itemIdentifier] as ItemId);
          }
        },
      },

      /**
       * Checks if current viewport is mobile (<= sm).
       */
      isMobile(): boolean {
        return !this.$viewport.isSm;
      },

      /**
       * Manages changes for the 'select' prop.
       */
      selectedInternal: {
        get(): ItemId[] {
          return this.selected.map(item => item[this.itemIdentifier] as ItemId);
        },
        set(itemIds: ItemId[]): void {
          this.$emit('update:selected', this.items.filter(item => itemIds.includes(item[this.itemIdentifier] as ItemId)));
        },
      },

      expandedRowRadioValue: {
        get() {
          return this.expandedRowsComputed[0];
        },
        set(value: ItemId) {
          if (value && value !== this.expandedRowRadioValue) {
            this.expandedRowsComputed = [value];
          } else {
            this.expandedRowsComputed = [];
          }
        },
      },

      /**
       * Handles changes to the 'expandedRows' prop.
       */
      expandedRowsComputed: {
        get(): ItemId[] {
          return this.expandedRows;
        },
        set(value: ItemId[]): void {
          this.expandedRows = value;
        },
      },

      /**
       * Returns a sorted copy of the table-items.
       */
      itemsSortedBy(): TableItem[] {
        const { sortBy } = this;
        const items = this.items.slice();

        if (sortBy) {
          const sort = typeof sortBy?.sort === 'function'
            ? sortBy.sort
            : this.sortByFieldConstructor(sortBy.key);

          items.sort(sort);
        }

        return items;
      },

      /**
       * Reverts the sort direction if required.
       */
      itemsSorted(): TableItem[] {
        if (!this.sortAscending) {
          return this.itemsSortedBy.slice().reverse();
        }

        return this.itemsSortedBy;
      },

      /**
       * Counts the amount for columns that the table has.
       */
      colspan(): number {
        let count = this.columns.length;

        if (this.selectable) {
          count += 1;
        }

        if (this.hasDetailRows) {
          count += 1;
        }

        return count;
      },

      clickOnRowOpensDetail(): boolean {
        return !this.isMobile && this.hasDetailRows && this.openDetailOnRowClick;
      },

      hasSortableColumns(): boolean {
        return this.columns.some(column => !!column.sortable);
      },

      rootStyles(): RootStyles {
        return {
          '--e-table--toggle-row-height': `${this.toggleButtonHeight}px`,
          '--e-table--header-row-height': `${this.headerRowHeight}px`,
        };
      },
    },
    watch: {
      /**
       * Observes the current viewport and sets flag to show table sorting as a collapsible menu.
       */
      isMobile: {
        immediate: true,
        handler(value: boolean): void {
          this.showSortingOptions = !value;

          if (value) {
            window.addEventListener('resizeend', this.updateHeightDefinitions);
          } else {
            window.removeEventListener('resizeend', this.updateHeightDefinitions);
          }
        },
      },

      expandedRowsComputed(expandedItemIds: ItemId[], oldExpandedItemIds: ItemId[]): void {
        let toggledItemId: ItemId | undefined;

        if (this.onlyOneDetailRow && expandedItemIds.length) {
          [toggledItemId] = expandedItemIds;
        } else if (expandedItemIds.length > oldExpandedItemIds.length) {
          toggledItemId = expandedItemIds.find(itemId => !oldExpandedItemIds.includes(itemId));
        }

        const toggledItem = this.items.find(item => item[this.itemIdentifier] === toggledItemId);

        if (toggledItem) {
          this.$emit('detailToggled', {
            open: true,
            item: toggledItem,
          });
        }
      },

      externalExpandedRows(expandedRows: ItemId[]): void {
        this.expandedRows = expandedRows;
      },
    },

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    mounted() {
      this.updateHeightDefinitions();

      const initialExpandedItems = this.items.filter(item => !!item.selected);

      if (initialExpandedItems.length) {
        this.expandedRows = initialExpandedItems.map(item => item.id as ItemId);
      }
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    beforeUnmount() {
      window.removeEventListener('resizeend', this.updateHeightDefinitions);
    },
    // unmounted() {},

    methods: {
      /**
       * Returns the title of the actual table column.
       */
      columnTitle(column: TableColumn): string | null {
        switch (typeof column?.title) {
          case 'string':
            return column.title;

          case 'function':
            return column.title();

          default:
            return null;
        }
      },

      /**
       * Toggle the visibility for the sorting options.
       */
      toggleSortingOptions(): void {
        if (this.isMobile) {
          this.updateHeightDefinitions();
        }

        this.showSortingOptions = !this.showSortingOptions;
      },

      /**
       * Set the height of the sorting list toggle button.
       */
      updateHeightDefinitions(): void {
        this.toggleButtonHeight = this.toggleButton?.clientHeight || 0;
        this.headerRowHeight = this.headerRow?.clientHeight || 0;
      },

      /**
       * Returns a title for the row link, based on the type of the definition.
       */
      rowTitle(item: TableItem): string | undefined {
        const { rowLink } = this;

        switch (typeof rowLink?.title) {
          case 'string':
            return rowLink.title;

          case 'function':
            return rowLink.title(item) || undefined;

          default:
            return undefined;
        }
      },

      /**
       * Checks if the given column should display the header label.
       */
      isHeaderLabelVisible(column: TableColumn): boolean {
        // Adding the support for functions was needed, to change visibility state dynamically (improved a11y).
        return !!(typeof column.titleHidden === 'function' ? column.titleHidden() : column.titleHidden !== true);
      },

      /**
       * Checks if the table is sorted by a given column.
       *
       * Since Vue3 leverages proxies for data properties for reactivity, we can't compare the objects directly.
       */
      isSortedBy(column: TableColumn): boolean {
        return this.sortBy?.key === column.key;
      },

      /**
       * Will set the sort-parameters.
       */
      onClickSort(column: TableColumn): void {
        if (this.isSortedBy(column)) {
          const asc = this.sortAscending;

          if (!asc) {
            this.sortBy = null;
          }

          this.sortAscending = !asc;
        } else {
          this.sortBy = column;
          this.sortAscending = true;
        }
      },

      /**
       * Calculates a sort button modifier object.
       */
      sortButtonModifiers(column: TableColumn): Modifiers {
        const active = this.isSortedBy(column);

        return {
          active,
          desc: active && !this.sortAscending,
        };
      },

      /**
       * Returns BEM modifiers for header cells.
       */
      headerCellModifiers(column: TableColumn): Modifiers {
        return {
          align: column.align || 'left',
          col: column.key,
          hidden: (this.isMobile && column.sortable === false) || !this.isHeaderLabelVisible(column),
          sortable: column.sortable !== false,
          printOnly: !!column.printOnly,
        };
      },

      /**
       * Returns BEM modifiers for cells.
       */
      cellModifiers(column: TableColumn): Modifiers {
        return {
          align: column.align || 'left',
          hasEvent: !!column.onClick,
          col: column.key,
          sortable: column.sortable !== false,
          nowrap: column.nowrap,
          printOnly: !!column.printOnly,
        };
      },

      /**
       * Returns a sort function which will sort the elements of an Array by the given field.
       */
      sortByFieldConstructor(field: string): (a: TableItem, b: TableItem) => number {
        return (a, b) => {
          const aValue = a[field as keyof TableItem];
          const bValue = b[field as keyof TableItem];

          switch (true) {
            case typeof aValue === 'string':
              return (aValue as string).localeCompare(bValue as string, undefined, { numeric: true }); // eslint-disable-line no-undefined, no-extra-parens

            case typeof aValue === 'number':
              return aValue as number > (bValue as number) ? 1 : -1; // eslint-disable-line no-extra-parens

            case typeof aValue === 'boolean':
              return !aValue ? 1 : -1;

            case aValue instanceof Date:
              return this.$dayjs(aValue as Date).isAfter(bValue as Date) ? 1 : -1;

            default:
              return 0;
          }
        };
      },

      /**
       * Enables the row link for a few ms to allow link specific context menus.
       */
      enableRowLink(): void {
        if (this.rowLink?.href && !this.hasSelection) { // It was not possible to test for rowHref when binding the event in the template.
          this.enableRowLinks = true;

          setTimeout(() => {
            this.enableRowLinks = false;
          }, 100);
        }
      },

      /**
       * Callback for the tables mousedown event.
       */
      onMouseDown(): void { // All browsers
        this.hasSelection = !!window.getSelection()?.toString();
      },

      /**
       * Callback for the tables contextmenu event.
       */
      onContextMenu(): void { // Chromium, webkit: mousedown, contextmenu
        this.enableRowLink();

        setTimeout(() => {
          window?.getSelection()?.removeAllRanges(); // Safari marks words on right click by default, which would cause trouble on next context event.
        });
      },

      /**
       * Callback for the tables mouseup event.
       */
      onMouseUp(event: MouseEvent): void { // FF: mousedown, mouseup, contextmenu
        if (!this.enableRowLinks) {
          // Firefox marks a cells content when holding ctrl/meta while clicking it.
          this.hasSelection = !(event.ctrlKey || event.metaKey) && !!window.getSelection()?.toString();

          this.enableRowLink();
        }
      },

      /**
       * Callback for clicks within a row.
       */
      onCellClick(item: TableItem, column: TableColumn, event: MouseEvent): void {
        if (this.hasSelection) { // Cancel cell action if a text selection is active.
          return;
        }

        if (typeof column.onClick === 'function') {
          column.onClick(item, column, () => this.toggleRowDetail(item), event);
        } else {
          const url = this.rowLink?.href?.(item, column);

          if (!url) {
            return;
          }

          if (event.ctrlKey || event.metaKey) {
            window.open(url, '_blank');
          } else if (typeof url === 'string') {
            window.location.href = url;
          }
        }
      },

      /**
       * Click callback for the toggle cell (increases click area on mobile).
       */
      toggleRowDetail(item: TableItem): void {
        const id = item[this.itemIdentifier] as ItemId;

        if (!id || !this.hasDetailRows) {
          return;
        }

        const expandedRows = this.expandedRowsComputed.slice();

        if (expandedRows.includes(id)) {
          this.expandedRowsComputed = expandedRows.filter(itemId => itemId !== id);
        } else if (this.onlyOneDetailRow) {
          this.expandedRowsComputed = [id];
        } else {
          expandedRows.push(id);

          this.expandedRowsComputed = expandedRows;
        }
      },

      calculateRowModifiers(item: TableItem): RowModifiers {
        const itemId = item[this.itemIdentifier] as ItemId;

        return {
          disabled: !!item.disabled,
          detailVisible: !!(itemId && this.expandedRowsComputed.includes(itemId)),
          detailToggle: this.clickOnRowOpensDetail,
        };
      },

      isOpen(itemId: unknown): boolean {
        return (typeof itemId === 'string' || typeof itemId === 'number') && this.expandedRowsComputed.includes(itemId);
      },

      resetRadioToggleIfAlreadySelected(item: TableItem) {
        const value = item[this.itemIdentifier];

        if (value && value === this.expandedRowsComputed[0]) {
          this.expandedRowsComputed = [];
        }
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '../setup/scss/variables';
  @use '../setup/scss/mixins';

  .e-table {
    $this: &;

    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-collapse: separate;

    @include mixins.media(sm) {
      display: table;
    }

    &__toggle-row {
      @include mixins.z-index(front);

      position: sticky;
      top: var(--header-height, 0);
      background-color: variables.$color-grayscale--1000;
    }

    &__header-cell,
    &__data-cell,
    &__footer-cell {
      vertical-align: middle;
    }

    &__toggle {
      @include mixins.font(variables.$font-size--18, variables.$line-height--20, variables.$font-weight--bold);

      position: relative;
      display: flex;
      align-items: center;
      overflow: hidden;
      border-bottom: 0;
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      column-gap: variables.$spacing--5;
      padding-block: variables.$spacing--10;
    }

    &__toggle-icon {
      position: absolute;
      top: 50%;
      left: variables.$spacing--5;
      transform: translateY(-50%);
      transition: transform variables.$transition-duration--200 ease;

      &--open {
        transform: translateY(-50%) rotate(180deg);
      }
    }

    &__header-row {
      @include mixins.z-index(front);

      position: sticky;
      top: calc(var(--header-height, 0px) + var(--e-table--toggle-row-height, 0px));
      display: flex;
      flex-direction: column;
      background-color: variables.$color-grayscale--1000;

      @include mixins.media(sm) {
        top: calc(var(--header-height, 0px));
        display: table-row;
      }

      @include mixins.media($media: print) {
        position: static !important; // stylelint-disable-line declaration-no-important
      }
    }

    &__header-row--sorting {
      @include mixins.media(sm) {
        margin-bottom: 0;
      }
    }

    &__header-cell {
      @include mixins.font(variables.$font-size--18, variables.$line-height--20, variables.$font-weight--bold);

      padding-bottom: variables.$spacing--10;
      border-bottom: 1px solid variables.$color-grayscale--0;
      color: variables.$color-grayscale--0;
      vertical-align: bottom;

      @include mixins.media(sm) {
        padding: variables.$spacing--10 variables.$spacing--5;
        border-width: 2px;
      }

      @include mixins.media($media: print) {
        font-size: variables.$font-size--16;
      }

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }

      &--select-column {
        width: auto;
        vertical-align: middle;

        @include mixins.media(sm) {
          width: 1px; // Forces minimal width for checkbox column.
        }
      }

      &--align-center {
        text-align: center;
      }

      &--align-right {
        text-align: right;
      }

      &--hidden {
        padding: 0;
      }

      &--detail-toggle {
        display: none;
        width: 2.5em;
        margin-bottom: variables.$spacing--5;
        border-width: 2px;

        @include mixins.media(sm) {
          display: table-cell;
        }
      }

      &--sortable {
        @include mixins.media($down: xs) {
          padding-top: variables.$spacing--5;
        }
      }

      &--print-only {
        display: none;

        @include mixins.media($media: print) {
          display: table-cell;
        }
      }
    }

    &__data-row {
      display: flex;
      flex-direction: column;
      padding-top: variables.$spacing--30;
      padding-bottom: variables.$spacing--5;

      @include mixins.media(sm) {
        display: table-row;
        padding-block: variables.$spacing--5;
      }

      @include mixins.media($media: print) {
        break-inside: avoid;
      }

      &--disabled {
        position: relative;
        pointer-events: none;

        #{$this}__data-cell {
          opacity: 0.5;
        }
      }

      &--detail-visible #{$this}__data-cell {
        @include mixins.media(sm) {
          border-bottom: 1px solid variables.$color-grayscale--400;
        }
      }

      &--detail-toggle {
        cursor: pointer;
      }
    }

    &__sort {
      display: inline-flex;
      align-items: center;
      margin-right: variables.$spacing--5;
      cursor: pointer;

      @include mixins.media($down: xs) {
        justify-content: space-between;
        width: 100%;
      }

      .e-icon {
        margin-left: variables.$spacing--5;
        opacity: 0.3;
        transform: rotate(180deg);
      }

      &--desc {
        .e-icon {
          transform: none;
        }
      }

      &--active {
        .e-icon {
          opacity: 1;
        }
      }
    }

    &__sort-label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__data-cell {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding-block: variables.$spacing--5;
      border-bottom: 1px solid variables.$color-grayscale--400;

      // This is a hacky solution for a FF issue where the background color overlaps the border.
      // See: https://bugzilla.mozilla.org/show_bug.cgi?id=688556
      // https://sonepar-suisse.atlassian.net/browse/WF-5573
      background-clip: padding-box;
      text-align: right;

      @include mixins.media(sm) {
        display: table-cell;
        padding: variables.$spacing--10 variables.$spacing--5;
        border-bottom-color: variables.$color-grayscale--0;
        text-align: inherit;
      }

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }

      &::before {
        content: attr(data-label);
        margin-right: auto;
        padding-right: variables.$spacing--10;
        font-weight: variables.$font-weight--regular;
        text-align: left;

        @include mixins.media(sm) {
          display: none;
        }
      }

      &--align-center {
        text-align: center;
      }

      &--align-right {
        text-align: right;
      }

      &--has-event {
        cursor: pointer;
      }

      &--nowrap {
        white-space: nowrap;
      }

      &--detail-toggle {
        justify-content: center;
        order: 1;
        text-align: right;
        vertical-align: middle;
        padding-inline: 0 variables.$spacing--5;

        @include mixins.media($down: xs) {
          border-bottom: none;
        }

        &::before {
          display: none;
        }
      }

      &--col-code {
        @include mixins.media($down: xs) {
          border-bottom-width: 2px;
          border-bottom-color: variables.$color-grayscale--0;
          font-weight: variables.$font-weight--bold;
        }

        &::before {
          font-weight: variables.$font-weight--bold;
        }
      }

      &--print-only {
        display: none;

        @include mixins.media($media: print) {
          display: table-cell;
        }
      }
    }

    &__detail-cell {
      border-bottom: 2px solid variables.$color-grayscale--0;
    }

    &__select-column {
      width: 100%;
      margin-left: -(variables.$spacing--10);
      text-align: left;

      @include mixins.media(sm) {
        margin-left: 0;
      }
    }

    &__footer-row {
      color: variables.$color-grayscale--400;
    }

    &__footer-cell {
      padding: variables.$spacing--15 variables.$spacing--2;
      border-bottom: 3px solid variables.$color-grayscale--400;
    }

    &__cell-link {
      position: absolute;
      inset: 0;
      display: block;
      pointer-events: none;
    }

    &__no-results {
      padding-top: variables.$spacing--15;
      padding-bottom: variables.$spacing--15;
    }

    &--has-row-links {
      #{$this}__data-row {
        cursor: pointer;
      }
    }

    &--enable-row-links {
      #{$this}__cell-link {
        pointer-events: auto;
      }
    }

    &__detail-toggle-label {
      display: flex;
      justify-content: flex-start;
      margin: 0;
      cursor: pointer;

      &--disabled {
        pointer-events: none;
      }
    }

    &__detail-toggle-input {
      position: absolute;
      visibility: hidden;
      -webkit-appearance: none;
      pointer-events: none;

      &:checked ~ #{$this}__detail-toggle-icon {
        transform: rotate(180deg);
      }
    }

    &__detail-toggle-icon {
      width: 1em;
      height: 1em;
      transition: transform variables.$transition-duration--200;
    }

    &__detail-row {
      @include mixins.media($down: xs) {
        display: grid;
      }

      @include mixins.media($media: print) {
        page-break-before: avoid;
        page-break-inside: avoid;
      }
    }

    &--html-headings &__data-cell::before {
      display: none;
    }
  }
</style>
