import i18n from '@/setup/i18n';
import useSessionStore from '@/stores/session';

/**
 * Formats the price according to project standards.
 */
export default function formatPrice(
  priceValue: number,
  prefixCurrency = false,
  currency: string | undefined = undefined
): string {
  const formattedParts = new Intl.NumberFormat(i18n.global.locale, {
    style: 'currency',
    currency: currency ?? useSessionStore().getCurrencyIsoCode,
    currencyDisplay: 'code',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).formatToParts(priceValue);

  return formattedParts.map(({ type, value }) => {
    switch (type) {
      case 'group':
        return "'";

      case 'currency':
        return prefixCurrency ? value : '';

      case 'literal':
        return prefixCurrency ? ' ' : '';

      default:
        return value;
    }
  }).reduce((previousValue, currentValue) => [previousValue, currentValue].join(''));
}
