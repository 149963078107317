<template>
  <div :class="b({ colSm: width })"></div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { OxomiPortal } from '@/types/oxomi-portal';
  import { ColumnWidth } from '@/components/c-row-container.vue';

  // type Setup = {};

  // type Data = {}

  export type OxomiPortalPageProps = {
    portal: OxomiPortal;
    page?: string;
    width?: ColumnWidth;
  }

  /**
   * Renders an Oxomi Portal Page.
   */
  export default defineComponent({
    name: 'c-oxomi-portal-page',

    // components: {},

    props: {
      /**
       * Expects an oxomi portal.
       */
      portal: {
        type: Object as PropType<OxomiPortal>,
        required: true,
      },

      /**
       * Allows a page to be passed.
       */
      page: {
        type: String,
        default: undefined,
      },

      /**
       * Allows to set the column width.
       */
      width: {
        type: Number as PropType<ColumnWidth>,
        default: 12,
      },
    },
    // emits: {},

    // setup(): Setup {},
    // data(): Data {},

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    mounted() {
      this.$oxomi(this.portal).then(() => {
        window.oxomi.portalPage({
          target: this.$el,
          page: this.page,
        });
      });
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-oxomi-portal-page {
    &--col-sm-4 {
      @include mixins.media(sm) {
        grid-column: span 4;
      }
    }

    &--col-sm-6 {
      @include mixins.media(sm) {
        grid-column: span 6;
      }
    }

    &--col-sm-12 {
      @include mixins.media(sm) {
        grid-column: span 12;
      }
    }

    .oxomi-element {
      margin-bottom: variables.$spacing--30;
    }

    // stylelint-disable-next-line selector-class-pattern
    .oxomi-h2 {
      @include mixins.heading-h3();

      color: variables.$color-grayscale--0;
      text-transform: none;
    }

    .oxomi-content-container,
    .oxomi-group > .oxomi-group-content {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: variables.$spacing--30;

      @include mixins.media(sm) {
        grid-template-columns: repeat(3, 1fr);
      }

      @include mixins.media(lg) {
        grid-template-columns: repeat(6, 1fr);
      }

      // stylelint-disable selector-max-id
      .oxomi-catalog-container {
        height: auto !important; // stylelint-disable-line declaration-no-important
        margin: 0;
        padding: 0;
      }

      .oxomi-catalog {
        display: flex;
        width: auto;
        height: auto;
        margin: 0;
      }

      .oxomi-border {
        padding: 0;
        border: 0;
      }

      .oxomi-well {
        background: none;
      }

      .oxomi-catalog-details {
        @include mixins.font(variables.$font-size--16, variables.$line-height--18);

        width: auto !important; // stylelint-disable-line declaration-no-important
      }
    }

    .oxomi-group {
      grid-column: 1 / -1;
    }

    .oxomi-content-container {
      display: grid;
      grid-row-gap: variables.$spacing--30;
    }

    #oxomi-search-bar {
      margin-bottom: variables.$spacing--15;

      .oxomi-form-control {
        border-color: variables.$color-grayscale--0;
        border-radius: 0;
        box-shadow: none;
      }

      .oxomi-form-control,
      .ms-ctn .ms-trigger,
      .oxomi-home {
        border-color: variables.$color-grayscale--0;
      }
    }

    #oxomi-search-filters {
      .oxomi-nav-header {
        @include mixins.heading-h4();

        color: variables.$color-grayscale--0;
      }

      .oxomi-nav-list {
        margin-bottom: variables.$spacing--15;
        padding: 0;

        .oxomi-link {
          @include mixins.link--secondary();

          display: inline-flex;
          align-items: center;
          margin: 0;
          padding: 0;
          color: variables.$color-grayscale--0;
          column-gap: variables.$spacing--10;

          &:hover,
          &:focus {
            background: none;
          }
        }
      }

      .oxomi-well {
        border: 0;
        background: none;
      }
    }

    #oxomi-search-output {
      .oxomi-brand.oxomi-border {
        border-color: variables.$color-grayscale--0;
        border-radius: 0;
      }
    }
  }
</style>
