<template>
  <div :class="b()">
    <c-row-container v-if="mappedHybrisComponents.length"
                     :components="mappedHybrisComponents"
                     :margin-bottom="marginBottom"
                     :slider-on-mobile="sliderOnMobile"
    />
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { CmsRowContainerDataProp } from '@/types/cms-row-container';
  import cRowContainer, { RowComponent } from '@/components/c-row-container.vue';
  import mapHybrisRowContainerComponents from '@/helpers/map-hybris-row-container-components';

  // interface Data {}
  export enum CmsColumnWidth {
    Col4 = 'COL4',
    Col6 = 'COL6',
    Col12 = 'COL12',
  }

  interface Setup {
    mappedHybrisComponents: RowComponent[];
    marginBottom?: number;
    sliderOnMobile: boolean;
  }

  /**
   *  This component is an abstract CMS component which renders it's children.
   *  You need to register every component than can be delivered from the backend.
   */
  export default defineComponent({
    name: 'c-cms-row-container',

    components: {
      cRowContainer,
    },

    props: {
      /**
       *  The data object which contains the components to render.
       */
      data: {
        type: Object as PropType<CmsRowContainerDataProp>,
        required: true,
      },
    },

    // emits: {},

    setup(props): Setup {
      const mappedHybrisComponents = mapHybrisRowContainerComponents(props.data.data.sfsRowContainerComponent);
      const { alternativeSpacingBottom, sliderOnMobile } = props.data.data.sfsRowContainerComponent; // eslint-disable-line vue/no-setup-props-destructure -- props don't need to be reactive.

      return {
        mappedHybrisComponents,
        marginBottom: alternativeSpacingBottom,
        sliderOnMobile: sliderOnMobile || false,
      };
    },
    // data(): Data {
    //   return {};
    // },

    // computed: {},
    // watch: {},
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';

  .c-cms-row-container {
    margin-bottom: variables.$spacing--40;
  }
</style>
