<template>
  <div :class="b({ isDropdownOpen: isDropdownOpen })">
    <button :class="b('toggle')"
            :aria-label="$t('c-language-switcher.buttonChooseLanguage')"
            type="button"
            @click="isDropdownOpen = !isDropdownOpen"
    >
      {{ selectedLanguage?.isocode }}
      <e-icon icon="i-arrow--down" size="16" />
    </button>
    <ul :class="b('list')">
      <li v-for="lang in activeLanguages"
          :key="lang.isocode"
          :class="b('item', { selected: lang.selected })"
      >
        <component :is="lang.selected ? 'span' : 'a'"
                   :href="!lang.selected && lang.url || null"
                   :title="lang.nativeName"
        >
          {{ lang.isocode }}
        </component>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { Language } from '@/types/language';
  import useSessionStore from '@/stores/session';
  import eIcon from '@/elements/e-icon.vue';

  interface Setup {
    sessionStore: ReturnType<typeof useSessionStore>;
  }

  interface Data {
    isDropdownOpen: boolean;
  }

  /**
   * Language switcher dropdown that allows users to change the language.
   */
  export default defineComponent({
    name: 'c-language-switcher',

    components: {
      eIcon,
    },

    // props: {},
    // emits: {},

    setup(): Setup {
      return {
        sessionStore: useSessionStore(),
      };
    },
    data(): Data {
      return {
        isDropdownOpen: false,
      };
    },

    computed: {
      /**
       * Returns the active languages.
       */
      activeLanguages(): Language[] {
        return this.sessionStore.languages.filter((language) => {
          if (!language.displayInLanguageSwitcher) {
            return false;
          }

          return this.$viewport.isMd || (!this.$viewport.isMd && this.sessionStore.getIsLoggedIn) || language.selected === false;
        });
      },

      /**
       * Returns the selected language.
       */
      selectedLanguage(): Language | null {
        return this.sessionStore.languages.find(language => language.selected === true) || null;
      },
    },
    // watch: {},
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';
  @use '@/setup/scss/mixins';

  .c-language-switcher {
    $this: &;

    position: relative;
    display: flex;
    align-items: center;
    text-transform: uppercase;

    &__list {
      @include mixins.z-index(front);

      display: flex;
      gap: variables.$spacing--0;
      flex-direction: column;
      align-items: baseline;
      padding: 0 variables.$spacing--5;

      @include mixins.media($down: sm) {
        position: absolute;
        top: 0;
        height: 0;
        overflow: hidden;
        background: variables.$color-grayscale--1000;
        transition: top variables.$transition-duration--200 ease;
      }

      @include mixins.media(md) {
        gap: variables.$spacing--15;
        flex-direction: row;
      }

      #{$this}__item {
        border-bottom: 2px solid transparent;
        text-transform: uppercase;

        &:hover,
        &:focus {
          border-bottom: 2px solid variables.$color-primary--1;
        }

        &--selected {
          color: variables.$color-primary--1;
          font-weight: variables.$font-weight--bold;
          pointer-events: none;
        }
      }
    }

    &__toggle {
      @include mixins.z-index(dropdown);

      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 55px;
      padding: 0 variables.$spacing--5;
      background: variables.$color-grayscale--1000;
      cursor: pointer;
      color: variables.$color-primary--1;
      font-weight: variables.$font-weight--bold;
      text-transform: uppercase;

      @include mixins.media(md) {
        display: none;
      }

      .e-icon {
        color: variables.$color-grayscale--0;
        transition: transform variables.$transition-duration--200 linear;
      }
    }

    &--is-dropdown-open {
      background: variables.$color-grayscale--1000;

      #{$this}__list {
        top: 100%;
        height: auto;
        overflow: auto;
      }

      .e-icon {
        transform: rotate(180deg);
      }
    }
  }
</style>
