import { AdditionalStockInfo, Icon } from '@/setup/globals';
import { StockState } from '@/helpers/map-stock-indicator';
import i18n from '@/setup/i18n';

type IconMap = {
  [key in AdditionalStockInfo]: string;
}

type LabelMap = {
  [key in AdditionalStockInfo]: string;
}

const labelMap: LabelMap = {
  [AdditionalStockInfo.AsLongAsInStock]: 'global.productStock.asLongAsInStockLabel',
  [AdditionalStockInfo.NoLongerDeliverable]: 'global.productStock.noLongerDeliverableLabel',
  [AdditionalStockInfo.NotYetAvailable]: 'global.productStock.notYetAvailableLabel',
};

const iconMap: IconMap = {
  [AdditionalStockInfo.AsLongAsInStock]: Icon.AsLongAsInStock,
  [AdditionalStockInfo.NoLongerDeliverable]: Icon.OutOfStock,
  [AdditionalStockInfo.NotYetAvailable]: Icon.OutOfStock,
};

export default function mapAdditionalStockInfoIndicator(
  additionalStockInfo?: AdditionalStockInfo
): StockState | null {
  if (!additionalStockInfo || !Object.values(AdditionalStockInfo).includes(additionalStockInfo)) {
    return null;
  }

  return {
    icon: iconMap[additionalStockInfo],
    label: i18n.global.t(labelMap[additionalStockInfo]),
  };
}
