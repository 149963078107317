<template>
  <div :class="b({ padding, amount: tabs.length })">
    <div :class="b('tab-list-wrapper')">
      <ul ref="tabList"
          :class="b('tab-list')"
          :aria-label="ariaLabel"
          role="tablist"
      >
        <li v-for="tab in tabs"
            :key="tab.id"
            :class="b('tab-item')"
            :aria-selected="tab.id === activeTab?.id"
            :aria-controls="`c-tabs-${uuid}--panel-${tab.id}`"
            role="tab"
        >
          <a :id="`c-tabs-${uuid}--tab-${tab.id}`"
             :class="b('tab', { [tab.id]: true, active: tab.id === activeTab?.id })"
             :href="tab.link?.href || `#c-tabs-${uuid}--panel-${tab.id}`"
             :target="tab.link?.target || LinkTarget.Self"
             :rel="tab.link?.target === LinkTarget.Blank ? 'noopener noreferrer' : undefined"
             @click.prevent="onTabClick(tab)"
          >
            <!-- @slot ${tab.id}__tab - Allows to overwrite the tab title. -->
            <slot :name="`${tab.id}__tab`">
              {{ tab.title }}
            </slot>
          </a>
        </li>
      </ul>
      <button :class="b('scroll-button', { left: true, visible: showScrollLeftIndicator })"
              :aria-label="$t('c-tabs.buttonScrollLeft')"
              type="button"
              @click="scroll('left')"
      >
        <e-icon icon="i-arrow--left" size="20" />
      </button>
      <button :class="b('scroll-button', { right: true, visible: showScrollRightIndicator })"
              :aria-label="$t('c-tabs.buttonScrollRight')"
              type="button"
              @click="scroll('right')"
      >
        <e-icon icon="i-arrow--right" size="20" />
      </button>
    </div>
    <!-- @slot default - Allows to use the component without panels. -->
    <slot>
      <div v-for="tab in tabs"
           :id="`c-tabs-${uuid}--panel-${tab.id}`"
           :key="tab.id"
           :class="b('panel', { [tab.id]: true, visible: tab.id === activeTab?.id })"
           :aria-labelledby="`c-tabs-${uuid}--tab-${tab.id}`"
           :tabindex="tab.id === activeTab?.id ? 0 : -1"
           role="tabpanel"
      >
        <!-- Title only visible for Print view -->
        <h3 :class="b('panel-title')">
          {{ tab.title }}
        </h3>
        <!-- @slot ${tab.id} - Renders the panel content for the current tab. -->
        <slot :name="tab.id"></slot>
      </div>
    </slot>
  </div>
</template>

<script lang="ts">
  import {
    defineComponent,
    PropType,
    Ref,
    ref,
  } from 'vue';
  import useUuid, { Uuid } from '@/compositions/uuid';
  import { LinkTarget } from '@/setup/globals';
  import { Link } from '@/types/link';
  import eIcon from '@/elements/e-icon.vue';

  interface Setup extends Uuid {
    tabList: Ref<HTMLUListElement>;
  }

  export interface Tab {

    /**
     * The text title for the current tab.
     */
    title: string;

    /**
     * A unique id for the current tab.
     */
    id: string;

    /**
     * Determines if the given tab should be initially active.
     */
    active?: boolean;

    /**
     * Allows to track additional information for a tab. e.g. a link.
     */
    link?: Link;
  }

  interface Data {
    LinkTarget: typeof LinkTarget;

    /**
     * Holds the currently active tab definition.
     */
    activeTab: Tab | null;

    /**
     * Holds scroll-related positions and definitions.
     */
    scrollInformation: {
      offsetWidth: number;
      scrollWidth: number;
      scrollLeft: number;
    };

    /**
     * Holds the resize observer instance.
     */
    resizeObserver: ResizeObserver | null;
  }

  const scrollIndicatorThreshold = 10;

  /**
   * Renders an A11y compatible tab component.
   *
   * Inspired by
   * - https://www.w3.org/TR/wai-aria-practices/examples/tabs/tabs-1/tabs.html
   * - http://web-accessibility.carnegiemuseums.org/code/tabs/
   */
  export default defineComponent({
    name: 'c-tabs',

    components: {
      eIcon,
    },

    props: {
      /**
       * Expects a list of tab definitions.
       */
      tabs: {
        type: Array as PropType<Tab[]>,
        required: true,
      },

      /**
       * Accepts a label that describes the purpose of the set of tabs.
       */
      ariaLabel: {
        type: String,
        default: null,
      },

      /**
       * Allows defining padding styling for the tab panels
       */
      padding: {
        type: String,
        default: '100',
        validator: (value: string) => [
          '0',
          '100',
          '400',
          '800',
        ].includes(value),
      },
    },
    emits: {
      change(payload: Tab): boolean {
        return typeof payload === 'object';
      },
    },

    setup(): Setup {
      const tabList = ref();

      return {
        ...useUuid(),
        tabList,
      };
    },
    data(): Data {
      return {
        LinkTarget,
        activeTab: this.tabs.find(tab => tab.active) || null,
        scrollInformation: {
          offsetWidth: 0,
          scrollWidth: 0,
          scrollLeft: 0,
        },
        resizeObserver: null,
      };
    },

    computed: {
      /**
       * Determines whether the scroll left indicator is visible.
       */
      showScrollLeftIndicator(): boolean {
        return this.scrollInformation.scrollLeft > scrollIndicatorThreshold;
      },

      /**
       * Determines whether the scroll right indicator is visible.
       */
      showScrollRightIndicator(): boolean {
        const { offsetWidth, scrollWidth, scrollLeft } = this.scrollInformation;

        return scrollWidth > offsetWidth && Math.ceil(scrollLeft) + scrollIndicatorThreshold < scrollWidth - offsetWidth;
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    mounted() {
      this.tabList.addEventListener('scroll', this.updateScrollPosition, { passive: true });
      this.resizeObserver = new ResizeObserver(this.updateScrollPosition);
      this.resizeObserver.observe(this.tabList);
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    beforeUnmount() {
      this.tabList.removeEventListener('scroll', this.updateScrollPosition);
      this.resizeObserver?.disconnect();
    },
    // unmounted() {},

    methods: {
      /**
       * Handles the click event of tabs.
       */
      onTabClick(tab: Tab): void {
        this.activeTab = tab;
        this.$emit('change', tab);
      },

      /**
       * Updates scroll-related positions and definitions.
       */
      updateScrollPosition(): void {
        const { offsetWidth, scrollWidth, scrollLeft } = this.tabList;

        this.scrollInformation = {
          offsetWidth,
          scrollWidth,
          scrollLeft,
        };
      },

      /**
       * Scrolls the tab list.
       */
      scroll(direction: 'left' | 'right' = 'left'): void {
        const scroll = direction === 'left' ? -50 : 50;

        this.tabList?.scroll({
          left: this.scrollInformation.scrollLeft + scroll,
          behavior: 'smooth',
        });
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-tabs {
    $this: &;
    $scroll-button-size: 20px;

    &__tab-list-wrapper {
      position: relative;

      @include mixins.media($media: print) {
        display: none;
      }
    }

    &__tab-list {
      display: flex;
      align-items: stretch;
      overflow-x: scroll;
      column-gap: variables.$spacing--30;
      scrollbar-width: none;

      @include mixins.media(sm) {
        column-gap: variables.$spacing--60;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__tab-item {
      display: flex;
      align-items: stretch;
    }

    &__tab {
      @include mixins.font(variables.$font-size--20, null, variables.$font-weight--bold);

      display: flex;
      align-items: center;
      padding: variables.$spacing--5 0;
      border-bottom: 4px solid variables.$color-grayscale--0;
      cursor: pointer;
      white-space: nowrap;

      @include mixins.media(md) {
        @include mixins.font(variables.$font-size--30);
      }

      @include mixins.media($media: print) {
        @include mixins.font(variables.$font-size--20);
      }

      &:hover,
      &:focus,
      &--active {
        border-bottom-color: 4px solid variables.$color-primary--1;
        color: variables.$color-primary--1;
      }
    }

    &__panel {
      display: none;
      border-top: 0;

      @include mixins.media($media: print) {
        display: block;
      }

      &--visible {
        display: block;
      }
    }

    &__scroll-button {
      position: absolute;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      width: $scroll-button-size;
      opacity: 0;
      background-color: variables.$color-grayscale--1000;
      transition: opacity variables.$transition-duration--200 ease-in-out;
      pointer-events: none;
    }

    &__scroll-button--visible {
      opacity: 1;
      cursor: pointer;
      pointer-events: auto;

      &::before {
        position: absolute;
        content: '';
        width: 1.5em;
        height: 100%;
      }
    }

    &__scroll-button--left {
      left: 0;

      &::before {
        left: $scroll-button-size;
        background: linear-gradient(to left, transparent, variables.$color-grayscale--1000);
      }
    }

    &__scroll-button--right {
      right: 0;

      &::before {
        right: $scroll-button-size;
        background: linear-gradient(to right, transparent, variables.$color-grayscale--1000);
      }
    }

    &__panel-title {
      display: none;

      @include mixins.media($media: print) {
        @include mixins.font(variables.$font-size--20);

        display: block;
      }
    }

    &--amount-1 .c-tabs__tab--active {
      border-bottom: transparent;
      color: variables.$color-grayscale--0;
      pointer-events: none;
    }

    &--padding-0 &__panel {
      padding: 0;
    }

    &--padding-100 &__panel {
      padding: variables.$spacing--5 0;
    }

    &--padding-400 &__panel {
      padding: variables.$spacing--20 0;
    }

    &--padding-800 &__panel {
      padding: variables.$spacing--40 0;
    }
  }
</style>
