<template>
  <c-banner
    :class="b({ heroBanner: banner.heroBanner })"
    :banner="banner"
    :is-lcp-candidate="isLcpCandidate"
  />
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import cBanner from '@/components/c-banner.vue';
  import { CmsBanner } from '@/types/cms-banner';

  type Setup = {
    banner: CmsBanner;
  }

  export interface CmsBannerComponentData {
    data: {
      sfsBannerComponent: CmsBanner;
    };
  }

  type Data = {
    isLcpCandidate?: boolean;
  }

  /**
   * Renders a banner component.
   */
  export default defineComponent({
    name: 'c-cms-banner',

    components: {
      cBanner,
    },

    props: {
      /**
       * Receives cms banner data.
       */
      data: {
        type: Object as PropType<CmsBannerComponentData>,
        required: true,
      },
    },
    // emits: {},

    setup(props): Setup {
      return {
        banner: props.data.data.sfsBannerComponent,
      };
    },
    data(): Data {
      return {
        isLcpCandidate: undefined,
      };
    },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    mounted() {
      const { parentNode } = this.$el;

      this.isLcpCandidate = parentNode.tagName === 'MAIN' && parentNode.children[0] === this.$el;
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-cms-banner {
    margin-bottom: variables.$spacing--40;

    &--hero-banner {
      @include mixins.fullwidth();

      @include mixins.media(2000px) {
        @include mixins.fullwidth(2000px);
      }
    }
  }
</style>
