<template>
  <div :class="b()">
    <dl v-for="alternateQuantity in product.alternateQuantities"
        :key="`${alternateQuantity.targetQuantity}-${alternateQuantity.sourceQuantity}`"
        :class="b('quantities')"
    >
      <dt :class="b('key')">
        {{ alternateQuantity.targetQuantity }} {{ alternateQuantity.targetUnit }}
      </dt>
      <dd :class="b('value')">
        {{ alternateQuantity.sourceQuantity }} {{ alternateQuantity.sourceUnit }}
      </dd>
    </dl>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { Product } from '@/types/product';

  // type Setup = {}

  // type Data = {}

  /**
   * Renders a list of alternative product quantities.
   */
  export default defineComponent({
    name: 'c-product-quantities',

    // components: {},

    props: {
      /**
       * Expects a product for which the alternative quantities should be shown.
       */
      product: {
        type: Object as PropType<Product>,
        required: true,
      },
    },
    // emits: {},

    // setup(): Setup {},
    // data(): Data {
    //   return {};
    // },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  // @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-product-quantities {
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 variables.$spacing--20;

    &__quantities {
      display: contents;
    }

    &__key,
    &__value {
      white-space: nowrap;
    }

    &__value {
      text-align: right;
    }
  }
</style>
