<template>
  <li :class="b()">
    <c-banner
      :banner="banner"
      :carousel-index="slideIndex"
      :is-lcp-candidate="isLcpCandidate"
      @load="$emit('load')"
    />
  </li>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import cBanner from '@/components/c-banner.vue';
  import { CmsBanner } from '@/types/cms-banner';

  // interface Setup {}
  // interface Data {}

  /**
   * Implements the "c-banner" component, which serves as a slider item.
   */
  export default defineComponent({
    name: 'c-slider-item-5',

    components: {
      cBanner,
    },

    props: {
      /**
       * Expects a banner.
       */
      banner: {
        type: Object as PropType<CmsBanner>,
        required: true,
      },

      /**
       * Expects the slider item index.
       */
      slideIndex: {
        type: Number,
        required: true,
      },

      /**
       * Allows defining if the current item is a potential LCP candidate.
       */
      isLcpCandidate: {
        type: Boolean,
        default: false,
      },
    },
    emits: {
      load: () => true,
    },

    // setup(): Setup {
    //   return {};
    // },
    // data(): Data {
    //   return {};
    // },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-slider-item-5 {
    flex: 0 0 auto;
    flex-basis: 100%;
  }
</style>
