import { CmsColumnWidth } from '@/components/c-cms-row-container.vue';
import { CmsLink } from '@/types/cms-link';
import { MediaContainer } from '@/types/media-container';
import { HybrisType } from '@/setup/globals';

export enum CmsImagePosition {
  Left = 'LEFT',
  Right = 'RIGHT',
  Top = 'TOP',
}

export interface CmsMarketingParagraph {
  border: boolean;
  headline: string;
  headlineOption: string;
  headlineClasses?: string;
  mainImage?: MediaContainer;
  imagePosition: CmsImagePosition;
  link?: CmsLink;
  text?: string;
  width: CmsColumnWidth;
  type: HybrisType.MarketingParagraph;
}
