/**
 * Handle setting a browser cookie.
 */
export default function setCookie(cookieName: string, cookieValue: string, expireDays?: number) {
  const date = new Date();

  date.setTime(date.getTime() + ((expireDays || 365) * 24 * 60 * 60 * 1000));

  const expires = `expires=${date.toUTCString()}`;

  document.cookie = `${cookieName}=${cookieValue}; ${expires}; path=/`;
}
