/**
 * Produces a duplicate-free version of an object array, using `new Set()` for test equality.
 * Not meant to be used for array which items are of primitive type.
 */
export default function<T>(input: T[]): T[] {
  if (!Array.isArray(input)) {
    return [];
  }

  return [...new Set(input.map(element => JSON.stringify(element)))].map(element => JSON.parse(element)) as T[];
}
