<template>
  <div :class="b()">
    <div v-outside-click="{ handler: close, excludeIds: ['c-service-navigation__contact-person-toggle'] }"
         :class="b('inner')"
    >
      <h2>
        {{ $t('c-contact-person-overlay.title') }}
      </h2>
      <c-contact-person :contact-person="sessionStore.contactPerson"
                        :picture-sizes="pictureSizes"
                        show-restricted-fields
      />
      <a :class="b('link')"
         :href="addContextPathToUrl(sessionStore.links.contactPersonOverviewLink.url)"
      >
        {{ $t('c-contact-person-overlay.contactPersonOverviewLink') }}
        <e-icon icon="i-arrow-2--right" size="20" />
      </a>
      <button :class="b('close-button')"
              :aria-label="$t('global.close')"
              type="button"
              @click="close"
      >
        <e-icon icon="i-close" size="18" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import useSessionStore from '@/stores/session';
  import cContactPerson from '@/components/c-contact-person.vue';
  import eIcon from '@/elements/e-icon.vue';
  import addContextPathToUrl from '@/helpers/add-context-path-to-url';
  import { ImageSizes } from '@/types/sizes';

  interface Setup {
    sessionStore: ReturnType<typeof useSessionStore>;
    addContextPathToUrl: typeof addContextPathToUrl;
    pictureSizes: ImageSizes;
  }

  // interface Data {}

  /**
   * Renders the contact person overlay.
   */
  export default defineComponent({
    name: 'c-contact-person-overlay',

    components: {
      eIcon,
      cContactPerson,
    },

    // props: {},
    emits: {
      close: () => true,
    },

    setup(): Setup {
      return {
        sessionStore: useSessionStore(),
        addContextPathToUrl,
        pictureSizes: {
          xxs: 439,
          xs: 727,
          sm: 991,
          md: 350,
          fallback: 350,
        },
      };
    },
    // data(): Data {
    //   return {};
    // },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      close(): void {
        this.$emit('close');
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-contact-person-overlay {
    overflow-y: auto;

    @include mixins.media(md) {
      @include mixins.layout();

      display: flex;
      justify-content: flex-end;
      overflow-y: visible;
      padding-block: variables.$spacing--5 variables.$spacing--20;
    }

    &__inner {
      position: relative;
      padding: variables.$spacing--40 variables.$spacing--20;
      background-color: variables.$color-grayscale--1000;

      @include mixins.media(md) {
        width: 390px;
        overflow-y: auto;
      }
    }

    &__link {
      @include mixins.link--secondary();
      @include mixins.font(variables.$font-size--20, variables.$line-height--25, variables.$font-weight--bold);

      display: inline-flex;
      align-items: center;
      column-gap: variables.$spacing--10;
    }

    &__close-button {
      position: absolute;
      top: variables.$spacing--20;
      right: variables.$spacing--20;
      cursor: pointer;
    }

    .c-contact-person {
      margin-bottom: variables.$spacing--30;
    }
  }
</style>
