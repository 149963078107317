<template>
  <div :class="b({ colSm: width })">
    <e-picture v-bind="picture"
               :class="b('picture')"
               :sizes="pictureSizes"
               :alt="contactPerson.name"
               :ratio="2"
    />
    <address>
      <div :class="b('address-line', { name: true })">
        {{ contactPerson.name }}
      </div>
      <div v-if="contactPerson.function" :class="b('address-line')">
        {{ contactPerson.function }}
      </div>
      <div v-if="contactPerson.functionAddon" :class="b('address-line')">
        {{ contactPerson.functionAddon }}
      </div>
      <div v-if="showRestrictedFields" :class="b('restricted-fields')">
        <div v-if="contactPerson.email" :class="b('address-line', { email: true })">
          <a v-protect-mailto :href="`mailto:${contactPerson.email}`" :class="b('email')">
            {{ emailLabel }}
          </a>
        </div>
        <div v-if="contactPerson.phone" :class="b('address-line')">
          {{ contactPerson.phone }}
        </div>
        <div v-if="contactPerson.mobile" :class="b('address-line')">
          {{ contactPerson.mobile }}
        </div>
      </div>
    </address>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import ePicture from '@/elements/e-picture.vue';
  import mapMediaContainerSrcSet from '@/helpers/map-media-container-srcset';
  import { ImageSrcset } from '@/types/image';
  import { ContactPerson } from '@/types/contact-person';
  import { ImageSizes } from '@/types/sizes';
  import { ColumnWidth } from '@/components/c-row-container.vue';

  // type Setup = {}

  // type Data = {}

  export type ContactPersonProps = {
    contactPerson: ContactPerson;
    showRestrictedFields?: boolean;
    pictureSizes?: ImageSizes;
    width?: ColumnWidth;
  }

  /**
   * Renders a contact person tile.
   */
  export default defineComponent({
    name: 'c-contact-person',

    components: { ePicture },

    props: {
      /**
       * Expects a contact person object.
       */
      contactPerson: {
        type: Object as PropType<ContactPerson>,
        required: true,
      },

      /**
       * Allows defining whether to display the restricted fields.
       */
      showRestrictedFields: {
        type: Boolean,
        default: false,
      },

      /**
       * Allows sizes for picture to be passed.
       */
      pictureSizes: {
        type: Object as PropType<ImageSizes>,
        default: () => ({
          xxs: 425,
          xs: 712,
          sm: 936,
          md: 1144,
          fallback: 1420,
        }),
      },

      /**
       * Allows to set the column width.
       */
      width: {
        type: Number as PropType<ColumnWidth>,
        default: 12,
      },
    },
    // emits: {},

    // setup(): Setup {},
    // data(): Data {
    //   return {};
    // },

    computed: {
      picture(): ImageSrcset | undefined {
        const { contactImage } = this.contactPerson;

        return contactImage && mapMediaContainerSrcSet(contactImage);
      },

      emailLabel(): string | undefined {
        return import.meta.env.VITE_CONTACT_PERSON_TILE_EMAIL_ADDRESS_LABEL !== '1'
          ? this.$t('global.email')
          : this.contactPerson.email;
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-contact-person {
    &__picture {
      margin-bottom: variables.$spacing--25;
    }

    &__address-line {
      &--name {
        @include mixins.font(variables.$font-size--20, variables.$line-height--25, variables.$font-weight--bold);
      }

      &--email {
        margin-bottom: variables.$spacing--10;
      }
    }

    &__email {
      @include mixins.link--primary();

      font-weight: variables.$font-weight--bold;
    }

    &__restricted-fields {
      margin-top: variables.$spacing--15;
    }

    &--col-sm-4 {
      @include mixins.media(sm) {
        grid-column: span 4;
      }
    }

    &--col-sm-6 {
      @include mixins.media(sm) {
        grid-column: span 6;
      }
    }

    &--col-sm-12 {
      @include mixins.media(sm) {
        grid-column: span 12;
      }
    }

    .e-picture--placeholder:not(.e-picture--loaded) {
      border: 1px solid variables.$color-grayscale--400;
      background: url('../assets/placeholder-image-person.svg') no-repeat center;
    }
  }
</style>
