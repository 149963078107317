import { PosStock } from '@/types/pos-stock';
import useSessionStore from '@/stores/session';
import i18n from '@/setup/i18n';
import { HybrisType, PosStockStatus, Icon } from '@/setup/globals';
import { StockState } from '@/helpers/map-stock-indicator';

type IconMap = {
  [key in PosStockStatus]: string;
}

type LabelMap = {
  [key in PosStockStatus]: string;
}

export const labelMap: LabelMap = {
  [PosStockStatus.PickupInStock]: 'global.productStock.pickupInStock',
  [PosStockStatus.PickupInOtherStore]: 'global.productStock.pickupInOtherStore',
  [PosStockStatus.PickupOutOfStock]: 'global.productStock.pickupOutOfStock',
};

export const iconMap: IconMap = {
  [PosStockStatus.PickupInStock]: Icon.PickupInStock,
  [PosStockStatus.PickupInOtherStore]: Icon.PickupInOtherStore,
  [PosStockStatus.PickupOutOfStock]: Icon.PickupOutOfStock,
};

export default function mapPosStockIndicator(stock?: PosStock, showPointOfService = true): StockState | null {
  if (stock?.type !== HybrisType.Stock || !stock.stockLevelStatus) {
    return null;
  }

  const sessionStore = useSessionStore();
  const { stockLevelStatus, stockLevel, unit } = stock || {};
  const { code } = stockLevelStatus || {};

  const state = {
    icon: iconMap[code],
    label: i18n.global.t(labelMap[code]),
  };

  if (showPointOfService) {
    if (code === PosStockStatus.PickupInStock) {
      state.label = `${sessionStore.user.defaultPos.displayName} (${stockLevel} ${unit})`;
    } else if (code !== PosStockStatus.PickupInOtherStore) {
      state.label = `${sessionStore.user.defaultPos.displayName} – ${i18n.global.t(labelMap[code])}`;
    }
  }

  return state;
}
