<template>
  <transition-group :class="b()" name="list" tag="div">
    <c-notification v-for="notification in filteredNotifications"
                    :key="notification.id"
                    :notification="notification"
    />
  </transition-group>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import cNotification from '@/components/c-notification.vue';
  import notificationStore, { MappedNotificationItem } from '@/stores/notification';

  interface Setup {
    notificationStore: ReturnType<typeof notificationStore>;
  }

  /**
   * Container for rendering notifications. See /styleguide/notifications for demo.
   */
  export default defineComponent({
    name: 'c-notification-container',

    components: {
      cNotification,
    },

    props: {
      /**
       * Defines which notifications should get displayed in the container.
       */
      selector: {
        type: String,
        default: 'default',
        validator: (value: string) => [
          'default',
          'footer',
        ].includes(value),
      },
    },

    setup(): Setup {
      return {
        notificationStore: notificationStore(),
      };
    },
    // data() {
    //   return {};
    // },

    computed: {
      /**
       * Gets the filtered notifications depending on the selector.
       */
      filteredNotifications(): readonly MappedNotificationItem[] {
        if (this.selector !== 'default') {
          return this.notificationStore.notifications
            .filter(notification => notification.selector === this.selector);
        }

        return this.notificationStore.notifications.filter(notification => !notification.selector);
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-notification-container {
    @include mixins.media($media: print) {
      display: none;
    }

    .list-move,
    .list-enter-active,
    .list-leave-active {
      transition: all variables.$transition-duration--200;
    }

    .list-enter-active,
    .list-leave-active {
      @include mixins.z-index(back);

      inset-inline: 0;
    }

    .list-leave-active {
      position: absolute;
    }

    .list-enter-from,
    .list-leave-to {
      transform: translateY(-100%);
    }
  }
</style>
