import { defineStore } from 'pinia';
import { Store } from '@/setup/globals';

export interface BreadcrumbItem {
  name: string;
  url: string;
}

interface BreadcrumbState {

  /**
   * Holds the breadcrumb items.
   */
  items: BreadcrumbItem[];

  /**
   * Shows if the breadcrumb component is available.
   */
  hasBreadcrumbComponent: boolean;
}

interface InitialData {

  /**
   * Holds the initial breadcrumb items in data.
   */
  data: {
    breadcrumbs: BreadcrumbItem[];
  };
}

const storeName = Store.Breadcrumbs;

export default defineStore(storeName, {
  state: () => {
    const state: BreadcrumbState = {
      items: [],
      hasBreadcrumbComponent: false,
    };

    const initialData: InitialData = window.initialData?.[storeName] || {};
    const { breadcrumbs } = initialData?.data || {};

    if (Array.isArray(breadcrumbs)) {
      state.items = breadcrumbs;
    }

    return state;
  },

  getters: {
    hasBreadcrumbs(): boolean {
      return this.hasBreadcrumbComponent && this.items.length > 0;
    },
  },
});
