<template>
  <c-modal v-model:is-open="isOpen"
           size="800"
           close-on-outside-click
  >
    <iframe v-if="cIframeModalState.url"
            :class="b('iframe')"
            :src="cIframeModalState.url"
    ></iframe>
  </c-modal>
</template>

<script lang="ts">
  import { defineComponent, reactive } from 'vue';
  import cModal from '@/components/c-modal.vue';
  import { MODAL_LINK_IDENTIFIER } from '@/setup/globals';

  type IframeModalState = Record<'url', string | null>;

  export const cIframeModalState: IframeModalState = reactive({
    url: null,
  });

  interface Setup {
    cIframeModalState: IframeModalState;
  }
  // interface Data {}

  /**
   * Creates a modal containing an iframe.
   *
   * Can be triggered by:
   * - Intercepting links that have the attribute `data-modal-link`
   * - Setting the state in other components directly
   */
  export default defineComponent({
    name: 'c-iframe-modal',
    components: {
      cModal,
    },

    // props: {},
    // emits: {},

    setup(): Setup {
      return {
        cIframeModalState,
      };
    },
    // data(): Data {},

    computed: {
      isOpen: {

        /**
         * Returns the `is-open`-state for the modal.
         */
        get(): boolean {
          return !!cIframeModalState.url;
        },

        /**
         * Resets state after modal was closed.
         */
        set(value: boolean): void {
          if (!value) {
            cIframeModalState.url = null;
          }
        },
      },
    },
    // watch: {},

    // beforeCreate() {},
    created() {
      this.interceptModalLinks();
    },
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      /**
       * Intercepts modal links across the application.
       */
      interceptModalLinks(): void {
        window.addEventListener('click', (event) => {
          const target = event.target as HTMLElement;

          if (target?.hasAttribute(MODAL_LINK_IDENTIFIER) && target.hasAttribute('href')) {
            event.preventDefault();

            cIframeModalState.url = target.getAttribute('href');
          }
        });
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  .c-iframe-modal {
    &__iframe {
      display: block;
      width: 100%;
      height: 60vh;
      border: none;
    }
  }
</style>
