<template>
  <span :class="b({ variant })">
    <span :class="b('rect', { 'bar-1' : true })"></span>
    <span :class="b('rect', { 'bar-2' : true })"></span>
    <span :class="b('rect', { 'bar-3' : true })"></span>
    <span :class="b('rect', { 'bar-4' : true })"></span>
    <span :class="b('rect', { 'bar-5' : true })"></span>
    <span class="invisible">
      {{ loadingMessage }}
    </span>
  </span>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  // interface Setup {}
  // interface Data {}

  /**
   * The e-loader component provides a animated loading indicator.
   */
  export default defineComponent({
    name: 'e-loader',

    // components: {},

    props: {
      /**
       * Sets the variant of the loader
       */
      variant: {
        type: String,
        default: 'default',
        validator(value: string) {
          return [
            'default',
            'tiny',
          ].includes(value);
        },
      },

      /**
       * Defines a fallback message. "e-loader.loading" is used by default
       */
      message: {
        type: String,
        default: null, // Translation can not be set here because it will not be computed
      },
    },
    // emits: {},

    // setup(): Setup {},
    // data(): Data {
    //   return {};
    // },

    computed: {
      /**
       * Returns a readable loading message.
       */
      loadingMessage(): string {
        return this.message || this.$t('e-loader.loading');
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '../setup/scss/variables';

  .e-loader {
    display: grid;
    grid-column-gap: 0.125em;
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;
    width: 3em;
    max-width: 100%;
    height: 3em;
    margin: 0 auto;
    font-size: 1em;
    text-align: center;

    &__rect {
      display: inline-block;
      height: 100%;
      background-color: currentColor;
      animation: wave-loader 1.2s infinite ease-in-out;
    }

    &__rect--bar-1 {
      animation-delay: -1.2s;
    }

    &__rect--bar-2 {
      animation-delay: -1.1s;
    }

    &__rect--bar-3 {
      animation-delay: -1s;
    }

    &__rect--bar-4 {
      animation-delay: -0.9s;
    }

    &__rect--bar-5 {
      animation-delay: -0.8s;
    }

    @keyframes wave-loader {
      0%,
      40%,
      100% {
        transform: scaleY(0.4);
      }

      20% {
        transform: scaleY(1);
      }
    }

    &--variant-tiny {
      width: 1.5em;
      height: 0.6em;
    }
  }
</style>
