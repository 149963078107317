<template>
  <div :class="b({ initialized: !!emblaInstance, variant })">
    <div ref="stage" :class="b('stage')">
      <ul :class="b('stage-inner')">
        <slot></slot>
      </ul>
    </div>
    <div v-if="noOfPages > 1" :class="b('controls')">
      <button v-if="navigation"
              :class="b('navigation', { previous: true })"
              :aria-label="$t('c-slider.navigationPrevious')"
              :disabled="disablePrevious"
              type="button"
              @click="previous"
      >
        <e-icon icon="i-arrow--left" />
      </button>
      <ul v-if="pagination" :class="b('pagination')">
        <li v-for="(slidePosition, index) in paginationSize" :key="slidePosition">
          <button :class="b('pagination-item', { active: slidePosition === activePage + 1 })"
                  :aria-label="$t('c-slider.goToSlide', { slide: index })"
                  type="button"
                  @click="navigateTo(slidePosition - 1)"
          >
          </button>
        </li>
      </ul>
      <button v-if="navigation"
              :class="b('navigation', { next: true })"
              :aria-label="$t('c-slider.navigationNext')"
              :disabled="disableNext"
              type="button"
              @click="next"
      >
        <e-icon icon="i-arrow--right" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
  import EmblaCarousel, {
    EmblaCarouselType,
    EmblaEventType,
    EmblaOptionsType,
    EmblaPluginType,
  } from 'embla-carousel';
  import Autoplay, { AutoplayOptionsType } from 'embla-carousel-autoplay';
  import {
    defineComponent, PropType,
    Ref,
    ref,
  } from 'vue';
  import eIcon from '@/elements/e-icon.vue';

  interface Setup {
    stage: Ref<HTMLDivElement>;
    resizeObserver: ResizeObserver | undefined;
  }

  export enum SliderVariant {
    Default = 'default',
    ProductSlider = 'product-slider',
    BannerCarousel = 'banner-carousel',
    Lightbox = 'lightbox',
    RowContainer = 'row-container',
  }

  interface Data {
    emblaInstance: undefined | EmblaCarouselType;
    activeSlideInternal: number;
    activePage: number;
    slidesPerPage: number;
    noOfPages: number;
    noOfSlides: number;
    hasEmblaRendered: boolean;
    resizeTimeout: ReturnType<typeof setTimeout> | null;
  }

  export enum NavigationMode {
    Slide = 'slide',
    Page = 'page',
  }

  /**
   * Renders a slider.
   */
  export default defineComponent({
    name: 'c-slider',
    components: {
      eIcon,
    },

    props: {
      /**
       * Allows passing options for the Embla carousel.
       */
      emblaOptions: {
        type: Object as PropType<EmblaOptionsType>,
        default: null,
      },

      /**
       * Allows defining auto play options.
       */
      autoPlayOptions: {
        type: Object as PropType<AutoplayOptionsType>,
        default: () => ({
          delay: 4000,
          stopOnMouseEnter: true,
        }),
      },

      /**
       * Allows enabling autoplay.
       */
      autoPlay: {
        type: Boolean,
        default: false,
      },

      /**
       * Allows to disable the pagination.
       */
      pagination: {
        type: Boolean,
        default: true,
      },

      /**
       * Allows to disable the navigation elements.
       */
      navigation: {
        type: Boolean,
        default: true,
      },

      /**
       * Allows to control the active slide.
       * v-model:activeSlide is supported.
       */
      activeSlide: {
        type: Number,
        default: 0,
      },

      /**
       * Defines the mode by which the prev/next buttons should act.
       */
      navigationMode: {
        type: String as PropType<NavigationMode>,
        default: NavigationMode.Slide,
        validator(value: NavigationMode) {
          return Object.values(NavigationMode).includes(value);
        },
      },

      /**
       * Allows to change the active class for a single slide item.
       */
      activeClass: {
        type: String,
        default: 'is-active',
      },

      /**
       * Expects the variant of the slider to be passed.
       */
      variant: {
        type: String as PropType<SliderVariant>,
        default: SliderVariant.Default,
        validator(value: SliderVariant) {
          return Object.values(SliderVariant).includes(value);
        },
      },
    },
    emits: {
      'update:activeSlide': (payload: number) => payload > -1,
    },

    setup(): Setup {
      const stage = ref();

      return {
        stage,
        resizeObserver: undefined,
      };
    },
    data(): Data {
      return {
        emblaInstance: undefined,
        activeSlideInternal: this.activeSlide,
        activePage: 0,
        slidesPerPage: 0,
        noOfPages: 0,
        noOfSlides: 0,
        hasEmblaRendered: false,
        resizeTimeout: null,
      };
    },

    computed: {
      /**
       * Defines if the previous button should be disabled.
       */
      disablePrevious(): boolean {
        return !this.emblaOptions?.loop && this.activeSlideInternal === 0;
      },

      /**
       * Defines if the next button should be disabled.
       */
      disableNext(): boolean {
        switch (this.navigationMode) {
          case 'page':
            return !this.emblaOptions?.loop && this.activePage === this.noOfPages - 1;

          default: // slide
            return !this.emblaOptions?.loop && this.activeSlideInternal === this.noOfSlides - 1;
        }
      },

      /**
       * Calculates the pagination size based on the navigationMode.
       */
      paginationSize(): number {
        switch (this.navigationMode) {
          case NavigationMode.Page:
            return this.noOfPages;

          default: // slide
            return this.noOfSlides;
        }
      },

      plugins(): EmblaPluginType[] {
        const plugins = [];

        if (this.autoPlay) {
          plugins.push(Autoplay(this.autoPlayOptions)); // eslint-disable-line new-cap
        }

        return plugins;
      },
    },
    watch: {
      /**
       * Updates the internal slide state on prop change.
       */
      activeSlide(value: number, oldValue: number): void {
        if (value !== oldValue) {
          this.scrollToSlide(value);
        }
      },

      /**
       * Updates the internal slide state on the components prop.
       */
      activeSlideInternal(value: number, oldValue: number): void {
        if (value !== oldValue) {
          this.$emit('update:activeSlide', value);
        }
      },

      /**
       * Update pagination if number of slides changes.
       */
      noOfSlides(): void {
        this.$nextTick(() => { // Was required because DOM was initial not ready.
          this.updatePagination();
        });
      },

      /**
       * Reset slider position on navigation mode change.
       */
      navigationMode(): void {
        this.navigateTo(0);
      },

      autoPlay(): void {
        this.refreshEmblaInstance();
      },
    },

    // beforeConResize
    // created() {},
    // beforeMount() {},
    mounted() {
      this.$nextTick(() => { // Was required e.g. for cases, where the slider is placed inside a <dialog> element.
        this.createSlider();
      });

      if ([
        SliderVariant.Lightbox, // Requires a refresh when the dialog is shown.
      ].includes(this.variant)) {
        this.resizeObserver = new ResizeObserver(this.refreshEmblaInstance);
        this.resizeObserver.observe(this.$el);
      }
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    beforeUnmount() {
      this.emblaInstance?.destroy();
    },
    // unmounted() {},

    methods: {
      /**
       * Creates an instance of the Embla slider.
       */
      createSlider(): void {
        if (this.emblaInstance) {
          return;
        }

        this.emblaInstance = EmblaCarousel(this.stage, this.mergeEmblaOptions(), this.plugins); // eslint-disable-line new-cap
        this.emblaInstance
          .on('init', this.onInit)
          .on('select', this.onSelect)
          .on('resize', this.onResize)
          .on('reInit', (api, event) => {
            this.updateRenderingState(event);
            this.updateInternalStates();
          });
      },

      /**
       * Returns Embla options based on the current viewport.
       */
      mergeEmblaOptions(): Partial<EmblaOptionsType> {
        return {
          align: 'start',
          skipSnaps: true,
          containScroll: 'keepSnaps',
          slidesToScroll: this.navigationMode === 'slide' ? 1 : 'auto',
          startIndex: this.activeSlideInternal,
          inViewThreshold: 0.1,
          ...this.emblaOptions,
        };
      },

      /**
       * Event handler for the resize event of the slider instance.
       */
      onResize(): void {
        this.updateRenderingState('resize');

        if (this.resizeTimeout) {
          clearTimeout(this.resizeTimeout);
        }

        this.resizeTimeout = setTimeout(() => {
          this.updateInternalStates();
        }, 200);
      },

      /**
       * Event handler for the sliders init event.
       */
      onInit(): void {
        setTimeout(() => {
          this.updateInternalStates();
          this.updateActiveSlide();
        });
      },

      /**
       * Event handler for the sliders select event.
       */
      onSelect(): void {
        this.updateActivePage();
        this.updateActiveSlide();
      },

      /**
       * Updates the internal render state. This was required for the 'fade' transition, so that we can switch between 'flex' and 'grid' layout.
       */
      updateRenderingState(event: EmblaEventType): void {
        const isResizeEvent = event === 'resize';

        this.hasEmblaRendered = !isResizeEvent;

        if (isResizeEvent) {
          this.$nextTick(this.refreshEmblaInstance);
        }
      },

      refreshEmblaInstance() {
        this.emblaInstance?.reInit(this.mergeEmblaOptions(), this.plugins);
      },

      /**
       * Updates internal states.
       */
      updateInternalStates(): void {
        this.updateSlidesPerPage();
      },

      /**
       * Scrolls to given page.
       */
      scrollToPage(page: number): void {
        this.emblaInstance?.scrollTo(page);
      },

      /**
       * Scroll to given slide.
       */
      scrollToSlide(slideIndex: number): void {
        switch (this.navigationMode) {
          case NavigationMode.Page:
            this.emblaInstance?.scrollTo(Math.floor(slideIndex / this.slidesPerPage));
            break;

          default: // slide
            this.emblaInstance?.scrollTo(slideIndex);

            if (this.noOfPages === 1) { // There will be no embla event if only 1 page is available.
              this.updateActiveSlide(slideIndex);
            }
        }
      },

      /**
       * Scrolls to the next page.
       */
      next(): void {
        switch (this.navigationMode) {
          case NavigationMode.Page:
            this.scrollToPage(this.activePage + 1);
            break;

          default: // slide
            this.emblaInstance?.scrollNext();
        }
      },

      /**
       * Scrolls to the previous page.
       */
      previous(): void {
        switch (this.navigationMode) {
          case NavigationMode.Page:
            this.scrollToPage(this.activePage - 1);
            break;

          default: // slide
            this.emblaInstance?.scrollPrev();
        }
      },

      /**
       * Recalculates the amount of slides per page.
       */
      updateSlidesPerPage(): void {
        const totalNumberOfSlides = this.emblaInstance?.slideNodes().length;

        this.slidesPerPage = this.emblaInstance?.slidesInView().length || 1;
        this.noOfPages = totalNumberOfSlides ? Math.ceil(totalNumberOfSlides / this.slidesPerPage) : 1;
      },

      /**
       * Updates the pagination (including number of pages).
       */
      updatePagination(): void {
        this.noOfPages = this.noOfSlides ? Math.ceil(this.noOfSlides / this.slidesPerPage) : 1;

        this.updateActivePage();
      },

      /**
       * Updates the active page.
       */
      updateActivePage(): void {
        this.activePage = this.emblaInstance?.selectedScrollSnap() || 0;
      },

      /**
       * Updates the slides active classes.
       */
      updateActiveSlide(activeSlide?: number): void {
        const slides = this.emblaInstance?.slideNodes();
        const newOrActiveSlide = activeSlide || this.emblaInstance?.selectedScrollSnap() || 0;

        if (!slides) {
          return;
        }

        slides.forEach(element => element.classList.remove(this.activeClass));
        slides[newOrActiveSlide]?.classList.add(this.activeClass);

        this.activeSlideInternal = newOrActiveSlide;
        this.noOfSlides = slides.length;
      },

      /**
       * Navigate to the given index. This is navigationMode sensitive.
       */
      navigateTo(index: number): void {
        switch (this.navigationMode) {
          case NavigationMode.Page:
            this.scrollToPage(index);
            break;

          default: // slide
            this.scrollToSlide(index);
        }
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '../setup/scss/variables';
  @use '../setup/scss/mixins';

  .c-slider {
    $this: &;

    position: relative;
    display: grid;
    grid-template-columns: [left] auto [center] auto [right] auto [end];
    grid-template-rows: [top] auto [end];

    &__stage {
      grid-area: top / left / end / end;
      width: 100%;
      overflow: hidden;
    }

    &__stage-inner {
      display: flex;
      cursor: grab;
    }

    &__navigation {
      display: none;

      @include mixins.media(sm) {
        position: absolute;
        top: initial;
        display: block;
        padding: variables.$spacing--5;
        opacity: 0;
        background-color: variables.$color-primary--1;
        transform: translateY(-50%);
        cursor: pointer;
        color: variables.$color-grayscale--1000;
        transition: opacity variables.$transition-duration--100 ease-in-out;

        .e-icon {
          width: 2.5em;
          height: 2.5em;
        }

        &--next {
          right: - 40px;
        }

        &--previous {
          left: - 40px;
        }

        &[disabled] {
          opacity: 0;
          cursor: default;
        }
      }
    }

    &__controls {
      position: absolute;
      bottom: 0;
      width: 100%;
    }

    &__pagination {
      @include mixins.z-index(front);

      display: flex;
      grid-area: top / left / end / end;
      gap: variables.$spacing--10;
      justify-content: center;
      margin-bottom: variables.$spacing--10;
      padding: 0 variables.$spacing--30;
      place-self: end center;
    }

    &__pagination-item {
      display: block;
      width: 10px;
      border-radius: 50%;
      background-color: variables.$color-grayscale--0;
      aspect-ratio: 1 / 1;
      cursor: pointer;

      &--active {
        background-color: variables.$color-primary--1;
      }
    }

    &--initialized &__navigation:not([disabled]) {
      opacity: 1;
    }

    &--variant-product-slider {
      #{$this}__controls {
        display: contents;
      }

      #{$this}__navigation {
        top: 0;
        display: none;
        height: 100%;
        background-color: transparent;
        transform: translateY(0);
        color: variables.$color-grayscale--0;

        @include mixins.media(1500px) { // Custom breakpoints prevents the arrow from being cut off.
          display: block;

          &--next {
            right: - 45px;
          }

          &--previous {
            left: - 45px;
          }
        }
      }

      #{$this}__pagination {
        margin-bottom: - variables.$spacing--30;
      }
    }

    &--variant-lightbox {
      grid-template-columns: [left] auto [center] auto [right] auto [end];
      grid-template-rows: [top] auto [center] auto [end];
      gap: variables.$spacing--30;

      #{$this}__controls {
        display: contents;
      }

      #{$this}__stage {
        grid-area: top / center / center / center;
      }

      #{$this}__navigation {
        position: static;
        background: transparent;
        transform: none;
        color: variables.$color-grayscale--1000;

        &--next {
          right: auto;
          grid-area: top / right / center / right;
        }

        &--previous {
          left: auto;
          grid-area: top / left / center / left;
        }
      }

      #{$this}__pagination {
        grid-area: center / center / end / center;
      }

      #{$this}__pagination-item {
        background: variables.$color-grayscale--1000;

        &--active {
          background-color: variables.$color-primary--1;
        }
      }
    }

    &--variant-banner-carousel {
      #{$this}__controls {
        left: 50%;
        display: flex;
        align-items: center;
        width: auto;
        margin-bottom: variables.$spacing--10;
        transform: translate(-50%, 0);
      }

      #{$this}__navigation {
        position: relative;
        display: none;
        background-color: transparent;
        transform: none;
        color: variables.$color-grayscale--1000;

        @include mixins.media(md) {
          display: block;
        }

        .e-icon {
          width: 1.45em;
          height: 1.45em;
        }

        &--next {
          right: 0;
        }

        &--previous {
          left: 0;
        }
      }

      #{$this}__pagination {
        padding: 0 variables.$spacing--10;

        @include mixins.media(md) {
          margin: auto;
        }
      }

      #{$this}__pagination-item {
        background: variables.$color-grayscale--1000;

        &--active {
          background-color: variables.$color-primary--1;
        }
      }
    }

    &--variant-row-container {
      padding-bottom: variables.$spacing--60;

      &::after {
        position: absolute;
        bottom: variables.$spacing--35;
        left: 0;
        content: '';
        width: 100%;
        border-bottom: 4px solid variables.$color-grayscale--0;
      }
    }
  }
</style>
