<template>
  <div :class="b({ heroBanner: carousel.heroBanner })">
    <c-slider :class="b('slider')"
              :embla-options="{ align: 'center', loop: true }"
              :variant="SliderVariant.BannerCarousel"
              :auto-play="isFirstBannerLoaded"
    >
      <c-slider-item-5 v-for="(banner, index) in carousel.banners"
                       :key="index"
                       :banner="banner"
                       :slide-index="index"
                       :is-lcp-candidate="isLcpCandidate && index === 0"
                       @load="onLoad(index)"
      />
    </c-slider>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import cSlider, { SliderVariant } from '@/components/c-slider.vue';
  import cSliderItem5 from '@/components/c-slider-item-5.vue';
  import { CmsBannerCarousel } from '@/types/cms-banner-carousel';

  type Setup = {
    carousel: CmsBannerCarousel;
    SliderVariant: typeof SliderVariant;
  }

  export interface CmsBannerCarouselComponentData {
    data: {
      sfsBannerCarouselComponent: CmsBannerCarousel;
    };
  }

  type Data = {
    isLcpCandidate?: boolean;
    isFirstBannerLoaded: boolean;
  }

  /**
   * Renders a multiple banner components as a slider.
   */
  export default defineComponent({
    name: 'c-cms-banner-carousel',

    components: {
      cSlider,
      cSliderItem5,
    },

    props: {
      /**
       * Receives cms banner data.
       */
      data: {
        type: Object as PropType<CmsBannerCarouselComponentData>,
        required: true,
      },
    },
    // emits: {},

    setup(props): Setup {
      // eslint-disable-next-line vue/no-setup-props-destructure
      const carousel = props.data.data.sfsBannerCarouselComponent;

      return {
        carousel,
        SliderVariant,
      };
    },

    data(): Data {
      return {
        isLcpCandidate: undefined,
        isFirstBannerLoaded: false,
      };
    },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    mounted() {
      const { parentNode } = this.$el;

      this.isLcpCandidate = parentNode.tagName === 'MAIN' && parentNode.children[0] === this.$el;
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      onLoad(index: number): void {
        if (index === 0) {
          this.isFirstBannerLoaded = true;
        }
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-cms-banner-carousel {
    margin-bottom: variables.$spacing--40;

    &--hero-banner {
      @include mixins.fullwidth();

      @include mixins.media(2000px) {
        @include mixins.fullwidth(2000px);
      }
    }
  }
</style>
