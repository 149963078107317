import addContextPathToUrl from '@/helpers/add-context-path-to-url';
import useSessionStore from '@/stores/session';

/**
 * Gets the URL to the search result page.
 * Adds query if available.
 */
export default function searchResultPageUrl(query?: string, isEanSearch = false) {
  const { search, searchUrlEan } = useSessionStore().apiUrls;
  const baseUrl = addContextPathToUrl(isEanSearch ? searchUrlEan : search);

  if (!query) {
    return baseUrl;
  }

  const url = new URL(baseUrl, window.location.origin);

  url.searchParams.set('text', query);

  return `${url.pathname}${url.search}`;
}
