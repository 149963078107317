import { CmsLink } from '@/types/cms-link';
import { HybrisLinkTarget, LinkTarget, MODAL_LINK_IDENTIFIER } from '@/setup/globals';
import addContextPathToUrl from '@/helpers/add-context-path-to-url';
import { Link } from '@/types/link';

export interface LinkWithModalAttribute extends Link {
  [MODAL_LINK_IDENTIFIER]?: boolean;
}

/**
 * Returns attributes for binding to a link based on a CMS Link object.
 */
export default function cmsLinkAttributes(link: CmsLink | undefined | null): LinkWithModalAttribute | undefined {
  if (!link || typeof link !== 'object') {
    return undefined;
  }

  const { linkTarget } = link || {};
  const target = linkTarget === HybrisLinkTarget.NewWindow ? LinkTarget.Blank : undefined;

  return {
    target,
    rel: target === LinkTarget.Blank ? 'noopener noreferrer' : undefined,
    href: !link.external && link.addContextPath ? addContextPathToUrl(link.url) : link.url,
    [MODAL_LINK_IDENTIFIER]: linkTarget === 'modal' || undefined,
  };
}
