<template>
  <div :class="b({ columnWidth: `col-sm-${width}` })" class="wysiwyg">
    <div :id="containerId" :class="b('wrapper')"></div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { PAGE_LANG } from '@/setup/i18n';
  import { ColumnWidth } from '@/components/c-row-container.vue';
  import useUuid, { Uuid } from '@/compositions/uuid';
  import loadScript from '@/helpers/load-script';
  import { FormNamespace } from '@/types/cms-newsletter-registration';

  type Setup = Uuid

  // interface Data {}

  /**
   * The newsletter registration component injects a 3rd party that creates a registration form.
   */
  export default defineComponent({
    name: 'c-newsletter-registration',

    // components: {},

    props: {
      /**
       * Expects the newsletter name.
       */
      newsletterName: {
        type: String,
        required: true,
      },

      /**
       * Expects the form namespace.
       */
      namespace: {
        type: String as PropType<FormNamespace>,
        required: true,
      },

      /**
       * Allows to set the column width.
       */
      width: {
        type: Number as PropType<ColumnWidth>,
        default: 12,
      },
    },
    // emits: {},

    setup(): Setup {
      return {
        ...useUuid(),
      };
    },
    // data(): Data {
    //   return {};
    // },

    computed: {
      /**
       * Returns a string including a unique id.
       */
      containerId(): string {
        return `${this.$options.name}__container--${this.uuid}`;
      },
    },
    // watch: {},
    beforeCreate() {
      loadScript('https://www.google.com/recaptcha/api.js', undefined, {
        async: true,
      });
    },

    mounted() {
      if (!this.namespace || !this.newsletterName || !this.containerId) {
        throw new Error('Newsletter endpoint not available');
      }

      const url = `https://newsletter.sfs.biz/p/de.pinuts.cmsbs.${this.namespace}.Widget/index?name=${this.newsletterName}&containerId=${this.containerId}&locale=${PAGE_LANG?.split('-')?.[0] || 'de'}`;

      loadScript(url);
    },

    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {}
  });
</script>

<style lang="scss">
  @use 'sass:math';
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-newsletter-registration {
    $this: &;

    @include mixins.media(sm) {
      grid-column: span 12;
    }

    .pi-widget {
      padding: 0;
    }

    .pi-form {
      .pi-form-label {
        @include mixins.font(variables.$font-size--16, variables.$spacing--20, variables.$font-weight--bold);
      }

      .pi-form-input input[type='text'] {
        @include mixins.font(variables.$font-size--18, variables.$line-height--18, variables.$font-weight--bold);

        position: relative;
        width: 100%;
        height: 28px;
        padding: 0;
        border: none;
        border-bottom: 2px solid variables.$color-grayscale--0;
        border-radius: 0;
        box-shadow: none;
        color: variables.$color-primary--1;
        font-family: variables.$font-family--primary;
        -webkit-appearance: none;
        -webkit-text-fill-color: initial;

        &:focus {
          border-bottom-color: variables.$color-primary--1;
        }

        &::placeholder {
          color: variables.$color-grayscale--0;
          font-weight: variables.$font-weight--regular;
        }
      }

      .pi-radio-control {
        line-height: initial;

        & > label {
          @include mixins.font(variables.$font-size--16, 20px);

          cursor: pointer;

          &::before,
          &::after {
            position: absolute;
            top: -0.1em;
            left: 0;
            content: '';
            width: 21px;
            height: 21px;
            border-radius: 50%;
          }

          &::before {
            margin: 0;
            border: 2px solid variables.$color-grayscale--0;
            box-shadow: none;
          }

          &::after {
            opacity: 0;
            background-color: variables.$color-primary--1;
            transform: scale(0.6);
          }
        }

        & > input:checked + label {
          &::before {
            border-color: variables.$color-grayscale--0;
            background: none;
          }

          &::after {
            opacity: 1;
          }
        }
      }

      .pi-form-control a.upload-delete-file {
        border-radius: unset;
        background-color: transparent;
        background-image: none;
        background-repeat: unset;
        background-position: unset;
      }

      .pi-checkbox-control-vertical {
        & > label {
          @include mixins.font(variables.$font-size--16, 20px);

          &::before {
            width: 1em;
            height: 1em;
            border: 2px solid variables.$color-grayscale--0;
            border-radius: 0;
            box-shadow: none;
          }
        }

        & > input:checked + label {
          &::before {
            border-color: variables.$color-primary--1;
            background-color: variables.$color-primary--1;
          }
        }
      }
    }

    .pi-form-button button {
      @include mixins.button--primary(2px, 24px);

      height: auto;
      border-radius: 0;
    }
  }
</style>
