<template>
  <div :class="b()">
    <div v-outside-click="{ handler: onOutsideClick, excludeIds: ['c-service-navigation__account-navigation-toggle'] }"
         :class="b('inner')"
    >
      <div :class="b('logged-in-user')">
        {{ $t('c-account-navigation.loggedInAs') }}
        <span :class="b('user-name')">
          {{ formattedUserName }}
        </span>
      </div>

      <ul v-for="(group, listIndex) in navigation"
          :key="listIndex"
          :class="b('link-list')"
          body-scroll-lock-ignore
      >
        <li v-for="(item, itemIndex) in group"
            :key="itemIndex"
            :class="b('item')"
        >
          <a v-bind="item.link"
             :class="b('link')"
          >
            <e-icon :icon="item.icon" size="40 22" />
            <span :class="b('label')">
              {{ item.label }}
              <span v-if="item.badgeAmount"
                    :class="b('badge-amount')"
              >
                {{ item.badgeAmount }}
              </span>
            </span>
          </a>
        </li>
      </ul>
      <slot name="extended-service-navigation"></slot>
    </div>
    <button :class="b('close-button')"
            :aria-label="$t('global.close')"
            type="button"
            @click="close"
    >
      <e-icon icon="i-close" size="18" />
    </button>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
  import eIcon from '@/elements/e-icon.vue';
  import useSessionStore from '@/stores/session';
  import mapAccountNavigation, { AccountNavigationItem } from '@/helpers/map-account-navigation';
  import { bodyScrollOptions } from '@/setup/options';

  interface Setup {
    sessionStore: ReturnType<typeof useSessionStore>;
  }

  // interface Data {}

  /**
   * This is an user account navigation component which renders a list of links with icons.
   */
  export default defineComponent({
    name: 'c-account-navigation',

    components: {
      eIcon,
    },

    // props: {},
    emits: {
      outsideClick: () => true,
      close: () => true,
    },

    setup(): Setup {
      return {
        sessionStore: useSessionStore(),
      };
    },
    // data(): Data {
    //   return {};
    // },

    computed: {
      navigation(): Array<AccountNavigationItem[]> {
        const mappedNavigation = mapAccountNavigation(this.sessionStore.dashboard, true);

        const accountHistoryGroup = [
          'i-wishlist',
          'i-digital-customer-card',
          'i-offer',
          'i-favorite',
          'i-order-history',
          'i-collective-order',
          'i-commission',
          'i-my-product',
          'i-return',
        ];

        const accountSettingsGroup = [
          'i-update-password',
          'i-profile',
          'i-users-2',
        ];

        const mappedAccountHistoryGroup = mappedNavigation.filter(item => accountHistoryGroup.includes(item.icon));
        const mappedAccountSettingsGroup = mappedNavigation.filter(item => accountSettingsGroup.includes(item.icon));

        return [mappedAccountHistoryGroup, mappedAccountSettingsGroup].filter(group => group.length);
      },

      /**
       * Returns the formatted username by capitalizing the first letter of each name.
       */
      formattedUserName(): string {
        const { firstName, lastName } = this.sessionStore.user;

        const formattedFirstName = firstName.charAt(0).toUpperCase();
        const formattedLastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);

        return `${formattedFirstName}. ${formattedLastName}`;
      },
    },

    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    mounted() {
      disableBodyScroll(this.$el, bodyScrollOptions);
    },

    beforeUnmount() {
      enableBodyScroll(this.$el);
    },
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      /**
       * Handles outside click.
       */
      onOutsideClick(): void {
        this.$emit('outsideClick');
      },

      /**
       * Emits an close event.
       */
      close(): void {
        this.$emit('close');
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';
  @use '@/setup/scss/mixins';

  .c-account-navigation {
    $this: &;

    @include mixins.layout();

    position: relative;
    display: flex;
    justify-content: flex-end;
    padding-top: 0;
    overflow: auto;

    @include mixins.media(sm) {
      padding-top: variables.$spacing--5;
    }

    &__inner {
      display: flex;
      gap: variables.$spacing--35;
      flex-direction: column;
      width: 100%;
      padding-block: variables.$spacing--40 variables.$spacing--20;
      padding-top: 0;
      overflow-y: auto;
      background-color: variables.$color-grayscale--1000;

      @include mixins.media(md) {
        width: 360px;
        padding: variables.$spacing--40 variables.$spacing--20;
      }
    }

    &__logged-in-user {
      @include mixins.z-index(front);

      position: sticky;
      top: 0;
      display: flex;
      flex-direction: column;
      padding-top: variables.$spacing--15;
      padding-bottom: variables.$spacing--5;
      border-bottom: 4px solid variables.$color-grayscale--0;
      background: variables.$color-grayscale--1000;

      @include mixins.media(md) {
        padding-top: 0;
      }
    }

    &__user-name {
      @include mixins.font(variables.$font-size--25, 27px, variables.$font-weight--bold);
    }

    &__link-list {
      display: flex;
      gap: variables.$spacing--20;
      flex-direction: column;
      padding-bottom: variables.$spacing--10;
      border-bottom: 2px solid variables.$color-grayscale--0;
    }

    &__item:last-child &__link {
      align-items: end;
    }

    &__item:last-child &__link &__label {
      padding-bottom: variables.$spacing--2;
      border-bottom: none;
    }

    &__link {
      @include mixins.font(
        variables.$font-size--18,
        variables.$line-height--18,
        variables.$font-weight--bold
      );

      display: flex;
      gap: variables.$spacing--20;

      &:hover,
      &:focus {
        color: variables.$color-primary--1;
      }
    }

    &__label {
      position: relative;
      width: 100%;
      padding-bottom: variables.$spacing--5;
      border-bottom: 2px dotted variables.$color-grayscale--0;
    }

    &__badge-amount {
      @include mixins.font(variables.$font-size--14, 16px, variables.$font-weight--bold);

      position: relative;
      top: 0;
      right: 0;
      align-self: flex-start;
      padding: variables.$spacing--2;
      background-color: variables.$color-primary--1;
      color: variables.$color-grayscale--1000;
    }

    &__close-button {
      display: none;

      @include mixins.media(sm) {
        position: absolute;
        top: variables.$spacing--25;
        right: variables.$spacing--40;
        display: block;
        cursor: pointer;
      }
    }

    &__icon {
      width: variables.$spacing--25;
      height: variables.$spacing--20;
    }
  }
</style>
