<template>
  <transition :name="b('transition', { slideTop: true })">
    <div v-if="sessionStore.isGlobalBackdropVisible" :class="b()"></div>
  </transition>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import useSessionStore from '@/stores/session';

  interface Setup {
    sessionStore: ReturnType<typeof useSessionStore>;
  }
  // interface Data {}

  /**
   * Renders a global backdrop over the page.
   */
  export default defineComponent({
    name: 'e-global-backdrop',

    // components: {},

    // props: {},
    // emits: {},

    setup(): Setup {
      return {
        sessionStore: useSessionStore(),
      };
    },
    // data(): Data {
    //   return {};
    // },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';
  @use '@/setup/scss/mixins';

  .e-global-backdrop {
    position: fixed;
    top: var(--header-height);
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(variables.$color-grayscale--0, 0.85);

    &__transition--slide-top-enter-active,
    &__transition--slide-top-leave-active {
      @include mixins.z-index(back);

      transition: transform variables.$transition-duration--300 ease;
    }

    &__transition--slide-top-enter-from,
    &__transition--slide-top-leave-to {
      transform: translateY(-100%);
    }
  }
</style>
