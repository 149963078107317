<template>
  <div v-if="cart" :class="b()">
    <dl :class="b('list')">
      <template v-if="showVocPrice && vocPrice && vocPrice?.value > 0">
        <dt :class="b('label')">
          {{ $t('c-checkout-totals.vocPrice') }}
        </dt>
        <dd v-price="vocPrice.value" :class="b('value')"></dd>
      </template>
      <template v-if="showVrgPrice && vrgPrice && vrgPrice?.value > 0">
        <dt :class="b('label')">
          {{ $t('c-checkout-totals.vrgPrice') }}
        </dt>
        <dd v-price="vrgPrice.value" :class="b('value')"></dd>
      </template>
      <template v-if="showSubTotal && subTotal">
        <dt :class="b('label', { subTotal: true, })">
          {{ $t('c-checkout-totals.subTotal') }}
        </dt>
        <dd v-price="subTotal.value" :class="b('value', { subTotal: true, })"></dd>
      </template>
      <template v-if="showCharges && charges">
        <dt :class="b('label')">
          {{ $t('c-checkout-totals.charges') }}
        </dt>
        <dd v-price="charges.value" :class="b('value')"></dd>
      </template>
      <template v-if="showPriceDifferences && priceDifferences">
        <dt :class="b('label')">
          {{ $t('c-checkout-totals.priceDifferences') }}
        </dt>
        <dd v-price="priceDifferences.value" :class="b('value')"></dd>
      </template>
      <template v-if="showDeliveryCost && deliveryCost">
        <dt :class="b('label')">
          {{ $t('c-checkout-totals.deliveryCost') }}
        </dt>
        <dd v-price="deliveryCost.value" :class="b('value')"></dd>
      </template>
      <template v-if="cart.showTax && taxIncluded && showTotalTax && totalTax">
        <dt :class="b('label')">
          {{ $t('c-checkout-totals.totalTax') }}
        </dt>
        <dd v-price="totalTax.value" :class="b('value')"></dd>
      </template>
      <template v-if="showTotalPrice && totalPrice">
        <dt :class="b('label', { totalPrice: true, })">
          {{ $t('c-checkout-totals.totalPrice') }}
        </dt>
        <dd v-price.prefixCurrency="totalPrice.value" :class="b('value', { totalPrice: true, })"></dd>
      </template>
    </dl>
    <div :class="b('tax-info')">
      {{ taxIncluded ? $t('global.taxIncluded') : $t('global.taxExcluded') }}
    </div>
    <dl v-if="cart.showTax && !taxIncluded && showTotalTax && totalTax" :class="b('list')">
      <dt :class="b('label')">
        {{ $t('c-checkout-totals.totalTax') }}
      </dt>
      <dd v-price="totalTax.value" :class="b('value')"></dd>
    </dl>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import useCheckoutStore from '@/stores/checkout';
  import { Price } from '@/types/price';
  import { Cart } from '@/types/cart';

  interface Setup {
    checkoutStore: ReturnType<typeof useCheckoutStore>;
  }
  // interface Data {}

  /**
   * Renders the checkout total prices.
   */
  export default defineComponent({
    name: 'c-checkout-totals',

    // components: {},

    props: {
      /**
       * Expects the total price.
       * Total price given from outside because different properties can be used.
       */
      totalPrice: {
        type: Object as PropType<Price>,
        default: null,
      },

      /**
       * Allows defining whether to show the VOC price.
       */
      showVocPrice: {
        type: Boolean,
        default: true,
      },

      /**
       * Allows defining whether to show the vRG price.
       */
      showVrgPrice: {
        type: Boolean,
        default: true,
      },

      /**
       * Allows defining whether to show the subtotal.
       */
      showSubTotal: {
        type: Boolean,
        default: true,
      },

      /**
       * Allows defining whether to show the charges.
       */
      showCharges: {
        type: Boolean,
        default: true,
      },

      /**
       * Allows defining whether to show the price differences.
       */
      showPriceDifferences: {
        type: Boolean,
        default: true,
      },

      /**
       * Allows defining whether to show the delivery costs.
       */
      showDeliveryCost: {
        type: Boolean,
        default: true,
      },

      /**
       * Allows defining whether to show the total tax.
       */
      showTotalTax: {
        type: Boolean,
        default: true,
      },

      /**
       * Allows defining whether to show the total price.
       */
      showTotalPrice: {
        type: Boolean,
        default: true,
      },

      /**
       * Allows defining if VAT is included in total price.
       */
      taxIncluded: {
        type: Boolean,
        default: true,
      },
    },
    // emits: {},

    setup(): Setup {
      return {
        checkoutStore: useCheckoutStore(),
      };
    },
    // data(): Data {
    //   return {};
    // },

    computed: {
      /**
       * Returns the cart object.
       */
      cart(): Cart | undefined {
        return this.checkoutStore.cart;
      },

      /**
       * Returns the VOC price.
       */
      vocPrice(): Price | undefined {
        return this.cart?.vocPrice;
      },

      /**
       * Returns the vRG price.
       */
      vrgPrice(): Price | undefined {
        return this.cart?.vrgPrice;
      },

      /**
       * Returns the vRG subtotal.
       */
      subTotal(): Price | undefined {
        return this.cart?.subTotal;
      },

      /**
       * Returns the charges.
       */
      charges(): Price | undefined {
        return this.cart?.charges;
      },

      /**
       * Returns the price differences.
       */
      priceDifferences(): Price | undefined {
        return this.cart?.priceDifferences;
      },

      /**
       * Returns the delivery cost.
       */
      deliveryCost(): Price | undefined {
        return this.cart?.deliveryCost;
      },

      /**
       * Returns the total tax.
       */
      totalTax(): Price | undefined {
        return this.cart?.totalTax;
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';
  @use '@/setup/scss/mixins';

  .c-checkout-totals {
    @include mixins.media(xs) {
      width: auto;
      min-width: 350px;
    }

    &__list {
      display: grid;
      grid-gap: variables.$spacing--5 variables.$spacing--20;
      grid-template-columns: 1fr min-content;
    }

    &__value {
      text-align: right;
      white-space: nowrap;
    }

    &__label--sub-total,
    &__value--sub-total {
      font-weight: variables.$font-weight--bold;
    }

    &__label--total-price,
    &__value--total-price {
      @include mixins.font(variables.$font-size--25, variables.$line-height--30, variables.$font-weight--bold);

      margin-top: variables.$spacing--20;
    }

    &__tax-info {
      @include mixins.font(variables.$font-size--16);

      text-align: right;
    }
  }
</style>
