<template>
  <div :class="b()" :style="style">
    <c-slider v-if="!$viewport.isSm && sliderOnMobile"
              :variant="SliderVariant.RowContainer"
              :navigation="false"
    >
      <li v-for="(component, index) in components"
          :key="index"
          :class="b('slide')"
      >
        <component v-bind="component.attributes"
                   :is="component.name"
        />
      </li>
    </c-slider>
    <component v-else
               v-for="(component, index) in components"
               v-bind="component.attributes"
               :is="component.name"
               :key="index"
    />
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import cMarketingParagraph from '@/components/c-marketing-paragraph.vue';
  import cYoutubeVideo, { YoutubeVideoProps } from '@/components/c-youtube-video.vue';
  import cContactPerson, { ContactPersonProps } from '@/components/c-contact-person.vue';
  import cNewsletterRegistration from '@/components/c-newsletter-registration.vue';
  import cOxomiPortalPage, { OxomiPortalPageProps } from '@/components/c-oxomi-portal-page.vue';
  import cOxomiEmbedCatalog, { OxomiEmbedCatalogProps } from '@/components/c-oxomi-embed-catalog.vue';
  import { MarketingParagraphProps } from '@/helpers/map-cms-marketing-paragraph-props';
  import { NewsletterRegistrationProps } from '@/helpers/map-newsletter-registration-props';
  import cSlider, { SliderVariant } from '@/components/c-slider.vue';
  import cM2mCalculator from '@/components/c-m2m-calculator.vue';

  // interface Data {}

  export enum VueComponent {
    MarketingParagraph = 'c-marketing-paragraph',
    RowContainer = 'c-row-container',
    AccordionGroup = 'c-accordion-group',
    AccordionEntry = 'c-accordion-entry',
    YoutubeVideo = 'c-youtube-video',
    NewsletterRegistration = 'c-newsletter-registration',
    ContactPerson = 'c-contact-person',
    OxomiPortalPage = 'c-oxomi-portal-page',
    OxomiEmbedCatalog = 'c-oxomi-embed-catalog',
    M2mCaclulator = 'c-m2m-calculator',
  }

  export enum ColumnWidth {
    Col6 = 6,
    Col4 = 4,
    Col12 = 12,
  }

  export interface RowComponent {
    name: VueComponent;
    attributes?: MarketingParagraphProps
      | YoutubeVideoProps
      | NewsletterRegistrationProps
      | ContactPersonProps
      | OxomiPortalPageProps
      | OxomiEmbedCatalogProps;
  }

  type Setup = {
    SliderVariant: typeof SliderVariant;
  }

  type Style = {
    marginBottom: string;
  }

  /**
   *  This component is an abstract CMS component which renders its children.
   *  You need to register every component than can be delivered from the backend.
   */
  export default defineComponent({
    name: 'c-row-container',

    components: {
      cSlider,
      cMarketingParagraph,
      cYoutubeVideo,
      cNewsletterRegistration,
      cContactPerson,
      cOxomiPortalPage,
      cOxomiEmbedCatalog,
      cM2mCalculator,
    },

    props: {
      /**
       * Accepts a list of row supported CMS components.
       */
      components: {
        type: Array as PropType<RowComponent[]>,
        required: true,
      },

      /**
       * Allows passing the bottom spacing.
       */
      marginBottom: {
        type: Number,
        default: undefined,
      },

      /**
       * Allows enabling slider on mobile viewports.
       */
      sliderOnMobile: {
        type: Boolean,
        default: false,
      },
    },

    // emits: {},

    setup(): Setup {
      return {
        SliderVariant,
      };
    },
    // data(): Data {
    //   return {};
    // },

    computed: {
      style(): Style | undefined {
        if (this.marginBottom) {
          return {
            marginBottom: `${this.marginBottom}px`,
          };
        }

        return undefined;
      },
    },
    // watch: {},
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';
  @use '@/setup/scss/mixins';

  .c-row-container {
    display: grid;
    row-gap: variables.$spacing--40;

    @include mixins.media(sm) {
      grid-template-columns: repeat(12, 1fr);
      gap: variables.$spacing--40 variables.$spacing--25;
    }

    &__slide {
      flex: 0 0 100%;
    }
  }
</style>
