import { BrowserHelper } from 'scandit-sdk';

/**
 * Checks whether the current device is supported by Scandit and if it is not a desktop device
 * and if scandit is enabled for the platform.
 */
export default function isValidDeviceForBarcodeScanner(): boolean {
  if (import.meta.env.VITE_ENABLE_SCANDIT !== '1') {
    return false;
  }

  return BrowserHelper.checkBrowserCompatibility().scannerSupport && !BrowserHelper.isDesktopDevice();
}
