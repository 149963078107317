<template>
  <ul :class="b({ isLoading, hasSpecialPrice })">
    <li v-if="internalShowListPrice"
        :class="b('item', { listPrice: true })"
    >
      {{ formatPrice(product.price.value, true) }}
    </li>
    <li :class="b('item', { price: true })">
      <span v-if="showDiscountGroup && discountGroup" :class="b('discount-group')">
        {{ discountGroup }}
      </span>
      <e-icon v-if="hasSpecialPrice"
              :class="b('special-price-indicator')"
              :alt="$t('global.productStock.specialPrice')"
              icon="i-discount"
              size="22"
      />
      <span v-if="pricePrefix" :class="b('price-prefix')">
        {{ pricePrefix }}
      </span>
      {{ formattedPrice }}
      <button v-if="showVolumePriceModalButton"
              v-tooltip:top="$t('global.volumePrices')"
              :class="b('volume-price-modal-button')"
              :aria-label="$t('global.volumePrices')"
              type="button"
              @click="showVolumePriceModal"
      >
        <e-icon icon="i-info" size="18" />
      </button>
    </li>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <li v-if="info.length" v-html="info" :class="b('item', { info: true })"></li>
  </ul>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { PriceType } from '@/setup/globals';
  import useCustomerPriceStore from '@/stores/customer-price';
  import useSessionStore from '@/stores/session';
  import eIcon from '@/elements/e-icon.vue';
  import { state as volumePriceModalState } from '@/components/c-volume-price-modal.vue';
  import formatPrice from '@/helpers/format-price';
  import getBasePriceUnit from '@/helpers/get-base-price-unit';
  import tooltipDirective from '@/plugins/tooltip/directives/directive';
  import { Product } from '@/types/product';
  import { Price } from '@/types/price';

  interface Setup {
    customerPriceStore: ReturnType<typeof useCustomerPriceStore>;
    sessionStore: ReturnType<typeof useSessionStore>;
    formatPrice: typeof formatPrice;
  }

  // interface Data {}

  /**
   * Renders the detailed price of a product.
   */
  export default defineComponent({
    name: 'c-product-price-detailed',

    components: { eIcon },

    directives: {
      tooltip: tooltipDirective,
    },

    props: {
      /**
       * Expects a product object.
       */
      product: {
        type: Object as PropType<Product>,
        required: true,
      },

      /**
       * Allows defining whether to show the list price.
       */
      showListPrice: {
        type: Boolean,
        default: true,
      },

      /**
       * Allows defining whether to show the tax info.
       */
      showTaxInfo: {
        type: Boolean,
        default: true,
      },

      /**
       * Allows defining whether to show the unit.
       */
      showBasePriceUnit: {
        type: Boolean,
        default: true,
      },

      /**
       * Allows defining whether to show the discount group.
       */
      showDiscountGroup: {
        type: Boolean,
        default: false,
      },
    },
    // emits: {},

    setup(): Setup {
      return {
        customerPriceStore: useCustomerPriceStore(),
        sessionStore: useSessionStore(),
        formatPrice,
      };
    },
    // data(): Data {},

    computed: {
      internalShowListPrice(): boolean {
        if (!this.showListPrice) {
          return false;
        }

        if (!this.product.price) {
          return false;
        }

        if (!(this.hasSpecialPrice || this.hasCustomerPrice)) {
          return false;
        }

        return !this.sessionStore.getShowOnlyRecommendedRetailPrice;
      },

      formattedPrice(): string {
        const { price } = this;

        if (!price) {
          return '';
        }

        const prefix = price.priceType === PriceType.From
          ? `${this.$t('global.price.fromPricePrefix')} `
          : '';

        return `${prefix}${formatPrice(price.value, true)}`;
      },

      customerPrice(): Price | undefined {
        return this.customerPriceStore.getCustomerPriceByProduct(this.product);
      },

      pricePrefix(): string | undefined {
        return this.sessionStore.getShowOnlyRecommendedRetailPrice
          ? this.$t('global.price.recommendedRetailPrice')
          : undefined;
      },

      price(): Price | undefined {
        const { price, specialPrice, recommendedRetailPrice } = this.product;

        if (this.sessionStore.getShowOnlyRecommendedRetailPrice) {
          return recommendedRetailPrice || undefined;
        }

        return this.customerPrice || specialPrice || price || undefined;
      },

      hasCustomerPrice(): boolean {
        return !!this.customerPrice;
      },

      hasSpecialPrice(): boolean {
        return !!this.product.specialPrice;
      },

      isLoading(): boolean {
        return this.customerPriceStore.queue.some(product => product.code === this.product.code);
      },

      basePriceUnit(): string | null {
        return getBasePriceUnit(this.product.price);
      },

      info(): string {
        const { basePriceUnit } = this;
        const { vrgPrice, vocPrice } = this.product;
        const items: string[] = [];

        if (this.showBasePriceUnit && basePriceUnit) {
          items.push(basePriceUnit);
        }

        if (vrgPrice) {
          items.push(`${this.$t('global.price.vrgIncluded')} ${formatPrice(vrgPrice.value, true)}`);
        }

        if (vocPrice) {
          items.push(`${this.$t('global.price.vocIncluded')} ${formatPrice(vocPrice.value, true)}`);
        }

        if (this.showTaxInfo) {
          const taxInfo = this.product.taxIncluded || this.sessionStore.getShowOnlyRecommendedRetailPrice
            ? this.$t('global.price.taxInfoTaxIncluded')
            : this.$t('global.price.taxInfoTaxExcluded');

          items.push(taxInfo);
        }

        if (import.meta.env.VITE_SHOW_DELIVERY_COST_EXCLUDED_PRICE_INFO === '1') {
          items.push(this.$t('global.price.deliveryCostExcluded'));
        }

        return items.join(' / ');
      },

      discountGroup(): string | null {
        const { discountGroup } = this.product;

        if (!discountGroup && import.meta.env.VITE_SHOW_PRODUCT_DISCOUNT_GROUP !== '1') {
          return null;
        }

        return this.$t('global.price.discountGroup', { discountGroup });
      },

      showVolumePriceModalButton(): boolean {
        const { volumePrices } = this.product;

        return !!volumePrices && volumePrices.length > 1;
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      showVolumePriceModal(): void {
        volumePriceModalState.product = this.product;
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-product-price-detailed {
    display: grid;
    justify-self: end;
    text-align: right;

    &__item--price {
      @include mixins.font(variables.$font-size--25, variables.$line-height--30, variables.$font-weight--bold);

      display: flex;
      justify-content: flex-end;
      align-items: baseline;
      column-gap: variables.$spacing--5;
    }

    &__item--list-price {
      @include mixins.font(variables.$font-size--18, variables.$line-height--20, variables.$font-weight--bold);

      color: variables.$color-grayscale--400;
    }

    &__item--info {
      @include mixins.font(variables.$font-size--14);
    }

    &__discount-group {
      @include mixins.font(variables.$font-size--14, variables.$line-height--18, variables.$font-weight--regular);
    }

    &__volume-price-modal-button {
      cursor: pointer;

      &:hover,
      &:focus {
        color: variables.$color-primary--1;
      }
    }

    &__special-price-indicator {
      display: inline-block;
      transform: translateY(0.1em);
      color: variables.$color-primary--1;
    }

    &--has-special-price &__item--list-price {
      text-decoration: line-through;
    }

    &--is-loading &__item--price {
      color: variables.$color-grayscale--400;
    }
  }
</style>
