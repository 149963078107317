import { defineStore } from 'pinia';
import { AxiosPromise, AxiosResponse } from 'axios';
import { Store } from '@/setup/globals';
import { Product, VariantsResult } from '@/types/product';
import { ProductNavigation } from '@/types/product-navigation';
import { PreviousNextProductApiResponse } from '@/styleguide/mock-data/api-response/previous-next-product';

export enum ProductScopeIdentifier {
  DETAIL = 'detail',
  VARIANTS_MODAL = 'variantsModal',
}

type ProductScope = {
  product?: Product;
  variantsResult?: VariantsResult;
  previousProduct?: ProductNavigation;
  nextProduct?: ProductNavigation;
}

type State = {
  scope: {
    [key: string]: ProductScope;
  };
  showPrices: boolean;
  runningRequests: {
    apiLoadFilteredProductVariants: boolean;
    apiLoadProductVariantDetail: boolean;
    apiFetchProductSliderTabs: boolean;
    apiFetchProductDetail: boolean;
  };
}

export type InitialData = {
  data: {
    product?: Product;
    variantsResult?: VariantsResult;
    showPrices: boolean;
  };
}

const storeName = Store.ProductDetail;

export default defineStore(
  storeName,
  {
    state: (): State => {
      const initialData = window.initialData?.[storeName];
      const { data } = initialData || {};
      const scope: { [key: string]: ProductScope } = {};

      if (data) {
        scope[ProductScopeIdentifier.DETAIL] = {
          product: data?.product,
          variantsResult: data?.variantsResult,
        };
      }

      return {
        scope,
        showPrices: !!data?.showPrices,
        runningRequests: {
          apiLoadFilteredProductVariants: false,
          apiLoadProductVariantDetail: false,
          apiFetchProductSliderTabs: false,
          apiFetchProductDetail: false,
        },
      };
    },
    getters: {
      getProduct:
          state => (scope: ProductScopeIdentifier): Product | undefined => state.scope[scope]?.product,
      getVariantResult:
          state => (scope: ProductScopeIdentifier): VariantsResult | undefined => {
            const { variantsResult, product } = state.scope[scope] || {};

            if (!variantsResult) {
              return undefined;
            }

            if (product) {
              return {
                ...variantsResult,
                results: variantsResult.results.map((variant) => {
                  if (variant.code === product.code) {
                    return {
                      ...product,
                      classifications: product.variantClassifications,
                    };
                  }

                  return variant;
                }),
              };
            }

            return variantsResult;
          },
      getPreviousProduct:
          state => (scope: ProductScopeIdentifier): ProductNavigation | undefined => state.scope[scope]?.previousProduct,
      getNextProduct:
          state => (scope: ProductScopeIdentifier): ProductNavigation | undefined => state.scope[scope]?.nextProduct,
    },
    actions: {
      apiFetchPreviousAndNextProduct(scopeIdentifier: ProductScopeIdentifier = ProductScopeIdentifier.DETAIL): AxiosPromise {
        const apiUrl = this.$api.getApiUrl('previousNextProduct', {
          productCode: this.getProduct(scopeIdentifier)?.code || '',
        });

        return this.$api.get(apiUrl)
          .then((response: AxiosResponse<PreviousNextProductApiResponse>) => {
            const { data } = response.data;

            if (!data) {
              return Promise.reject(new Error(`Invalid response for "${storeName}/apiFetchPreviousAndNextProduct".`));
            }

            this.scope[scopeIdentifier].nextProduct = data.next;
            this.scope[scopeIdentifier].previousProduct = data.previous;

            return response;
          });
      },

      apiLoadFilteredProductVariants(apiUrl: string, scopeIdentifier: ProductScopeIdentifier): AxiosPromise {
        this.runningRequests.apiLoadFilteredProductVariants = true;
        const scope = this.scope[scopeIdentifier];

        return this.$api.get(apiUrl)
          .then((response) => {
            const { variantsResult } = response.data?.data || {};

            if (!variantsResult) {
              return Promise.reject(new Error(`Invalid response for "${storeName}/apiLoadFilteredProductVariants".`));
            }

            if (!variantsResult.pagination.currentPage || !scope.variantsResult) {
              scope.variantsResult = variantsResult;
            } else {
              scope.variantsResult = {
                ...variantsResult,
                results: [
                  ...scope.variantsResult.results,
                  ...variantsResult.results,
                ],
              };
            }

            return response;
          })
          .finally(() => {
            this.runningRequests.apiLoadFilteredProductVariants = false;
          });
      },

      apiLoadProductVariantDetail(apiUrl: string, scopeIdentifier: ProductScopeIdentifier): AxiosPromise {
        this.runningRequests.apiLoadProductVariantDetail = true;
        const scope = this.scope[scopeIdentifier];

        return this.$api.get(apiUrl)
          .then((response) => {
            const { product } = response.data.data || {};

            if (!product) {
              return Promise.reject(new Error(`Invalid response for "${storeName}/apiLoadProductVariantDetail".`));
            }

            scope.product = product;

            return response;
          })
          .finally(() => {
            this.runningRequests.apiLoadProductVariantDetail = false;

            this.apiFetchPreviousAndNextProduct(scopeIdentifier);
          });
      },

      apiFetchProductSliderTabs(apiUrl: string): AxiosPromise {
        this.runningRequests.apiFetchProductSliderTabs = true;

        return this.$api.get(apiUrl).then((response) => {
          if (Array.isArray(response.data?.data?.tabs)) {
            return response;
          }

          return Promise.reject(new Error(`Invalid response for "${storeName}/apiFetchProductSliderTabs".`));
        }).finally(() => {
          this.runningRequests.apiFetchProductSliderTabs = false;
        });
      },

      apiFetchProductDetail(productCode: string, scopeIdentifier: ProductScopeIdentifier): AxiosPromise {
        this.runningRequests.apiFetchProductDetail = true;

        if (!this.scope[scopeIdentifier]) {
          this.scope[scopeIdentifier] = {};
        }

        const scope = this.scope[scopeIdentifier];

        return this.$api.get(this.$api.getApiUrl('product', { productCode })).then((response) => {
          const { product, variantsResult } = response.data.data || {};

          if (!product) {
            return Promise.reject(new Error(`Invalid response for "${storeName}/apiFetchProductDetail".`));
          }

          scope.product = product;
          scope.variantsResult = variantsResult;

          return response;
        }).finally(() => {
          this.runningRequests.apiFetchProductDetail = false;
        });
      },
    },
  }
);
