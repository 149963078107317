import { VueComponent, RowComponent } from '@/components/c-row-container.vue';
import { HybrisType } from '@/setup/globals';
import mapHybrisRowContainerComponents from './map-hybris-row-container-components';
import { CmsRowContainer } from '@/types/cms-row-container';

export interface AccordionEntry {
  name: VueComponent.RowContainer;
  attributes: {
    components: RowComponent[];
  };
}

/**
 * Creates an array with mapped accordion entries.
 */
export default function mapAccordionEntryComponents(cmsComponents: CmsRowContainer[]): AccordionEntry[] {
    return cmsComponents.reduce((acc, component) => {
      switch (component.type) {
        case HybrisType.RowContainer:
          acc.push({
            name: VueComponent.RowContainer,
            attributes: {
              components: mapHybrisRowContainerComponents(component),
            },
          });

          break;

        // no default
      }

      return acc;
    }, [] as AccordionEntry[]);
}

