<template>
  <div :class="b()">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <h3 v-html="headline"></h3>
    <c-collapse-group :one-open="false"
                      :class="b('group')"
    >
      <c-collapse v-for="(entry, entryIndex) in entries"
                  :key="`${entryIndex}-${entry.headline}`"
                  :title="entry.headline"
      >
        <component v-for="(component, indexComponents) in entry.cmsComponents"
                   v-bind="component.attributes"
                   :is="component.name"
                   :key="indexComponents"
        />
      </c-collapse>
    </c-collapse-group>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import cCollapseGroup from '@/components/c-collapse-group.vue';
  import cCollapse from '@/components/c-collapse.vue';
  import cRowContainer from '@/components/c-row-container.vue';
  import { AccordionGroupEntry } from '@/helpers/map-accordion-group-entry';

  // interface Setup {}

  // interface Data {}

  /**
   * Renders an accordion.
   *
   * **WARNING: uses 'v-html' for the 'headline'. Make sure, that the source for this data is trustworthy.**
   */
  export default defineComponent({
    name: 'c-accordion',

    components: {
      cCollapse,
      cCollapseGroup,
      cRowContainer,
    },

    props: {
      /**
       * Expects a headline.
       */
      headline: {
        type: String,
        required: true,
      },

      /**
       * Expects a list of accordion group entries.
       */
      entries: {
        type: Array as PropType<AccordionGroupEntry[]>,
        required: true,
      },
    },
    // emits: {},

    // setup(): Setup {},
    // data(): Data {
    //   return {};
    // },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  // @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-accordion {
    &__group {
      margin-bottom: variables.$spacing--30;
    }
  }
</style>
