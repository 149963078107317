import { Classification, ClassificationFeature, Product } from '@/types/product';

export default function getFeaturesByProduct(
  product: Product,
  includeClassifications = true,
  includeVariantClassifications = true
): ClassificationFeature[] {
  const classifications: Classification[] = [];

  if (includeClassifications && product.classifications?.length) {
    classifications.push(...product.classifications);
  }

  if (includeVariantClassifications && product.variantClassifications?.length) {
    classifications.push(...product.variantClassifications);
  }

  return classifications.reduce((accumulator, classification) => {
    classification.features.forEach((feature) => {
      if (feature.name) {
        accumulator.push(feature);
      }
    });

    return accumulator;
  }, [] as ClassificationFeature[]);
}
