<template>
  <div v-if="entry.product" :class="b()">
    <div :class="b('head')">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <a v-html="entry.product.name"
         :href="addContextPathToUrl(entry.product.url)"
         :class="b('product-name')"
      ></a>
      <button :class="b('button-remove')"
              :aria-label="$t('c-mini-cart-entry.buttonRemove')"
              :disabled="isRequestRunning"
              type="button"
              @click="onClickRemove"
      >
        <e-icon icon="i-trash" size="15" />
      </button>
    </div>
    <e-picture v-if="picture"
               v-bind="picture"
               :ratio="1"
               :class="b('picture')"
    />
    <div :class="b('quantity-and-price')">
      <div :class="b('quantity')">
        <e-quantity-select v-model="quantity"
                           :step-size="entry.product.roundingUnitFactor"
                           :is-loading="isRequestRunning"
                           :min="0"
                           :class="b('quantity-select')"
                           @enter="onUpdateQuantity"
                           @update:model-value="onUpdateQuantity"
        />
        <div :class="b('order-unit')">
          {{ entry.product.orderUnit }}
        </div>
      </div>
      <template v-if="checkoutStore.cart?.showPrices">
        <div v-if="entry.basePrice"
             v-price="entry.basePrice.value"
             :class="b('price')"
        ></div>
        <div :class="b('tax-info')">
          {{ entry.taxIncluded ? $t('global.taxIncluded') : $t('global.taxExcluded') }}
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { OrderEntry } from '@/types/order-entry';
  import useCheckoutStore from '@/stores/checkout';
  import ePicture from '@/elements/e-picture.vue';
  import eIcon from '@/elements/e-icon.vue';
  import eQuantitySelect from '@/elements/e-quantity-select.vue';
  import addContextPathToUrl from '@/helpers/add-context-path-to-url';
  import mapProductImagesSrcSet from '@/helpers/map-product-images-srcset';
  import { ImageSrcset } from '@/types/image';

  interface Setup {
    checkoutStore: ReturnType<typeof useCheckoutStore>;
    addContextPathToUrl: typeof addContextPathToUrl;
  }

  interface Data {
    quantity: number;
    runningRequests: {
      apiUpdateCartEntry: boolean;
      apiRemoveCartEntry: boolean;
    };
    debounceTimeoutId: ReturnType<typeof setTimeout> | null;
  }

  /**
   * Renders a mini cart entry.
   *
   * **WARNING: uses 'v-html' for the `entry.product.name`. Make sure, that the source for this data is trustworthy.**
   */
  export default defineComponent({
    name: 'c-mini-cart-entry',

    components: {
      eQuantitySelect,
      ePicture,
      eIcon,
    },

    props: {
      /**
       * Expects an order entry object to be passed.
       */
      entry: {
        type: Object as PropType<OrderEntry>,
        required: true,
      },
    },
    // emits: {},

    setup(): Setup {
      return {
        addContextPathToUrl,
        checkoutStore: useCheckoutStore(),
      };
    },

    data(): Data {
      return {
        quantity: this.entry.quantity,
        runningRequests: {
          apiUpdateCartEntry: false,
          apiRemoveCartEntry: false,
        },
        debounceTimeoutId: null,
      };
    },

    computed: {
      /**
       * Returns whether a request is currently running.
       */
      isRequestRunning(): boolean {
        return Object.values(this.runningRequests).some(Boolean);
      },

      /**
       * Returns the product picture.
       */
      picture(): ImageSrcset | null {
        const { images } = this.entry.product;

        if (!images) {
          return null;
        }

        return mapProductImagesSrcSet(images);
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      /**
       * Handles update from quantity select.
       */
      onUpdateQuantity(): void {
        if (this.quantity === this.entry.quantity) {
          return;
        }

        if (this.quantity === 0) {
          this.onClickRemove();

          return;
        }

        if (this.debounceTimeoutId) {
          clearTimeout(this.debounceTimeoutId);
        }

        this.debounceTimeoutId = setTimeout(this.updateQuantity, 300);
      },

      /**
       * Sends the request to update the quantity of the entry.
       */
      updateQuantity(): void {
        this.runningRequests.apiUpdateCartEntry = true;
        this.checkoutStore.apiUpdateCartEntry({
          commissionId: this.entry.commissionId || '',
          entryNumber: this.entry.entryNumber,
          product: {
            code: this.entry.product.code,
          },
          quantity: this.quantity,
        }).finally(() => {
          this.runningRequests.apiUpdateCartEntry = false;
        });
      },

      /**
       * Removes the cart entry.
       */
      onClickRemove(): void {
        this.runningRequests.apiRemoveCartEntry = true;
        this.checkoutStore.apiRemoveCartEntry({
          commissionId: this.entry.commissionId || '',
          entryNumber: this.entry.entryNumber,
          product: {
            code: this.entry.product.code,
          },
          quantity: 0,
        }).finally(() => {
          this.runningRequests.apiRemoveCartEntry = false;
        });
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-mini-cart-entry {
    display: grid;
    grid-gap: variables.$spacing--10 variables.$spacing--30;
    grid-template-columns: 135px 1fr;

    &__head {
      display: flex;
      grid-column: span 2;
      justify-content: space-between;
      align-items: baseline;
      column-gap: variables.$spacing--10;
    }

    &__quantity-and-price {
      align-self: end;
    }

    &__button-remove {
      padding: 0 0 variables.$spacing--10 variables.$spacing--10;
      cursor: pointer;
    }

    &__product-name {
      @include mixins.font(variables.$font-size--20, variables.$line-height--25);
    }

    &__quantity {
      margin-bottom: variables.$spacing--10;
    }

    &__quantity-select {
      margin-bottom: variables.$spacing--5;
    }

    &__order-unit {
      @include mixins.font(variables.$font-size--16);

      text-align: right;
    }

    &__price {
      @include mixins.font(variables.$font-size--25, null, variables.$font-weight--bold);

      text-align: right;
    }

    &__tax-info {
      @include mixins.font(variables.$font-size--16);

      text-align: right;
    }
  }
</style>
