<template>
  <div :class="b()" class="wysiwyg">
    <!-- eslint-disable vue/no-v-text-v-html-on-component, vue/no-v-html -->
    <component v-html="text"
               :is="headingTag || 'span'"
    />
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { Headline } from '@/types/headline';

  interface Setup {
    text: string;
    headingTag: string;
  }

  // interface Data {}

  /**
   * A headline component which can be implemented as block element via the CMS.
   */
  export default defineComponent({
    name: 'c-cms-headline',

    // components: {},

    props: {
      /**
       * Retrieves data object with headline properties.
       */
      data: {
        type: Object as PropType<Headline>,
        required: true,
      },
    },
    // emits: {},

    setup(props): Setup {
      const { data } = props.data || {};

      return {
        text: data.text,
        headingTag: data.type,
      };
    },

    // data(): Data {
    //   return {};
    // },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>
