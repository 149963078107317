<template>
  <div :class="b()">
    <slot></slot>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ComponentPublicInstance, reactive } from 'vue';

  export interface CollapseGroup {

    /**
     * Holds the component instance of a group.
     */
    component: ComponentPublicInstance;

    /**
     * Holds the open collapse component instance of a group.
     */
    openCollapse: ComponentPublicInstance | null;

    /**
     * Defines if only one item can be open at the time.
     */
    oneOpen: boolean;
  }

  export const cCollapseGroups: CollapseGroup[] = reactive([]);

  /**
   * Wrapper for multiple (c-collapse) items.
   */
  export default defineComponent({
    name: 'c-collapse-group',

    // components: {},
    // mixins: [],

    props: {
      /**
       * Defines if items can be open simultaneously.
       */
      oneOpen: {
        type: Boolean,
        default: true,
      },
    },

    // setup(): Setup {},
    // data() {
    //   return {};
    // },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    created() {
      cCollapseGroups.push({
        component: this,
        openCollapse: null,
        oneOpen: this.oneOpen,
      });
    },
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeDestroy() {},
    // destroyed() {},

    // methods: {},
    // render() {},
  });
</script>
