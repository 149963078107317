import { defineStore } from 'pinia';
import { AxiosPromise } from 'axios';
import { Store, AdditionalStockInfo } from '@/setup/globals';
import { Product } from '@/types/product';
import { Stock } from '@/types/stock';
import { PosStock } from '@/types/pos-stock';

type FetchSubstituteProductsPayload = Pick<Product, 'code'>[];

export interface ProductStockInformationResult {
  anyVariantIsInLiquidation: boolean;
  inLiquidation: boolean;
  stock: Stock;
  posStock: PosStock[];
  additionalStockInfo?: AdditionalStockInfo;
}

export interface ProductStockModal {
  product: Product;
  productStockInformationResult?: ProductStockInformationResult;
}

interface RunningRequests {
  apiGetProductStock: boolean;
  apiFetchSubstituteProducts: boolean;
}

interface State {
  productStockModal: ProductStockModal | null;
  substituteProductsModalProduct?: Product;
  runningRequests: RunningRequests;
}

const storeName = Store.ProductStock;

export default defineStore(storeName, {
  state: () => {
    const state: State = {
      productStockModal: null,
      runningRequests: {
        apiGetProductStock: false,
        apiFetchSubstituteProducts: false,
      },
    };

    return state;
  },

  getters: {
    getIsProductStockModalEnabled(): boolean {
      return import.meta.env.VITE_ENABLE_STOCK_MODAL === '1';
    },
  },

  actions: {

    /**
     * Fetches the detail availability information for a product.
     */
    apiGetProductStock(product: Product): AxiosPromise {
      this.runningRequests.apiGetProductStock = true;

      return this.$api.get(this.$api.getApiUrl('productStock', { productCode: `${product.code}` })).then((response) => {
        const { stock, posStock } = response.data?.data || {};

        if (stock && Array.isArray(posStock)) {
          return response;
        }

        return Promise.reject(new Error('API failure during "product-stock/apiGetProductStock" request.'));
      }).finally(() => {
        this.runningRequests.apiGetProductStock = false;
      });
    },

    apiFetchSubstituteProducts(payload: FetchSubstituteProductsPayload): AxiosPromise {
      this.runningRequests.apiFetchSubstituteProducts = true;

      return this.$api.post(this.$api.getApiUrl('productComparison'), payload).then((response) => {
        const { results } = response.data?.data || {};

        if (Array.isArray(results)) {
          return response;
        }

        return Promise.reject(new Error(`API failure during "${storeName}/apiFetchSubstituteProducts" request.`));
      }).finally(() => {
        this.runningRequests.apiFetchSubstituteProducts = false;
      });
    },
  },
});
