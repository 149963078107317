import uniqueObjectArray from '@/helpers/unique-object-array';
import { CategorySuggestion } from '@/types/category-suggestion';

export interface CategoryGroup {
  code: string;
  name: string;
  categories: CategorySuggestion[];
}

export interface CategoriesGrouped {
  groups: CategoryGroup[];
  remainingCategories: CategorySuggestion[];
}

/**
 * Groups categories by a certain threshold.
 * Returns a list of category groups and a list of the categories which
 * could not be assigned to a group.
 */
export default function groupCategoriesByThreshold(
  categories: CategorySuggestion[],
  groupingThreshold = 3
): CategoriesGrouped {
  const parentCategories = uniqueObjectArray(categories.map(parentCategory => ({
    code: parentCategory.parentCode,
    name: parentCategory.parentName || '',
  })));
  const categoriesGrouped: CategoriesGrouped = {
    groups: [],
    remainingCategories: [],
  };

  parentCategories.forEach((parentCategory) => {
    const filteredCategories = categories.filter(category => category.parentCode === parentCategory.code);

    if (filteredCategories.length >= groupingThreshold) {
      categoriesGrouped.groups.push({
        ...parentCategory,
        categories: filteredCategories,
      });
    } else {
      categoriesGrouped.remainingCategories.push(...filteredCategories);
    }
  });

  return categoriesGrouped;
}
