<template>
  <li :class="b()">
    <c-product-grid-item :product="product" :gtm-list-name="GA_LIST_NAME.ProductSlider()" />
  </li>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { Product } from '@/types/product';
  import cProductGridItem from '@/components/c-product-grid-item.vue';
  import { GA_LIST_NAME } from '@/plugins/google-tag-manager';

  type Setup = {
    GA_LIST_NAME: typeof GA_LIST_NAME;
  }
  // interface Data {}

  /**
   * Implements the "c-product-grid-item" component, which serves as a slider item.
   */
  export default defineComponent({
    name: 'c-slider-item-1',

    components: {
      cProductGridItem,
    },

    props: {
      /**
       * Expects a product.
       */
      product: {
        type: Object as PropType<Product>,
        required: true,
      },
    },
    // emits: {},

    setup(): Setup {
      return {
        GA_LIST_NAME,
      };
    },
    // data(): Data {
    //   return {};
    // },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use 'sass:math';
  @use '../setup/scss/variables';
  @use '../setup/scss/mixins';

  .c-slider-item-1 {
    position: relative;
    flex: 0 0 auto;
    flex-basis: percentage(math.div(1, 1.3));
    padding-inline: variables.$spacing--20;

    @include mixins.media(sm) {
      flex-basis: percentage(math.div(1, 3));
    }

    @include mixins.media(md) {
      flex-basis: percentage(math.div(1, 4));
      padding-inline: variables.$spacing--10;
    }

    @include mixins.media(lg) {
      flex-basis: percentage(math.div(1, 5));
    }

    @include mixins.media(xl) {
      flex-basis: percentage(math.div(1, 6));
    }

    &:not(:last-of-type) {
      border-right: 2px solid variables.$color-grayscale--0;

      @include mixins.media(md) {
        border-right-width: 1px;
      }
    }
  }
</style>
