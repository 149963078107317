import { ContactPerson } from '@/types/contact-person';
import decodeBase64 from '@/helpers/decode-base64';

/**
 * Normalizes the contact person delivered from the Backend (with base64 encoded email address).
 */
export default function normalizeContactPerson(person: ContactPerson): ContactPerson {
  return {
    ...person,
    email: decodeBase64(person.email),
  };
}
