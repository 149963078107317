<template>
  <div :class="b()">
    <!-- eslint-disable-next-line vue/html-self-closing -->
    <sfs-m2m-calculator
      :locale.attr="i18n.global.locale"
      :currency.attr="sessionStore.getCurrencyIsoCode"
    ></sfs-m2m-calculator>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import 'sfs-m2m-calculator';
  import i18n from '@/setup/i18n';
  import useSessionStore from '@/stores/session';

  type Setup = {
    i18n: typeof i18n;
    sessionStore: ReturnType<typeof useSessionStore>;
  }

  // type Data = {}

  /**
   * Integrates the m2m-calculator web-component.
   */
  export default defineComponent({
    name: 'c-m2m-calculator',

    // components: {},

    // props: {},
    // emits: {},

    setup(): Setup {
      return {
        i18n,
        sessionStore: useSessionStore(),
      };
    },
    // data(): Data {},

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  // stylelint-disable selector-class-pattern
  .c-cms-row-container {
    .c-m2m-calculator {
      @include mixins.media(sm) {
        grid-column: span 12;
      }
    }
  }
</style>

