import { defineStore } from 'pinia';
import { RemovableRef, useStorage } from '@vueuse/core';
import { AxiosPromise } from 'axios';
import i18n from '@/setup/i18n';
import useNotificationStore from '@/stores/notification';
import { StorageKey, Store } from '@/setup/globals';
import { Product } from '@/types/product';

const maximumAmountOfProductsToCompare = 20;

interface State {
  productCodes: string[] | RemovableRef<string[]>;
  products: Product[];
  showPrices: boolean;
  runningRequests: {
    apiFetchComparisonProducts: boolean;
  };
}

export interface InitialData {
  data: {
    showPrices: boolean;
  };
}

export type FetchComparisonProductsPayload = Pick<Product, 'code'>[];

const storeName = Store.ProductComparison;

export default defineStore(storeName, {
  state: (): State => {
    const initialData = window.initialData?.[storeName];

    return {
      productCodes: useStorage(StorageKey.ProductComparison, []),
      products: [],
      showPrices: initialData?.data.showPrices || false,
      runningRequests: {
        apiFetchComparisonProducts: false,
      },
    };
  },
  getters: {
    getIsProductInComparison(): (product: Product) => boolean {
      return (product: Product): boolean => this.productCodes.includes(product.code);
    },

    getProducts(): Product[] {
      const { productCodes } = this;

      return this.products.filter(product => productCodes.includes(product.code));
    },
  },
  actions: {
    addOrRemoveProduct(product: Product): void {
      const notificationStore = useNotificationStore();
      const { code } = product;

      if (this.getIsProductInComparison(product)) {
        this.productCodes = this.productCodes.filter(productCode => productCode !== code);

        notificationStore.showNotification({
          type: 'info',
          expire: true,
          message: i18n.global.t('global.productWasRemovedFromComparison', {
            productCode: product.code,
            productName: product.name,
          }),
        });
      } else {
        if (this.productCodes.length >= maximumAmountOfProductsToCompare) {
          notificationStore.showNotification({
            type: 'error',
            expire: true,
            message: i18n.global.t('global.productComparisonMaxAmount', {
              amount: maximumAmountOfProductsToCompare,
            }),
          });

          return;
        }
        this.productCodes.push(code);

        notificationStore.showNotification({
          type: 'success',
          expire: true,
          message: i18n.global.t('global.productWasAddedToComparison', {
            productCode: product.code,
            productName: product.name,
          }),
        });
      }
    },

    addProducts(products: Product[]): void {
      const notificationStore = useNotificationStore();
      const { productCodes } = this;
      const filteredProductCodes = products
        .map(product => product.code)
        .filter(productCode => !productCodes.includes(productCode));
      const previousAmount = productCodes.length;
      const exceedsMaximumAmount = productCodes.length + filteredProductCodes.length > maximumAmountOfProductsToCompare;

      if (exceedsMaximumAmount) {
        notificationStore.showNotification({
          type: 'info',
          expire: true,
          message: i18n.global.t('global.productComparisonMaxAmount', {
            amount: maximumAmountOfProductsToCompare,
          }),
        });
      }

      this.productCodes = [
        ...productCodes,
        ...filteredProductCodes,
      ].slice(0, maximumAmountOfProductsToCompare);

      const amountAdded = this.productCodes.length - previousAmount;

      if (amountAdded) {
        notificationStore.showNotification({
          type: 'success',
          expire: true,
          message: i18n.global.t('global.multipleProductsAddedToComparison', { amount: amountAdded }),
        });
      }
    },

    removeAllProducts(): void {
      this.productCodes = [];
    },

    apiFetchComparisonProducts(payload: FetchComparisonProductsPayload): AxiosPromise {
      this.runningRequests.apiFetchComparisonProducts = true;

      return this.$api.post(this.$api.getApiUrl('productComparison'), payload).then((response) => {
        const { results } = response.data?.data || {};

        if (Array.isArray(results)) {
          this.products = results;

          return response;
        }

        return Promise.reject(new Error(`API failure during "${storeName}/apiFetchComparisonProducts" request.`));
      }).finally(() => {
        this.runningRequests.apiFetchComparisonProducts = false;
      });
    },
  },
});
