<template>
  <div :class="b({ columnWidth: `col-sm-${width}` })">
    <component
      v-if="headline"
      :is="headlineTag"
      :class="b('title')"
    >
      {{ headline }}
    </component>
    <c-consent-gatekeeper :consent-group="ConsentGroup.Marketing">
      <e-video
        :source="VideoSource.Youtube"
        :video-id="videoId"
        :enable-youtube-js-api="enableJsApi"
        allow-fullscreen
      />
    </c-consent-gatekeeper>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { HeadlineTag } from '@/setup/globals';
  import { ColumnWidth } from '@/components/c-row-container.vue';
  import eVideo, { VideoSource } from '@/elements/e-video.vue';
  import cConsentGatekeeper from '@/plugins/consent/c-consent-gatekeeper.vue';
  import { ConsentGroup } from '@/plugins/consent';

  export interface YoutubeVideoProps {
    source: VideoSource.Youtube;
    width: ColumnWidth;
    videoId: string;
  }

  interface Setup {
    ConsentGroup: typeof ConsentGroup;
    VideoSource: typeof VideoSource;
  }

  // interface Data {}

  /**
   * Youtube specific video component which is rendered inside the c-row-container.
   */
  export default defineComponent({
    name: 'c-youtube-video',

    components: {
      cConsentGatekeeper,
      eVideo,
    },

    props: {
      /**
       * Accepts YouTube video ID.
       */
      videoId: {
        type: String,
        default: undefined,
      },

      /**
       * Allows a headline to be passed.
       */
      headline: {
        type: String,
        default: undefined,
      },

      /**
       * Allows defining the tag for the headline.
       */
      headlineTag: {
        type: String,
        default: HeadlineTag.H2,
      },

      /**
       * Allows to set the column width.
       */
      width: {
        type: Number as PropType<ColumnWidth>,
        default: 12,
      },

      /**
       * Allows defining if the JS API is enabled via adding `enablejsapi=1`.
       */
      enableJsApi: {
        type: Boolean,
        default: true,
      },
    },
    // emits: {},

    setup(): Setup {
      return {
        ConsentGroup,
        VideoSource,
      };
    },
    // data(): Data {
    //   return {};
    // },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-youtube-video {
    $this: &;

    &--column-width-col-sm-4 {
      margin-bottom: variables.$spacing--30;

      @include mixins.media(sm) {
        display: grid;
        grid-column: span 6;
        grid-template-rows: auto 1fr;
      }

      @include mixins.media(md) {
        grid-column: span 4;
      }
    }

    &--column-width-col-sm-6 {
      @include mixins.media(sm) {
        grid-column: span 6;
      }
    }

    &--column-width-col-sm-12 {
      @include mixins.media(sm) {
        grid-column: span 12;
      }
    }
  }
</style>
