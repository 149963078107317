<template>
  <!-- ID is needed for outside click handling in `c-search-suggestions` -->
  <e-search v-model="searchSuggestionStore.query"
            id="c-product-search"
            :autofocus="isHomepage"
            variant="global-search"
            @enter="onSearchInput"
  />
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import axios from 'axios';
  import useSearchSuggestionStore from '@/stores/search-suggestion';
  import searchResultPageUrl from '@/helpers/search-result-page-url';
  import eSearch from '@/elements/e-search.vue';

  interface Setup {
    searchSuggestionStore: ReturnType<typeof useSearchSuggestionStore>;
    inputDebounceTime: number;
    isHomepage: boolean;
  }

  interface Data {
    inputDebounceTimeoutId: ReturnType<typeof setTimeout> | null;
  }

  const HOMEPAGE_IDENTIFIER_CLASS = 'page-homepage';

  /**
   * This component is a search input field designed to execute search requests.
   */
  export default defineComponent({
    name: 'c-product-search',

    components: {
      eSearch,
    },

    // props: {},
    // emits: {},

    setup(): Setup {
      return {
        searchSuggestionStore: useSearchSuggestionStore(),
        inputDebounceTime: 300,
        isHomepage: document.body.classList.contains(HOMEPAGE_IDENTIFIER_CLASS),
      };
    },

    data(): Data {
      return {
        inputDebounceTimeoutId: null,
      };
    },

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // computed: {},

    watch: {
      /**
       * Observers query input and submits search with a timeout.
       */
      'searchSuggestionStore.query': function(query: string): void {
        if (query.length >= this.searchSuggestionStore.minimumCharacters) {
          if (this.inputDebounceTimeoutId) {
            clearTimeout(this.inputDebounceTimeoutId);
          }

          this.inputDebounceTimeoutId = setTimeout(() => {
            this.searchSuggestionStore.apiGetSearchSuggestions(query).then(() => {
              this.$gtm.pushSearch(query);
            }).catch((error) => {
              if (axios.isCancel(error)) {
                return; // Do nothing, we don't want to show an error message in this case.
              }

              console.error(error); // eslint-disable-line no-console
            });
          }, this.inputDebounceTime);
        } else {
          this.searchSuggestionStore.result = null;
        }
      },
    },

    methods: {
      /**
       * Executes search requests and redirects to the search URL.
       */
      onSearchInput(): void {
        const { query } = this.searchSuggestionStore;

        if (query) {
          window.location.href = searchResultPageUrl(query);
        }
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';
  @use '@/setup/scss/mixins';

  .c-product-search {
    // Styles
  }
</style>
