/* eslint-disable vue/max-len */
import { ColumnWidth } from '@/components/c-row-container.vue';
import mapCmsColumnWidth from '@/helpers/map-column-width';
import { CmsNewsletterRegistration, FormNamespace } from '@/types/cms-newsletter-registration';

export interface NewsletterRegistrationProps {
  newsletterName: string;
  namespace: FormNamespace;
  width: ColumnWidth;
}

/**
 * Maps the given CMS data to a NewsletterRegistrationProps compatible object.
 */
export default function mapNewsletterRegistrationProps(newsletterRegistration: CmsNewsletterRegistration): NewsletterRegistrationProps {
    const mappedNewsletterRegistration: NewsletterRegistrationProps = {
        newsletterName: newsletterRegistration.newsletterName,
        namespace: newsletterRegistration.namespace,
        width: mapCmsColumnWidth(newsletterRegistration.width),
    };

   return mappedNewsletterRegistration;
}

