<template>
  <table :class="b()">
    <thead>
      <tr>
        <th>&nbsp;</th>
        <th>
          {{ priceLabel }}
        </th>
        <th v-if="showCustomerPrices">
          {{ $t('global.price.net') }}
        </th>
      </tr>
      <template v-if="mappedPrices?.length">
        <tr v-for="price in mappedPrices" :key="price.volumePrice.minQuantity">
          <td>
            {{ price.volumePrice.minQuantity }} {{ price.volumePrice.baseUnit }}
          </td>
          <td>
            <div v-price.prefixCurrency="price.volumePrice.value"
                 :class="b('price', { hasSpecialPrice: !!price.specialPrice })"
            ></div>
            <div v-if="price.specialPrice" :class="b('price', { specialPrice: true })">
              <e-icon :class="b('discount-icon')"
                      icon="i-discount"
                      size="18"
              />
              <span v-price.prefixCurrency="price.specialPrice.value"></span>
            </div>
          </td>
          <td v-if="showCustomerPrices">
            <span v-if="price.customerPrice" v-price.prefixCurrency="price.customerPrice.value"></span>
          </td>
        </tr>
      </template>
    </thead>
  </table>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import useCustomerPriceStore from '@/stores/customer-price';
  import useSessionStore from '@/stores/session';
  import eIcon from '@/elements/e-icon.vue';
  import { Product } from '@/types/product';
  import { Price } from '@/types/price';

  type Setup = {
    customerPriceStore: ReturnType<typeof useCustomerPriceStore>;
    sessionStore: ReturnType<typeof useSessionStore>;
  }

  // type Data = {}

  type MappedPrice = {
    volumePrice: Price;
    specialPrice?: Price;
    customerPrice?: Price;
  }

  /**
   * Renders the volume prices of a product.
   */
  export default defineComponent({
    name: 'c-volume-prices',

    components: { eIcon },

    props: {
      /**
       * Expects a product object to be passed.
       */
      product: {
        type: Object as PropType<Product>,
        required: true,
      },
    },
    // emits: {},

    setup(): Setup {
      return {
        customerPriceStore: useCustomerPriceStore(),
        sessionStore: useSessionStore(),
      };
    },
    // data(): Data {},

    computed: {
      mappedPrices(): MappedPrice[] {
        const { product } = this;

        return product.volumePrices?.filter(price => price?.minQuantity && price.minQuantity > 0).map(volumePrice => ({
          volumePrice,
          specialPrice: product.specialVolumePrices
            ?.find(specialVolumePrice => specialVolumePrice.minQuantity === volumePrice.minQuantity),
          customerPrice: this.customerPriceStore.getCustomerPricesByProduct(product)
            ?.find(customerPrice => customerPrice.minQuantity === volumePrice.minQuantity),
        })) || [];
      },

      priceLabel(): string {
        return import.meta.env.VITE_ENABLE_RECOMMENDED_RETAIL_PRICE === '1'
          ? this.$t('global.price.recommendedRetailPrice')
          : this.$t('global.price.gross');
      },

      hasCustomerPrices(): boolean {
        return this.mappedPrices.some(mappedPrice => !!mappedPrice.customerPrice);
      },

      showCustomerPrices(): boolean {
        return this.hasCustomerPrices && this.sessionStore.flags.showCustomerSpecificPrices;
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-volume-prices {
    width: 100%;

    th {
      @include mixins.heading-h4();

      margin-bottom: 0;
      padding-bottom: variables.$spacing--10;
    }

    th,
    td {
      padding: variables.$spacing--5;
      text-align: right;
      white-space: nowrap;
      vertical-align: bottom;

      &:first-of-type {
        padding-left: 0;
        text-align: left;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }

    &__price--has-special-price {
      @include mixins.font(variables.$font-size--14);

      text-decoration: line-through;
    }

    &__price--special-price {
      display: inline-flex;
      align-items: center;
      column-gap: variables.$spacing--5;
    }

    &__discount-icon {
      display: inline-block;
      margin-left: variables.$spacing--5;
      color: variables.$color-primary--1;
    }
  }
</style>
