<template>
  <li :class="b()">
    <e-picture v-bind="image"
               :sizes="sizes"
    />
  </li>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import ePicture from '@/elements/e-picture.vue';
  import { ImageSrcset } from '@/types/image';
  import { ImageSizes } from '@/types/sizes';

  interface Setup {
    sizes: ImageSizes;
  }
  // interface Data {}

  /**
   * Renders a thumbnail slide.
   */
  export default defineComponent({
    name: 'c-slider-item-3',
    components: {
      ePicture,
    },

    // components: {},

    props: {
      /**
       * Expects an ImageSrcset.
       */
      image: {
        type: Object as PropType<ImageSrcset>,
        required: true,
      },
    },
    // emits: {},

    setup(): Setup {
      return {
        sizes: {
          xxs: 46,
          fallback: 46,
        },
      };
    },
    // data(): Data {
    //   return {};
    // },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use 'sass:math';
  @use '@/setup/scss/variables';
  @use '@/setup/scss/mixins';

  .c-slider-item-3 {
    position: relative;
    flex: 0 0 percentage(math.div(1, 6.5));
    border: 1px solid variables.$color-grayscale--400;
    cursor: pointer;

    &.is-active {
      border-width: 2px;
      border-color: variables.$color-primary--1;
    }
  }
</style>
