import { Plugin, defineAsyncComponent } from 'vue';

import { Layout } from '@/setup/globals';

// General
import cHeader from '@/components/c-header.vue';
import cFooter from '@/components/c-footer.vue';
import cBreadcrumb from '@/components/c-breadcrumb.vue';

import lProductDetail from '@/layouts/l-product-detail.vue';
import lSearchResult from '@/layouts/l-search-result.vue';
import lCategoryResult from '@/layouts/l-category-result.vue';

// CMS
import cCmsHeadline from '@/components/c-cms-headline.vue';
import cCmsRowContainer from '@/components/c-cms-row-container.vue';
import cCmsAccordion from '@/components/c-cms-accordion.vue';
import cCmsContactPerson from '@/components/c-cms-contact-person.vue';
import cCmsBannerCarousel from '@/components/c-cms-banner-carousel.vue';
import cCmsBanner from '@/components/c-cms-banner.vue';
import cCcmsCookiebotDeclaration from '@/components/c-cms-cookiebot-declaration.vue';
import cCmsM2mCalculator from '@/components/c-cms-m2m-calculator.vue';

const plugin: Plugin = {
  install(app) {
    // Core components
    const components = [
      // General
      cHeader,
      cFooter,
      cBreadcrumb,

      lProductDetail,
      lSearchResult,
      lCategoryResult,

      // CMS
      cCmsHeadline,
      cCmsRowContainer,
      cCmsAccordion,
      cCmsContactPerson,
      cCmsBannerCarousel,
      cCmsBanner,
      cCcmsCookiebotDeclaration,
      cCmsM2mCalculator,
    ];

    components.forEach((component) => {
      app.component(component.name as string, component);
    });

    // Layouts (loaded asynchronously)
    app.component(Layout.Cart, defineAsyncComponent(() => import(`@/layouts/${Layout.Cart}.vue`)));
    app.component(Layout.CheckoutDelivery, defineAsyncComponent(() => import(`@/layouts/${Layout.CheckoutDelivery}.vue`)));
    app.component(Layout.CheckoutPayment, defineAsyncComponent(() => import(`@/layouts/${Layout.CheckoutPayment}.vue`)));
    app.component(Layout.CheckoutReview, defineAsyncComponent(() => import(`@/layouts/${Layout.CheckoutReview}.vue`)));
    app.component(Layout.Registration, defineAsyncComponent(() => import(`@/layouts/${Layout.Registration}.vue`)));
    app.component(
      Layout.CheckoutConfirmation,
      defineAsyncComponent(() => import(`@/layouts/${Layout.CheckoutConfirmation}.vue`))
    );
    app.component(Layout.CheckoutLogin, defineAsyncComponent(() => import(`@/layouts/${Layout.CheckoutLogin}.vue`)));
    app.component(Layout.WishlistOverview, defineAsyncComponent(() => import(`@/layouts/${Layout.WishlistOverview}.vue`)));
    app.component(Layout.Favourites, defineAsyncComponent(() => import(`@/layouts/${Layout.Favourites}.vue`)));
    app.component(Layout.UserManagement, defineAsyncComponent(() => import(`@/layouts/${Layout.UserManagement}.vue`)));
    app.component(Layout.Profile, defineAsyncComponent(() => import(`@/layouts/${Layout.Profile}.vue`)));
    app.component(Layout.OrderHistory, defineAsyncComponent(() => import(`@/layouts/${Layout.OrderHistory}.vue`)));
    app.component(
      Layout.CommissionManagement,
      defineAsyncComponent(() => import(`@/layouts/${Layout.CommissionManagement}.vue`))
    );
    app.component(Layout.AccountNavigation, defineAsyncComponent(() => import(`@/layouts/${Layout.AccountNavigation}.vue`)));
    app.component(
      Layout.CollectiveOrderOverview,
      defineAsyncComponent(() => import(`@/layouts/${Layout.CollectiveOrderOverview}.vue`))
    );
    app.component(Layout.CustomerCard, defineAsyncComponent(() => import(`@/layouts/${Layout.CustomerCard}.vue`)));
    app.component(Layout.ProductComparison, defineAsyncComponent(() => import(`@/layouts/${Layout.ProductComparison}.vue`)));
    app.component(
      Layout.CustomerAssortment,
      defineAsyncComponent(() => import(`@/layouts/${Layout.CustomerAssortment}.vue`))
    );
    app.component(Layout.ContactList, defineAsyncComponent(() => import(`@/layouts/${Layout.ContactList}.vue`)));
    app.component(Layout.LocationFinder, defineAsyncComponent(() => import(`@/layouts/${Layout.LocationFinder}.vue`)));
    app.component(Layout.LocationDetail, defineAsyncComponent(() => import(`@/layouts/${Layout.LocationDetail}.vue`)));

    app.component(Layout.Cpq, defineAsyncComponent(() => import(`@/layouts/${Layout.Cpq}.vue`)));

    // CMS components (loaded asynchronously)
    app.component(
      'c-cms-product-slider-tabs',
      defineAsyncComponent(() => import('@/components/c-cms-product-slider-tabs.vue'))
    );
    app.component('c-cms-accordion-group', defineAsyncComponent(() => import('@/components/c-cms-accordion-group.vue')));
  },
};

export default plugin;
