<template>
  <div :class="b()">
    <e-label :name="facet.name">
      <ul :class="b('list')">
        <template v-for="value in facet.values" :key="value.code">
          <li v-if="value.icon && Object.keys(iconMap).includes(value.icon)" :class="b('item')">
            <button v-tooltip:bottom.start.mouseover="value.name"
                    :class="b('button', { selected: !!value.selected })"
                    :disabled="disabled"
                    type="button"
                    @click="onClick(value)"
            >
              <span class="invisible">
                {{ value.name }}
              </span>
              <e-icon :icon="iconMap[value.icon]" size="33" />
            </button>
          </li>
        </template>
      </ul>
    </e-label>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import tooltipDirective from '@/plugins/tooltip/directives/directive';
  import eLabel from '@/elements/e-label.vue';
  import eIcon from '@/elements/e-icon.vue';
  import { Facet, FacetValue } from '@/types/facet';

  export enum FacetIcon {
    Flachkopf = 'FLACHKOPF',
    Flanschkopf = 'FLANSCHKOPF',
    Kleinkopf = 'KLEINKOPF',
    Linsensenkkopf = 'LINSENSENKKOPF',
    OhneKopf = 'OHNE_KOPF',
    Rundkopf = 'RUNDKOPF',
    Sechskantkopf = 'SECHSKANTKOPF',
    Senkkopf = 'SENKKOPF',
    SpezialkopfSdk = 'SPEZIALKOPF_SDK',
    Tellerkopf = 'TELLERKOPF',
    Trompetenkopf = 'TROMPETENKOPF',
    Zylinderkopf = 'ZYLINDERKOPF',
    Innenachtkant = 'INNENACHTKANT',
    Innensechskant = 'INNENSECHSKANT',
    Innensechsrund = 'INNENSECHSRUND',
    Innenvierkant = 'INNENVIERKANT',
    Kreuzschlitz = 'KREUZSCHLITZ',
    Schlitz = 'SCHLITZ',
    Sechskant = 'SECHSKANT',
    SfsIrius = 'SFS_IRIUS',
    Fluegel = 'FLUEGEL',
    Vierkant = 'VIERKANT',
    Flachrundkopf = 'FLACHRUNDKOPF',
    DiverseKopfFormen = 'DIVERSE_KOPF_FORMEN',
    AnbriebDiverse = 'ANTRIEB_DIVERSE',
    AntriebOhne = 'ANTRIEB_OHNE',
  }

  type FacetIconMap = {
    [key in FacetIcon]: string;
  }

  const iconMap: FacetIconMap = {
    [FacetIcon.Flachkopf]: 'i-screw-flachkopf',
    [FacetIcon.Flanschkopf]: 'i-screw-flanschkopf',
    [FacetIcon.Kleinkopf]: 'i-screw-fallback',
    [FacetIcon.Linsensenkkopf]: 'i-screw-linsensenkkopf',
    [FacetIcon.OhneKopf]: 'i-screw-ohne-kopf',
    [FacetIcon.Rundkopf]: 'i-screw-rundkopf',
    [FacetIcon.Sechskantkopf]: 'i-screw-sechskantkopf',
    [FacetIcon.Senkkopf]: 'i-screw-senkkopf',
    [FacetIcon.SpezialkopfSdk]: 'i-screw-fallback',
    [FacetIcon.Tellerkopf]: 'i-screw-tellerkopf',
    [FacetIcon.Trompetenkopf]: 'i-screw-trompetenkopf',
    [FacetIcon.Zylinderkopf]: 'i-screw-zylinderkopf',
    [FacetIcon.Innenachtkant]: 'i-screw-innenachtkant',
    [FacetIcon.Innensechskant]: 'i-screw-innensechskant',
    [FacetIcon.Innensechsrund]: 'i-screw-innensechskantrund',
    [FacetIcon.Innenvierkant]: 'i-screw-innenvierkant',
    [FacetIcon.Kreuzschlitz]: 'i-screw-kreuzschlitz',
    [FacetIcon.Schlitz]: 'i-screw-schlitz',
    [FacetIcon.Sechskant]: 'i-screw-sechskant',
    [FacetIcon.SfsIrius]: 'i-screw-sfsirius',
    [FacetIcon.Fluegel]: 'i-screw-fluegel',
    [FacetIcon.Vierkant]: 'i-screw-vierkant',
    [FacetIcon.Flachrundkopf]: 'i-screw-flachrundkopf',
    [FacetIcon.DiverseKopfFormen]: 'i-screw-diverse-kopf-formen',
    [FacetIcon.AnbriebDiverse]: 'i-screw-antrieb-diverse',
    [FacetIcon.AntriebOhne]: 'i-screw-antrieb-ohne',
  };

  interface Setup {
    iconMap: FacetIconMap;
  }
  // interface Data {}

  /**
   * Renders an icon filter facet.
   */
  export default defineComponent({
    name: 'c-facet-icon',

    components: {
      eIcon,
      eLabel,
    },

    directives: {
      tooltip: tooltipDirective,
    },

    props: {
      /**
       * Expects a facet object.
       */
      facet: {
        type: Object as PropType<Facet>,
        required: true,
      },

      /**
       * Allows passing disabled state.
       */
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    emits: {
      update: (payload: string) => typeof payload === 'string',
    },

    setup(): Setup {
      return {
        iconMap,
      };
    },
    // data(): Data {
    //   return {};
    // },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      /**
       * Handles update.
       */
      onClick(value: FacetValue): void {
        const { apiUrl, resetApiUrl } = value.query;

        this.$emit('update', value.selected ? resetApiUrl : apiUrl);
        this.$gtm.pushSelectFilter(this.facet, value.code);
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';

  .c-facet-icon {
    &__list {
      display: flex;
      gap: variables.$spacing--10;
      flex-wrap: wrap;
      margin-top: variables.$spacing--10;
    }

    &__button {
      border: 1px solid variables.$color-grayscale--0;
      cursor: pointer;

      &:hover,
      &:focus,
      &--selected {
        border-color: variables.$color-primary--1;
        color: variables.$color-primary--1;
      }

      &:disabled {
        border-color: variables.$color-grayscale--400;
        cursor: default;
        color: variables.$color-grayscale--400;
      }
    }
  }
</style>
